import React, {
  useMemo,
} from 'react'

import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from '@redux/hooks'
import { getSelectedLanguage } from '@redux/modules/customer/customer.selectors'

import DateRangeSliderComponent from '@base/dates/DateRangeSlider'
import DateRangePickerComponent from '@base/dates/DateRangePicker'
import InsightsFiltersLayoutComponent from '@components/insights/InsightsFiltersLayout'

import { foldsToOptions } from '@utils/monitor.utils'
import { requestBaselineComparisonGridStateChangeAction } from '@redux/modules/monitor/monitor.actions'
import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'

import {
  getBaselineComparisonTable,
  getBaselineComparisonAvailableForecastHorizons,
  isFetchingBaselineComparison,
} from '@redux/modules/monitor/monitor.selectors'

const BaselineComparisonFiltersContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const baselineComparisonTable = useSelector(getBaselineComparisonTable)
  const isFetchingBaselineComparisonFlag = useSelector(isFetchingBaselineComparison)
  const baselineComparisonAvailableForecastHorizons = useSelector(getBaselineComparisonAvailableForecastHorizons)
  const userLanguage = useSelector(getSelectedLanguage)

  const {
    gridState,
  } = baselineComparisonTable

  const timeWindow = useMemo(() => (gridState && gridState.timeWindow), [gridState])
  const liveForecastHorizonOffset = useMemo(() => (gridState && gridState.liveForecastHorizonOffset), [gridState])
  const liveForecastHorizonOffsetOptions = useMemo(() => {
    return foldsToOptions(intl, baselineComparisonAvailableForecastHorizons)
  }, [intl, baselineComparisonAvailableForecastHorizons])

  const handleDatesChange = (value: Common.DatePickerValue) => {
    dispatch(
      requestBaselineComparisonGridStateChangeAction({
        timeWindow: value,
      }),
    )
  }

  const handleViewResultAsOfChange = (value: number | string) => {
    dispatch(
      requestBaselineComparisonGridStateChangeAction({
        liveForecastHorizonOffset: Number(value),
      }),
    )
  }

  const leftSideSection = (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      gap={2}
    >
      <DateRangePickerComponent
        overline={intl.formatMessage({ id: 'insights.timeFrame' })}
        onChange={handleDatesChange}
        value={timeWindow}
        name='timeWindow'
        disabled={!gridState}
        userLanguage={userLanguage}
        hiddenShortcuts={[
          DATE_PICKER_SHORTCUT.FROM_TODAY,
          DATE_PICKER_SHORTCUT.FROM_LAST_WEEK,
          DATE_PICKER_SHORTCUT.FROM_THIS_MONTH,
          DATE_PICKER_SHORTCUT.FROM_THIS_YEAR,
        ]}
      />

      <DateRangeSliderComponent
        overline={intl.formatMessage({ id: 'insights.viewResultAsOf' })}
        onChange={handleViewResultAsOfChange}
        value={liveForecastHorizonOffset!}
        name='viewResultAsOf'
        loading={isFetchingBaselineComparisonFlag}
        disabled={!gridState}
        options={liveForecastHorizonOffsetOptions}
        reversed={true}
      />
    </Box>
  )
  return (
    <InsightsFiltersLayoutComponent
      dataTestId={BaselineComparisonFiltersContainer.name}
      leftSideSection={leftSideSection}
    />
  )
}

export default BaselineComparisonFiltersContainer
