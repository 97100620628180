import React, { useMemo } from 'react'
import get from 'lodash.get'
import { useIntl } from 'react-intl'
import { TooltipProps } from 'recharts'
import { formatDateForTooltip } from '@utils/moment.utils'
import { getEventDetailsAtDatapoint } from '@utils/events.utils'
import { defaultInsightsTooltipValueFormatter } from '@utils/insights.utils'
import { useTheme } from '@mui/material'
import { INSIHGTS_DEFAULT_LAST_YEAR_LINE_COLOR } from '@constants/insights.constants'

import ChartTooltipComponent from '@components/charts/tooltips/ChartTooltip'

export interface InsightsChartTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
  /**
   * Flag to indicate if the tooltip is active
   */
  active?: boolean
  /**
   * Flag to indicate if the promotion days should be shown
   */
  eventsVisibility?: boolean
  /**
   * Flag to indicate if the last year value should be shown
   */
  lastYearVisibility?: boolean
  /**
   * Name of the target
   */
  targetName?: string
  /**
   * Key for the target payload
   */
  targetPayloadKey: string
  /**
   * Prefix for the prediction key
   */
  predictionPayloadKey: string
  /**
   * Prefix for the absolute deviation key
   */
  absDeviationPayloadKey?: string
  /**
   * Key for the last year target payload
   */
  lastYearTargetPayloadKey?: string
  /**
   * Key for the baseline payload
   */
  baselinePayloadKey?: string
  /**
   * Data to be displayed in the tooltip
   */
  payload?: Insights.BaseChartDatasetItem[]
  /**
   * Flag to show promotion days
   */
  eventsToExclude?: string[]
}

const InsightsChartTooltipComponent: React.FC<InsightsChartTooltipProps> = ({
  active, payload,
  eventsToExclude = [],
  targetName,
  eventsVisibility = true,
  lastYearVisibility = true,
  targetPayloadKey = '',
  lastYearTargetPayloadKey = '',
  predictionPayloadKey = '',
  absDeviationPayloadKey = '',
  baselinePayloadKey = '',
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const data: Insights.BaseChartDatasetItem = get(payload, '[0].payload', {})

  const {
    formattedEvents,
    hasEvents,
    label: promotionLabel,
  } = getEventDetailsAtDatapoint({
    data,
    eventsVisibility,
    eventsToExclude,
    intl,
  })

  const rows: Common.TooltipRow<Insights.BaseChartDatasetItem>[] = useMemo(() => {
    const listOfRows: Common.TooltipRow<Insights.BaseChartDatasetItem>[] = []

    listOfRows.push({
      key: 'date',
      groupId: 'primary',
      label: intl.formatMessage({ id: 'insights.chart.x.title' }),
      valueFormatter: (value) => formatDateForTooltip(intl, value),
      legendColor: theme.palette.new.black,
    })

    if (hasEvents) {
      listOfRows.push({
        key: 'events',
        groupId: 'primary',
        label: promotionLabel,
        valueFormatter: () => formattedEvents,
        sx: {
          '& td': {
            verticalAlign: 'top',
          },
        },
      })
    }

    if (lastYearVisibility && lastYearTargetPayloadKey) {
      listOfRows.push({
        key: lastYearTargetPayloadKey,
        groupId: baselinePayloadKey ? 'middle' : undefined,
        label: intl.formatMessage({ id: 'insights.chart.lastYearResults' }, { name: targetName }),
        valueFormatter: (value) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true }),
        legendColor: INSIHGTS_DEFAULT_LAST_YEAR_LINE_COLOR,
      })
    }

    listOfRows.push({
      key: targetPayloadKey,
      groupId: baselinePayloadKey ? 'middle' : undefined,
      label: intl.formatMessage({ id: 'insights.chart.actualResults' }, { name: targetName }),
      valueFormatter: (value: any, dataPoint: Insights.BaseChartDatasetItem) => {
        const finalValue = dataPoint.virtualTarget ? null : value

        return defaultInsightsTooltipValueFormatter(intl, finalValue, { showIntervals: true })
      },
      legendColor: theme.palette.new.black,
    })

    if (baselinePayloadKey) {
      listOfRows.push({
        key: baselinePayloadKey,
        groupId: 'bottom',
        label: intl.formatMessage({ id: 'insights.chart.baselineResults' }, { name: targetName }),
        valueFormatter: (value) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true }),
        legendColor: theme.palette.new.versatile_violet,

        secondaryValueColor: 'neutral',
        secondaryValueFormatter: (value) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: false, showAbs: true }),
        secondaryValueGetter: (d: Insights.BaseChartDatasetItem) => {
          const targetValue = d[targetPayloadKey]
          const baselineValue = d[baselinePayloadKey]

          if (targetValue === null || baselineValue === null) {
            return null
          }

          return Math.round(baselineValue) - Math.round(targetValue)
        },
      })
    }

    listOfRows.push({
      key: predictionPayloadKey,
      groupId: baselinePayloadKey ? 'bottom' : undefined,
      label: intl.formatMessage({
        id: baselinePayloadKey ? 'insights.chart.paretosResults' : 'insights.chart.predictionResults',
      }, {
        name: targetName,
      }),
      valueFormatter: (value) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true }),
      legendColor: theme.palette.new.pink,

      ...(baselinePayloadKey ? {
        secondaryValueColor: 'neutral',
        secondaryValueFormatter: (value) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: false, showAbs: true }),
        secondaryValueGetter: (d: Insights.BaseChartDatasetItem) => {
          const predictionValue = d[predictionPayloadKey]
          const targetValue = d[targetPayloadKey]

          if (predictionValue === null || targetValue === null) {
            return null
          }

          return Math.round(predictionValue) - Math.round(targetValue)
        },
      } : {}),
    })

    if (absDeviationPayloadKey) {
      listOfRows.push({
        groupId: 'bottom',
        label: intl.formatMessage({ id: 'insights.chart.tooltip.absDeviation' }),
        key: absDeviationPayloadKey,
        valueVariant: 'contained',
        legendColor: 'neutral',
        valueFormatter: (value) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: false }),
      })
    }

    if (baselinePayloadKey) {
      listOfRows.push({
        groupId: 'bottom',
        label: intl.formatMessage({ id: 'insights.chart.tooltip.fullComparison' }),

        secondaryValueColor: (d) => {
          const targetValue = d[targetPayloadKey]
          const predictionValue = d[predictionPayloadKey]
          const baselineValue = d[baselinePayloadKey]

          if (predictionValue === null || baselineValue === null || targetValue === null) {
            return 'neutral'
          }

          const roundedPredictionValue = Math.round(predictionValue)
          const roundedBaselineValue = Math.round(baselineValue)
          const roundedTargetValue = Math.round(targetValue)

          const predictionMinusTarget = Math.abs(roundedPredictionValue - roundedTargetValue)
          const baselineMinusTarget = Math.abs(roundedBaselineValue - roundedTargetValue)

          if (predictionMinusTarget < baselineMinusTarget) {
            return 'positive'
          }

          if (predictionMinusTarget > baselineMinusTarget) {
            return 'negative'
          }

          return 'neutral'
        },
        secondaryValueFormatter: (value) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: false, showAbs: true }),
        secondaryValueGetter: (d: Insights.BaseChartDatasetItem) => {
          const targetValue = d[targetPayloadKey]
          const predictionValue = d[predictionPayloadKey]
          const baselineValue = d[baselinePayloadKey]

          if (predictionValue === null || baselineValue === null || targetValue === null) {
            return null
          }

          const roundedPredictionValue = Math.round(predictionValue)
          const roundedBaselineValue = Math.round(baselineValue)
          const roundedTargetValue = Math.round(targetValue)

          return Math.abs(Math.abs(roundedPredictionValue - roundedTargetValue) - Math.abs(roundedBaselineValue - roundedTargetValue))
        },
      })
    }

    return listOfRows
  }, [
    formattedEvents,
    targetPayloadKey,
    absDeviationPayloadKey,
    lastYearTargetPayloadKey,
    predictionPayloadKey,
    lastYearVisibility,
    baselinePayloadKey,
    targetName,
    hasEvents,
    intl,
    promotionLabel,
    theme,
  ])

  return (
    <ChartTooltipComponent
      active={active}
      payload={payload}
      rows={rows}
    />
  )
}

export default InsightsChartTooltipComponent
