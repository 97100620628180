import React, { SyntheticEvent, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { SxProps, Theme } from '@mui/material'

import { copyToClipboard } from '@utils/common.utils'

import CopyIcon from '@icons/copy.icon'
import InlineButtonComponent from '@base/buttons/InlineButton'
import TooltipComponent from '@base/tooltips/Tooltip/Tooltip.component'

export interface CopyButtonComponentProps {
  /**
   * Name of the button
   */
  name?: string,
  /**
   * Value to copy
   */
  value: string,
  /**
   * Label to display
   */
  label?: string,
  /**
   * Tooltip to display
   */
  tooltip?: string,
  /**
   * If true, only icon will be displayed
   */
  iconOnly?: boolean,
  /**
   * If true, icon placement will be reversed
   */
  reversed?: boolean,
  /**
   * Max width value before text is ellipsized
   */
  maxValueWidth?: string,
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>,
}

export const CopyButtonComponent: React.FC<CopyButtonComponentProps> = ({
  name = 'copyButton',
  value,
  label,
  reversed,
  tooltip,
  maxValueWidth = '150px',
  iconOnly,
  sx,
}) => {
  const intl = useIntl()

  const onClick = useCallback((e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    copyToClipboard({ e, text: value, intl })
  }, [value, intl])

  const button = (
    <InlineButtonComponent
      name={name}
      label={label || value}
      iconOnly={iconOnly}
      onClick={onClick}
      StartIconComponent={reversed ? undefined : CopyIcon}
      EndIconComponent={reversed ? CopyIcon : undefined}
      typographyProps={{
        maxWidth: maxValueWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      sx={sx}
    />
  )

  if (tooltip) {
    return (
      <TooltipComponent
        title={tooltip}
        placement='bottom'
        arrow={true}
        noMaxWidth={true}
      >
        {button}
      </TooltipComponent>
    )
  }

  return button
}

export default CopyButtonComponent
