import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box, Typography, useTheme } from '@mui/material'
import sortBy from 'lodash.sortby'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
  SidePanelButtonContainerComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import {
  requestGroupingAction,
  updateGroupingAction,
  removeGroupingAction,
} from '@redux/modules/parameters/parameters.actions'

import {
  CATEGORIES_TO_LABEL_MAP,
  SOURCE_TYPES,
  SOURCE_TYPES_CATEGORIES,
  SOURCE_TYPES_OPTIONS,
} from '@constants/flow.constants'

import DeleteIcon from '@icons/delete.icon'
import ConnectorIcon from '@icons/connector.icon'
import TextFieldComponent from '@base/forms/TextField'
import AutocompleteChipsFieldComponent from '@base/autocomplete/AutocompleteChipsField'
import HelpButtonComponent from '@base/buttons/HelpButton'
import ButtonComponent from '@base/buttons/Button'
import ReactFlowParameterBlockComponent, { PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import ParameterSourceSelectorComponent from '@components/connect-view/parameters/ParameterSourceSelector'

import { getTextBackgroundFillStyles } from '@utils/ui.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { getCompanyConnectionsBySource } from '@redux/modules/hermes/hermes.selectors'
import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'
import { enrichSourceTypeOptions, SourceTypeOptionExtended } from '@utils/flow.utils'
import { CONNECT_GROUPING_MODAL_NAME } from '@constants/modals.constants'
import { getUseCaseFreezeStatus } from '@redux/modules/use-case/use-case.selectors'
import { getGroupingItem, isFetchingGrouping, isSubmittingGrouping } from '@redux/modules/parameters/parameters.selectors'

import connectGroupingValidations from './ConnectGroupingModal.validations'

const ConnectGroupingModalContainer: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()

  const isSubmitting = useSelector(isSubmittingGrouping)
  const isFetching = useSelector(isFetchingGrouping)
  const groupingItem = useSelector(getGroupingItem)
  const isUseCaseFrozen = useSelector(getUseCaseFreezeStatus)
  const connectionsList = useSelector(getCompanyConnectionsBySource)
  const filesIdentifiers = useSelector(getUseCaseFileIdentifiers)
  const isAdmin = useSelector(getIsAdmin)
  const modalPageName = useSelector(getOpenedModal)

  const open = modalPageName === CONNECT_GROUPING_MODAL_NAME
  const fileVersionsCount = filesIdentifiers.length || 0
  const edit = Boolean(groupingItem?.name)
  const initialValues = {
    ...groupingItem,
    filterValues: groupingItem?.filterValues || [],
  }

  const allowedOptions: SourceTypeOptionExtended[] = sortBy(SOURCE_TYPES_OPTIONS.map((item) => {
    return enrichSourceTypeOptions(item, connectionsList, fileVersionsCount, isAdmin)
  }), (option) => {
    const category: SOURCE_TYPES_CATEGORIES = option.category

    return CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : ''
  })

  const handleModalClose = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(
        setPrimaryModalPageName(''),
      )
    }

    dispatch(
      requestGroupingAction({} as UseCase.GroupingParameter),
    )
  }

  const handleModalSubmit = (values: UseCase.GroupingParameter) => {
    const payload = {
      groupingAttributeId: initialValues.groupingAttributeId,
      name: values.name,
      description: values.description || '',
      sourceType: values.sourceType,
      unitLabel: values.unitLabel || '',
      filterValues: values.filterValues,
    }

    dispatch(
      updateGroupingAction(payload),
    )

    handleModalClose(false)
  }

  const handleDelete = () => {
    dispatch(
      removeGroupingAction({
        groupingAttributeId: initialValues.groupingAttributeId,
      }),
    )

    handleModalClose(false)
  }

  const {
    handleChange,
    handleSubmit,
    resetForm,
    handleBlur,
    touched,
    errors,
    isValid,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
    validationSchema: connectGroupingValidations(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const sourceType = values.sourceType as SOURCE_TYPES
  const name = values.name
  const unitLabel = values.unitLabel
  const isReadOnly = isUseCaseFrozen || isSubmitting
  const shouldShowDeleteButton = isAdmin || !isUseCaseFrozen

  const modalTitle = edit ? (
    intl.formatMessage({ id: 'connect.modal.grouping.edit' }, { name: initialValues.name })
  ) : (
    intl.formatMessage({ id: 'connect.modal.grouping' })
  )

  return (
    <SidePanelComponent
      open={open}
      title={modalTitle}
      handleClose={handleModalClose}
      loading={false}
      hasUnsavedChanges={dirty || isSubmitting}
      headerRightSideBlocks={(
        <HelpButtonComponent
          tooltip={intl.formatMessage({ id: 'connect.modal.grouping.help' })}
          name='modalHelpButton'
        />
      )}
    >
      <SidePanelLoadingComponent loading={isFetching || !initialValues.sourceType}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <Typography
              sx={{
                fontSize: '30px',
                lineHeight: '35px',
                maxWidth: theme.spacing(80),
                marginBottom: theme.spacing(2),
                ...getTextBackgroundFillStyles(),
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.grouping.header' })}
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                lineHeight: '20px',
                color: theme.palette.new.black_b,
                maxWidth: theme.spacing(60),
                marginBottom: theme.spacing(4),
                [theme.breakpoints.down('xl')]: {
                  marginBottom: theme.spacing(6),
                },
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.grouping.description' })}
            </Typography>
            <Box
              sx={{
                marginBottom: theme.spacing(4),
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'center',
                '& > div': {
                  marginTop: theme.spacing(0),
                },
              }}
            >
              <ReactFlowParameterBlockComponent
                name={name}
                sourceType={sourceType}
                unitLabel={unitLabel}
                type={PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE}
                nonFlowUsage={true}
                filterValues={values.filterValues}
              />
              <Box
                sx={{
                  zIndex: 5,
                }}
              >
                <ConnectorIcon />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  marginLeft: theme.spacing(-0.5),
                  '& .MuiInputLabel-outlined': {
                    transform: 'translate(14px, 10px) scale(1)',
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    padding: '10px 65px 10px 25px',
                  },
                }}
              >
                <ParameterSourceSelectorComponent
                  disabled={isReadOnly}
                  options={allowedOptions}
                  isFetching={isFetching}
                  handleChangeCallback={(e, selectedValue) => {
                    if (!selectedValue) {
                      return
                    }

                    handleChange({
                      target: {
                        name: 'sourceType',
                        value: selectedValue?.value,
                      },
                    })
                  }}
                  value={SOURCE_TYPES_OPTIONS.find((item) => {
                    return item.value === sourceType
                  }) || null}
                />
              </Box>
            </Box>

            <FormLayoutContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'connect.modal.parameter.details' })}
                divider={false}
              >
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.name' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.name.help' })}
                    name='name'
                    id='name'
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.name}
                    errors={errors.name}
                    disabled={isReadOnly}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.unit' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.unit.help' })}
                    name='unitLabel'
                    id='unitLabel'
                    value={values.unitLabel}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.unitLabel}
                    errors={errors.unitLabel}
                    disabled={isReadOnly}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.description_field' })}
                    name='description'
                    id='description'
                    touched={touched.description}
                    errors={errors.description}
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline={true}
                    minRows={6}
                    disabled={isReadOnly}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <AutocompleteChipsFieldComponent<string>
                    label={intl.formatMessage({ id: 'connect.block.grouping.filter' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.block.grouping.filter.help' })}
                    name='filterValues'
                    touched={touched.filterValues}
                    placeholder={intl.formatMessage({ id: 'connect.block.grouping.filter.placeholder' })}
                    value={values.filterValues}
                    errors={errors.filterValues as any}
                    disabled={isReadOnly}
                    freeSolo={true}
                    options={[]}
                    getOptionLabel={(item: string) => item}
                    handleChangeCallback={(e: any, selectedValue: any, newValue: any) => {
                      handleChange({
                        target: {
                          name: 'filterValues',
                          value: newValue,
                        },
                      })
                    }}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>

            <SidePanelButtonContainerComponent
              hidden={!shouldShowDeleteButton}
            >
              <ButtonComponent
                color='tertiary'
                name='deleteGroupingParameterButton'
                StartIconComponent={DeleteIcon}
                label={intl.formatMessage({ id: 'connect.modal.parameter.delete' })}
                onClick={handleDelete}
              />
            </SidePanelButtonContainerComponent>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='connectGroupingModalCloseButton'
              onClick={() => handleModalClose(true)}
              disabled={isSubmitting}
              type='cancel'
            />

            <ModalButtonComponent
              name='connectGroupingModalSubmitButton'
              onClick={(e) => handleModalSubmit(values)}
              type='submit'
              loading={isSubmitting}
              disabled={!dirty || !isValid}
              label={intl.formatMessage({ id: 'common.modal.button.apply' })}
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ConnectGroupingModalContainer
