import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import HelpButtonComponent from '@base/buttons/HelpButton'
import TextFieldComponent from '@base/forms/TextField'
import SwitchFieldComponent from '@base/forms/SwitchField'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'

import { CreateForecastParametersPayload } from '@redux/modules/use-case/use-case.types'
import { createForecastParametersAction } from '@redux/modules/use-case/use-case.actions'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { isSubmittingUseCase } from '@redux/modules/use-case/use-case.selectors'
import { getUseCaseForecastAddedValue } from '@utils/use-cases.utils'
import { USE_CASE_FORECAST_PARAMETERS_MODAL_NAME } from '@constants/modals.constants'

import validation from './UseCaseForecastParametersModal.validations'

export interface UseCaseForecastParametersModalDetails extends Common.ModalDetails {
  useCaseId: string
  useCaseName: string
  forecastParameters: UseCase.ForecastParameters | null
  forecastValue: Hera.ForecastValue | null
  returnTo: string
}

const UseCaseForecastParametersModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isSubmitting = useSelector(isSubmittingUseCase)
  const modalDetails = useSelector(getModalDetails<UseCaseForecastParametersModalDetails>)
  const modalPageName = useSelector(getOpenedModal)

  const open = modalPageName === USE_CASE_FORECAST_PARAMETERS_MODAL_NAME
  const usecaseId = modalDetails.useCaseId
  const usecaseName = modalDetails.useCaseName
  const forecastParameters = (modalDetails.forecastParameters || {}) as UseCase.ForecastParameters
  const co2SavingsYearly = modalDetails.forecastValue?.co2SavingsYearly || 0

  const initialValues: CreateForecastParametersPayload = {
    useCaseId: usecaseId,
    displayForecastValue: forecastParameters.displayForecastValue || false,
    overestimateCostPerUnit: forecastParameters.overestimateCostPerUnit || 0,
    underestimateCostPerUnit: forecastParameters.underestimateCostPerUnit || 0,
  } as CreateForecastParametersPayload

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(modalDetails.returnTo))
    }
  }

  const handleSubmitAction = (values: CreateForecastParametersPayload) => {
    const payload: CreateForecastParametersPayload = {
      ...initialValues,
      ...values,
    }

    dispatch(
      createForecastParametersAction(payload),
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: validation(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'use_cases.forecast.configuration' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
      headerRightSideBlocks={(
        <HelpButtonComponent
          tooltip={intl.formatMessage({ id: 'use_cases.forecast.help' })}
          name='modalHelpButton'
        />
      )}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'use_cases.forecast.general' })}
              >
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='useCaseId'
                    value={usecaseId}
                    label={intl.formatMessage({ id: 'use_cases.form.id.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='name'
                    value={usecaseName}
                    label={intl.formatMessage({ id: 'use_cases.form.name.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'use_cases.forecast.configuration' })}
              >
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='overestimateCostPerUnit'
                    value={values.overestimateCostPerUnit}
                    label={intl.formatMessage({ id: 'use_cases.forecast.overestimateCostPerUnit.name' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={false}
                    type='number'
                  />
                </FormLayoutItem>

                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='underestimateCostPerUnit'
                    value={values.underestimateCostPerUnit}
                    label={intl.formatMessage({ id: 'use_cases.forecast.underestimateCostPerUnit.name' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={false}
                    type='number'
                  />
                </FormLayoutItem>

                <FormLayoutItem xs={12}>
                  <SwitchFieldComponent
                    name='displayForecastValue'
                    label={intl.formatMessage({
                      id: co2SavingsYearly ? 'use_cases.forecast.displayWithCo2SavingsYearly' : 'use_cases.forecast.display',
                    }, {
                      co2SavingsYearly: (<IntlFormatBoldComponent>{co2SavingsYearly}</IntlFormatBoldComponent>),
                      value: (
                        <IntlFormatBoldComponent>
                          {
                            getUseCaseForecastAddedValue({
                              ...modalDetails.forecastValue,
                              overestimateCostPerUnit: values.overestimateCostPerUnit,
                              underestimateCostPerUnit: values.underestimateCostPerUnit,
                            } as Hera.ForecastValue)
                          }
                        </IntlFormatBoldComponent>
                      ),
                    })}
                    checked={values.displayForecastValue}
                    value={values.displayForecastValue}
                    onChange={handleChange}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='useCaseForecastParametersModalCloseButton'
              onClick={() => handleCloseAction()}
              type='back'
            />

            <ModalButtonComponent
              name='useCaseForecastParametersModalApplyButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !dirty || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default UseCaseForecastParametersModalContainer
