import React, { useLayoutEffect } from 'react'
import { Box } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'

import PageLayoutContainer from '@containers/application/PageLayout'
import TopArticlesContainer from '@containers/shared/artifacts/TopArticlesArtifact'
import ConstraintsAnalyzeArtifactContainer from '@containers/shared/artifacts/ConstraintsAnalyzeArtifact'
import ArticlesOverviewArtifactContainer from '@containers/shared/artifacts/ArticlesOverviewArtifact'

const DiscoverContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCase = useSelector(getUseCaseItem)
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      fetchUseCaseAction({
        useCaseId,
      }),
    )
  }, [dispatch, useCaseId])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'recommendation.discover.browser_tab.title' }, { name: useCase.name })}
    >
      <ArticlesOverviewArtifactContainer />

      <Box mt={4} />

      <TopArticlesContainer />

      <Box mt={4} />

      <ConstraintsAnalyzeArtifactContainer />
    </PageLayoutContainer>
  )
}

export default DiscoverContainer
