import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { getComparisonModeState } from '@redux/modules/recommendation-artifacts/recommendation-artifacts.selectors'
import { toggleComparisonModeAction } from '@redux/modules/recommendation-artifacts/recommendation-artifacts.actions'

import ToggleButtonComponent from '@base/pagebar/ToggleButton'

export interface ComparisonToggleContainerProps {}

const ComparisonToggleContainer: React.FC<ComparisonToggleContainerProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const comparisonMode = useSelector(getComparisonModeState)
  const handleFieldChange = () => {
    dispatch(
      toggleComparisonModeAction(),
    )
  }

  return (
    <ToggleButtonComponent
      label={intl.formatMessage({ id: 'recommendation.comparisonToggle' })}
      onChange={handleFieldChange}
      name='comparisonToggle'
      value={comparisonMode}
      checked={comparisonMode}
    />
  )
}

export default ComparisonToggleContainer
