import React from 'react'
import Keycloak from 'keycloak-js'
import { useSelector } from '@redux/hooks'
import { Box } from '@mui/material'

import TopBarContainer from '@containers/application/TopBar'
import SideBarContainer from '@containers/application/SideBar'

import { CHAT_WIDTH } from '@constants/chat.constnats'
import { getChatbotState } from '@redux/modules/common/common.selectors'
import { CONTENT_LAYOUT_ID, TOPBAR_HEIGHT } from '@constants/ui.constants'

export interface MainLayoutProps {
  /**
   * Children components
   */
  children?: React.ReactNode,
  /**
   * Keycloak instance
   */
  keycloak: Keycloak,
}

const MainLayoutContainer: React.FC<MainLayoutProps> = ({
  children,
  keycloak,
}) => {
  const chatbotState = useSelector(getChatbotState)
  const [chatWidth, setChatWidth] = React.useState<number>(CHAT_WIDTH)

  return (
    <Box
      data-testid={MainLayoutContainer.name}
      sx={{
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        marginRight: `${chatbotState ? chatWidth || CHAT_WIDTH : 0}px`,
        width: chatbotState ? 'auto' : '100%',
      }}
    >
      <TopBarContainer
        keycloak={keycloak}
        setChatWidth={setChatWidth}
        chatWidth={chatWidth}
      />

      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingTop: `${TOPBAR_HEIGHT}px`,
        }}
      >
        <SideBarContainer />

        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <Box
            id={CONTENT_LAYOUT_ID}
            sx={{
              flex: '1 1 auto',
              height: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
              overflow: 'auto',
              background: 'white',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MainLayoutContainer
