import React, { Fragment } from 'react'
import get from 'lodash.get'
import { TooltipProps } from 'recharts'
import { useTheme, Box } from '@mui/material'

import ChartTooltipContainerComponent from '@components/charts/tooltips/ChartTooltipContainer'
import ChartTooltipGroupBlockComponent from '@components/charts/tooltips/ChartTooltipGroupBlock'
import ChartTooltipLegendItemComponent from '@components/charts/tooltips/ChartTooltipLegendItem'
import ChartTooltipTableRowComponent from '@components/charts/tooltips/ChartTooltipTableRow'
import ChartTooltipTableCellComponent from '@components/charts/tooltips/ChartTooltipTableCell'
import ChartTooltipValueComponent from '@components/charts/tooltips/ChartTooltipValue'
import ChartTooltipTableComponent from '@components/charts/tooltips/ChartTooltipTable'

import { createId } from '@utils/common.utils'

export interface ChartTabularTooltipComponentProps<T> extends Omit<TooltipProps<any, any>, 'payload'> {
  /**
   * If 'true' the tooltip is active
   */
  active?: boolean,
  /**
   * The payload of the chart data point
   */
  payload?: T[],
  /**
   * The rows to be displayed in the tooltip
   */
  rows: Pick<Common.TooltipRow<T>, 'key' | 'legendColor' | 'legendType' | 'label'>[]
  /**
   * The primary set of rows to be displayed in the tooltip
   */
  primaryRows?: Common.TooltipRow<T>[]
  /**
   * The headers of the tabular tooltip
   */
  columns: Common.TabularTooltipColumns[]
}

const ChartTabularTooltipComponent = <T extends {}> ({
  active, payload, rows, columns = [], primaryRows = [],
} : ChartTabularTooltipComponentProps<T>) => {
  const theme = useTheme()
  const data: T = get(payload, '[0].payload', {} as T) as T

  if (active && payload && rows && rows.length && payload.length) {
    return (
      <ChartTooltipContainerComponent
        data-testid={ChartTabularTooltipComponent.name}
        padding='15px'
        maxWidth='800px'
      >
        {
          primaryRows && primaryRows.length ? (
            <ChartTooltipTableComponent>
              <Box component='tbody'>
                <ChartTooltipGroupBlockComponent
                  data={data}
                  groupRows={primaryRows}
                  showDivider={true}
                />
              </Box>
            </ChartTooltipTableComponent>
          ) : (
            null
          )
        }

        <ChartTooltipTableComponent>
          <Box
            component='thead'
            sx={{
              borderBottom: '5px solid transparent',
            }}
          >
            <ChartTooltipTableRowComponent>
              {
                columns.map((column, index) => {
                  const textAlign = column.headerAlignment || 'center'
                  const color = column.headerColor || theme.palette.new.business_black
                  const hasSecondaryValue = Boolean(column.secondaryKey || column.secondaryValueGetter)

                  return (
                    <Fragment
                      key={createId(index, column.headerLabel)}
                    >
                      {
                        index === 0 && (
                          <ChartTooltipTableCellComponent>
                            &nbsp;
                          </ChartTooltipTableCellComponent>
                        )
                      }

                      <ChartTooltipTableCellComponent
                        colSpan={hasSecondaryValue ? 2 : 1}
                        sx={{
                          textAlign,
                          color,
                          fontWeight: 500,
                        }}
                      >
                        {column.headerLabel}
                      </ChartTooltipTableCellComponent>
                    </Fragment>
                  )
                })
              }
            </ChartTooltipTableRowComponent>
          </Box>
          <Box component='tbody'>
            {
              rows.map((row, rowIndex) => {
                const legendColor = row.legendColor ? (typeof row.legendColor === 'function' ? row.legendColor(data) : row.legendColor) : undefined

                return (
                  <ChartTooltipTableRowComponent
                    key={createId(rowIndex)}
                  >
                    <ChartTooltipTableCellComponent>
                      <ChartTooltipLegendItemComponent
                        type={row.legendType}
                        color={legendColor}
                        label={row.label}
                      />
                    </ChartTooltipTableCellComponent>

                    {
                      columns.map((column, columnIndex) => {
                        const columnKey = typeof column.key === 'function' ? column.key(rowIndex, row.key!) : column.key
                        const value = column.valueGetter ? column.valueGetter(data, row.key!) : get(data, columnKey, 0) as number | string

                        const valueColor = column.valueColor ?
                          (typeof column.valueColor === 'function' ? column.valueColor(rowIndex, row.key!, data) : column.valueColor) :
                          theme.palette.new.business_black

                        const secondaryKey = typeof column.secondaryKey === 'function' ? column.secondaryKey(rowIndex, row.key!) : column.secondaryKey
                        const secondaryValueFromKey = secondaryKey ? get(data, secondaryKey, 0) as number | string : undefined
                        const secondaryValueFromGetter = column.secondaryValueGetter ? column.secondaryValueGetter(data, row.key!) : undefined

                        const secondaryValue = column.secondaryValueGetter ? secondaryValueFromGetter : secondaryValueFromKey
                        const secondaryValueColor =
                          (typeof column.secondaryValueColor === 'function' ? column.secondaryValueColor(rowIndex, row.key!, data) : column.secondaryValueColor)

                        const formattedValue = column.valueFormatter ? column.valueFormatter(value, data) : value
                        const formattedSecondaryValue = column.secondaryValueFormatter ? column.secondaryValueFormatter(secondaryValue, data) : secondaryValue

                        const textAlign = column.valueAlignment || 'center'
                        const hasSecondaryValue = Boolean(column.secondaryKey || column.secondaryValueGetter)

                        return (
                          <Fragment
                            key={createId(columnIndex, column.key)}
                          >
                            <ChartTooltipTableCellComponent
                              sx={{ textAlign, paddingRight: hasSecondaryValue ? '5px' : undefined }}
                            >
                              <ChartTooltipValueComponent
                                color={valueColor}
                                value={formattedValue}
                                variant={column.valueVariant}
                              />
                            </ChartTooltipTableCellComponent>

                            {
                              hasSecondaryValue ? (
                                <ChartTooltipTableCellComponent
                                  sx={{ textAlign }}
                                >
                                  <ChartTooltipValueComponent
                                    color={secondaryValueColor}
                                    value={formattedSecondaryValue}
                                    variant='contained'
                                  />
                                </ChartTooltipTableCellComponent>
                              ) : (
                                null
                              )
                            }

                          </Fragment>
                        )
                      })
                    }
                  </ChartTooltipTableRowComponent>
                )
              })
            }
          </Box>
        </ChartTooltipTableComponent>
      </ChartTooltipContainerComponent>
    )
  }

  return null
}

export default ChartTabularTooltipComponent
