import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { Box, Typography, useTheme } from '@mui/material'
import ButtonComponent from '@base/buttons/Button'
import ConfirmationDialogComponent from '@base/dialogs/ConfirmationDialog'

export interface VideoBlockComponentProps {
  /**
   * Link to the documentation
   */
  documentationLink?: string,
  /**
   * Link to the video
   */
  videoLink?: string,
  /**
   * Height of the video
   */
  videoHeight?: string,
  /**
   * Title of the video
   */
  title?: string,
}

export interface VideoBlockComponentState extends VideoBlockComponentProps {
  open?: boolean,
}

const VideoBlockComponent: React.FC<VideoBlockComponentProps> = ({
  documentationLink, videoLink,
  videoHeight, title,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const defaultDialogState = {
    open: false,
    title: '',
    videoLink: undefined,
    videoHeight: '450px',
    documentationLink: undefined,
  }

  const [dialogState, setDialogState] = useState<VideoBlockComponentState>(defaultDialogState)

  return (
    <>
      <Box
        data-testid={VideoBlockComponent.name}
        sx={{
          width: '100%',
          position: 'relative',
          marginRight: theme.spacing(2.5),
          borderRadius: theme.spacing(1),
          minHeight: '200px',
          maxWidth: '400px',
          background: 'url("/video_placeholder_new.png") no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Typography
          variant='caption'
          sx={{
            position: 'absolute',
            top: '27px',
            left: '90px',
            color: 'rgba(255, 255, 255, 0.6)',
            border: '0.6px solid rgba(255, 255, 255, 0.6)',
            borderRadius: '100px',
            padding: '3px 7px 4px',
          }}
        >
          {intl.formatMessage({ id: 'connect.modal.data_sources.introduction' })}
        </Typography>
        <Typography
          variant='h1'
          sx={{
            position: 'absolute',
            bottom: '13px',
            left: '30px',
            color: theme.palette.new.white,
          }}
        >
          {intl.formatMessage({ id: 'connect.modal.data_sources.how_to' })}
        </Typography>
        <ButtonComponent
          name='watchHowToButton'
          color='primary'
          sx={{
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
          }}
          onClick={() => setDialogState({
            open: true,
            documentationLink,
            videoLink,
            title: intl.formatMessage({ id: 'connect.modal.data_sources.watch_how_to' }, { name: title }),
          })}
          label={intl.formatMessage({ id: 'connect.modal.data_sources.watch' })}
        />
      </Box>

      <ConfirmationDialogComponent
        open={dialogState.open}
        width='900px'
        onClose={() => setDialogState(defaultDialogState)}
        title={dialogState.title}
        description={(
          <Box
            display='flex'
            height='100%'
            dangerouslySetInnerHTML={{
              __html: `
                <iframe
                  title="${dialogState.title}"
                  src="${dialogState.videoLink}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen="true"
                  width="100%"
                  height="${videoHeight}"
                  id='video_iframe'
                />
              `,
            }}
          />
        )}
        closeButtonLabel={intl.formatMessage({ id: 'connect.automateDataExchange.closeButton' })}
      />
    </>
  )
}

export default VideoBlockComponent
