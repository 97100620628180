import React, { useMemo } from 'react'
import cn from 'classnames'
import { Box } from '@mui/material'

import {
  DataGridPremium,
  DataGridPremiumProps,
  useGridApiRef,
  GridRowClassNameParams,
  useKeepGroupedColumnsHidden,
  GridRowGroupingModel,
} from '@mui/x-data-grid-premium'

import {
  ROW_HEIGHT,
  COLUMN_HEADER_HEIGHT,
  DATA_GRID_CUSTOM_CLASS_NAMES,
  DEFAULT_DEBOUNCE_INTERVAL,
} from '@constants/data-grid.constants'

import { DataGridCheckboxBaseComponentProps } from '@base/datagrid/data-grid-checkbox-base/DataGridCheckboxBase.component'
import { DataGridCustomFooterComponentProps } from '@base/datagrid/data-grid-custom-footer/DataGridCustomFooter.component'

import { useDataGridOnChangeTracking } from './hooks/useDataGridOnChangeTracking.hook'
import { useDataGridSlots } from './hooks/useDataGridSlots.hook'
import { useDataGridSlotProps } from './hooks/useDataGridSlotProps.hook'
import { useDataGridAggregationFunctions } from './hooks/useDataGridAggregationFunctions.hook'
import { useDataGridLocales } from './hooks/useDataGridLocales.hook'
import { useDataGridOnClipboardCopy } from './hooks/useDataGridOnClipboardCopy.hook'
import { useDataGridReversPinningOrder } from './hooks/useDataGridReversPinningOrder.hook'
import { useDataGridPersistance } from './hooks/useDataGridPersistance.hook'
import { useDataGridAutosize } from './hooks/useDataGridAutosize.hook'
import { useDataGridTransformedColumns } from './hooks/useDataGridTransformedColumns.hook'

declare module '@mui/x-data-grid-premium' {
  interface BaseCheckboxPropsOverrides extends DataGridCheckboxBaseComponentProps {}
  interface FooterPropsOverrides extends DataGridCustomFooterComponentProps {}
  interface PaginationPropsOverrides {
    disabledPagination?: boolean
  }

  interface ColumnMenuPropsOverrides {
    disableGrouping?: boolean
    disableColumnVisibility?: boolean
  }

  interface LoadingOverlayPropsOverrides {
    actionsMenuWidth?: number
    uploading?: boolean
  }

  interface NoRowsOverlayPropsOverrides {
    labels: string[]
  }

  interface ToolbarPropsOverrides {
    disableColumnVisibilityPanelWhenGrouping?: boolean
    withExport?: boolean
    groupingMode?: 'server' | 'client'
    groupingModel?: GridRowGroupingModel
    onRowGroupingModelChange?: DataGridPremiumProps['onRowGroupingModelChange']
    customToolbarChildren?: React.ReactNode
  }
}

export interface DataGridComponentProps extends DataGridPremiumProps {
  /**
   * The id of the grid
   */
  id: string
  /**
   * The name of the grid. Used for tracking and settings persistence
   */
  name: string
  /**
   * The height of the grid
   */
  height?: string
  /**
   * Whether the grid is disabled
   */
  disabled?: boolean
  /**
   * Whether the grid is readonly
   */
  readonly?: boolean
  /**
   * Whether the grid is uploading
   */
  uploading?: boolean
  /**
   * Whether the grid should persist settings
   */
  enablePersistence?: boolean
  /**
   * Whether the grid should enable group expand
   */
  enableGroupExpand?: boolean
  /**
   * Whether the grid should reverse the pinning order Left to Right vs Right to Left
   */
  reversedPinningOrder?: boolean,
  /**
   * The component to use for the header checkbox
   */
  DataGridHeaderCheckboxComponent?: React.FC<any>
  /**
   * Whether the grid should be rounded
   */
  rounded?: boolean
  /**
   * The mode of the grouping
   */
  groupingMode?: 'server' | 'client'
  /**
   * The mode of the aggregation
   */
  aggregationMode?: 'server' | 'client'
  /**
   * The mode of the row selection
   */
  rowSelectionMode?: 'server' | 'client'
  /**
   * The grouping model
   */
  groupingModel?: GridRowGroupingModel
  /**
   * The locales
   */
  customLocales?: Record<string, string | Function>
  /**
   * When the key changes, the state in the grid is reset
   * https://github.com/mui/mui-x/issues/4301#issuecomment-2168072343
   */
  stateResetKey?: string
}

export const DataGridComponent: React.FC<DataGridComponentProps> = ({
  id,
  stateResetKey,
  name,
  apiRef,
  rows,
  columns,
  initialState,
  loading,
  disableVirtualization,
  rounded = true,
  disabled = false,
  readonly = false,
  pagination = true,
  enablePersistence = true,
  enableGroupExpand = true,
  reversedPinningOrder = true,
  uploading = false,
  height = '555px',
  slotProps,
  slots,
  autoHeight,
  aggregationFunctions,
  groupingColDef,
  groupingMode = 'client',
  aggregationMode = 'client',
  sortingMode = 'client',
  paginationMode = 'client',
  filterMode = 'client',
  rowSelectionMode = 'client',
  groupingModel,
  disableAutosize,
  autosizeOnMount,
  onPinnedColumnsChange: onPinnedColumnsChangeOriginal,
  onRowGroupingModelChange: onRowGroupingModelChangeOriginal,
  onPaginationModelChange: onPaginationModelChangeOriginal,
  onSortModelChange: onSortModelChangeOriginal,
  onFilterModelChange: onFilterModelChangeOriginal,
  onAggregationModelChange: onAggregationModelChangeOriginal,
  onColumnVisibilityModelChange: onColumnVisibilityModelChangeOriginal,
  onRowSelectionModelChange: onRowSelectionModelChangeOriginal,
  onDetailPanelExpandedRowIdsChange: onDetailPanelExpandedRowIdsChangeOriginal,
  checkboxSelection,
  columnGroupingModel,
  columnGroupHeaderHeight,
  disableMultipleRowSelection,
  DataGridHeaderCheckboxComponent,
  customLocales,
  sortingOrder = ['asc', 'desc'],
  ...props
}) => {
  const _apiRef = useGridApiRef()
  const finalApiRef = apiRef || _apiRef
  const empty = !loading && !rows?.length
  const isLoading = loading || uploading
  const onClipboardCopy = useDataGridOnClipboardCopy()
  const getRowClassName = (params: GridRowClassNameParams) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? DATA_GRID_CUSTOM_CLASS_NAMES.EVEN : DATA_GRID_CUSTOM_CLASS_NAMES.ODD
  }

  const finalColumns = useDataGridTransformedColumns({
    columns,
    useCheckboxSelection: checkboxSelection!,
    rowSelectionMode,
    disableMultipleRowSelection,
    DataGridHeaderCheckboxComponent,
  })

  const {
    onPinnedColumnsChange: onPinnedColumnsChangeWithTracking,
    onRowGroupingModelChange: onRowGroupingModelChangeWithTracking,
    onPaginationModelChange: onPaginationModelChangeWithTracking,
    onSortModelChange: onSortModelChangeWithTracking,
    onFilterModelChange: onFilterModelChangeWithTracking,
    onAggregationModelChange: onAggregationModelChangeWithTracking,
    onColumnVisibilityModelChange: onColumnVisibilityModelChangeWithTracking,
    onRowSelectionModelChange: onRowSelectionModelChangeWithTracking,
    onClipboardCopy: onClipboardCopyWithTracking,
    onDetailPanelExpandedRowIdsChange: onDetailPanelExpandedRowIdsChangeWithTracking,
  } = useDataGridOnChangeTracking({
    name,
    onClipboardCopy,
    onPinnedColumnsChange: onPinnedColumnsChangeOriginal,
    onRowGroupingModelChange: onRowGroupingModelChangeOriginal,
    onPaginationModelChange: onPaginationModelChangeOriginal,
    onSortModelChange: onSortModelChangeOriginal,
    onFilterModelChange: onFilterModelChangeOriginal,
    onAggregationModelChange: onAggregationModelChangeOriginal,
    onColumnVisibilityModelChange: onColumnVisibilityModelChangeOriginal,
    onRowSelectionModelChange: onRowSelectionModelChangeOriginal,
    onDetailPanelExpandedRowIdsChange: onDetailPanelExpandedRowIdsChangeOriginal,
  })

  const finalSlots = useDataGridSlots({ slots })

  const localeText = useDataGridLocales({
    customLocales,
  })

  const finalSlotProps = useDataGridSlotProps({
    slotProps,
    disabled,
    groupingModel,
    enableGroupExpand,
    columns: finalColumns,
    groupingMode,
    onRowGroupingModelChange: onRowGroupingModelChangeWithTracking,
    uploading,
  })

  const finalAggregationFunctions = useDataGridAggregationFunctions({
    aggregationFunctions,
  })

  useDataGridPersistance({
    id,
    apiRef: finalApiRef,
    enablePersistence,
    groupingMode,
    rowSelectionMode,
    aggregationMode,
  })

  useDataGridAutosize({
    apiRef: finalApiRef,
    autosizeOnMount,
    loading,
    disableVirtualization,
    disableAutosize,
    uploading,
  })

  const finalState = useKeepGroupedColumnsHidden({
    apiRef: finalApiRef,
    initialState,
  })

  const {
    pinnedColumns,
    onPinnedColumnsChange: onPinnedColumnsChangeWithTrackingAndReversPinningOrder,
  } = useDataGridReversPinningOrder({
    apiRef: finalApiRef,
    initialState: finalState.pinnedColumns,
    onPinnedColumnsChange: onPinnedColumnsChangeWithTracking,
    reversedPinningOrder,
  })

  const classNames = cn({
    rounded,
    disabled,
    loading: isLoading,
    readonly,
    empty,
    singleSelection: disableMultipleRowSelection && checkboxSelection,
    'non-expandable-group': !enableGroupExpand,
    'has-column-grouping': Boolean(columnGroupingModel),
  })

  const rootStyles = useMemo(() => {
    const base = {
      width: '100%',
      height: autoHeight ? '100%' : height,
    }

    return base
  }, [
    height,
    autoHeight,
  ])

  return (
    <Box
      data-testid={DataGridComponent.name}
      sx={rootStyles}
    >
      <DataGridPremium
        key={stateResetKey}
        rows={rows}
        columns={finalColumns}
        apiRef={finalApiRef}
        rowHeight={ROW_HEIGHT}
        loading={isLoading}
        disableVirtualization={disableVirtualization}
        columnHeaderHeight={COLUMN_HEADER_HEIGHT}
        disableAutosize={disableAutosize}
        autosizeOnMount={autosizeOnMount}
        pagination={pagination}
        autoHeight={autoHeight}
        getRowClassName={getRowClassName}
        className={classNames}
        slots={finalSlots}
        slotProps={finalSlotProps}
        initialState={finalState}
        localeText={localeText}
        aggregationFunctions={finalAggregationFunctions}
        sortingOrder={sortingOrder}
        pinnedColumns={pinnedColumns}
        filterDebounceMs={DEFAULT_DEBOUNCE_INTERVAL}
        sortingMode={sortingMode}
        paginationMode={paginationMode}
        filterMode={filterMode}
        columnGroupingModel={columnGroupingModel}
        columnGroupHeaderHeight={columnGroupHeaderHeight}
        checkboxSelection={checkboxSelection}
        disableMultipleRowSelection={disableMultipleRowSelection}
        onClipboardCopy={onClipboardCopyWithTracking}
        onRowGroupingModelChange={onRowGroupingModelChangeWithTracking}
        onPinnedColumnsChange={onPinnedColumnsChangeWithTrackingAndReversPinningOrder}
        onPaginationModelChange={onPaginationModelChangeWithTracking}
        onSortModelChange={onSortModelChangeWithTracking}
        onFilterModelChange={onFilterModelChangeWithTracking}
        onAggregationModelChange={onAggregationModelChangeWithTracking}
        onRowSelectionModelChange={onRowSelectionModelChangeWithTracking}
        onColumnVisibilityModelChange={onColumnVisibilityModelChangeWithTracking}
        onDetailPanelExpandedRowIdsChange={onDetailPanelExpandedRowIdsChangeWithTracking}
        {...props}
      />
    </Box>
  )
}

export default DataGridComponent
