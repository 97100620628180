import React, { useMemo } from 'react'

import {
  Box,
  styled,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'

import InfoButtonComponent from '@base/buttons/InfoButton/InfoButton.component'

import { getTextBackgroundFillStyles } from '@utils/ui.utils'

import type { KpiTileComponentProps } from './KpiTile.component'

export const OverlineTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'tileVariant',
})<{ tileVariant?: KpiTileComponentProps['variant'] }>(({
  theme, tileVariant,
}) => {
  switch (tileVariant) {
    case 'sustainability': {
      return {
        color: theme.palette.new.generous_green,
        opacity: 0.6,
      }
    }
    case 'paretos-bold': {
      return {
        color: theme.palette.new.white,
        opacity: 0.6,
      }
    }
    case 'paretos':
    case 'highlighted':
    case 'default':
    default: {
      return {
        color: theme.palette.new.business_black,
        opacity: 0.4,
      }
    }
  }
})

export const TitleTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'tileVariant' && prop !== 'headerColor',
})<{
  tileVariant?: KpiTileComponentProps['variant']
  headerColor?: KpiTileComponentProps['headerColor']
}>(({
  theme, tileVariant, headerColor,
}) => {
  switch (tileVariant) {
    case 'sustainability': {
      return {
        color: theme.palette.new.generous_green,
      }
    }
    case 'paretos-bold': {
      return {
        color: theme.palette.new.white,
      }
    }
    case 'paretos': {
      return getTextBackgroundFillStyles()
    }
    case 'highlighted': {
      return getTextBackgroundFillStyles()
    }
    case 'default':
    default: {
      return {
        color: headerColor || theme.palette.new.business_black,
      }
    }
  }
})

export const getIconComponentProps = (theme: Theme, variant: KpiTileComponentProps['variant'], baseProps?: Common.IconProps) => {
  let customProps = {}

  switch (variant) {
    case 'sustainability': {
      customProps = {
        detailsFill: theme.palette.new.generous_green,
      }
      break
    }
    case 'paretos-bold': {
      customProps = {
        detailsFill: theme.palette.new.white,
      }
      break
    }
    case 'paretos':
    case 'highlighted':
    case 'default':
    default: {
      customProps = {
        detailsFill: theme.palette.new.business_black,
      }
      break
    }
  }

  return {
    ...customProps,
    ...baseProps,
  }
}

export interface KpiTileHeaderBlockComponentProps extends Pick<KpiTileComponentProps,
  'title' | 'overline' | 'tooltip' | 'size' | 'variant' |
  'IconComponent' | 'iconComponentProps' | 'loading' | 'headerColor'
> {
}

export const KpiTileHeaderBlockComponent: React.FC<KpiTileHeaderBlockComponentProps> = ({
  title,
  tooltip,
  overline,
  size = 'default',
  variant = 'default',
  headerColor,
  loading,
  IconComponent,
  iconComponentProps,
}) => {
  const theme = useTheme()
  const isCompact = size === 'compact'
  const iconProps = getIconComponentProps(theme, variant, iconComponentProps)
  const titleContainerWidth = useMemo(() => {
    if (IconComponent && tooltip) {
      return 'calc(100% - 60px)'
    }

    if (IconComponent || tooltip) {
      return 'calc(100% - 30px)'
    }

    return '100%'
  }, [IconComponent, tooltip])

  const loadingStyles = loading ? {
    filter: 'blur(5px)',
  } : {}

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='5px'
    >
      {
        overline ? (
          <OverlineTypography
            variant='overline'
            tileVariant={variant}
            title={overline}
          >
            {overline}
          </OverlineTypography>
        ) : (
          null
        )
      }

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        gap='10px'
        sx={loadingStyles}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='start'
          gap='5px'
          width={titleContainerWidth}
        >
          {
            IconComponent ? (
              <Box
                display='flex'
                alignItems='center'
              >
                <IconComponent
                  {...iconProps}
                />
              </Box>
            ) : (
              null
            )
          }

          <TitleTypography
            variant={isCompact ? 'subtitle2' : 'h6'}
            tileVariant={variant}
            noWrap={true}
            title={title}
            headerColor={headerColor}
          >
            {title}
          </TitleTypography>
        </Box>

        {
          tooltip ? (
            <InfoButtonComponent
              name='kpiTileInfoButton'
              placement='left'
              tooltip={tooltip}
              IconComponentProps={{
                detailsFill: variant === 'paretos-bold' ? theme.palette.new.white : theme.palette.new.business_black,
              }}
            />
          ) : (
            <Box />
          )
        }
      </Box>
    </Box>
  )
}

export default KpiTileHeaderBlockComponent
