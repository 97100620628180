import React, { useCallback } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { GridColumnGroupingModel } from '@mui/x-data-grid-premium'
import { useSelector } from '@redux/hooks'
import { getBaselineComparisonTable, isFetchingBaselineComparisonTable } from '@redux/modules/monitor/monitor.selectors'
import { requestBaselineComparisonGridStateChangeAction } from '@redux/modules/monitor/monitor.actions'
import { transformBaselineComparisonTableColumns } from '@utils/monitor.utils'
import { getInsightsAbcTotals } from '@redux/modules/insights/insights.selectors'
import { FORCED_RIGHT_BORDER_CLASS_NAME } from '@constants/data-grid.constants'

import {
  DEFAULT_PARETOS_REL_DEVIATION_COLUMN,
  DEFAULT_PARETOS_ABS_DEVIATION_COLUMN,
  DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN,
  DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN,
  DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN,
  DEFAULT_BASELINE_REL_DEVIATION_COLUMN,
  DEFAULT_BASELINE_ABS_DEVIATION_COLUMN,
} from '@constants/insights.constants'

import InsightsTableComponent from '@components/insights/InsightsTable'

const BaselineComparisonTableContainer = () => {
  const intl = useIntl()
  const baselineComparisonTable = useSelector(getBaselineComparisonTable)
  const isFetching = useSelector(isFetchingBaselineComparisonTable)
  const abcTotals = useSelector(getInsightsAbcTotals)

  const {
    tableId,
    rows,
    rowCount,
    columns,
    gridState,
    targetName,
  } = baselineComparisonTable

  const alwaysVisibleColumns = [
    DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN,
    DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN,
    DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN,

    DEFAULT_BASELINE_ABS_DEVIATION_COLUMN,
    DEFAULT_PARETOS_ABS_DEVIATION_COLUMN,

    DEFAULT_BASELINE_REL_DEVIATION_COLUMN,
    DEFAULT_PARETOS_REL_DEVIATION_COLUMN,
  ]

  const columnGroupingModel = [
    {
      groupId: 'abs_deviations',
      headerName: intl.formatMessage({ id: 'insights.table.baselineAbsDeviationGroup' }),
      children: [{ field: DEFAULT_BASELINE_ABS_DEVIATION_COLUMN }, { field: DEFAULT_PARETOS_ABS_DEVIATION_COLUMN }],
      headerClassName: FORCED_RIGHT_BORDER_CLASS_NAME,
    },
    {
      groupId: 'rel_deviations',
      headerName: intl.formatMessage({ id: 'insights.table.baselineRelDeviationGroup' }),
      children: [{ field: DEFAULT_BASELINE_REL_DEVIATION_COLUMN }, { field: DEFAULT_PARETOS_REL_DEVIATION_COLUMN }],
      headerClassName: FORCED_RIGHT_BORDER_CLASS_NAME,
    },
    {
      groupId: 'values',
      headerName: targetName,
      children: [
        { field: DEFAULT_BASELINE_COMPARISON_TARGET_COLUMN },
        { field: DEFAULT_BASELINE_COMPARISON_BASELINE_COLUMN },
        { field: DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN },
      ],
      headerClassName: FORCED_RIGHT_BORDER_CLASS_NAME,
    },
  ] as GridColumnGroupingModel

  const columnTransformer = useCallback((reactIntl: IntlShape, columnsToTransform: Insights.SimplifiedGridColumnDefinition[]) => {
    return transformBaselineComparisonTableColumns(reactIntl, targetName, columnsToTransform)
  }, [targetName])

  return (
    <InsightsTableComponent
      tableId={tableId}
      rows={rows}
      rowCount={rowCount}
      totalRowCount={abcTotals.total}
      columns={columns}
      gridState={gridState}
      isFetching={isFetching}
      requestGridStateChangeAction={requestBaselineComparisonGridStateChangeAction}
      columnTransformer={columnTransformer}
      defaultSortingColumn={DEFAULT_BASELINE_COMPARISON_PREDICTION_COLUMN}
      alwaysVisibleColumns={alwaysVisibleColumns}
      columnGroupingModel={columnGroupingModel}
      disableRowGrouping={true}
    />
  )
}

export default BaselineComparisonTableContainer
