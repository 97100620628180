import React, { useCallback, useEffect } from 'react'
import { GridRowId, useGridApiRef } from '@mui/x-data-grid-premium'

import SectionTileComponent from '@base/tiles/SectionTile'
import DataGridComponent from '@base/datagrid/data-grid'
import { getColumnsConfigFromSchema } from '@utils/recommendation-artifacts.utils'
import { DATA_GRIDS, DEFAULT_DATA_GIRD_AUTOSIZE_OPTIONS } from '@constants/data-grid.constants'
import type { SectionTileComponentProps } from '@base/tiles/SectionTile/SectionTile.component'

export interface TopArticlesItemComponentProps {
  /**
   * Unique identifier for the component
   */
  id: string
  /**
   * Title of the component
   */
  title: string
  /**
   * Flag to indicate if the component is expanded.
   * Used to trigger the autosize of the columns
   */
  expanded?: boolean
  /**
   * Flag to indicate if the component is in comparison mode
   */
  comparisonMode?: boolean
  /**
   * Limit of the number of rows to be displayed
   */
  limit?: number
  /**
   * Column names of the actual value columns
   */
  actualValueColumnNames: string[]
  /**
   * Columns of the data grid
   */
  columns: RecommendationArtifacts.DataGridColumnSchema[]
  /**
   * Rows of the data grid
   */
  rows: RecommendationArtifacts.DataGridRowSchema[]
  /**
   * Additional props for grid the container
   */
  containerProps?: Partial<SectionTileComponentProps>
}

const TopArticlesItemComponent: React.FC<TopArticlesItemComponentProps> = ({
  title,
  comparisonMode = false,
  actualValueColumnNames = [],
  columns, id, rows,
  limit = 10,
  expanded = false,
  containerProps,
}) => {
  const apiRef = useGridApiRef()
  const actualColumns = getColumnsConfigFromSchema(columns)
  const actualRows = rows.slice(0, limit)
  const slots = {
    toolbar: null,
  }

  useEffect(() => {
    if (apiRef.current && apiRef.current.setColumnVisibility) {
      actualValueColumnNames.forEach((actualValueColumnName) => {
        apiRef.current.setColumnVisibility(actualValueColumnName, comparisonMode)
      })

      apiRef.current.autosizeColumns(DEFAULT_DATA_GIRD_AUTOSIZE_OPTIONS)
    }
  }, [comparisonMode, expanded, limit, apiRef, actualValueColumnNames])

  const getRowId = useCallback((row: RecommendationArtifacts.DataGridRowSchema) => row.id as GridRowId, [])

  return (
    <SectionTileComponent
      name={id}
      title={title}
      contentType='data-grid-headless'
      {...containerProps}
    >
      <DataGridComponent
        id={id}
        name={DATA_GRIDS.TOP_ITEMS_ARTIFACT}
        columns={actualColumns}
        apiRef={apiRef}
        rows={actualRows}
        getRowId={getRowId}
        rounded={false}
        enablePersistence={false}
        pagination={false}
        hideFooter={true}
        autosizeOnMount={true}
        disableAutosize={false}
        columnHeaderHeight={40}
        readonly={true}
        autoHeight={true}
        disableColumnMenu={true}
        disableColumnResize={true}
        disableVirtualization={true}
        disableAggregation={true}
        slots={slots}
      />
    </SectionTileComponent>
  )
}

export default TopArticlesItemComponent
