import { useMemo } from 'react'
import { deDE, enUS } from '@mui/x-data-grid-premium/locales'
import { SHORT_LOCALES } from '@constants/locales.constants'
import { useSelector } from '@redux/hooks'
import { getSelectedLanguage } from '@redux/modules/customer/customer.selectors'

export interface useDataGridLocalesProps {
  customLocales?: Record<string, string | Function>
}

export const useDataGridLocales = ({
  customLocales,
}: useDataGridLocalesProps) => {
  const userLanguage = useSelector(getSelectedLanguage)

  return useMemo(() => {
    const base = {}

    switch (userLanguage) {
      case SHORT_LOCALES.de:
        Object.assign(base, deDE.components.MuiDataGrid.defaultProps.localeText)
        break
      case SHORT_LOCALES.en:
      default:
        Object.assign(base, enUS.components.MuiDataGrid.defaultProps.localeText)
        break
    }

    if (customLocales) {
      Object.assign(base, customLocales)
    }

    return base
  }, [userLanguage, customLocales])
}
