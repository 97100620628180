import {
  takeLatest,
  put, call,
} from 'redux-saga/effects'

import { HERA_PIPELINE_RUN_NOT_FOUND, TOAST_TYPE_ERROR } from '@constants/common.constants'
import { PROMOTION_DAYS } from '@constants/events.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload } from '@redux/modules/types'
import { setEventsVisibility, setEventsToExclude } from '@utils/local-storage.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { requestAnalyzeViewAction } from '@redux/modules/analyze/analyze.actions'
import { requestBacktestingAction, requestBaselineComparisonAction, requestLiveMonitoringAction } from '@redux/modules/monitor/monitor.actions'

import * as API from '@redux/modules/hera/hera.api'

import {
  REQUEST_PROMOTIONS_SETTINGS_CHANGE,
  REQUEST_PROMOTIONS_VISIBILITY_CHANGE,
  REQUEST_INSIGHTS_ABC_TOTALS,
  REQUEST_INSIGHTS_PIPELINE_RUN_INFO,
  REQUEST_LAST_YEAR_VISIBILITY_CHANGE,
  REQUEST_INSIGHTS_PRELOAD,
} from './insights.action-types'

import {
  receiveEventsSettingsChangeAction,
  receiveEventsVisibilityChangeAction,
  receiveInsightsPipelineRunInfoAction,
  receiveLastYearVisibilityChangeAction,
  startFetchingInsightsAction,
  stopFetchingInsightsAction,
  receiveInsightsAbcTotalsAction,
  receiveInsightsPreloadAction,
} from './insights.actions'

import {
  RequestInsightsAbcTotalsActionPayload,
  RequestInsightsPreloadActionPayload,
  RequestLatestPipelineRunInfoActionPayload,
} from './insights.types'

export function* requestInsightsAbcTotalsGenerator({ payload }: ActionPayload<RequestInsightsAbcTotalsActionPayload>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_INSIGHTS_ABC_TOTALS))

    const {
      preload,
      ...rest
    } = payload

    const response: Hera.AbcTotalsApiResponse = yield call(API.getABCTotals, rest)

    yield put(receiveInsightsAbcTotalsAction(response))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveInsightsAbcTotalsAction({}))

    if (!payload.preload) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_INSIGHTS_ABC_TOTALS))
  }
}

export function* requestInsightsPipelineRunInfoHandler({ payload }: ActionPayload<RequestLatestPipelineRunInfoActionPayload>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_INSIGHTS_PIPELINE_RUN_INFO))

    const {
      preload,
      ...rest
    } = payload

    const response: Hera.LatestPipelineRunInfoAPIResponse = yield call(API.getInsightsLatestPipelineRunInfo, rest)

    yield put(receiveInsightsPipelineRunInfoAction(response))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveInsightsPipelineRunInfoAction({}))

    if (e?.response?.data?.data?.reason !== HERA_PIPELINE_RUN_NOT_FOUND && !payload.preload) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_INSIGHTS_PIPELINE_RUN_INFO))
  }
}

export function* requestEventsVisibilityChangeGenerator({ payload }: ActionPayload<boolean>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_PROMOTIONS_VISIBILITY_CHANGE))

    yield call(setEventsVisibility, payload)

    yield put(receiveEventsVisibilityChangeAction(payload))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_PROMOTIONS_VISIBILITY_CHANGE))
  }
}

export function* requestEventsSettingsChangeGenerator({ payload }: ActionPayload<PROMOTION_DAYS[]>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_PROMOTIONS_SETTINGS_CHANGE))

    yield call(setEventsToExclude, payload)

    yield put(receiveEventsSettingsChangeAction(payload))

    yield put(setPrimaryModalPageName(null))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_PROMOTIONS_SETTINGS_CHANGE))
  }
}

export function* requestLastYearVisibilityChangeGenerator({ payload }: ActionPayload<boolean>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_LAST_YEAR_VISIBILITY_CHANGE))

    yield put(receiveLastYearVisibilityChangeAction(payload))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_LAST_YEAR_VISIBILITY_CHANGE))
  }
}

export function* requestInsightsPreloadGenerator({ payload }: ActionPayload<RequestInsightsPreloadActionPayload>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_INSIGHTS_PRELOAD))

    yield put(requestAnalyzeViewAction({
      useCaseId: payload.useCaseId,
      preload: true,
    }))

    yield put(requestBacktestingAction({
      useCaseId: payload.useCaseId,
      preload: true,
    }))

    yield put(requestLiveMonitoringAction({
      useCaseId: payload.useCaseId,
      preload: true,
    }))

    yield put(requestBaselineComparisonAction({
      useCaseId: payload.useCaseId,
      preload: true,
    }))

    yield put(receiveInsightsPreloadAction())
  } catch (e) {
    /** no-op */
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_INSIGHTS_PRELOAD))
  }
}

export function* watchRequestInsightsAbcTotals() {
  yield takeLatest(REQUEST_INSIGHTS_ABC_TOTALS, requestInsightsAbcTotalsGenerator)
}

export function* watchRequestInsightsPipelineRunInfo() {
  yield takeLatest(REQUEST_INSIGHTS_PIPELINE_RUN_INFO, requestInsightsPipelineRunInfoHandler)
}

export function* watchRequestEventsVisibilityChange() {
  yield takeLatest(REQUEST_PROMOTIONS_VISIBILITY_CHANGE, requestEventsVisibilityChangeGenerator)
}

export function* watchRequestEventsSettingsChange() {
  yield takeLatest(REQUEST_PROMOTIONS_SETTINGS_CHANGE, requestEventsSettingsChangeGenerator)
}

export function* watchRequestLastYearVisibilityChange() {
  yield takeLatest(REQUEST_LAST_YEAR_VISIBILITY_CHANGE, requestLastYearVisibilityChangeGenerator)
}

export function* watchRequestInsightsPreload() {
  yield takeLatest(REQUEST_INSIGHTS_PRELOAD, requestInsightsPreloadGenerator)
}

export default {
  watchRequestInsightsAbcTotals,
  watchRequestInsightsPipelineRunInfo,
  watchRequestEventsVisibilityChange,
  watchRequestEventsSettingsChange,
  watchRequestLastYearVisibilityChange,
  watchRequestInsightsPreload,
}
