import throttle from 'lodash.throttle'
import { EMITTER_TYPES, DEFAULT_THROTTLE_TIMEOUT } from '@constants/emitter.constants'
import eventEmitter, { axiosCancelTokenController } from '@utils/emitter.utils'

import { AXIOS_UPLOAD_TIMEOUT_IN_MS, FILES } from '@constants/api-provider.constants'
import { FILES_URI } from '@constants/env-replacements.constants'
import { checkApiForError } from '@utils/api.utils'
import apiProvider from '@redux/api-provider'
import axios from 'axios'

export const getFilesList = (bucket: string, searchPattern?: string) => {
  return apiProvider.getApi(FILES).post('v2/files/list', {
    bucket,
    searchPattern: searchPattern || undefined,
  })
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data?.files || []
    })
}

export const uploadFile = (formData: FormData, fileName: string, filePath: string) => {
  return apiProvider.getApi(FILES).post('v2/file/store', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: AXIOS_UPLOAD_TIMEOUT_IN_MS,
    signal: axiosCancelTokenController.controller.signal,
    onUploadProgress: throttle((progressEvent) => {
      eventEmitter.emit(
        EMITTER_TYPES.FILE_UPLOAD_PROGRESS,
        {
          name: fileName,
          path: filePath,
          total: progressEvent.total,
          loaded: progressEvent.loaded,
        },
      )
    }, DEFAULT_THROTTLE_TIMEOUT),
  })
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data?.fileUrl || ''
    })
}

export const generateUploadToken = () => {
  return apiProvider.getApi(FILES).post('v2/upload-token/generate', {})
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data?.uploadToken || ''
    })
}

export const generateDownloadToken = (bucket: string, fileKey: string) => {
  return apiProvider.getApi(FILES).post('v2/download-token/generate', {
    fileKey,
    bucket,
  })
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data?.downloadToken || ''
    })
}

export const deleteFile = (bucket: string, fileKey: string) => {
  return apiProvider.getApi(FILES).post('v1/file/delete', {
    fileKey,
    bucket,
  })
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export interface DownloadByTokenPayload {
  downloadToken: string,
  fileName: string,
}

export const downloadByToken = ({ downloadToken, fileName } : DownloadByTokenPayload) => {
  const URL = `${FILES_URI}v2/file/download-by-token?downloadToken=${downloadToken}`

  const link = document.createElement('a')
  link.download = fileName
  link.href = URL

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadFile = (bucket: string, fileKey: string) => {
  return apiProvider.getApi(FILES).post('v2/file/download', {
    fileKey: encodeURIComponent(fileKey),
    bucket,
  })
    .then(({ data }) => {
      checkApiForError(data)

      return data
    })
}

export const downloadFileForPreviewByToken = (downloadToken: string, fileName: string) => {
  const URL = `${FILES_URI}v2/file/download-by-token?downloadToken=${downloadToken}`

  return apiProvider.getApi(FILES).get(URL, {
    responseType: 'blob',
    signal: axiosCancelTokenController.controller.signal,
    onDownloadProgress: throttle((progressEvent) => {
      eventEmitter.emit(
        EMITTER_TYPES.FILE_DOWNLOAD_PROGRESS,
        {
          name: fileName,
          total: progressEvent.total,
          loaded: progressEvent.loaded,
        },
      )
    }, DEFAULT_THROTTLE_TIMEOUT),
  }).then((response) => {
    checkApiForError(response.data)

    return response
  })
}

export const downloadFileForPreview = (bucket: string, fileKey: string) => {
  return apiProvider.getApi(FILES).post('v2/file/download', {
    fileKey,
    bucket,
  }, {
    responseType: 'blob',
    signal: axiosCancelTokenController.controller.signal,
    onDownloadProgress: throttle((progressEvent) => {
      eventEmitter.emit(
        EMITTER_TYPES.FILE_DOWNLOAD_PROGRESS,
        {
          name: fileKey,
          total: progressEvent.total,
          loaded: progressEvent.loaded,
        },
      )
    }, DEFAULT_THROTTLE_TIMEOUT),
  }).then((response) => {
    checkApiForError(response.data)

    return response
  })
}

export const getRemoteFileSize = async (bucket: string, fileKey: string) => {
  const cancelTokenSource = axios.CancelToken.source()
  let totalLength = 0

  try {
    await apiProvider.getApi(FILES).post('v2/file/download', {
      fileKey,
      bucket,
    }, {
      cancelToken: cancelTokenSource.token,
      onDownloadProgress: (progressEvent) => {
        if (totalLength === 0) {
          totalLength = progressEvent.total || 0
        } else if (totalLength > 0) {
          try {
            cancelTokenSource.cancel('Request canceled after obtaining total size')
          } catch (e) {
            /* ignore */
          }
        }
      },
    })
  } catch (e) {
    /* ignore */
  }

  return totalLength
}
