import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { isFetchingLiveMonitoring, getLiveMonitoringChart, isFetchingLiveMonitoringChart } from '@redux/modules/monitor/monitor.selectors'
import { formatRelativeDeviation } from '@utils/insights.utils'

import InsightsKpisComponent from '@components/insights/InsightsKpis'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'

const LiveMonitoringKpisContainer = () => {
  const intl = useIntl()
  const liveMonitoringChart = useSelector(getLiveMonitoringChart)
  const isFetchingLiveMonitoringChartFlag = useSelector(isFetchingLiveMonitoringChart)
  const isFetchingLiveMonitoringFlag = useSelector(isFetchingLiveMonitoring)

  const {
    targetName,
    overallNumbers = {
      absDeviation: 0,
      relDeviation: 0,
      target: 0,
      prediction: 0,
    },
  } = liveMonitoringChart

  const kpiCards = [
    {
      title: intl.formatMessage({ id: 'insights.kpis.absDeviation' }),
      unit: intl.formatMessage({ id: 'insights.table.mae' }),
      tooltip: intl.formatMessage({ id: 'insights.table.mae.help' }, {
        name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.mae.label' })}</IntlFormatBoldComponent>,
      }),
      value: overallNumbers.absDeviation,
    },
    {
      title: intl.formatMessage({ id: 'insights.kpis.relDeviation' }),
      unit: intl.formatMessage({ id: 'insights.table.wape' }),
      tooltip: intl.formatMessage({ id: 'insights.table.wape.help' }, {
        name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.wape.label' })}</IntlFormatBoldComponent>,
      }),
      value: formatRelativeDeviation(intl, overallNumbers.relDeviation),
      valueType: 'string' as const,
    },
    {
      title: intl.formatMessage({ id: 'insights.table.targetColumn' }, { name: targetName }),
      unit: intl.formatMessage({ id: 'insights.table.sum' }),
      value: overallNumbers.target,
    },
    {
      title: intl.formatMessage({ id: 'insights.table.predictionColumn' }, { name: targetName }),
      unit: intl.formatMessage({ id: 'insights.table.sum' }),
      value: overallNumbers.prediction,
      variant: 'highlighted' as const,
    },
  ]

  return (
    <InsightsKpisComponent
      loading={isFetchingLiveMonitoringFlag}
      loadingValues={isFetchingLiveMonitoringChartFlag}
      cards={kpiCards}
    />
  )
}

export default LiveMonitoringKpisContainer
