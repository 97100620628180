import React, {
  useCallback, useEffect,
  useMemo, useState,
} from 'react'

import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

import EditIcon from '@icons/edit.icon'
import ViewIcon from '@icons/view.icon'
import CopyIcon from '@icons/copy.icon'
import DeleteIcon from '@icons/delete.icon'
import DownloadIcon from '@icons/download.icon'

import { useDispatch, useSelector } from '@redux/hooks'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { DATA_UPLOAD_PATH } from '@constants/routes.constants'

import {
  removeTrainingDataAction,
  downloadTrainingDataAction,
} from '@redux/modules/training-files/training-files.actions'

import {
  generateActionsColumnDefinition,
  generateDateTimeColumnDefinition,
  generateFileSizeColumnDefinition,
  generateRegularColumnDefinition,
  generateCustomMasterDetailColumnDefinition,
  getSimplifiedStringOperators,
} from '@utils/data-grid-cells.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import FileBrowserItemPreviewDialog from '@components/file-browser/FileBrowserItemPreviewDialog'
import TrainingStatusChipComponent from '@components/training-data/TrainingStatusChip'
import TrainingDataErrorRowComponent from '@components/training-data/TrainingDataErrorRow'

import { isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'
import { FILE_PREVIEW_TYPE, SUPPORTED_PREVIEW_FILE_TYPES } from '@constants/files.constants'
import { requestFilePreviewAction } from '@redux/modules/file-service/file-service.actions'
import { isLegacyUploadCheck } from '@utils/use-cases.utils'
import { getProcessingStatusOptions, isActionButtonDisabled } from '@utils/training-data.utils'
import {
  getCsvEditButtonLabel, getCsvPreviewButtonLabel,
  isCsvEditDisabled, isCsvPreviewDisabled,
} from '@utils/files.utils'

import {
  getUseCaseTrainingData, isFetchingFileIdentifiers, isFetchingTrainingData,
  isSubmittingFileIdentifier,
  isSubmittingTrainingData, isUploadingTrainingData,
} from '@redux/modules/training-files/training-files.selectors'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'
import { copyToClipboard } from '@utils/common.utils'
import {
  GRID_ACTIONS_COLUMN_TYPE, GridColDef,
  GridRowId, GridRowParams, GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-premium'

import useGridInitialState from '@hooks/useGridInitialState.hook'

export interface TrainingFilesListComponentProps {
  /**
   * Selected file which will be expanded in the detail panel
   */
  selectedFile: string | null,
}

const TrainingFilesListComponent: React.FC<TrainingFilesListComponentProps> = ({
  selectedFile,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const list = useSelector(getUseCaseTrainingData)
  const isAdmin = useSelector(getIsAdmin)

  const isSubmittingFileIdentifierFlag = useSelector(isSubmittingFileIdentifier)
  const isFetchingFileIdentifiersFlag = useSelector(isFetchingFileIdentifiers)
  const isFetchingUseCaseData = useSelector(isFetchingUseCase)
  const isFetchingTrainingDataList = useSelector(isFetchingTrainingData)
  const isUploading = useSelector(isUploadingTrainingData)
  const isSubmitting = useSelector(isSubmittingTrainingData)
  const isFetching = isFetchingUseCaseData || isFetchingTrainingDataList || isFetchingFileIdentifiersFlag || isUploading || isSubmittingFileIdentifierFlag || isSubmitting

  const [removeFileDialogState, setRemoveFileDialogState] = useState<null | TrainingFiles.TrainingFileItem>(null)
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])

  const {
    params: {
      usecase: useCaseId,
      version: selectedVersion,
      identifier: selectedFileIdentifier,
      file: fileLink,
    },
  } = useRouteMatch<Common.RouterMatch>()

  const tableId = getDataGridId(DATA_GRIDS.TRAINING_DATA_TABLE, 1)
  const isLegacyUpload = isLegacyUploadCheck(selectedFileIdentifier)
  const initialState = useGridInitialState(tableId, {
    pagination: {
      paginationModel: {
        pageSize: 10,
      },
    },
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    columns: {
      columnVisibilityModel: {
        [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
      },
    },
    sorting: {
      sortModel: [{ field: 'createdAt', sort: 'desc' }],
    },
  })

  const slotProps = {
    toolbar: {
      withExport: false,
    },
  }

  useEffect(() => {
    if (selectedFile) {
      setDetailPanelExpandedRowIds([selectedFile])
    } else {
      setDetailPanelExpandedRowIds([])
    }
  }, [selectedFile])

  const handleCopyTrainingFileItemId = useCallback((file: TrainingFiles.TrainingFileItem) => {
    copyToClipboard({
      e: null,
      text: file.trainingDataFileId,
      intl,
    })
  }, [intl])

  const handleFileDownload = useCallback((file: TrainingFiles.TrainingFileItem) => {
    dispatch(
      downloadTrainingDataAction({
        useCaseId,
        fileName: file.fileName,
        trainingDataFileId: file.trainingDataFileId,
      }),
    )
  }, [dispatch, useCaseId])

  const handleFileDelete = useCallback((file: TrainingFiles.TrainingFileItem | null) => {
    if (!file) {
      return
    }

    dispatch(
      removeTrainingDataAction({
        meta: {
          useCaseId,
          trainingDataFileId: file.trainingDataFileId,
          version: selectedVersion ? Number(selectedVersion) : undefined,
          fileIdentifier: selectedFileIdentifier,
          fetchList: true,
          showToast: true,
        },
      }),
    )

    if (file.trainingDataFileId === fileLink) {
      history.push(generatePath(DATA_UPLOAD_PATH, { usecase: useCaseId, identifier: selectedFileIdentifier, version: selectedVersion }))
    }
  }, [history, useCaseId, selectedFileIdentifier, selectedVersion, fileLink, dispatch])

  const handlePreview = useCallback((file: TrainingFiles.TrainingFileItem, edit = false) => {
    dispatch(
      requestFilePreviewAction({
        fileName: file.fileName,
        filePath: file.trainingDataFileId,
        fileExtension: SUPPORTED_PREVIEW_FILE_TYPES.CSV,
        fileType: FILE_PREVIEW_TYPE.TRAINING_FILES,
        isEditable: edit,
      }),
    )
  }, [dispatch])

  const actionsCount = useMemo(() => {
    let defaultActionsCount = 2

    if (isAdmin) {
      defaultActionsCount += 2
    }

    if (!isLegacyUpload) {
      defaultActionsCount += 1
    }

    return defaultActionsCount
  }, [
    isAdmin,
    isLegacyUpload,
  ])

  const getActionItems = useCallback((params: GridRowParams<TrainingFiles.TrainingFileItem>) => {
    const actions = [
      (
        <DataGridActionButtonComponent
          name='previewFile'
          icon={<ViewIcon />}
          onClick={() => handlePreview(params.row)}
          label={getCsvPreviewButtonLabel(intl, params.row)}
          disabled={isCsvPreviewDisabled(params.row)}
          id={params.id}
        />
      ),
    ]

    if (!isLegacyUpload) {
      actions.push(
        (
          <DataGridActionButtonComponent
            name='editFile'
            icon={<EditIcon />}
            onClick={() => handlePreview(params.row, true)}
            label={getCsvEditButtonLabel(intl, params.row)}
            disabled={isCsvEditDisabled(params.row)}
            id={params.id}
          />
        ),
      )
    }

    actions.push(
      (
        <DataGridActionButtonComponent
          name='downloadFile'
          icon={<DownloadIcon />}
          onClick={() => handleFileDownload(params.row)}
          label={intl.formatMessage({ id: 'common.tables.actions.download' })}
          disabled={isActionButtonDisabled(isUploading, params.row)}
          id={params.id}
        />
      ),
    )

    if (isAdmin) {
      actions.unshift(
        (
          <DataGridActionButtonComponent
            name='copyFileId'
            icon={<CopyIcon />}
            onClick={() => handleCopyTrainingFileItemId(params.row)}
            label={intl.formatMessage({ id: 'common.tables.actions.copy' })}
            id={params.id}
          />
        ),
      )

      actions.push(
        (
          <DataGridActionButtonComponent
            name='removeFile'
            icon={<DeleteIcon />}
            onClick={() => setRemoveFileDialogState(params.row)}
            label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
            disabled={isActionButtonDisabled(isUploading, params.row)}
            id={params.id}
          />
        ),
      )
    }

    return actions
  }, [
    intl, isAdmin, isUploading, isLegacyUpload,
    handleFileDownload, handleCopyTrainingFileItemId, handlePreview,
  ])

  const processingStatusOptions = useMemo(() => getProcessingStatusOptions(intl), [intl])

  const columns = useMemo<GridColDef[]>(() => {
    const cols = [
      generateRegularColumnDefinition({
        intl,
        field: 'fileName',
        headerName: intl.formatMessage({ id: 'connect.data_input.modal.dropzone.file_name' }),
        gridColDefOverrides: {
          minWidth: 150,
          width: 200,
          filterOperators: getSimplifiedStringOperators(intl),
        },
      }),
      ...generateCustomMasterDetailColumnDefinition({
        field: 'processingStatus',
        headerName: intl.formatMessage({ id: 'connect.data_input.modal.dropzone.status' }),
        CellComponent: TrainingStatusChipComponent,
        cellComponentProps: {
          useCaseId,
          reflectRouteChange: true,
        },
        gridColDefOverrides: {
          type: 'singleSelect',
          valueOptions: processingStatusOptions,
        },
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'createdAt',
        headerName: intl.formatMessage({ id: 'connect.data_input.modal.dropzone.upload_date' }),
        gridColDefOverrides: {
          minWidth: 200,
          width: 200,
        },
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: actionsCount,
      }),
    ]

    if (!isLegacyUpload) {
      cols.push(
        generateFileSizeColumnDefinition({
          intl,
          field: 'fileSizeBytes',
          headerName: intl.formatMessage({ id: 'connect.data_input.modal.dropzone.file_size' }),
          gridColDefOverrides: {
            minWidth: 120,
            width: 120,
          },
        }),
      )
    }

    return cols
  }, [
    intl,
    useCaseId,
    actionsCount,
    isLegacyUpload,
    processingStatusOptions,
    getActionItems,
  ])

  const getDetailPanelContent = useCallback(({ row }: GridRowParams) => <TrainingDataErrorRowComponent record={row} />, [])
  const getDetailPanelHeight = useCallback(() => 'auto', [])
  const getRowId = useCallback((row: TrainingFiles.TrainingFileItem) => row.trainingDataFileId, [])
  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      )
    },
    [],
  )

  return (
    <Box
      data-testid={TrainingFilesListComponent.name}
    >
      <DataGridComponent
        stateResetKey={selectedFileIdentifier}
        id={tableId}
        name={DATA_GRIDS.TRAINING_DATA_TABLE}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={false}
        disableVirtualization={true}
        getRowId={getRowId}
        initialState={initialState}
        disableRowGrouping={true}
        slotProps={slotProps}
        enablePersistence={false}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
      />

      <FileBrowserItemPreviewDialog />

      <TextConfirmationDialogComponent
        open={Boolean(removeFileDialogState) && isAdmin}
        onClose={() => setRemoveFileDialogState(null)}
        onSubmit={() => handleFileDelete(removeFileDialogState)}
        confirmationText={removeFileDialogState?.fileName}
        confirmationInputLabel={intl.formatMessage({ id: 'connect.block.data_input.table.removeFileDialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'connect.block.data_input.table.removeFileDialog.content' }, {
            name: <Box component='strong'>{removeFileDialogState?.fileName}</Box>,
          })
        }
      />
    </Box>
  )
}

export default TrainingFilesListComponent
