import {
  takeLatest, all,
  put, select, take,
} from 'redux-saga/effects'

import * as Effects from 'redux-saga/effects'

import { push } from 'redux-first-history'
import { generatePath } from 'react-router-dom'

import omit from 'lodash.omit'

import {
  NOT_FOUND_PATH, CONNECT_PATH,
  ARTICLE_ALLOCATION_SETUP_PATH,
  REPLENISHMENT_SETUP_PATH,
} from '@constants/routes.constants'

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse, isNotFound } from '@utils/api.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import {
  usecaseEnrichment,
  isDemandFamilyCheck,
  isArticleAllocationFamilyCheck,
  isRecommendationFamilyCheck,
} from '@utils/use-cases.utils'

import {
  fetchAllSourcesAction,
  fetchConnectionsWithDetailsAction,
} from '@redux/modules/hermes/hermes.actions'

import {
  getAvailableSources,
  getCompanyDetailedConnections,
} from '@redux/modules/hermes/hermes.selectors'

import {
  changeCompanyIdAction,
} from '@redux/modules/customer/customer.actions'

import {
  RECEIVE_SOURCES,
  RECEIVE_DETAILED_CONNECTIONS,
} from '@redux/modules/hermes/hermes.action-types'

import { changeToastAction } from '@redux/modules/common/common.actions'
import { getSelectedCompanyId, getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { ActionPayload, State } from '@redux/modules/types'

import { requestFileIdentifiersListAction } from '@redux/modules/training-files/training-files.actions'
import { RECEIVE_FILE_IDENTIFIERS_LIST } from '@redux/modules/training-files/training-files.action-types'
import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'
import { getForecastValue } from '@redux/modules/hera/hera.api'

import * as API from './use-case.api'
import { getUseCaseItem, getUseCasesList } from './use-case.selectors'

import {
  REDIRECT_TO_USE_CASE,
  REQUEST_DEMAND_PROBLEM_DEFINITION,
  FREEZE_USE_CASE,
  MANAGE_USE_CASE,
  REQUEST_USE_CASES,
  REQUEST_USE_CASE,
  CREATE_USE_CASE,
  RECEIVE_USE_CASE,
  UPDATE_USE_CASE,
  UPDATE_DEMAND_PROBLEM_DEFINITION,
  DELETE_USE_CASE,
  REQUEST_ARTIFACT_MAPPINGS,
  CREATE_ARTIFACT_MAPPINGS,
  PREPARE_CONNECT_OVERVIEW,
  DELETE_ARTIFACT_MAPPINGS,
  CREATE_FORECAST_PARAMETERS,
  RECEIVE_USE_CASES,
  SET_USE_CASE_CONTACTS,
  RECEIVE_DEMAND_PROBLEM_DEFINITION,
  RECEIVE_USE_CASE_AVAILABLE_META_DATA_COLUMNS,
  RECEIVE_USE_CASE_SPECIFIED_META_DATA_COLUMNS,
  REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS,
  REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS,
  SET_USE_CASE_META_DATA_COLUMNS,
} from './use-case.action-types'

import {
  fetchUseCaseAction,
  fetchAllUseCasesAction,
  receiveUseCaseActionDone,
  receiveUseCasesActionDone,
  prepareConnectOverviewActionDone,
  startFetchingUseCasesAction,
  stopFetchingUseCasesAction,
  receiveArtifactsMappingsActionDone,
  requestArtifactsMappingsAction,
  updateDemandProblemDefinitionAction,
  receiveDemandProblemDefinitionActionDone,
  setUseCaseContactsAction,
  requestDemandProblemDefinitionAction,
  requestUseCaseAvailableMetaDataColumnsAction,
  requestUseCaseSpecifiedMetaDataColumnsAction,
  receiveUseCaseAvailableMetaDataColumnsActionDone,
  receiveUseCaseSpecifiedMetaDataColumnsActionDone,
  setUseCaseMetaDataColumnsAction,
  redirectToUseCaseActionDone,
  redirectToUseCaseAction,
} from './use-case.actions'

import {
  ManageUseCaseActionPayload,
  CreateArtifactsMappingsPayload,
  CreatePayload,
  DeleteArtifactsMappingsPayload,
  EditDemandProblemDefinitionPayload,
  FetchAllUseCasesActionActionPayload,
  FetchUseCaseActionPayload,
  GetArtifactsMappingsPayload,
  UpdatePayload,
  UpdateUseCaseActionPayload,
  GetDemandProblemDefinitionPayload,
  SetUseCaseContactsPayload,
  CreateForecastParametersPayload,
  ConnectOverviewUseCaseGeneratorPayload,
  GetAvailableMetaDataColumnsActionPayload,
  GetSpecifiedMetaDataColumnsActionPayload,
  SetSpecifiedMetaDataColumnsActionPayload,
  FreezeUseCaseActionPayload,
  RemoveUseCaseActionPayload,
  RedirectToUseCaseActionPayload,
} from './use-case.types'

const CREATE_FORECAST_PARAMETERS_SUCCESS = 'use_cases.forecast.parameters_create'
const CREATE_USE_CASE_SUCCESS = 'use_cases.confirmation.create'
const UPDATE_USE_CASE_SUCCESS = 'use_cases.confirmation.update'
const DELETE_USE_CASE_SUCCESS = 'use_cases.confirmation.deleteOne'
const ARTIFACT_CREATE_USE_CASE_SUCCESS = 'use_cases.confirmation.artifact_created'
const ARTIFACT_DELETE_USE_CASE_SUCCESS = 'use_cases.confirmation.artifact_deleted'
const FREEZE_USE_CASE_SUCCESS = 'use_cases.confirmation.freeze'
const UNFREEZE_USE_CASE_SUCCESS = 'use_cases.confirmation.unfreeze'
const NOT_FOUND_MESSAGE = 'common.useCaseNotFound.title'
const SET_SPECIFIED_META_DATA_COLUMNS_SUCCESS = 'use_cases.confirmation.set_specified_meta_data_columns'

const call: any = Effects.call

export const useCaseFieldsToOmit = [
  'finishedAt', 'updatedAt', 'createdAt', 'removedAt',
  'analysisPlotAvailable', 'artifactAvailable', 'executePlotAvailable',
  'predictionPlotAvailable', 'fileStorageBucket', 'artifacts', 'contacts',
  'forecastValue', 'forecastParameters', 'metaDataColumns', 'demandUseCaseId',
]

export function* fetchAllUseCasesGenerator({ payload } : ActionPayload<FetchAllUseCasesActionActionPayload>) {
  try {
    const {
      silent, includeForecast, companyId,
    } = payload

    if (!silent) {
      yield put(startFetchingUseCasesAction(REQUEST_USE_CASES))
    }

    const state: State = yield select()
    const selectedCompanyId: string = yield call(getSelectedCompanyId, state)
    const companyIdToUse = companyId || selectedCompanyId

    /** Blocking request: Get use-cases IDs */
    const useCases: string[] = yield call(API.fetchAll, { companyId: companyIdToUse })

    /** Parallel requests: Get use-cases details */
    const [useCasesWithDetails, forecastDetails, forecastParameters]: [
      UseCase.Details[],
      Hera.ForecastValue[],
      UseCase.ForecastParameters[],
    ] = yield all([
      useCases.length ? call(API.fetchMany, { useCaseIds: useCases }) : [],
      includeForecast ? call(getForecastValue, { useCaseIds: useCases }) : [],
      includeForecast ? call(API.getForecastParameters, { useCaseIds: useCases }) : [],
    ])

    yield put(
      receiveUseCasesActionDone(
        useCasesWithDetails.map((useCase) => {
          return usecaseEnrichment({
            useCase,
            forecastDetails,
            forecastParameters,
          })
        }),
      ),
    )
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))

    yield put(receiveUseCasesActionDone([]))
  } finally {
    yield put(stopFetchingUseCasesAction(REQUEST_USE_CASES))
  }
}

export function* fetchUseCaseGenerator({ payload } : ActionPayload<FetchUseCaseActionPayload>) {
  try {
    const {
      silent,
      includeForecast,
      useCaseId,
      includeDemandProblemDefinition,
      includeMetaDataColumns,
    } = payload

    if (!silent) {
      yield put(startFetchingUseCasesAction(REQUEST_USE_CASE))
    }

    const state: State = yield select()
    const isAdmin: boolean = yield call(getIsAdmin, state)
    const companyId: string = yield call(getSelectedCompanyId, state)

    /** Parallel requests: Get use-cases details */
    const [useCase, forecastDetails, forecastParameters]: [
      UseCase.Details,
      Hera.ForecastValue[],
      UseCase.ForecastParameters[],
    ] = yield all([
      call(API.fetch, { useCaseId, companyId }),
      includeForecast ? call(getForecastValue, { useCaseIds: [useCaseId] }) : [],
      includeForecast ? call(API.getForecastParameters, { useCaseIds: [payload.useCaseId] }) : [],
    ])

    if ((useCase.companyId !== companyId) && isAdmin) {
      yield put(changeCompanyIdAction({
        companyId: useCase.companyId,
        saveToLs: true,
      }))

      yield put(fetchAllUseCasesAction({
        companyId: useCase.companyId,
      }))
    }

    yield put(receiveUseCaseActionDone(
      usecaseEnrichment({
        useCase,
        forecastDetails,
        forecastParameters,
      }),
    ))

    if (includeDemandProblemDefinition) {
      yield put(requestDemandProblemDefinitionAction({ useCaseId }))

      yield take(RECEIVE_DEMAND_PROBLEM_DEFINITION)
    }

    if (includeMetaDataColumns) {
      yield put(requestUseCaseAvailableMetaDataColumnsAction({ useCaseId }))

      yield take(RECEIVE_USE_CASE_AVAILABLE_META_DATA_COLUMNS)

      yield put(requestUseCaseSpecifiedMetaDataColumnsAction({ useCaseId }))

      yield take(RECEIVE_USE_CASE_SPECIFIED_META_DATA_COLUMNS)
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isUseCaseNotFound = isNotFound(e as any)

    if (isUseCaseNotFound) {
      yield put(push('/'))
      yield put(changeToastAction({ useIntl: true, message: NOT_FOUND_MESSAGE, severity: TOAST_TYPE_ERROR }))
    } else {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }
  } finally {
    yield put(stopFetchingUseCasesAction(REQUEST_USE_CASE))
  }
}

export function* redirectToUseCaseGenerator({ payload }: ActionPayload<RedirectToUseCaseActionPayload>) {
  try {
    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const useCasesList: UseCase.DetailsExtended[] = yield call(getUseCasesList, state)
    const useCaseToRedirectTo = useCasesList.find((item) => item.useCaseId === payload.useCaseId) || useCase

    let route = CONNECT_PATH

    /**
     * If the use-case id is different from the current use-case id,
     * and the use-case details are available, fill the state with the use-case details.
     */
    if (useCase.useCaseId !== payload.useCaseId) {
      if (useCaseToRedirectTo) {
        yield put(redirectToUseCaseActionDone(useCaseToRedirectTo))
      }
    }

    /**
     * If the current level is company, we redirect to the connect page.
     */
    if (payload.currentLevel === 'company') {
      if (isRecommendationFamilyCheck(useCaseToRedirectTo.family)) {
        const isArticleAllocationFamily = isArticleAllocationFamilyCheck(useCaseToRedirectTo.family)

        route = isArticleAllocationFamily ? ARTICLE_ALLOCATION_SETUP_PATH : REPLENISHMENT_SETUP_PATH
      }
    }

    /**
     * If the current level is use-case, we redirect to the use-case page.
     */
    if (payload.currentLevel === 'use-case' && payload.currentPath) {
      /**
       * In case the use-case family is the same, we redirect to the same page.
       * This is to avoid redirecting to the same page when the use-case family is the same.
       */
      if (useCase.family === useCaseToRedirectTo.family) {
        route = payload.currentPath
      } else if (isRecommendationFamilyCheck(useCaseToRedirectTo.family)) {
        const isArticleAllocationFamily = isArticleAllocationFamilyCheck(useCaseToRedirectTo.family)

        route = isArticleAllocationFamily ? ARTICLE_ALLOCATION_SETUP_PATH : REPLENISHMENT_SETUP_PATH
      }
    }

    yield put(push(generatePath((payload.route || route), { usecase: payload.useCaseId })))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  }
}

export function* createUseCaseGenerator({ payload } : ActionPayload<CreatePayload>) {
  try {
    yield put(startFetchingUseCasesAction(CREATE_USE_CASE))

    const state: State = yield select()
    const companyId: string = yield call(getSelectedCompanyId, state)

    yield call(API.create, {
      ...payload,
      companyId,
    })

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: CREATE_USE_CASE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(fetchAllUseCasesAction({}))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(CREATE_USE_CASE))
  }
}

export function* updateUseCaseGenerator({ payload } : ActionPayload<UpdateUseCaseActionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(UPDATE_USE_CASE))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    yield call(API.update, {
      ...omit(useCase, useCaseFieldsToOmit),
      ...omit(payload, useCaseFieldsToOmit),
    } as UpdatePayload)

    yield put(fetchUseCaseAction({ useCaseId: useCase.useCaseId }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(UPDATE_USE_CASE))
  }
}

export function* updateDemandProblemDefinitionGenerator({ payload } : ActionPayload<EditDemandProblemDefinitionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(UPDATE_DEMAND_PROBLEM_DEFINITION))

    const demandConfig = {
      useCaseId: payload.useCaseId,
      predictionHorizon: Number(payload.predictionHorizon),
      evaluationHorizon: Number(payload.evaluationHorizon),
      timeResolution: payload.timeResolution,
      targetName: payload.targetName,
      targetUnit: payload.targetUnit,
    }

    yield call(API.updateDemandProblemDefinition, demandConfig)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(UPDATE_DEMAND_PROBLEM_DEFINITION))
  }
}

export function* deleteUseCaseGenerator({ payload }: ActionPayload<RemoveUseCaseActionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(DELETE_USE_CASE))

    yield call(API.remove, {
      useCaseId: payload.useCaseId,
    })

    yield put(fetchAllUseCasesAction({}))

    yield take(RECEIVE_USE_CASES)

    yield put(changeToastAction({ useIntl: true, message: DELETE_USE_CASE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(null))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(DELETE_USE_CASE))
  }
}

export function* freezeUseCaseGenerator({ payload }: ActionPayload<FreezeUseCaseActionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(FREEZE_USE_CASE))

    const state: State = yield select()
    const companyId: String = yield call(getSelectedCompanyId, state)
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const {
      freeze,
      showToast = true,
    } = payload

    yield call(API.update, {
      companyId,
      ...omit(useCase, useCaseFieldsToOmit),
      frozen: freeze ? 'True' : 'False',
    } as UpdatePayload)

    yield put(fetchUseCaseAction({ useCaseId: useCase.useCaseId }))

    yield take(RECEIVE_USE_CASE)

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: freeze ? FREEZE_USE_CASE_SUCCESS : UNFREEZE_USE_CASE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(FREEZE_USE_CASE))
  }
}

export function* manageUseCaseGenerator({ payload }: ActionPayload<ManageUseCaseActionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(MANAGE_USE_CASE))

    const {
      demandProblemDefinition,
      useCase,
      contacts,
      metaDataColumns,
    } = payload

    const state: State = yield select()
    const companyId: string = yield call(getSelectedCompanyId, state)

    if (!useCase.useCaseId) {
      const createdUseCase: UseCase.Details = yield call(API.create, {
        ...useCase,
        companyId,
      } as UseCase.Details)

      yield put(fetchAllUseCasesAction({}))

      yield take(RECEIVE_USE_CASES)

      yield put(redirectToUseCaseAction({
        useCaseId: createdUseCase.useCaseId,
      }))

      if (demandProblemDefinition) {
        yield put(updateDemandProblemDefinitionAction({
          ...demandProblemDefinition,
          useCaseId: createdUseCase.useCaseId,
        }))
      }

      yield put(changeToastAction({ useIntl: true, message: CREATE_USE_CASE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    } else {
      const prevUseCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

      if (prevUseCase.family !== useCase.family) {
        yield call(API.changeFamily, {
          useCaseId: useCase.useCaseId,
          family: useCase.family,
        })
      }

      if (demandProblemDefinition) {
        yield put(updateDemandProblemDefinitionAction({
          ...demandProblemDefinition,
          useCaseId: useCase.useCaseId,
        }))
      }

      if (contacts) {
        yield put(setUseCaseContactsAction({
          contacts,
          useCaseId: useCase.useCaseId,
        }))
      }

      if (metaDataColumns) {
        yield put(setUseCaseMetaDataColumnsAction({
          useCaseId: useCase.useCaseId,
          outputMetaDataColumns: metaDataColumns,
          showToast: false,
        }))
      }

      if (prevUseCase.demandUseCaseId !== useCase.demandUseCaseId) {
        yield call(API.linkDemandUseCase, {
          useCaseId: useCase.useCaseId,
          demandUseCaseId: useCase.demandUseCaseId ? useCase.demandUseCaseId : null,
        })
      }

      yield call(API.update, {
        companyId,
        ...useCase,
      } as UpdatePayload)

      yield put(fetchAllUseCasesAction({
        silent: true,
      }))

      yield take(RECEIVE_USE_CASES)

      yield put(changeToastAction({ useIntl: true, message: UPDATE_USE_CASE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }

    yield put(setPrimaryModalPageName(null))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(MANAGE_USE_CASE))
  }
}

export function* connectOverviewUseCaseGenerator({ payload }: ActionPayload<ConnectOverviewUseCaseGeneratorPayload>) {
  try {
    if (payload.recalculation) {
      const state: State = yield select()
      const isAdmin: boolean = yield call(getIsAdmin, state)
      const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
      const filesIdentifiers: TrainingFiles.FileIdentifierItem[] = yield call(getUseCaseFileIdentifiers, state)

      const connectionsList: Hermes.ConnectionDetails[] = yield call(getCompanyDetailedConnections, state)
      const sourcesList: Hermes.SourceDetails[] = yield call(getAvailableSources, state)

      yield put(prepareConnectOverviewActionDone({
        useCase,
        isAdmin,
        filesIdentifiers,
        connectionsList,
        sourcesList,
      }))
    } else {
      yield put(startFetchingUseCasesAction(PREPARE_CONNECT_OVERVIEW))

      const state: State = yield select()
      const companyId: string = yield call(getSelectedCompanyId, state)
      const isAdmin: boolean = yield call(getIsAdmin, state)

      yield put(fetchUseCaseAction({ useCaseId: payload.useCaseId }))

      yield put(requestFileIdentifiersListAction({ useCaseId: payload.useCaseId }))

      yield put(fetchAllSourcesAction())

      yield put(fetchConnectionsWithDetailsAction({ useCaseId: payload.useCaseId, companyId, updateConnectView: false }))

      yield all([
        take(RECEIVE_USE_CASE),
        take(RECEIVE_FILE_IDENTIFIERS_LIST),
        take(RECEIVE_SOURCES),
        take(RECEIVE_DETAILED_CONNECTIONS),
      ])

      const newState: State = yield select()
      const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, newState)
      const filesIdentifiers: TrainingFiles.FileIdentifierItem[] = yield call(getUseCaseFileIdentifiers, newState)
      const connectionsList: Hermes.ConnectionDetails[] = yield call(getCompanyDetailedConnections, newState)
      const sourcesList: Hermes.SourceDetails[] = yield call(getAvailableSources, newState)

      yield put(prepareConnectOverviewActionDone({
        useCase,
        isAdmin,
        filesIdentifiers,
        connectionsList,
        sourcesList,
      }))
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isUseCaseNotFound = isNotFound(e as any)

    if (isUseCaseNotFound) {
      yield put(push(NOT_FOUND_PATH))
    }

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(PREPARE_CONNECT_OVERVIEW))
  }
}

export function* deleteArtifactMappingGenerator({ payload }: ActionPayload<DeleteArtifactsMappingsPayload>) {
  try {
    yield put(startFetchingUseCasesAction(CREATE_ARTIFACT_MAPPINGS))

    yield call(API.deleteArtifactsMappings, payload)

    yield put(changeToastAction({ useIntl: true, message: ARTIFACT_DELETE_USE_CASE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestArtifactsMappingsAction({ useCaseId: payload.useCaseId }))

    yield put(fetchAllUseCasesAction({}))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(CREATE_ARTIFACT_MAPPINGS))
  }
}

export function* createArtifactMappingGenerator({ payload }: ActionPayload<CreateArtifactsMappingsPayload>) {
  try {
    yield put(startFetchingUseCasesAction(CREATE_ARTIFACT_MAPPINGS))

    yield call(API.createArtifactsMappings, payload)

    yield put(changeToastAction({ useIntl: true, message: ARTIFACT_CREATE_USE_CASE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestArtifactsMappingsAction({ useCaseId: payload.useCaseId }))

    yield put(fetchAllUseCasesAction({}))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(CREATE_ARTIFACT_MAPPINGS))
  }
}

export function* requestArtifactMappingGenerator({ payload }: ActionPayload<GetArtifactsMappingsPayload>) {
  try {
    yield put(startFetchingUseCasesAction(REQUEST_ARTIFACT_MAPPINGS))

    const data: UseCase.ArtifactMappingItem[] = yield call(API.getArtifactsMappings, payload)

    yield put(receiveArtifactsMappingsActionDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(REQUEST_ARTIFACT_MAPPINGS))
  }
}

export function* requestDemandProblemDefinitionGenerator({ payload }: ActionPayload<GetDemandProblemDefinitionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(REQUEST_DEMAND_PROBLEM_DEFINITION))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    if (isDemandFamilyCheck(useCase.family)) {
      const data: UseCase.DemandProblemDefinition = yield call(API.getDemandProblemDefinition, payload)

      yield put(receiveDemandProblemDefinitionActionDone(data))
    } else {
      yield put(receiveDemandProblemDefinitionActionDone(API.defaultDemandProblemDefinition))
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))

    yield put(receiveDemandProblemDefinitionActionDone(API.defaultDemandProblemDefinition))
  } finally {
    yield put(stopFetchingUseCasesAction(REQUEST_DEMAND_PROBLEM_DEFINITION))
  }
}

export function* setUseCaseContactsGenerator({ payload }: ActionPayload<SetUseCaseContactsPayload>) {
  try {
    yield put(startFetchingUseCasesAction(SET_USE_CASE_CONTACTS))

    yield call(API.setUseCaseContacts, payload)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(SET_USE_CASE_CONTACTS))
  }
}

export function* createForecastParametersGenerator({ payload }: ActionPayload<CreateForecastParametersPayload>) {
  try {
    yield put(startFetchingUseCasesAction(CREATE_FORECAST_PARAMETERS))

    yield call(API.createForecastParameters, payload)

    yield put(fetchAllUseCasesAction({}))

    yield take(RECEIVE_USE_CASES)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: CREATE_FORECAST_PARAMETERS_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(CREATE_FORECAST_PARAMETERS))
  }
}

export function* getAvailableMetaDataColumnsGenerator({ payload }: ActionPayload<GetAvailableMetaDataColumnsActionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS))

    const data: UseCase.MetaDataColumns = yield call(API.getAvailableMetaDataColumns, payload)

    yield put(receiveUseCaseAvailableMetaDataColumnsActionDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS))
  }
}

export function* getSpecifiedMetaDataColumnsGenerator({ payload }: ActionPayload<GetSpecifiedMetaDataColumnsActionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS))

    const data: UseCase.MetaDataColumns = yield call(API.getSpecifiedMetaDataColumns, payload)

    yield put(receiveUseCaseSpecifiedMetaDataColumnsActionDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS))
  }
}

export function* setSpecifiedMetaDataColumnsGenerator({ payload }: ActionPayload<SetSpecifiedMetaDataColumnsActionPayload>) {
  try {
    yield put(startFetchingUseCasesAction(SET_USE_CASE_META_DATA_COLUMNS))

    yield call(API.setSpecifiedMetaDataColumns, payload)

    if (payload.showToast) {
      yield put(changeToastAction({ useIntl: true, message: SET_SPECIFIED_META_DATA_COLUMNS_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingUseCasesAction(SET_USE_CASE_META_DATA_COLUMNS))
  }
}

export function* watchCreateForecastParameters() {
  yield takeLatest(CREATE_FORECAST_PARAMETERS, createForecastParametersGenerator)
}

export function* watchFetchAllUseCases() {
  yield takeLatest(REQUEST_USE_CASES, fetchAllUseCasesGenerator)
}

export function* watchFetchUseCase() {
  yield takeLatest(REQUEST_USE_CASE, fetchUseCaseGenerator)
}

export function* watchCreateUseCase() {
  yield takeLatest(CREATE_USE_CASE, createUseCaseGenerator)
}

export function* watchUpdateUseCase() {
  yield takeLatest(UPDATE_USE_CASE, updateUseCaseGenerator)
}

export function* watchUpdateDemandProblemDefinition() {
  yield takeLatest(UPDATE_DEMAND_PROBLEM_DEFINITION, updateDemandProblemDefinitionGenerator)
}

export function* watchDeleteUseCase() {
  yield takeLatest(DELETE_USE_CASE, deleteUseCaseGenerator)
}

export function* watchFreezeUseCase() {
  yield takeLatest(FREEZE_USE_CASE, freezeUseCaseGenerator)
}

export function* watchManageUseCase() {
  yield takeLatest(MANAGE_USE_CASE, manageUseCaseGenerator)
}

export function* watchRequestArtifactMappings() {
  yield takeLatest(REQUEST_ARTIFACT_MAPPINGS, requestArtifactMappingGenerator)
}

export function* watchCreateArtifactMappings() {
  yield takeLatest(CREATE_ARTIFACT_MAPPINGS, createArtifactMappingGenerator)
}

export function* watchDeleteArtifactMappings() {
  yield takeLatest(DELETE_ARTIFACT_MAPPINGS, deleteArtifactMappingGenerator)
}

export function* watchPrepareConnectOverviewUseCase() {
  yield takeLatest(PREPARE_CONNECT_OVERVIEW, connectOverviewUseCaseGenerator)
}

export function* watchRequestDemandProblemDefinition() {
  yield takeLatest(REQUEST_DEMAND_PROBLEM_DEFINITION, requestDemandProblemDefinitionGenerator)
}

export function* watchSetUseCaseContacts() {
  yield takeLatest(SET_USE_CASE_CONTACTS, setUseCaseContactsGenerator)
}

export function* watchGetAvailableMetaDataColumns() {
  yield takeLatest(REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS, getAvailableMetaDataColumnsGenerator)
}

export function* watchGetSpecifiedMetaDataColumns() {
  yield takeLatest(REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS, getSpecifiedMetaDataColumnsGenerator)
}

export function* watchSetSpecifiedMetaDataColumns() {
  yield takeLatest(SET_USE_CASE_META_DATA_COLUMNS, setSpecifiedMetaDataColumnsGenerator)
}

export function* watchRedirectToUseCase() {
  yield takeLatest(REDIRECT_TO_USE_CASE, redirectToUseCaseGenerator)
}

export default {
  watchCreateForecastParameters,
  watchFetchAllUseCases,
  watchFetchUseCase,
  watchCreateUseCase,
  watchUpdateUseCase,
  watchUpdateDemandProblemDefinition,
  watchDeleteUseCase,
  watchFreezeUseCase,
  watchManageUseCase,
  watchRequestArtifactMappings,
  watchCreateArtifactMappings,
  watchDeleteArtifactMappings,
  watchPrepareConnectOverviewUseCase,
  watchRequestDemandProblemDefinition,
  watchSetUseCaseContacts,
  watchGetAvailableMetaDataColumns,
  watchGetSpecifiedMetaDataColumns,
  watchSetSpecifiedMetaDataColumns,
  watchRedirectToUseCase,
}
