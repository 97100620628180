import React from 'react'
import { useIntl } from 'react-intl'

import { Box, Typography, useTheme } from '@mui/material'

import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'
import { INSIGHT_CHART_HEIGHT } from '@components/insights/InsightsChart/InsightsChart.component'

const BaselineComparisonEmptyContainer = () => {
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Box
      data-testid={BaselineComparisonEmptyContainer.name}
      sx={{
        width: '100%',
        height: INSIGHT_CHART_HEIGHT,
        borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
        border: `1px solid ${theme.palette.new.business_black_20}`,
        marginBottom: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <Typography
          variant='h4'
        >
          {intl.formatMessage({
            id: 'insights.empty.title',
          })}
        </Typography>
        <Typography
          variant='h6'
        >
          {intl.formatMessage({
            id: 'insights.empty.subtitle',
          })}
        </Typography>
      </Box>
    </Box>
  )
}

export default BaselineComparisonEmptyContainer
