import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  Box,
  ListItem, ListItemIcon, ListItemText,
  Typography,
} from '@mui/material'

import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch } from '@redux/hooks'
import { copyToClipboard, humanFileSize } from '@utils/common.utils'
import { FILE_MANAGER_FOLDER_PATH } from '@constants/routes.constants'
import {
  canPreviewFileWithExtension,
  getFileIcon,
} from '@utils/files.utils'

import DeleteIcon from '@icons/delete.icon'
import DownloadIcon from '@icons/download.icon'
import CopyIcon from '@icons/copy.icon'
import IconButtonComponent from '@base/buttons/IconButton'
import ViewIcon from '@icons/view.icon'
import ClockIcon from '@icons/clock.icon'
import StorageIcon from '@icons/storage.icon'
import TooltipComponent from '@base/tooltips/Tooltip'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'

import { requestFileDownloadAction, requestFilePreviewAction } from '@redux/modules/file-service/file-service.actions'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { FILE_BROWSER_ITEM_REMOVAL_DIALOG_NAME } from '@constants/modals.constants'
import { FILE_PREVIEW_TYPE, MAX_PREVIEW_FILE_SIZE_IN_BYTES } from '@constants/files.constants'
import { formatDate, formatTime } from '@utils/moment.utils'
import { DATE_FORMATS, TIME_FORMATS } from '@constants/date-formats.constants'
import { FileBrowserItemRemovalDialogModalDetails } from '@components/file-browser/FileBrowserItemRemovalDialog/FileBrowserItemRemovalDialog.component'
import { DEFAULT_BORDER_RADIUS, DEFAULT_PADDING } from '@constants/ui.constants'

export interface FileBrowserItemComponentProps extends FileService.BrowserTreeNode {
  style?: React.CSSProperties
}

const FileBrowserItemComponent: React.FC<FileBrowserItemComponentProps> = ({
  name,
  pfpUrl,
  fileKey,
  bucketName,
  isDirectory,
  fileExtension,
  directoriesCount = 0,
  filesCount = 0,
  sizeInBytes,
  createdAt,
  style,
  isSftp = false,
}) => {
  const history = useHistory()
  const intl = useIntl()
  const match = useRouteMatch<Common.FileBrowserRouterMatch>()
  const dispatch = useDispatch()

  const existingFolderPath = match.params[0] ? match.params[0] : undefined
  const goToFoledrPath = existingFolderPath ? `${existingFolderPath}/${name}` : name
  const formattedFileSize = sizeInBytes ? humanFileSize(sizeInBytes, {
    maximumFractionDigits: 2,
  }) : undefined

  const handleDownload = useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(
      requestFileDownloadAction({
        fileName: name,
        filePath: fileKey,
        bucketName,
      }),
    )
  }, [dispatch, fileKey, bucketName, name])

  const handleDelete = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: FILE_BROWSER_ITEM_REMOVAL_DIALOG_NAME,
        modalDetails: {
          filePath: fileKey,
          fileName: name,
          bucketName,
          returnTo: '',
        } as FileBrowserItemRemovalDialogModalDetails,
      }),
    )
  }, [dispatch, fileKey, name, bucketName])

  const handlePreview = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(
      requestFilePreviewAction({
        fileName: name,
        filePath: fileKey,
        fileExtension,
        fileType: FILE_PREVIEW_TYPE.FILE_MANAGER,
        isEditable: false,
        bucketName,
      }),
    )
  }, [dispatch, fileExtension, fileKey, name, bucketName])

  const handleCopy = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    copyToClipboard({
      e,
      text: pfpUrl,
      intl,
      customMessageId: 'fileManager.list.copySuccess',
    })
  }, [
    pfpUrl,
    intl,
  ])

  const handleItemClick = useCallback((e: React.SyntheticEvent) => {
    if (!isDirectory) {
      return undefined
    }

    const newPath = generatePath(FILE_MANAGER_FOLDER_PATH, {
      // @ts-ignore
      0: goToFoledrPath,
      usecase: match.params.usecase,
    })

    history.push(newPath)

    return null
  }, [history, isDirectory, match, goToFoledrPath])

  const IconComponent = useMemo(() => {
    if (isDirectory) {
      return getFileIcon('folder')
    }

    return getFileIcon(fileExtension)
  }, [fileExtension, isDirectory])

  const secondaryText = useMemo(() => {
    if (isDirectory) {
      return intl.formatMessage({ id: 'fileManager.list.dirInfo' }, {
        directoriesCount: <IntlFormatBoldComponent>{directoriesCount}</IntlFormatBoldComponent>,
        filesCount: <IntlFormatBoldComponent>{filesCount}</IntlFormatBoldComponent>,
      })
    }

    if (createdAt !== undefined && sizeInBytes !== undefined) {
      return (
        <Box
          component='span'
          display='flex'
          alignItems='center'
          width='100%'
          gap={1}
        >
          <TooltipComponent
            describeChild={true}
            title={createdAt}
            placement='bottom'
          >
            <Box
              tabIndex={0}
              component='span'
              display='flex'
              alignItems='center'
              gap={0.5}
              sx={{
                cursor: 'pointer',
                minWidth: '140px',
              }}
            >
              <Box
                component='span'
                display='flex'
                alignItems='center'
              >
                <ClockIcon
                  width={12}
                  height={12}
                />
              </Box>
              <Typography
                component='span'
                variant='body2'
              >
                {`${formatDate(intl, createdAt, DATE_FORMATS.FULL_DATE)} ${formatTime(intl, createdAt, TIME_FORMATS.TIME_24_DURATION)}`}
              </Typography>
            </Box>
          </TooltipComponent>
          <Box
            component='span'
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            <Box
              component='span'
              display='flex'
              alignItems='center'
            >
              <StorageIcon
                width={12}
                height={12}
              />
            </Box>
            <Typography
              component='span'
              variant='body2'
            >
              {formattedFileSize}
            </Typography>
          </Box>
        </Box>
      )
    }

    return pfpUrl
  }, [
    directoriesCount, filesCount,
    isDirectory, sizeInBytes,
    pfpUrl, intl, formattedFileSize, createdAt,
  ])

  const actionButtons = useMemo(() => {
    const buttonsList = [(
      <IconButtonComponent
        key='fileManagerCopy'
        name='fileManagerCopy'
        color='tertiary'
        rounded={true}
        label={intl.formatMessage({ id: 'fileManager.list.button.copy' })}
        onClick={handleCopy}
        IconComponent={CopyIcon}
      />
    )]

    if (!isDirectory) {
      if (canPreviewFileWithExtension(fileExtension)) {
        buttonsList.unshift((
          <IconButtonComponent
            key='fileManagerPreview'
            name='fileManagerPreview'
            color='tertiary'
            rounded={true}
            label={intl.formatMessage({ id: 'fileManager.list.button.preview' })}
            onClick={handlePreview}
            IconComponent={ViewIcon}
            disabled={sizeInBytes! > MAX_PREVIEW_FILE_SIZE_IN_BYTES}
            disabledTooltip={intl.formatMessage({
              id: 'fileManager.preview.dialog.limit',
            }, {
              actualSize: formattedFileSize,
              limit: humanFileSize(MAX_PREVIEW_FILE_SIZE_IN_BYTES),
            })}
          />
        ))
      }

      buttonsList.push((
        <IconButtonComponent
          key='fileManagerDownload'
          name='fileManagerDownload'
          color='tertiary'
          rounded={true}
          label={intl.formatMessage({ id: 'fileManager.list.button.copy' })}
          onClick={handleDownload}
          IconComponent={DownloadIcon}
        />
      ))

      buttonsList.push((
        <IconButtonComponent
          key='fileManagerDelete'
          name='fileManagerDelete'
          color='tertiary'
          rounded={true}
          label={intl.formatMessage({ id: 'fileManager.list.button.delete' })}
          onClick={handleDelete}
          IconComponent={DeleteIcon}
        />
      ))
    }

    return buttonsList
  }, [
    intl, sizeInBytes, isDirectory, fileExtension, formattedFileSize,
    handlePreview, handleCopy, handleDownload, handleDelete,
  ])

  return (
    <Box
      data-testid={FileBrowserItemComponent.name}
      style={style}
    >
      <ListItem
        secondaryAction={(
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            gap={1}
          >
            {actionButtons}
          </Box>
        )}
        sx={{
          height: '60px',
          py: 0,
          px: DEFAULT_PADDING.MEDIUM,
          cursor: isDirectory ? 'pointer' : 'default',
          borderRadius: DEFAULT_BORDER_RADIUS.MEDIUM,
          backgroundColor: 'rgba(0, 0, 0, 0.025)',
          mb: 1,
          transition: 'background-color 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.075)',
          },
        }}
        onClick={handleItemClick}
      >
        <ListItemIcon
          sx={{
            minWidth: '30px',
          }}
        >
          <IconComponent />
        </ListItemIcon>
        <ListItemText
          primary={name}
          secondary={secondaryText}
          primaryTypographyProps={{
            noWrap: true,
            variant: 'subtitle1',
            sx: {
              maxWidth: 'calc(100% - 200px)',
            },
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'body2',
            sx: {
              maxWidth: 'calc(100% - 200px)',
            },
          }}
        />
      </ListItem>
    </Box>
  )
}

export default FileBrowserItemComponent
