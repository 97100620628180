import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Box, Dialog, DialogContent,
  DialogTitle, Slide, Typography,
} from '@mui/material'

import { useIntl } from 'react-intl'
import { useHistory, useRouteMatch } from 'react-router-dom'

import FileBrowserPreviewImageComponent from '@components/file-browser/FileBrowserPreviewImage'
import FileBrowserPreviewJsonComponent from '@components/file-browser/FileBrowserPreviewJson'
import FileBrowserPreviewTxtComponent from '@components/file-browser/FileBrowserPreviewTxt'
import FileBrowserPreviewCsvComponent from '@components/file-browser/FileBrowserPreviewCsv'
import FileBrowserPreviewHtmlComponent from '@components/file-browser/FileBrowserPreviewHtml'

import IconButtonComponent from '@base/buttons/IconButton'
import ButtonComponent from '@base/buttons/Button'

import CloseIcon from '@icons/close.icon'
import DownloadIcon from '@icons/download.icon'
import palette from '@configuration/theme/theme.palette'

import eventEmitter, { axiosCancelTokenController } from '@utils/emitter.utils'
import { useDispatch, useSelector } from '@redux/hooks'
import { FILE_BROWSER_ITEM_PREVIEW_DIALOG_NAME } from '@constants/modals.constants'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'
import { getFilePreview, isPreparingFilePreview } from '@redux/modules/file-service/file-service.selectors'
import { closeFilePreviewAction, requestFileDownloadAction } from '@redux/modules/file-service/file-service.actions'
import { REQUEST_CANCELED_BY_USER } from '@utils/request-cancelation.utils'
import { EMITTER_TYPES } from '@constants/emitter.constants'
import { FILE_PREVIEW_TYPE, SUPPORTED_PREVIEW_FILE_TYPES } from '@constants/files.constants'
import { TransitionProps } from '@mui/material/transitions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => {
  return <Slide direction='up' ref={ref} {...props} />
})

export interface FileBrowserItemPreviewDialogProps {
}

export interface FileBrowserItemPreviewDialogModalDetails extends Common.ModalDetails {
  /**
   * File type for the preview
   */
  fileType?: FILE_PREVIEW_TYPE
  /**
   * If true, the file is editable
   */
  isEditable?: boolean
}

const FileBrowserItemPreviewDialog: React.FC<FileBrowserItemPreviewDialogProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()

  const isAdmin = useSelector(getIsAdmin)
  const isDownloading = useSelector(isPreparingFilePreview)
  const filePreview = useSelector(getFilePreview)
  const openModalName = useSelector(getOpenedModal)
  const modalDetails = useSelector(getModalDetails<FileBrowserItemPreviewDialogModalDetails>)
  const isOpen = openModalName === FILE_BROWSER_ITEM_PREVIEW_DIALOG_NAME

  const [total, setTotal] = useState(0)
  const [loaded, setLoaded] = useState(0)
  const { params } = useRouteMatch<Common.RouterMatch>()

  const canCancel = useMemo(() => {
    return axiosCancelTokenController && axiosCancelTokenController.controller && isDownloading
  }, [isDownloading])

  const handleCancel = useCallback(() => {
    if (canCancel) {
      axiosCancelTokenController.controller.abort(REQUEST_CANCELED_BY_USER)

      // eslint-disable-next-line
      axiosCancelTokenController.controller = new AbortController()
    }
  }, [canCancel])

  const handleClose = useCallback(() => {
    handleCancel()

    dispatch(
      closeFilePreviewAction(),
    )
  }, [dispatch, handleCancel])

  const handleDownload = useCallback(async () => {
    if (!filePreview) {
      return
    }

    dispatch(
      requestFileDownloadAction({
        fileName: filePreview.fileName,
        filePath: filePreview.filePath,
        fileType: modalDetails?.fileType,
      }),
    )
  }, [dispatch, modalDetails, filePreview])

  const nodeToRender = useMemo(() => {
    if (isDownloading || !isOpen || !filePreview) {
      const progress = total ? Math.floor((loaded / total) * 100) : 0
      const loadedInMb = loaded ? (loaded / 1024 / 1024).toFixed(2) : 0
      const totalInMb = total ? (total / 1024 / 1024).toFixed(2) : 0

      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          height='100%'
          position='relative'
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            width='100%'
            height='100%'
            position='absolute'
            zIndex={10}
          >
            <Typography
              variant='h4'
              mt={0}
              color='white'
            >
              {`${progress}%`}
            </Typography>
            <Typography
              variant='h5'
              mt={2}
              color='white'
            >
              {`${loadedInMb} MB / ${totalInMb} MB`}
            </Typography>
          </Box>

          <LoadingFallbackComponent
            fluid={true}
            showLongRequestNote={false}
            size='large'
          />
        </Box>
      )
    }

    const defaultNode = (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100%'
      >
        <Typography
          variant='h6'
          mb={2}
        >
          {intl.formatMessage({ id: 'fileManager.preview.dialog.notSupported' })}
        </Typography>

        <ButtonComponent
          name='close'
          color='secondary'
          onClick={handleClose}
          label={intl.formatMessage({ id: 'fileManager.preview.dialog.close' })}
        />
      </Box>
    )

    switch (filePreview.fileExtension) {
      case SUPPORTED_PREVIEW_FILE_TYPES.SVG:
      case SUPPORTED_PREVIEW_FILE_TYPES.PNG: {
        return (
          <FileBrowserPreviewImageComponent
            filePreview={filePreview}
          />
        )
      }

      case SUPPORTED_PREVIEW_FILE_TYPES.JSON: {
        return (
          <FileBrowserPreviewJsonComponent
            filePreview={filePreview}
          />
        )
      }

      case SUPPORTED_PREVIEW_FILE_TYPES.HTML: {
        return (
          <FileBrowserPreviewHtmlComponent
            filePreview={filePreview}
          />
        )
      }

      case SUPPORTED_PREVIEW_FILE_TYPES.TXT: {
        return (
          <FileBrowserPreviewTxtComponent
            filePreview={filePreview}
          />
        )
      }

      case SUPPORTED_PREVIEW_FILE_TYPES.CSV: {
        return (
          <FileBrowserPreviewCsvComponent
            filePreview={filePreview}
            isEditable={modalDetails?.isEditable}
            isAdmin={isAdmin}
          />
        )
      }

      default: {
        return defaultNode
      }
    }
  }, [
    isOpen,
    isAdmin,
    isDownloading,
    filePreview,
    modalDetails,
    handleClose,
    total,
    loaded,
    intl,
  ])

  useEffect(() => {
    eventEmitter.on(
      EMITTER_TYPES.FILE_DOWNLOAD_PROGRESS,
      (progressEvent) => {
        if (progressEvent.name) {
          setTotal(progressEvent.total)
          setLoaded(progressEvent.loaded)
        }
      },
    )

    return function cleanup() {
      eventEmitter.off(EMITTER_TYPES.FILE_DOWNLOAD_PROGRESS)

      setTotal(0)
      setLoaded(0)
    }
  }, [dispatch, handleCancel, handleClose, intl])

  useEffect(() => {
    history.listen(() => handleClose())
  }, [history, handleClose])

  const dialogCustomStyle = useMemo(() => {
    if (!filePreview) {
      return {}
    }

    switch (filePreview.fileExtension) {
      case SUPPORTED_PREVIEW_FILE_TYPES.CSV: {
        return {
          overflow: 'unset',
          '& .MuiDialogContent-root': {
            overflow: 'hidden',
          },
        }
      }

      default: {
        return {}
      }
    }
  }, [filePreview])

  const dialogTitle = useMemo(() => {
    let fileName = filePreview?.fileName
    const isEditable = modalDetails?.isEditable

    if (params.version && params.identifier) {
      fileName = `${params.identifier} (v${params.version}) – ${fileName}`
    }

    if (isEditable) {
      return intl.formatMessage({ id: 'fileManager.edit.dialog.title' }, { name: fileName })
    }

    return intl.formatMessage({ id: 'fileManager.preview.dialog.title' }, { name: fileName })
  }, [
    modalDetails,
    intl,
    filePreview,
    params,
  ])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen={true}
      sx={dialogCustomStyle}
      disableEscapeKeyDown={modalDetails?.isEditable}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${palette.new.grey_a}`,
          mb: 0,
        }}
      >
        <Typography
          color='textPrimary'
          variant='h6'
          component='div'
        >
          {dialogTitle}
        </Typography>

        <Box
          display='flex'
          alignItems='center'
          gap={1}
        >
          <IconButtonComponent
            name='downloadFileFromPreview'
            color='secondary'
            rounded={true}
            label={intl.formatMessage({ id: 'fileManager.preview.dialog.download' })}
            onClick={handleDownload}
            IconComponent={DownloadIcon}
          />

          <IconButtonComponent
            name='closeFilePreview'
            color='secondary'
            rounded={true}
            label={intl.formatMessage({ id: 'fileManager.preview.dialog.close' })}
            onClick={handleClose}
            IconComponent={CloseIcon}
          />
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
        }}
      >
        {nodeToRender}
      </DialogContent>
    </Dialog>

  )
}

export default FileBrowserItemPreviewDialog
