import React, { useMemo } from 'react'
import classNames from 'classnames'

import {
  TooltipProps,
  styled,
  InputBaseProps,
  FormControl,
  InputLabel,
  useTheme,
  Box,
} from '@mui/material'

import InfoButtonComponent from '@base/buttons/InfoButton'
import InputBaseComponent from '@base/forms/InputBase'
import CopyButtonComponent from '@base/buttons/CopyButton'

const ParetosFormControl = styled(FormControl)(({ theme }) => ({
  '&.hidden': {
    display: 'none',
  },
  '&:hover': {
    '& .MuiInputLabel-root:not(.Mui-disabled):not(.Mui-error)': {
      color: theme.palette.new.business_black,
    },
  },
}))

const ParetosInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.new.business_black_60,
  fontSize: '0.75rem',
  lineHeight: '125%',
  letterSpacing: '0.24px',
  fontWeight: 400,
  transform: 'none',
  marginLeft: '10px',
  '&.Mui-error': {
    color: theme.palette.new.rebellious_red,
  },
  '&.Mui-disabled': {
    color: theme.palette.new.business_black_20,
  },
}))

export interface TextFieldComponentProps extends InputBaseProps {
  /**
   * The input id. Same as name.
   */
  id?: string
  /**
   * The input value.
   */
  value?: string | number | null
  /**
   * The input name. Same as id if not provided.
   * Some form libraries require a name attribute.
   */
  name: string
  /**
   * The label content.
   */
  label: string
  /**
   * If true, the label will be hidden.
   */
  hideLabel?: boolean
  /**
   * The input placeholder.
   */
  placeholder?: string
  /**
   * Input validation errors.
   */
  errors?: string | NonNullable<React.ReactNode>
  /**
   * Indicates if the input has been touched.
   */
  touched?: boolean
  /**
   * If provided, the input will be hidden.
   */
  hidden?: boolean
  /**
   * If provided, the input will be full width.
   * @default true
   */
  fullWidth?: boolean
  /**
   * If provided, the input will be disabled.
   */
  disabled?: boolean
  /**
   * Help text to be displayed in a tooltip.
   */
  floatingHelp?: string | NonNullable<React.ReactNode>
  /**
   * Tooltip placement for the help text.
   */
  floatingHelpPlacement?: TooltipProps['placement']
  /**
   * Tooltip placement for the error message.
   */
  floatingErrorPlacement?: TooltipProps['placement']
  /**
   * Ref to be forwarded to the input.
   */
  forwardedRef?: React.ForwardedRef<any>
  /**
   * If true, the copy button will be displayed when the input is disabled.
   */
  copyButtonWhenDisabled?: boolean
}

export const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  errors,
  touched,
  disabled,
  hidden,
  floatingHelp,
  floatingHelpPlacement = 'left',
  floatingErrorPlacement = 'left',
  type,
  placeholder,
  name,
  label,
  id,
  value,
  hideLabel,
  forwardedRef,
  fullWidth = true,
  endAdornment,
  copyButtonWhenDisabled = true,
  ...rest
}) => {
  const theme = useTheme()
  const errorMessage = touched && errors
  const identifier = id || name

  const baseEndAdornment = useMemo(() => {
    const endAdornmentStyles = {
      position: 'absolute',
      right: '12px',
    }

    if (errorMessage) {
      return (
        <InfoButtonComponent
          name='floatingError'
          tooltip={errorMessage}
          placement={floatingErrorPlacement}
          sx={endAdornmentStyles}
          IconComponentProps={{
            detailsFill: theme.palette.new.rebellious_red,
            opacity: disabled ? '0.6' : '1',
          }}
        />
      )
    }

    if (floatingHelp) {
      return (
        <InfoButtonComponent
          name='floatingHelp'
          tooltip={floatingHelp}
          placement={floatingHelpPlacement}
          sx={endAdornmentStyles}
          IconComponentProps={{
            opacity: disabled ? '0.6' : '1',
          }}
        />
      )
    }

    return null
  }, [
    disabled,
    theme,
    floatingHelp,
    errorMessage,
    floatingErrorPlacement,
    floatingHelpPlacement,
  ])

  const textFieldEndAdornment = useMemo(() => {
    if (endAdornment) {
      return (
        <>
          {endAdornment}

          {baseEndAdornment}
        </>
      )
    }

    return baseEndAdornment
  }, [
    endAdornment,
    baseEndAdornment,
  ])

  const copyButton = useMemo(() => {
    if (disabled && copyButtonWhenDisabled) {
      return (
        <Box
          sx={{
            position: 'absolute',
            right: (textFieldEndAdornment) ? '36px' : '13px',
            top: '24px',
            zIndex: 1,
            opacity: 0.6,
          }}
        >
          <CopyButtonComponent
            name={`${name}-copy`}
            value={String(value || 'n/a')}
            iconOnly={true}
          />
        </Box>
      )
    }

    return null
  }, [disabled, textFieldEndAdornment, copyButtonWhenDisabled, value, name])

  return (
    <ParetosFormControl
      variant='standard'
      fullWidth={fullWidth}
      disabled={disabled}
      error={Boolean(errorMessage)}
      className={classNames({
        hidden,
        hasEndAdornment: textFieldEndAdornment !== null,
      })}
    >
      {
        hideLabel ? (
          null
        ) : (
          <ParetosInputLabel shrink={true} htmlFor={identifier}>
            {label}
          </ParetosInputLabel>
        )
      }

      <InputBaseComponent
        data-testid={TextFieldComponent.name}
        error={Boolean(touched && errors)}
        type={type ?? 'text'}
        size='small'
        ref={forwardedRef}
        name={name}
        id={identifier}
        fullWidth={fullWidth}
        disabled={disabled}
        autoComplete='new-password'
        endAdornment={textFieldEndAdornment}
        placeholder={placeholder}
        value={value}
        aria-label={hideLabel ? label : undefined}
        {...rest}
      />

      {copyButton}
    </ParetosFormControl>
  )
}

export default React.forwardRef<React.FC, TextFieldComponentProps>((props, ref) => <TextFieldComponent {...props} forwardedRef={ref} />)
