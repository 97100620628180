import React, { useCallback, useMemo } from 'react'

import { Card } from '@mui/material'

import { useIntl } from 'react-intl'
import { getDataGridId } from '@utils/data-grid.utils'
import { DATA_GRIDS } from '@constants/data-grid.constants'

import {
  GRID_ACTIONS_COLUMN_TYPE,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-premium'

import {
  generateActionsColumnDefinition, generateCopyButtonColumnDefinition,
  generateDateTimeColumnDefinition, generateRegularColumnDefinition,
} from '@utils/data-grid-cells.utils'

import EditIcon from '@icons/edit.icon'
import DeleteIcon from '@icons/delete.icon'
import useGridInitialState from '@hooks/useGridInitialState.hook'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button/DataGridActionButton.component'
import DataGridComponent from '@base/datagrid/data-grid/DataGrid.component'
import { SOURCE_TYPES_OPTIONS } from '@constants/flow.constants'

export interface ConnectorsOverviewComponentProps {
  list: Hermes.ConnectionListItem[],
  handleEdit: {
    (record: Hermes.ConnectionListItem): any,
  },
  handleDelete: {
    (record: Hermes.ConnectionListItem): any,
  },
  isFetching?: boolean,
}

const ConnectorsOverviewComponent: React.FC<ConnectorsOverviewComponentProps> = ({
  list,
  isFetching,
  handleEdit,
  handleDelete,
}) => {
  const intl = useIntl()

  const tableId = getDataGridId(DATA_GRIDS.CONNECTORS_TABLE, 2)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'createdAt', sort: 'desc' }],
    },
  })

  const sourceValueGetter = useCallback((value: string) => {
    const labelId = value ? SOURCE_TYPES_OPTIONS.find((option) => option.sourceId === value)?.labelKey : ''

    return labelId ? intl.formatMessage({ id: labelId }) : value
  }, [intl])

  const getActionItems = useCallback((params: GridRowParams<Hermes.ConnectionListItem>) => {
    return [
      <DataGridActionButtonComponent
        name='connectorEdit'
        icon={<EditIcon />}
        onClick={() => handleEdit(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.edit' })}
        id={params.id}
      />,
      <DataGridActionButtonComponent
        name='connectorDelete'
        icon={<DeleteIcon />}
        onClick={() => handleDelete(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
        id={params.id}
      />,
    ]
  }, [intl, handleEdit, handleDelete])

  const columns = useMemo<GridColDef[]>(() => {
    return [
      generateRegularColumnDefinition({
        intl,
        field: 'name',
        headerName: intl.formatMessage({ id: 'connectors.table.name' }),
      }),
      generateRegularColumnDefinition({
        intl,
        field: 'sourceId',
        headerName: intl.formatMessage({ id: 'connectors.table.source' }),
        gridColDefOverrides: {
          valueGetter: sourceValueGetter,
          groupingValueGetter: sourceValueGetter,
        },
      }),
      generateCopyButtonColumnDefinition({
        field: 'connectionId',
        headerName: intl.formatMessage({ id: 'connectors.table.id' }),
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'createdAt',
        headerName: intl.formatMessage({ id: 'connectors.table.createdAt' }),
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 2,
      }),
    ]
  }, [
    intl,
    getActionItems,
    sourceValueGetter,
  ])

  const getRowId = useCallback((row: Hermes.ConnectionListItem) => row.connectionId, [])

  return (
    <Card
      data-testid={ConnectorsOverviewComponent.name}
      elevation={0}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.CONNECTORS_TABLE}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={getRowId}
        initialState={initialState}
      />
    </Card>
  )
}

export default ConnectorsOverviewComponent
