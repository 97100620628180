import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'

import { getBaselineComparisonChart, isFetchingBaselineComparisonChart, isFetchingBaselineComparison } from '@redux/modules/monitor/monitor.selectors'

import InsightsKpisComponent, { KpiCard } from '@components/insights/InsightsKpis/InsightsKpis.component'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'

import BaselineKpiValuesComponent from './BaselineKpiValues'
import BaselineKpiDeviationValuesComponent from './BaselineKpiDeviationValues'

const BaselineComparisonKpisContainer = () => {
  const intl = useIntl()
  const baselineComparisonChart = useSelector(getBaselineComparisonChart)
  const isFetchingBaselineComparisonChartFlag = useSelector(isFetchingBaselineComparisonChart)
  const isFetchingBaselineComparisonFlag = useSelector(isFetchingBaselineComparison)

  const {
    targetName,
    overallNumbers = {
      target: 0,
      baselineAbsDeviation: 0,
      baselinePrediction: 0,
      baselineRelDeviation: 0,
      paretosAbsDeviation: 0,
      paretosPrediction: 0,
      paretosRelDeviation: 0,
    },
  } = baselineComparisonChart

  const kpiCards = [
    {
      title: intl.formatMessage({ id: 'insights.kpis.absDeviation' }),
      unit: intl.formatMessage({ id: 'insights.table.mae' }),
      tooltip: intl.formatMessage({ id: 'insights.table.mae.help' }, {
        name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.mae.label' })}</IntlFormatBoldComponent>,
      }),
      size: 'compact',
      value: null,
      renderValues: overallNumbers.baselineAbsDeviation === null ? null : () => {
        return (
          <BaselineKpiDeviationValuesComponent
            baselineValue={overallNumbers.baselineAbsDeviation!}
            paretosValue={overallNumbers.paretosAbsDeviation!}
          />
        )
      },
    },
    {
      title: intl.formatMessage({ id: 'insights.kpis.relDeviation' }),
      unit: intl.formatMessage({ id: 'insights.table.wape' }),
      tooltip: intl.formatMessage({ id: 'insights.table.wape.help' }, {
        name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.wape.label' })}</IntlFormatBoldComponent>,
      }),
      value: null,
      size: 'compact',
      renderValues: overallNumbers.baselineRelDeviation === null ? null : () => {
        return (
          <BaselineKpiDeviationValuesComponent
            baselineValue={overallNumbers.baselineRelDeviation!}
            paretosValue={overallNumbers.paretosRelDeviation!}
            isRelative={true}
            showSign={true}
          />
        )
      },
    },
    {
      title: targetName || intl.formatMessage({ id: 'common.unknown' }),
      unit: intl.formatMessage({ id: 'insights.table.sum' }),
      value: null,
      size: 'compact',
      renderValues: () => {
        return (
          <BaselineKpiValuesComponent
            actualValue={overallNumbers.target}
            baselineValue={overallNumbers.baselinePrediction}
            paretosValue={overallNumbers.paretosPrediction}
          />
        )
      },
    },
  ] as KpiCard[]

  return (
    <InsightsKpisComponent
      loading={isFetchingBaselineComparisonFlag}
      loadingValues={isFetchingBaselineComparisonChartFlag}
      cards={kpiCards}
      gridItemSize={4}
    />
  )
}

export default BaselineComparisonKpisContainer
