import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { freezeUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { handleExport } from '@utils/export.utils'
import { EXPORT_AS_PNG } from '@constants/export.constants'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { LIST_PREDIFINED_PARAMETER_MODAL_NAME, USE_CASE_DATA_TYPES_MODAL_NAME } from '@constants/modals.constants'

import ExportIcon from '@icons/export.icon'
import IconButtonComponent from '@base/buttons/IconButton'
import ContextMenuComponent from '@base/menus/ContextMenu'
import DotsIcon from '@icons/dots.icon'
import CallToActionButton from '@base/pagebar/CallToActionButton'

import { getUseCaseFreezeStatus, getUseCaseItem, isRecommendationUseCase } from '@redux/modules/use-case/use-case.selectors'
import { isCompositePipelineRunning, isFetchingCompositePipelineStatus } from '@redux/modules/pipelines/pipelines.selectors'

const ConnectBreadcrumbsActionComponent = ({ exportId } : { exportId: string}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isRunning = useSelector(isCompositePipelineRunning)
  const isFetching = useSelector(isFetchingCompositePipelineStatus)
  const isRecommendation = useSelector(isRecommendationUseCase)

  const useCase = useSelector(getUseCaseItem)
  const frozen = useSelector(getUseCaseFreezeStatus)
  const isAdmin = useSelector(getIsAdmin)

  const manageParametersTemplates = useCallback(() => {
    dispatch(setPrimaryModalPageName(LIST_PREDIFINED_PARAMETER_MODAL_NAME))
  }, [dispatch])

  const handleFreezeUseCase = useCallback(() => {
    dispatch(freezeUseCaseAction({
      freeze: !frozen,
    }))
  }, [dispatch, frozen])

  const handleExportClick = useCallback(() => {
    handleExport({
      type: EXPORT_AS_PNG,
      data: [],
      fileName: intl.formatMessage({ id: 'connect.browser_tab.title' }, { name: useCase.name }),
      exportId,
    })
  }, [exportId, useCase, intl])

  const onStartOptmClick = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(USE_CASE_DATA_TYPES_MODAL_NAME),
    )
  }, [dispatch])

  const content = useMemo(() => {
    if (!isRecommendation) {
      const actionMenu = isAdmin ? (
        <ContextMenuComponent
          name='manageConnectView'
          label={intl.formatMessage({ id: 'common.button.manage' })}
          IconComponent={DotsIcon}
          actions={[{
            name: 'freezeUseCase',
            label: intl.formatMessage({ id: 'common.button.freeze_usecase' }),
            disabled: false,
            checked: frozen,
            type: 'toggle',
            handler: () => handleFreezeUseCase(),
          }, {
            name: 'manageParametersTemplates',
            label: intl.formatMessage({ id: 'common.button.predifined_parameters' }),
            disabled: false,
            handler: () => manageParametersTemplates(),
          }]}
        />
      ) : (
        null
      )

      return (
        <>
          {actionMenu}

          <IconButtonComponent
            name='export'
            color='secondary'
            tooltip={true}
            onClick={handleExportClick}
            IconComponent={ExportIcon}
            label={intl.formatMessage({ id: 'common.button.export' })}
          />
        </>
      )
    }

    return (
      <CallToActionButton
        name='startOptmButton'
        onClick={onStartOptmClick}
        disabled={isRunning || isFetching}
        label={intl.formatMessage({ id: isRunning ? 'connect.setup.button.optimization_in_progress' : 'connect.setup.button.start' })}
      />
    )
  }, [
    isAdmin,
    isRecommendation,
    isRunning,
    isFetching,
    frozen,
    intl,
    manageParametersTemplates,
    handleFreezeUseCase,
    handleExportClick,
    onStartOptmClick,
  ])

  return content
}

export default ConnectBreadcrumbsActionComponent
