import { handleActions } from 'redux-actions'
import { MonitorState } from '@redux/modules/types'

import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  START_FETCHING_MONITOR,
  STOP_FETCHING_MONITOR,
  RECEIVE_BACKTESTING_TABLE,
  RECEIVE_BACKTESTING_CHART,
  RECEIVE_BACKTESTING_GRID_STATE_CHANGE,
  RECEIVE_BACKTESTING_AVAILABLE_FOLDS,

  RECEIVE_LIVE_MONITORING_TABLE,
  RECEIVE_LIVE_MONITORING_CHART,
  RECEIVE_LIVE_MONITORING_GRID_STATE_CHANGE,
  RECEIVE_LIVE_MONITORING_FORECAST_HORIZONS,

  RECEIVE_BASELINE_COMPARISON_TABLE,
  RECEIVE_BASELINE_COMPARISON_CHART,
  RECEIVE_BASELINE_COMPARISON_GRID_STATE_CHANGE,
  RECEIVE_BASELINE_COMPARISON_FORECAST_HORIZONS,
} from './monitor.action-types'

import {
  receiveBacktestingTable,
  receiveBacktestingChart,
  receiveBacktestingGridStateChange,
  receiveBacktestingFoldsOptions,

  receiveLiveMonitoringTable,
  receiveLiveMonitoringChart,
  receiveLiveMonitoringGridStateChange,
  receiveLiveMonitoringForecastHorizonsOptions,

  receiveBaselineComparisonTable,
  receiveBaselineComparisonChart,
  receiveBaselineComparisonGridStateChange,
  receiveBaselineComparisonForecastHorizonsOptions,

  initialState,
} from './monitor.reducers'

export default handleActions<MonitorState, any>({
  [RECEIVE_BACKTESTING_TABLE]: receiveBacktestingTable,
  [RECEIVE_BACKTESTING_CHART]: receiveBacktestingChart,
  [RECEIVE_BACKTESTING_GRID_STATE_CHANGE]: receiveBacktestingGridStateChange,
  [RECEIVE_BACKTESTING_AVAILABLE_FOLDS]: receiveBacktestingFoldsOptions,

  [RECEIVE_LIVE_MONITORING_TABLE]: receiveLiveMonitoringTable,
  [RECEIVE_LIVE_MONITORING_CHART]: receiveLiveMonitoringChart,
  [RECEIVE_LIVE_MONITORING_GRID_STATE_CHANGE]: receiveLiveMonitoringGridStateChange,
  [RECEIVE_LIVE_MONITORING_FORECAST_HORIZONS]: receiveLiveMonitoringForecastHorizonsOptions,

  [RECEIVE_BASELINE_COMPARISON_TABLE]: receiveBaselineComparisonTable,
  [RECEIVE_BASELINE_COMPARISON_CHART]: receiveBaselineComparisonChart,
  [RECEIVE_BASELINE_COMPARISON_GRID_STATE_CHANGE]: receiveBaselineComparisonGridStateChange,
  [RECEIVE_BASELINE_COMPARISON_FORECAST_HORIZONS]: receiveBaselineComparisonForecastHorizonsOptions,

  [START_FETCHING_MONITOR]: startFetchingAction<MonitorState>,
  [STOP_FETCHING_MONITOR]: stopFetchingAction<MonitorState>,
}, initialState)
