import React, {
  useCallback,
  useLayoutEffect,
} from 'react'

import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { Box } from '@mui/material'

import PageLayoutContainer from '@containers/application/PageLayout'
import ViewInfoDateComponent from '@base/pagebar/ViewInfoDate'
import InsightsLatestRunMessageComponent from '@components/insights/InsightsLatestRunMessage'
import ActionButtonComponent from '@base/pagebar/ActionButton'
import ExportIcon from '@icons/export.icon'

import { handleExport } from '@utils/export.utils'
import { EXPORT_AS_PNG } from '@constants/export.constants'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { requestAnalyzeViewAction } from '@redux/modules/analyze/analyze.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getInsightsPipelineRunInfo, isFetchingPipelineRunInfo } from '@redux/modules/insights/insights.selectors'

import AnalyzeTableContainer from './analyze-table/AnalyzeTable.container'
import AnalyzeFiltersContainer from './analyze-filters/AnalyzeFilters.container'
import AnalyzeChartContainer from './analyze-chart/AnalyzeChart.container'

const EXPORT_ID = 'analyze'

const AnalyzeContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCase = useSelector(getUseCaseItem)
  const isAdmin = useSelector(getIsAdmin)
  const isFetching = useSelector(isFetchingPipelineRunInfo)
  const pipelineInfo = useSelector(getInsightsPipelineRunInfo)
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      requestAnalyzeViewAction({
        useCaseId,
      }),
    )
  }, [dispatch, useCaseId])

  const handleExportClick = useCallback(() => {
    handleExport({
      type: EXPORT_AS_PNG,
      fileName: intl.formatMessage({ id: 'insights.browser_tab.title' }, { name: useCase?.name }),
      exportId: EXPORT_ID,
    })
  }, [useCase, intl])

  const lastUpdateTooltip = isAdmin ? (
    <InsightsLatestRunMessageComponent
      pipelineInfo={pipelineInfo}
    />
  ) : null

  const pageBarRightSideBlocks = (
    <>
      <ViewInfoDateComponent
        date={pipelineInfo.dateOfExecution}
        label={intl.formatMessage({ id: 'insights.view.last_update' })}
        tooltip={lastUpdateTooltip}
        loading={isFetching}
      />

      <ActionButtonComponent
        name='exportAsPng'
        onClick={handleExportClick}
        IconComponent={ExportIcon}
        label={intl.formatMessage({ id: 'common.button.export_png' })}
      />
    </>
  )

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'insights.browser_tab.title' }, { name: useCase.name })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
    >
      <Box
        id={EXPORT_ID}
        data-testid={AnalyzeContainer.name}
        sx={{ backgroundColor: 'white' }}
      >
        <AnalyzeChartContainer />

        <AnalyzeFiltersContainer />

        <AnalyzeTableContainer />
      </Box>
    </PageLayoutContainer>
  )
}

export default AnalyzeContainer
