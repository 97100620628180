import React, { useCallback, useEffect, useMemo } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { useIntl } from 'react-intl'
import { formatDate } from '@utils/moment.utils'
import { Box, styled, Typography } from '@mui/material'

import {
  getCompositePipelineStatus,
  isFetchingCompositePipelineStatus,
  isCompositePipelineRunning,
} from '@redux/modules/pipelines/pipelines.selectors'

import { isRecommendationUseCase } from '@redux/modules/use-case/use-case.selectors'
import { requestCompositePipelineStatusAction } from '@redux/modules/pipelines/pipelines.actions'

import StatusBarComponent from '@base/topbar/StatusBar'
import { PIPELINE_STATUS } from '@constants/pipelines.constants'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { USE_CASE_PIPELINES_PATH } from '@constants/routes.constants'

export interface StatusBarContainerProps {}

const Link = styled(Typography)<{ component: string }>(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: theme.palette.new.rebellious_red,
  '&:hover': {
    textDecoration: 'unset',
  },
}))

const StatusBarContainer: React.FC<StatusBarContainerProps> = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const history = useHistory()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const isAdmin = useSelector(getIsAdmin)
  const isRecommendation = useSelector(isRecommendationUseCase)
  const isOptimizing = useSelector(isCompositePipelineRunning)
  const isFetching = useSelector(isFetchingCompositePipelineStatus)
  const pipelineStatus = useSelector(getCompositePipelineStatus)

  const statusCode = pipelineStatus?.status
  const lastSuccessfulRun = pipelineStatus?.lastSuccessfulRun
  const hasAtLeastOneRun = Boolean(lastSuccessfulRun?.startTime)

  useEffect(() => {
    if (usecase && isRecommendation) {
      dispatch(
        requestCompositePipelineStatusAction({
          useCaseId: usecase,
        }),
      )
    }
  }, [
    dispatch,
    usecase,
    isRecommendation,
  ])

  const onNotFoundClick = useCallback(() => {
    history.push(generatePath(USE_CASE_PIPELINES_PATH, { usecase }))
  }, [history, usecase])

  const onErrorClick = useCallback(() => {
    dispatch(requestCompositePipelineStatusAction({
      useCaseId: usecase,
    }))
  }, [dispatch, usecase])

  const label = useMemo(() => {
    if (isFetching) {
      return intl.formatMessage({ id: 'common.loading' })
    }

    if (isOptimizing) {
      return intl.formatMessage({ id: 'connect.setup.status_bar.step.optimization' })
    }

    if (statusCode === PIPELINE_STATUS.NOT_FOUND && isAdmin) {
      return (
        <Box component='span' display='inline-flex' alignItems='center' gap={0.5}>
          {
            intl.formatMessage({ id: 'connect.setup.status_bar.not_found' }, {
              link: (
                <Link
                  variant='body1'
                  component='span'
                  onClick={onNotFoundClick}
                >
                  {intl.formatMessage({ id: 'connect.setup.status_bar.not_found.click' })}
                </Link>
              ),
            })
          }
        </Box>
      )
    }

    if (statusCode === PIPELINE_STATUS.ERROR) {
      return (
        <Box component='span' display='inline-flex' alignItems='center' gap={0.5}>
          {
            intl.formatMessage({ id: 'connect.setup.status_bar.error' }, {
              link: (
                <Link
                  variant='body1'
                  component='span'
                  onClick={onErrorClick}
                >
                  {intl.formatMessage({ id: 'connect.setup.status_bar.error.click' })}
                </Link>
              ),
            })
          }
        </Box>
      )
    }

    if (hasAtLeastOneRun && lastSuccessfulRun) {
      return intl.formatMessage({
        id: 'connect.setup.status_bar.idle',
      }, {
        date: formatDate(intl, lastSuccessfulRun.startTime),
      })
    }

    return (
      intl.formatMessage({ id: 'connect.setup.status_bar.no_runs' })
    )
  }, [
    intl,
    lastSuccessfulRun,
    hasAtLeastOneRun,
    isFetching,
    isOptimizing,
    statusCode,
    isAdmin,
    onNotFoundClick,
    onErrorClick,
  ])

  const type = useMemo(() => {
    if (isOptimizing) {
      return 'processing'
    }

    if (isFetching) {
      return 'info'
    }

    if (
      (!hasAtLeastOneRun && !isFetching) ||
      (statusCode === PIPELINE_STATUS.NOT_FOUND && isAdmin) ||
      (statusCode === PIPELINE_STATUS.ERROR)
    ) {
      return 'alert'
    }

    return 'info'
  }, [
    isOptimizing,
    isFetching,
    hasAtLeastOneRun,
    statusCode,
    isAdmin,
  ])

  if (!usecase || !isRecommendation) {
    return null
  }

  return (
    <StatusBarComponent
      label={label}
      type={type}
    />
  )
}

export default StatusBarContainer
