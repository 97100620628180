import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import {
  Accordion, Box,
  AccordionSummary, Typography,
} from '@mui/material'

import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'
import { isLegacyUploadCheck } from '@utils/use-cases.utils'

import palette from '@configuration/theme/theme.palette'
import ChevronIcon from '@icons/flow/chevron.icon'
import { formatDateTime } from '@utils/moment.utils'

export interface DataTypesWidgetComponentProps {
  handleDataTypeChange(identifier: string, version: string): void,
}

const DataTypesWidgetComponent: React.FC<DataTypesWidgetComponentProps> = ({ handleDataTypeChange }) => {
  const intl = useIntl()
  const fileIdentifiersList = useSelector(getUseCaseFileIdentifiers)

  const handleChange = (identifier: string, version: string) => (event: React.ChangeEvent<{}>) => {
    handleDataTypeChange(identifier, version)
  }

  const isEmptyList = (!fileIdentifiersList || fileIdentifiersList.length === 0)

  if (isEmptyList) {
    return null
  }

  return (
    <>
      <Typography
        sx={{
          fontSize: '18px',
          lineHeight: '18px',
          fontWeight: 500,
          color: palette.new.black,
          mt: 3,
          mb: 2,
          ml: 0,
        }}
      >
        {intl.formatMessage({ id: 'connect.data_input.modal.data_types' })}
      </Typography>
      <Box
        data-testid={DataTypesWidgetComponent.name}
      >
        {
          fileIdentifiersList.map((item, index) => {
            const {
              value,
              latestVersion,
              updatedAt,
              label,
            } = item

            const isLegacyUpload = isLegacyUploadCheck(label)

            return (
              <Accordion
                key={value}
                elevation={0}
                expanded={false}
                onChange={handleChange(value, latestVersion)}
                sx={isLegacyUpload ? {
                  background: '#fcfcfc',
                } : undefined}
              >
                <AccordionSummary
                  aria-controls={value}
                  id={value}
                  expandIcon={<ChevronIcon />}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      mx: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '18px',
                        color: palette.new.black,
                      }}
                    >
                      {
                        isLegacyUpload ? (
                          intl.formatMessage({ id: 'connect.data_input.modal.data_types.legacy_files' })
                        ) : (
                          label
                        )
                      }
                    </Typography>

                    {
                      isLegacyUpload ? (
                        <Box />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            opacity: 0.4,
                            pr: 2,
                          }}
                        >
                          {formatDateTime(intl, updatedAt)}
                        </Typography>
                      )
                    }
                  </Box>
                </AccordionSummary>
              </Accordion>
            )
          })
        }
      </Box>
    </>
  )
}

export default DataTypesWidgetComponent
