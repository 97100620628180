import React, { useMemo } from 'react'
import moment from 'moment'
import get from 'lodash.get'

import { useIntl } from 'react-intl'
import { Box, useTheme } from '@mui/material'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  Text,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ReferenceLine,
  ReferenceArea,
} from 'recharts'

import {
  getArrowHead,
  getXAxisProps,
  getYAxisProps,
  getYTextProps,
  getCartesianGridProps,
  getTooltipProps,
  getLineProps,
  getReferenceLabelProps,
  getTooltipFormattedAxis,
  getXLabelProps,
} from '@utils/svg.utils'

import ChartLegendComponent from '@components/charts/ChartLegend'
import InsightsChartTooltipComponent from '@components/insights/InsightsChartTooltip'
import InsightsChartMenuComponent from '@components/insights/InsightsChartMenu'
import InsightsChartGroupingTooltipComponent from '@components/insights/InsightsChartGroupingTooltip'

import { defaultNumberFormatter } from '@utils/analysis.utils'
import { DEFAULT_CHART_TICKS_FORMAT, formatTimestamp, toUnixTimestamp } from '@utils/moment.utils'
import { createId } from '@utils/common.utils'

import {
  INSIGHTS_GROUPING_LAST_YEAR_COLORWAY_OPACITY,
  INSIGHTS_TRUTH_LINE_STROKE_WIDTH,
  INSIGHTS_PREDICTION_LINE_STROKE_WIDTH,
  INSIGHTS_LAST_YEAR_TRUTH_LINE_STROKE_WIDTH,
  INSIGHTS_BASELINE_STORKE_DASHARRAY,
  INSIGHTS_BASELINE_LINE_STROKE_WIDTH,
} from '@constants/insights.constants'

import {
  generateLastYearPayloadKey, generatePredictionPayloadKey,
  getInsightsLineColor, getInsightsLineStrokeWidth, getInsightsLineLabel,
  getInsightsLineStrokeDashArray,
  generateBaselinePayloadKey,
} from '@utils/insights.utils'

import InsightsEventsAreaComponent from '../InsightsEventsArea'

export interface GroupingModeAttributes {
  /**
   * Prefix for the target payload
   */
  predictionPayloadKeyPrefix: string
  /**
   * Prefix for the baseline payload
   */
  baselinePayloadKeyPrefix?: string
  /**
   * Prefix for the absolute deviation key
   */
  absDeviationPayloadKeyPrefix?: string
  /**
   * Prefix for the last year target key
   */
  lastYearTargetPayloadKeyPrefix?: string
}

export interface RegularModeAttributes {
  /**
   * Key for the target payload
   */
  targetPayloadKey: string
  /**
   * Key for the prediction payload
   */
  predictionPayloadKey: string
  /**
   * Key for the baseline payload
   */
  baselinePayloadKey?: string
  /**
   * Key for the absolute deviation payload
   */
  absDeviationPayloadKey?: string
  /**
   * Key for the last year target payload
   */
  lastYearTargetPayloadKey?: string
}

export interface InsightsLineChartComponentProps {
  /**
   * Dataset to be displayed in the chart
   */
  dataset?: Insights.BaseChartDatasetItem[]
  /**
   * Name of the target
   */
  targetName?: string
  /**
   * Unit of the target
   */
  targetUnit?: string
  /**
   * Lines to be displayed in the chart
   */
  lines?: Insights.BaseChartLineItem[]
  /**
   * Annotations to be displayed in the chart
   */
  annotations?: Insights.BaseChartAnnotationItem[]
  /**
   * Flag to enable today line
   */
  enableTodayLine?: boolean
  /**
   *lag to indicate if the grid has grouping enabled
   */
  hasGroupingEnabled?: boolean
  /**
   * Flag to indicate if the chart is fetching data
   */
  isFetching?: boolean
  /**
   * Flag to show promotion days
   */
  eventsVisibility?: boolean
  /**
   * Flag to show last year data
   */
  lastYearVisibility?: boolean
  /**
   * Flag to show promotion days
   */
  eventsToExclude?: string[]
  /**
   * Promotion days to be displayed in the chart
   */
  events?: Insights.BaseChartEventItem[]
  /**
   * Selected rows in the grid
   */
  selectedRows?: GridRowSelectionModel
  /**
   * Chart settings actions
   */
  chartSettingsActions?: Common.ContextMenuAction[]
  /**
   * Attributes for the grouping mode
   */
  groupingModeAttributes: GroupingModeAttributes
  /**
   * Attributes for the regular mode
   */
  regularModeAttributes: RegularModeAttributes
}

const InsightsLineChartComponent: React.FC<InsightsLineChartComponentProps> = ({
  dataset = [],
  lines = [],
  annotations = [],
  events = [],
  targetName = '',
  targetUnit = '',
  hasGroupingEnabled = false,
  enableTodayLine = true,
  eventsVisibility = true,
  lastYearVisibility = true,
  eventsToExclude = [],
  selectedRows = [],
  chartSettingsActions = [],
  isFetching,
  groupingModeAttributes,
  regularModeAttributes,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const xKey = 'date'

  const todayTimestamp = useMemo(() => Number(toUnixTimestamp(moment().utc().startOf('day'))), [])
  const firstTimestamp = useMemo(() => Number(get(dataset, '[0].date', todayTimestamp)), [todayTimestamp, dataset])
  const lastTimestamp = useMemo(() => Number(get(dataset, `[${dataset.length - 1}].date`, todayTimestamp)), [todayTimestamp, dataset])

  const TooltipComponent = useMemo(() => {
    return hasGroupingEnabled ? (
      <InsightsChartGroupingTooltipComponent
        lines={lines}
        isFetching={isFetching}
        selectedRows={selectedRows}
        predictionKeyPrefix={groupingModeAttributes.predictionPayloadKeyPrefix}
        absDeviationKeyPrefix={groupingModeAttributes.absDeviationPayloadKeyPrefix}
        lastYearTargetPayloadKeyPrefix={groupingModeAttributes.lastYearTargetPayloadKeyPrefix}
        baselinePayloadKeyPrefix={groupingModeAttributes.baselinePayloadKeyPrefix}
        eventsVisibility={eventsVisibility}
        eventsToExclude={eventsToExclude}
        lastYearVisibility={lastYearVisibility}
      />
    ) : (
      <InsightsChartTooltipComponent
        targetName={targetName}
        targetPayloadKey={regularModeAttributes.targetPayloadKey}
        predictionPayloadKey={regularModeAttributes.predictionPayloadKey}
        absDeviationPayloadKey={regularModeAttributes.absDeviationPayloadKey}
        lastYearTargetPayloadKey={regularModeAttributes.lastYearTargetPayloadKey}
        baselinePayloadKey={regularModeAttributes.baselinePayloadKey}
        eventsVisibility={eventsVisibility}
        eventsToExclude={eventsToExclude}
        lastYearVisibility={lastYearVisibility}
      />
    )
  }, [
    hasGroupingEnabled, lines, isFetching, targetName,
    regularModeAttributes, groupingModeAttributes,
    lastYearVisibility, eventsVisibility, eventsToExclude,
    selectedRows,
  ])

  const promotionDaysFiltered = useMemo(() => {
    return events.filter((promotionDay) => {
      return !eventsToExclude.includes(promotionDay.id) && !(promotionDay.from > lastTimestamp)
    })
  }, [lastTimestamp, events, eventsToExclude])

  const chartLines = useMemo(() => {
    const linesList: {
      id: string,
      strokeWidth: number,
      strokeDasharray?: string,
      color: string,
    }[] = []

    lines.forEach((line, index) => {
      if (hasGroupingEnabled) {
        /** Actual line */
        linesList.push({
          id: line.id,
          strokeWidth: INSIGHTS_TRUTH_LINE_STROKE_WIDTH,
          color: getInsightsLineColor(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: true,
            lastYearVisibility,
            selectedRows,
          }),
        })

        /** Prediction line */
        linesList.push({
          id: generatePredictionPayloadKey(line.id, groupingModeAttributes.predictionPayloadKeyPrefix),
          strokeWidth: INSIGHTS_PREDICTION_LINE_STROKE_WIDTH,
          color: getInsightsLineColor(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: true,
            lastYearVisibility,
            selectedRows,
          }),
        })

        /** Last Year Line */
        if (lastYearVisibility) {
          linesList.push({
            id: generateLastYearPayloadKey(line.id, groupingModeAttributes.lastYearTargetPayloadKeyPrefix),
            strokeWidth: INSIGHTS_LAST_YEAR_TRUTH_LINE_STROKE_WIDTH,
            color: getInsightsLineColor(index, line.id, {
              isFetching,
              hasGroupingEnabled,
              useColorWay: true,
              colorWayOpacity: INSIGHTS_GROUPING_LAST_YEAR_COLORWAY_OPACITY,
              lastYearVisibility,
              selectedRows,
            }),
          })
        }

        if (groupingModeAttributes.baselinePayloadKeyPrefix) {
          /** Baseline line */
          linesList.push({
            id: generateBaselinePayloadKey(line.id, groupingModeAttributes.baselinePayloadKeyPrefix),
            strokeWidth: INSIGHTS_BASELINE_LINE_STROKE_WIDTH,
            strokeDasharray: INSIGHTS_BASELINE_STORKE_DASHARRAY,
            color: getInsightsLineColor(index, line.id, {
              isFetching,
              hasGroupingEnabled,
              useColorWay: true,
              lastYearVisibility,
              selectedRows,
            }),
          })
        }
      } else {
        linesList.push({
          id: line.id,
          strokeWidth: getInsightsLineStrokeWidth(line.id),
          strokeDasharray: getInsightsLineStrokeDashArray(line.id),
          color: getInsightsLineColor(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: false,
            lastYearVisibility,
            selectedRows,
          }),
        })
      }
    })

    return linesList
  }, [
    lines,
    selectedRows,
    isFetching,
    lastYearVisibility,
    hasGroupingEnabled,
    groupingModeAttributes,
  ])

  const legendRows = useMemo(() => {
    const rows: Common.ChartLegendItem[] = []

    lines.forEach((line, index) => {
      rows.push({
        label: getInsightsLineLabel(intl, line, targetName),
        strokeWidth: hasGroupingEnabled ? INSIGHTS_PREDICTION_LINE_STROKE_WIDTH : getInsightsLineStrokeWidth(line.id),
        strokeDasharray: hasGroupingEnabled ? undefined : getInsightsLineStrokeDashArray(line.id),
        color: getInsightsLineColor(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: true,
          selectedRows,
        }),
      })
    })

    if (eventsVisibility) {
      rows.push({
        label: intl.formatMessage({ id: 'common.events' }),
        color: theme.palette.new.gainful_grey_20,
        type: 'square',
      })
    }

    return rows
  }, [
    theme, intl, lines, isFetching, selectedRows,
    targetName, hasGroupingEnabled, eventsVisibility,
  ])

  return (
    <Box
      data-testid={InsightsLineChartComponent.name}
      sx={{
        width: '100%',
        height: '100%',
        ...(isFetching ? {
          filter: 'blur(5px)',
        } : {}),
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '392px',
          position: 'relative',
          paddingTop: '20px',
        }}
      >
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            data={dataset}
            margin={{
              top: 0,
              right: 5,
              left: 50,
              bottom: 20,
            }}
          >
            <CartesianGrid
              {...getCartesianGridProps(true, true)}
            />

            <Tooltip
              {...getTooltipProps(false, { x: false, y: false })}
              cursor={!isFetching}
              content={TooltipComponent}
            />

            {
              chartLines.reverse().map((line, index) => {
                return (
                  <Line
                    key={index}
                    {...getLineProps({
                      key: line.id,
                      strokeWidth: line.strokeWidth,
                      stroke: line.color,
                      strokeDasharray: line.strokeDasharray,
                    })}
                  />
                )
              })
            }

            {
              enableTodayLine ? (
                <ReferenceLine
                  x={todayTimestamp}
                  stroke={theme.palette.new.black}
                  strokeWidth={1}
                >
                  <Label
                    value={intl.formatMessage({ id: 'insights.chart.today' })}
                    {
                      ...getReferenceLabelProps({
                        transform: 'translate(10px, 15px)',
                      })
                    }
                  />
                </ReferenceLine>
              ) : (
                null
              )
            }

            {
              annotations.map((annotation, index) => {
                /**
                 * Calculate edge proximity to determine annotation position.
                 * This calculates the edge position as 10% of the total chart width from the lastTimestamp.
                 */
                const annotationX = Number(annotation.x)
                const isNearRightEdge = annotationX > (lastTimestamp - (0.1 * (lastTimestamp - firstTimestamp)))
                const position = isNearRightEdge ? 'insideTopLeft' : 'insideTopRight'

                return (
                  <ReferenceLine
                    key={index}
                    x={Number(annotation.x)}
                    stroke={theme.palette.new.black}
                    strokeWidth={1}
                    className={position}
                  >
                    {
                      annotation.overline ? (
                        <Label
                          value={annotation.overline}
                          {
                            ...getReferenceLabelProps({
                              position,
                              transform: `translate(${isNearRightEdge ? -10 : 10}px, 15px)`,
                            })
                          }
                        />
                      ) : (
                        null
                      )
                    }

                    <Label
                      {
                        ...getReferenceLabelProps({
                          position,
                          transform: `translate(${isNearRightEdge ? -10 : 10}px, ${annotation.overline ? '35px' : '15px'})`,
                        })
                      }
                      value={annotation.label}
                      fontWeight={500}
                    />
                  </ReferenceLine>
                )
              })
            }

            {
              eventsVisibility && promotionDaysFiltered.map((promotionDay, index) => {
                return (
                  <ReferenceArea
                    key={createId(promotionDay.id, index)}
                    x1={promotionDay.from}
                    x2={promotionDay.to}
                    fill={theme.palette.new.gainful_grey}
                    fillOpacity={0.2}
                    ifOverflow='hidden'
                    shape={<InsightsEventsAreaComponent id={createId(promotionDay.id, index)} />}
                  />
                )
              })
            }

            <XAxis
              {...getXAxisProps()}
              dataKey={xKey}
              minTickGap={100}
              scale='time'
              type='number'
              domain={['auto', 'auto']}
              tickFormatter={(unixTime: number) => {
                return formatTimestamp(unixTime, intl.locale, DEFAULT_CHART_TICKS_FORMAT)
              }}
            >
              <Label
                {...getXLabelProps()}
                value={intl.formatMessage({ id: 'insights.chart.x.title' })}
              />
            </XAxis>

            <YAxis
              {...getYAxisProps()}
              tickFormatter={(value) => String(defaultNumberFormatter(value))}
            >
              <Label
                content={(
                  <Text
                    {...getYTextProps()}
                    style={{ transform: 'translate(100px, 0px)' }}
                  >
                    {
                      getTooltipFormattedAxis(intl, targetName, 'insights.chart.yAxis', targetUnit)
                    }
                  </Text>
                )}
              />
            </YAxis>

            {getArrowHead()}
          </LineChart>
        </ResponsiveContainer>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        gap={1}
        mt={1}
      >
        <ChartLegendComponent
          items={legendRows}
          sx={{
            padding: '0px',
            paddingLeft: '110px',
          }}
        />

        <InsightsChartMenuComponent options={chartSettingsActions} />
      </Box>
    </Box>
  )
}

export default InsightsLineChartComponent
