import React from 'react'

const FeedbackIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={FeedbackIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>
      <path d="M8.99972 0.642824C7.48803 0.643294 6.00477 1.05379 4.70793 1.83058C3.41109 2.60738 2.34923 3.72138 1.63545 5.05395C0.921672 6.38651 0.582704 7.88773 0.65465 9.39771C0.726596 10.9077 1.20676 12.3699 2.04401 13.6285L0.642578 17.3571L5.33544 16.5085C6.46546 17.0607 7.70555 17.3508 8.96327 17.357C10.221 17.3632 11.4639 17.0855 12.5993 16.5445C13.7348 16.0035 14.7335 15.2132 15.5209 14.2325C16.3084 13.2519 16.8644 12.1061 17.1474 10.8806C17.4305 9.65512 17.4333 8.38157 17.1556 7.15487C16.8779 5.92818 16.3269 4.77998 15.5437 3.79586C14.7605 2.81175 13.7653 2.01711 12.6322 1.47117C11.4991 0.925232 10.2575 0.642081 8.99972 0.642824Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default FeedbackIcon
