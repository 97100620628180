import React, { SyntheticEvent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { Box, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import omit from 'lodash.omit'
import get from 'lodash.get'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
  SidePanelButtonContainerComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { PARAMETER_TYPES } from '@constants/use-cases.constants'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getValidMinMax, parameterTypeToTemplateTypes } from '@utils/use-cases.utils'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

import {
  requestParameterAction,
  updateParameterAction,
  removeParameterAction,
} from '@redux/modules/parameters/parameters.actions'

import {
  GOAL_OPTIONS,
  SOURCE_TYPES,
  INPUT_TYPES_OPTIONS,
  INPUT_TYPES,
  SourceTypeOption,
  GOAL_DEFAULT,
  POSSIBLE_TEMPLATE_TYPES,
} from '@constants/flow.constants'

import ResetIcon from '@icons/reset.icon'
import DeleteIcon from '@icons/delete.icon'
import ConnectorIcon from '@icons/connector.icon'
import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import HelpButtonComponent from '@base/buttons/HelpButton'
import ButtonComponent from '@base/buttons/Button'

import { requestParametersTemplatesAction } from '@redux/modules/parameters-templates/parameters-templates.actions'
import { getParametersTemplatesList, isFetching as isFetchingTemplates } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { getParameterItem, isFetchingParameter, isSubmittingParameter } from '@redux/modules/parameters/parameters.selectors'
import { getUseCaseFreezeStatus } from '@redux/modules/use-case/use-case.selectors'
import { AGGREGATION_TYPES_OPTIONS, DEFAULT_AGGREGATION_TYPE } from '@constants/date.constants'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { CONNECT_PARAMETER_MODAL_NAME } from '@constants/modals.constants'

import ReactFlowParameterBlockComponent, { getParameterBlockByInputType, PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import ParameterTemplateSelectorComponent from '@components/connect-view/parameters/ParameterTemplateSelector'

import connectParameterValidations from './ConnectParameterModal.validations'

const ConnectParameterModalContainer: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const theme = useTheme()

  const isSubmitting = useSelector(isSubmittingParameter)
  const isFetchingTemplatesList = useSelector(isFetchingTemplates)
  const isFetchingParameterItem = useSelector(isFetchingParameter)
  const isFetching = isFetchingParameterItem || isFetchingTemplatesList
  const parameterItem = useSelector(getParameterItem)
  const isUseCaseFrozen = useSelector(getUseCaseFreezeStatus)
  const isAdmin = useSelector(getIsAdmin)
  const parametersTemplatesList = useSelector(getParametersTemplatesList)
  const modalPageName = useSelector(getOpenedModal)

  const open = modalPageName === CONNECT_PARAMETER_MODAL_NAME
  const edit = Boolean(parameterItem?.name)
  const initialValues = { ...parameterItem }
  const initialParameterTemplateType = parameterTypeToTemplateTypes(parameterItem)

  useEffect(() => {
    if (open) {
      dispatch(
        requestParametersTemplatesAction(),
      )
    }
  }, [open, dispatch])

  const handleModalClose = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(
        setPrimaryModalPageName(''),
      )
    }

    dispatch(
      requestParameterAction({} as UseCase.ParameterItem),
    )
  }

  const handleModalSubmit = (values: UseCase.ParameterItem) => {
    const isTarget = Boolean(initialValues.modelOutputParameterId)
    const config = {
      ...initialValues,
      ...values,
      description: values.description || '',
    }

    const payload = isTarget ? {
      parameterId: initialValues.modelOutputParameterId,
      type: PARAMETER_TYPES.OUTPUT,
      config: {
        ...omit(config, ['min', 'max', 'parameterPresetId']),
        parameterPresetId: values.parameterPresetId ? values.parameterPresetId : null,
        dataColumnName: values.dataColumnName ? values.dataColumnName.trim() : null,
      },
    } : {
      parameterId: initialValues.modelInputParameterId,
      type: PARAMETER_TYPES.INPUT,
      config: {
        ...omit(config, ['goal', 'parameterPresetId']),
        parameterPresetId: values.parameterPresetId ? values.parameterPresetId : null,
        dataColumnName: values.dataColumnName ? values.dataColumnName.trim() : null,
        min: getValidMinMax(values.min),
        max: getValidMinMax(values.max),
      },
    }

    dispatch(
      updateParameterAction(payload as any),
    )

    handleModalClose(false)
  }

  const handleDelete = () => {
    const isTarget = Boolean(initialValues.modelOutputParameterId)
    const payload = isTarget ? {
      parameterId: initialValues.modelOutputParameterId!,
      type: PARAMETER_TYPES.OUTPUT,
    } : {
      parameterId: initialValues.modelInputParameterId!,
      type: PARAMETER_TYPES.INPUT,
    }

    dispatch(
      removeParameterAction(payload),
    )

    handleModalClose(false)
  }

  const {
    handleChange,
    handleSubmit,
    resetForm,
    handleBlur,
    setFieldValue,
    touched,
    errors,
    isValid,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
    validationSchema: connectParameterValidations(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const {
    name,
    unitLabel,
    inputType,
    parameterPresetId,
  } = values

  const sourceType = values.sourceType as SOURCE_TYPES
  const type = getParameterBlockByInputType(inputType as INPUT_TYPES)
  const isTarget = type === PARAMETER_BLOCK_TYPES.TARGET
  const isReadOnly = isAdmin ? isSubmitting : (isUseCaseFrozen || isSubmitting)
  const shouldShowDeleteButton = isAdmin || !isUseCaseFrozen
  const shouldShowResetButton = isAdmin || !isUseCaseFrozen
  const parameterTemplateInUse = Boolean(parameterPresetId)
  const shouldHideInputTypeSelector = initialParameterTemplateType === POSSIBLE_TEMPLATE_TYPES.EXTERNAL
  const parameterTemplateType = parameterTypeToTemplateTypes(values)

  const handleReset = () => {
    resetForm()
  }

  const sourceTypeChangeCb = (e: SyntheticEvent, selectedValue: SourceTypeOption) => {
    if (!selectedValue) {
      return
    }

    if (parameterTemplateInUse) {
      handleReset()
    }

    handleChange({
      target: {
        name: 'sourceType',
        value: selectedValue?.value,
      },
    })

    handleChange({
      target: {
        name: 'parameterPresetId',
        value: '',
      },
    })
  }

  const predefinedInputChangeCb = (e: SyntheticEvent, selectedValue: UseCase.ParameterTemplateItem) => {
    if (!selectedValue) {
      return
    }

    const selectedParameterPresetId = selectedValue?.parameterPresetId

    handleChange({
      target: {
        name: 'parameterPresetId',
        value: selectedParameterPresetId,
      },
    })

    if (selectedParameterPresetId) {
      const selectedItem = parametersTemplatesList.find((item) => item.parameterPresetId === selectedParameterPresetId) || {}

      setFieldValue('name', get(selectedItem, 'name', ''))
      setFieldValue('description', get(selectedItem, 'description', ''))
      setFieldValue('dataColumnName', get(selectedItem, 'dataColumnName', ''))
      setFieldValue('unitLabel', get(selectedItem, 'unitLabel', ''))
      setFieldValue('aggregationFunction', get(selectedItem, 'aggregationFunction', ''))
    } else {
      const sourceTypeCopy = values.sourceType

      handleReset()

      setFieldValue('sourceType', sourceTypeCopy)
      setFieldValue('parameterPresetId', '')
    }
  }

  const modalTitle = edit ? (
    intl.formatMessage({ id: 'connect.modal.parameter.edit' }, { name: initialValues.name })
  ) : (
    intl.formatMessage({ id: 'connect.modal.parameter' })
  )

  return (
    <SidePanelComponent
      open={open}
      title={modalTitle}
      handleClose={handleModalClose}
      loading={false}
      hasUnsavedChanges={dirty || isSubmitting}
      headerRightSideBlocks={(
        <HelpButtonComponent
          tooltip={intl.formatMessage({ id: 'connect.modal.parameter.help' })}
          name='modalHelpButton'
        />
      )}
    >
      <SidePanelLoadingComponent loading={isFetching || !initialValues.sourceType}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <Typography
              sx={{
                fontSize: '30px',
                lineHeight: '35px',
                maxWidth: theme.spacing(50),
                marginBottom: theme.spacing(2),
                ...getTextBackgroundFillStyles(),
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.parameter.header' })}
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                lineHeight: '20px',
                color: theme.palette.new.black_b,
                maxWidth: theme.spacing(60),
                marginBottom: theme.spacing(6),
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.parameter.description' })}
            </Typography>
            <Box
              sx={{
                marginBottom: theme.spacing(6),
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'center',
                '& > div': {
                  marginTop: theme.spacing(0),
                },
              }}
            >
              <ReactFlowParameterBlockComponent
                name={name}
                sourceType={sourceType}
                unitLabel={unitLabel}
                type={type}
                nonFlowUsage={true}
              />
              <Box
                sx={{
                  zIndex: 5,
                }}
              >
                <ConnectorIcon />
              </Box>
              <Box width='100%'>
                <ParameterTemplateSelectorComponent
                  disabled={isReadOnly}
                  isFetching={isFetching}
                  selectedSourceType={sourceType}
                  selectedInputTemplate={parameterPresetId!}
                  sourceTypeChangeCb={sourceTypeChangeCb}
                  predefinedInputChangeCb={predefinedInputChangeCb}
                  parameterTemplateType={parameterTemplateType}
                />
              </Box>
            </Box>

            <FormLayoutContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'connect.modal.parameter.details' })}
                divider={false}
                hidden={!isTarget}
              >
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.name' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.name.help' })}
                    name='name'
                    value={values.name}
                    touched={touched.name}
                    errors={errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isReadOnly || parameterTemplateInUse}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.unit' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.unit.help' })}
                    name='unitLabel'
                    value={values.unitLabel}
                    touched={touched.unitLabel}
                    errors={errors.unitLabel}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isReadOnly || parameterTemplateInUse}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      label={intl.formatMessage({ id: 'connect.modal.parameter.description_field' })}
                      name='description'
                      touched={touched.description}
                      errors={errors.description}
                      value={values.description || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      multiline={true}
                      disabled={isReadOnly || parameterTemplateInUse}
                      rows={4}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6} container={true} alignContent='space-between'>
                    <FormLayoutItem xs={12}>
                      <SelectFieldComponent
                        label={intl.formatMessage({ id: 'connect.block.output.goal' })}
                        name='goal'
                        value={values.goal || GOAL_DEFAULT}
                        onBlur={handleBlur}
                        onChange={(e: any) => setFieldValue('goal', e.target.value, false)}
                        options={GOAL_OPTIONS.map((item) => ({ ...item, label: intl.formatMessage({ id: item.labelKey }) }))}
                        disabled={isReadOnly}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={12} hidden={!isAdmin}>
                      <TextFieldComponent
                        label={intl.formatMessage({ id: 'connect.modal.parameter.dataColumnName' })}
                        floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.dataColumnName.help' })}
                        name='dataColumnName'
                        value={values.dataColumnName || ''}
                        touched={touched.dataColumnName}
                        errors={errors.dataColumnName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isReadOnly || parameterTemplateInUse}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={12} hidden={!isAdmin}>
                  <SelectFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.aggregation_type' })}
                    name='aggregationFunction'
                    value={values.aggregationFunction || DEFAULT_AGGREGATION_TYPE}
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      setFieldValue('aggregationFunction', e.target.value, false)
                    }}
                    options={AGGREGATION_TYPES_OPTIONS.map((item) => ({ ...item, label: intl.formatMessage({ id: item.labelKey }) }))}
                    disabled={isReadOnly || parameterTemplateInUse}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'connect.modal.parameter.details' })}
                divider={false}
                hidden={isTarget}
              >
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.name' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.name.help' })}
                    name='name'
                    value={values.name}
                    touched={touched.name}
                    errors={errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isReadOnly || parameterTemplateInUse}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.unit' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.unit.help' })}
                    name='unitLabel'
                    value={values.unitLabel}
                    touched={touched.unitLabel}
                    errors={errors.unitLabel}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isReadOnly || parameterTemplateInUse}
                  />
                </FormLayoutItem>

                <FormLayoutItem xs={6} hidden={!isAdmin}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.dataColumnName' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.dataColumnName.help' })}
                    name='dataColumnName'
                    value={values.dataColumnName || ''}
                    touched={touched.dataColumnName}
                    errors={errors.dataColumnName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isReadOnly || parameterTemplateInUse}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={isAdmin ? 6 : 12} hidden={shouldHideInputTypeSelector}>
                  <SelectFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.type' })}
                    name='inputType'
                    value={values.inputType}
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      handleReset()

                      setFieldValue('inputType', e.target.value, false)
                    }}
                    options={INPUT_TYPES_OPTIONS.map((item) => ({ ...item, label: intl.formatMessage({ id: item.labelKey }) }))}
                    disabled={isReadOnly || parameterTemplateInUse}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      label={intl.formatMessage({ id: 'connect.modal.parameter.description_field' })}
                      name='description'
                      touched={touched.description}
                      errors={errors.description}
                      value={values.description || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isReadOnly || parameterTemplateInUse}
                      multiline={true}
                      rows={4}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6} container={true} alignContent='space-between'>
                    <FormLayoutItem xs={12}>
                      <TextFieldComponent
                        label={intl.formatMessage({ id: 'connect.modal.parameter.min' })}
                        floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.min.help' })}
                        name='min'
                        touched={touched.min}
                        errors={errors.min}
                        value={values.min || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isReadOnly}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={12}>
                      <TextFieldComponent
                        label={intl.formatMessage({ id: 'connect.modal.parameter.max' })}
                        floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.max.help' })}
                        name='max'
                        touched={touched.max}
                        errors={errors.max}
                        value={values.max || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isReadOnly}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={12} hidden={!isAdmin}>
                  <SelectFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.aggregation_type' })}
                    name='aggregationFunction'
                    value={values.aggregationFunction || DEFAULT_AGGREGATION_TYPE}
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      setFieldValue('aggregationFunction', e.target.value, false)
                    }}
                    options={AGGREGATION_TYPES_OPTIONS.map((item) => ({ ...item, label: intl.formatMessage({ id: item.labelKey }) }))}
                    disabled={isReadOnly || parameterTemplateInUse}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>

            <SidePanelButtonContainerComponent
              hidden={!(shouldShowDeleteButton || shouldShowResetButton)}
            >
              {
                shouldShowDeleteButton ? (
                  <ButtonComponent
                    color='tertiary'
                    name='deleteButton'
                    StartIconComponent={DeleteIcon}
                    label={intl.formatMessage({ id: 'connect.modal.parameter.delete' })}
                    onClick={handleDelete}
                  />
                ) : (
                  <Box />
                )
              }

              {
                shouldShowResetButton ? (
                  <ButtonComponent
                    color='tertiary'
                    name='resetButton'
                    StartIconComponent={ResetIcon}
                    label={intl.formatMessage({ id: 'connect.modal.parameter.reset' })}
                    onClick={handleReset}
                    disabled={!dirty}
                  />
                ) : (
                  <Box />
                )
              }
            </SidePanelButtonContainerComponent>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='connectParameterModalCloseButton'
              onClick={() => handleModalClose(true)}
              disabled={isSubmitting}
              type='cancel'
              label={intl.formatMessage({ id: 'common.modal.button.close' })}
            />

            <ModalButtonComponent
              name='connectParameterModalSubmitButton'
              onClick={(e) => handleModalSubmit(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid || !dirty}
              type='submit'
              label={intl.formatMessage({ id: 'common.modal.button.apply' })}
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ConnectParameterModalContainer
