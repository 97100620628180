import React, {
  useCallback,
  useLayoutEffect, useMemo,
  useState,
} from 'react'

import { generatePath, useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'
import { DATA_GRIDS } from '@constants/data-grid.constants'

import {
  GridColDef,
  GridRowParams,
  GRID_ACTIONS_COLUMN_TYPE,
  GridRowId,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-premium'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { getDataGridId } from '@utils/data-grid.utils'
import {
  generateActionsColumnDefinition,
  generateDateTimeColumnDefinition,
  generateRegularColumnDefinition,
  generateCustomMasterDetailColumnDefinition,
  generateFileSizeColumnDefinition,
  generateCopyButtonColumnDefinition,
  generateChipColumnDefinition,
  generateBooleanColumnDefinition,
} from '@utils/data-grid-cells.utils'

import ViewIcon from '@icons/view.icon'
import DeleteIcon from '@icons/delete.icon'
import DownloadIcon from '@icons/download.icon'
import OpenLinkIcon from '@icons/openLink.icon'
import ProgressIcon from '@icons/progress.icon'

import PageLayoutContainer from '@containers/application/PageLayout'
import DataGridComponent from '@base/datagrid/data-grid'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import TrainingStatusChipComponent from '@components/training-data/TrainingStatusChip'
import TrainingDataErrorRowComponent from '@components/training-data/TrainingDataErrorRow'
import FileBrowserItemPreviewDialog from '@components/file-browser/FileBrowserItemPreviewDialog'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import DataGridBooleanCellComponent from '@base/datagrid/data-grid-cells/data-grid-boolean-cell'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'

import { INSIGHTS_COLOR_WAY } from '@constants/insights.constants'
import { DATA_UPLOAD_PATH } from '@constants/routes.constants'
import { downloadTrainingDataAction, removeTrainingDataAction, requestTrainingDataFlatListAction } from '@redux/modules/training-files/training-files.actions'
import { getUseCaseTrainingDataFlatList, isFetchingTrainingDataFlatList } from '@redux/modules/training-files/training-files.selectors'
import { requestFilePreviewAction } from '@redux/modules/file-service/file-service.actions'
import { FILE_PREVIEW_TYPE, SUPPORTED_PREVIEW_FILE_TYPES } from '@constants/files.constants'
import { isLegacyUploadCheck } from '@utils/use-cases.utils'
import { getProcessingStatusOptions, isActionButtonDisabled } from '@utils/training-data.utils'
import { getCsvPreviewButtonLabel, isCsvPreviewDisabled } from '@utils/files.utils'

import useGridInitialState from '@hooks/useGridInitialState.hook'

export const getChipBgColorByFileIdentifier = (fileIdentifier: string) => {
  if (isLegacyUploadCheck(fileIdentifier)) {
    return `${INSIGHTS_COLOR_WAY[1].base}55`
  }

  return undefined
}

export const getChipBgColorByVersion = (version: string) => {
  if (!version || version === 'N/A') {
    return `${INSIGHTS_COLOR_WAY[1].base}55`
  }

  return undefined
}

const TrainingDataContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase

  const isAdmin = useSelector(getIsAdmin)
  const isFetching = useSelector(isFetchingTrainingDataFlatList)
  const useCase = useSelector(getUseCaseItem)
  const list = useSelector(getUseCaseTrainingDataFlatList)
  const tableId = getDataGridId(DATA_GRIDS.TRAINING_DATA_FLAT_TABLE, 2)
  const slotProps = {
    toolbar: {
      withExport: false,
    },
  }

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])
  const [removeFileDialogState, setRemoveFileDialogState] = useState<null | TrainingFiles.TrainingFileItem>(null)

  useLayoutEffect(() => {
    dispatch(
      requestTrainingDataFlatListAction({
        useCaseId,
      }),
    )
  }, [dispatch, useCaseId])

  const handleFileDownload = useCallback((file: TrainingFiles.TrainingFileItem) => {
    dispatch(
      downloadTrainingDataAction({
        useCaseId,
        fileName: file.fileName,
        trainingDataFileId: file.trainingDataFileId,
      }),
    )
  }, [dispatch, useCaseId])

  const handlePreview = useCallback((file: TrainingFiles.TrainingFileItem) => {
    dispatch(
      requestFilePreviewAction({
        fileName: file.fileName,
        filePath: file.trainingDataFileId,
        fileExtension: SUPPORTED_PREVIEW_FILE_TYPES.CSV,
        fileType: FILE_PREVIEW_TYPE.TRAINING_FILES,
        isEditable: false,
      }),
    )
  }, [dispatch])

  const handleFileDelete = useCallback((file: TrainingFiles.TrainingFileItem | null) => {
    if (!file) {
      return
    }

    dispatch(
      removeTrainingDataAction({
        meta: {
          useCaseId,
          trainingDataFileId: file.trainingDataFileId,
          version: file.version ? Number(file.version) : undefined,
          fileIdentifier: file.fileIdentifier,
          fetchList: false,
          showToast: true,
          fetchFlatList: true,
        },
      }),
    )
  }, [useCaseId, dispatch])

  const handleJumpToSidePanel = useCallback((file: TrainingFiles.TrainingFileItem) => {
    const path = generatePath(DATA_UPLOAD_PATH, {
      usecase: useCaseId,
      identifier: file.fileIdentifier!,
      version: file.version || 'upload',
    })

    window.open(path, '_blank')
  }, [useCaseId])

  const getActionItems = useCallback((params: GridRowParams<TrainingFiles.TrainingFileItem>) => {
    const actions = [
      (
        <DataGridActionButtonComponent
          name='previewFile'
          icon={<ViewIcon />}
          onClick={() => handlePreview(params.row)}
          label={getCsvPreviewButtonLabel(intl, params.row)}
          disabled={isCsvPreviewDisabled(params.row)}
          id={params.id}
        />
      ),
      (
        <DataGridActionButtonComponent
          name='downloadFile'
          icon={<DownloadIcon />}
          onClick={() => handleFileDownload(params.row)}
          label={intl.formatMessage({ id: 'common.tables.actions.download' })}
          id={params.id}
        />
      ),
      (
        <DataGridActionButtonComponent
          name='removeFile'
          icon={<DeleteIcon />}
          onClick={() => setRemoveFileDialogState(params.row)}
          label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
          disabled={isActionButtonDisabled(false, params.row)}
          id={params.id}
        />
      ),
      (
        <DataGridActionButtonComponent
          name='jumpTo'
          icon={<OpenLinkIcon />}
          onClick={() => handleJumpToSidePanel(params.row)}
          label={intl.formatMessage({ id: 'trainingData.table.openInConnectView' })}
          id={params.id}
        />
      ),
    ]

    return actions
  }, [
    intl, handleFileDownload,
    handleJumpToSidePanel, handlePreview,
  ])

  const processingStatusOptions = useMemo(() => getProcessingStatusOptions(intl), [intl])

  const columns = useMemo<GridColDef[]>(() => {
    const cols = [
      generateRegularColumnDefinition({
        intl,
        field: 'fileName',
        headerName: intl.formatMessage({ id: 'trainingData.table.file_name' }),
      }),
      generateChipColumnDefinition({
        intl,
        field: 'fileIdentifier',
        headerName: intl.formatMessage({ id: 'trainingData.table.fileIdentifier' }),
        getBgColorByValue: getChipBgColorByFileIdentifier,
      }),
      generateChipColumnDefinition({
        intl,
        field: 'version',
        headerName: intl.formatMessage({ id: 'trainingData.table.version' }),
        naLabel: intl.formatMessage({ id: 'common.na' }),
        getBgColorByValue: getChipBgColorByVersion,
        gridColDefOverrides: {
          type: 'number',
          minWidth: 150,
          width: 100,
        },
      }),
      generateBooleanColumnDefinition({
        intl,
        field: 'isLatestVersion',
        headerName: intl.formatMessage({ id: 'trainingData.table.isLatestVersion' }),
        gridColDefOverrides: {
          renderCell: (params) => (
            <DataGridBooleanCellComponent
              params={params}
              booleanLabels={{
                active: intl.formatMessage({ id: 'common.yes' }),
                inactive: isLegacyUploadCheck(params.row.fileIdentifier) ? intl.formatMessage({ id: 'common.na' }) : intl.formatMessage({ id: 'common.no' }),
              }}
            />
          ),
        },
      }),
      ...generateCustomMasterDetailColumnDefinition({
        field: 'processingStatus',
        headerName: intl.formatMessage({ id: 'trainingData.table.status' }),
        CellComponent: TrainingStatusChipComponent,
        cellComponentProps: {
          useCaseId,
          reflectRouteChange: false,
        },
        gridColDefOverrides: {
          type: 'singleSelect',
          valueOptions: processingStatusOptions,
        },
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'createdAt',
        headerName: intl.formatMessage({ id: 'trainingData.table.upload_date' }),
        gridColDefOverrides: {
          minWidth: 200,
          width: 200,
        },
      }),
      generateFileSizeColumnDefinition({
        intl,
        field: 'fileSizeBytes',
        headerName: intl.formatMessage({ id: 'trainingData.table.file_size' }),
        gridColDefOverrides: {
          minWidth: 120,
          width: 120,
        },
      }),
      generateCopyButtonColumnDefinition({
        field: 'trainingDataFileId',
        headerName: intl.formatMessage({ id: 'trainingData.table.id' }),
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 4,
      }),
    ]

    return cols
  }, [
    intl,
    useCaseId,
    processingStatusOptions,
    getActionItems,
  ])

  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    columns: {
      columnVisibilityModel: {
        [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
      },
    },
    sorting: {
      sortModel: [{ field: 'createdAt', sort: 'desc' }],
    },
  })

  const getDetailPanelContent = useCallback(({ row }: GridRowParams) => <TrainingDataErrorRowComponent record={row} />, [])
  const getDetailPanelHeight = useCallback(() => 'auto', [])
  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      )
    },
    [],
  )

  const onRefreshButtonClick = () => {
    dispatch(
      requestTrainingDataFlatListAction({
        useCaseId,
      }),
    )
  }

  const pageBarRightSideBlocks = (
    <CallToActionButtonComponent
      name='refreshButton'
      StartIconComponent={ProgressIcon}
      onClick={onRefreshButtonClick}
      loading={isFetching}
      label={intl.formatMessage({ id: 'common.refresh' })}
    />
  )

  const getRowId = useCallback((row: TrainingFiles.TrainingFileItem) => row.trainingDataFileId, [])

  if (!isAdmin) {
    return null
  }

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'trainingData.browser_tab.title' }, { name: useCase?.name })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
    >
      <DataGridComponent
        key={tableId}
        name={DATA_GRIDS.TRAINING_DATA_FLAT_TABLE}
        id={tableId}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={getRowId}
        initialState={initialState}
        disableRowGrouping={false}
        enablePersistence={true}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        slotProps={slotProps}
      />

      <FileBrowserItemPreviewDialog />

      <TextConfirmationDialogComponent
        open={Boolean(removeFileDialogState) && isAdmin}
        onClose={() => setRemoveFileDialogState(null)}
        onSubmit={() => handleFileDelete(removeFileDialogState)}
        confirmationText={removeFileDialogState?.fileName}
        confirmationInputLabel={intl.formatMessage({ id: 'connect.block.data_input.table.removeFileDialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'connect.block.data_input.table.removeFileDialog.content' }, {
            name: <Box component='strong'>{removeFileDialogState?.fileName}</Box>,
          })
        }
      />
    </PageLayoutContainer>
  )
}

export default TrainingDataContainer
