import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'

import { isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'

import {
  requestArticlesOverviewArtifactAction,
  requestArticlesOverviewDetailsArtifactAction,
} from '@redux/modules/recommendation-artifacts/recommendation-artifacts.actions'

import {
  getArticlesOverviewDetailsArtifact,
  getArticlesOverviewTableArtifact,
  isFetchingArticlesOverviewDetailsArtifact,
  isFetchingArticlesOverviewTableArtifact,
} from '@redux/modules/recommendation-artifacts/recommendation-artifacts.selectors'

import ArticlesOverviewComponent from '@components/recommendation-artifacts/ArticlesOverview'

interface ArticlesOverviewArtifactContainerProps {}

const ArticlesOverviewArtifactContainer: React.FC<ArticlesOverviewArtifactContainerProps> = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase

  const articlesOverviewTableArtifact = useSelector(getArticlesOverviewTableArtifact)
  const articlesOverviewDetailsArtifact = useSelector(getArticlesOverviewDetailsArtifact)
  const isFetchingTable = useSelector(isFetchingArticlesOverviewTableArtifact)
  const isFetchingDetails = useSelector(isFetchingArticlesOverviewDetailsArtifact)
  const isFetchingUseCaseDetails = useSelector(isFetchingUseCase)

  useEffect(() => {
    dispatch(
      requestArticlesOverviewArtifactAction({
        useCaseId,
      }),
    )
  }, [useCaseId, dispatch])

  const {
    table,
    metaData = {} as RecommendationArtifacts.ArticlesOverviewArtifactMetaData,
  } = articlesOverviewTableArtifact

  const {
    itemId,
    breakdown,
    kpiItems,
    visualisations,
  } = articlesOverviewDetailsArtifact

  const handleSelect = (id: string | number) => {
    dispatch(
      requestArticlesOverviewDetailsArtifactAction({
        useCaseId,
        itemId: String(id),
      }),
    )
  }

  return (
    <ArticlesOverviewComponent
      loadingTable={isFetchingTable || isFetchingUseCaseDetails}
      loadingDetails={isFetchingDetails}
      table={table}
      kpiItems={kpiItems}
      visualizations={visualisations}
      metaData={metaData}
      breakdown={breakdown}
      selectedItem={itemId}
      handleSelect={handleSelect}
    />
  )
}

export default ArticlesOverviewArtifactContainer
