import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import {
  Box, Menu,
  ListItemText,
  MenuItem,
} from '@mui/material'

import DropdownButtonComponent, { DropdownButtonProps } from '@base/dropdowns/DropdownButton/DropdownButton.component'

export interface DropdownMenuOption {
  label: string
  value: string | number
}

export interface DropdownMenuComponentProps {
  /**
   * Overline text
   */
  overline?: string
  /**
   * If 'true', the menu will expand to the full width of the container
   */
  fitMenu?: boolean
  /**
   * Data test id
   */
  dataTestId?: string
  /**
   * Name of the dropdown
   */
  name: string
  /**
   * If 'true', the dropdown will be in loading state
   */
  loading?: boolean
  /**
   * If 'true', the dropdown will be disabled
   */
  disabled?: boolean
  /**
   * If 'true', the dropdown will not allow to unselect the selected option
   */
  forbidUnselected?: boolean
  /**
   * Max height of the dropdown in pixels
   */
  maxHeight?: string
  /**
   * If 'true', the dropdown will allow multiple selection
   */
  multiple?: boolean
  /**
   * Dropdown options
   */
  options?: DropdownMenuOption[]
  /**
   * Selected value
   */
  value?: DropdownMenuOption['value'] | DropdownMenuOption['value'][]
  /**
   * Change handler
   */
  onChange: (value: DropdownMenuOption['value'] | DropdownMenuOption['value'][]) => void
  /**
   * Custom button props
   */
  buttonProps?: Partial<DropdownButtonProps>
  /**
   * Minimum width of the dropdown menu
   */
  minWidth?: string
}

export const DropdownMenuComponent: React.FC<DropdownMenuComponentProps> = ({
  dataTestId,
  name,
  value,
  disabled,
  forbidUnselected,
  multiple,
  overline,
  maxHeight,
  onChange,
  options = [],
  buttonProps,
  fitMenu = true,
  minWidth,
}) => {
  const intl = useIntl()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const [openMenu, setOpenMenu] = useState(false)

  const handleOpen = () => {
    setOpenMenu(true)
  }

  const handleClose = () => {
    setOpenMenu(false)
  }

  const handleOptionClick = (option: DropdownMenuOption) => {
    if (multiple && Array.isArray(value)) {
      const isChecked = value.includes(option.value)
      const newValue = isChecked ? value.filter((item) => item !== option.value) : [...value, option.value]

      onChange(newValue)
    } else {
      onChange(option.value)
    }

    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      option,
      router: params,
    })

    handleClose()
  }

  const label = useMemo(() => {
    if (value === undefined || value === null) {
      return intl.formatMessage({ id: 'common.dropdown.loading' })
    }

    if (!options || options.length === 0) {
      return intl.formatMessage({ id: 'common.dropdown.noOptions' })
    }

    if (multiple) {
      if (!Array.isArray(value) || !value || value.length === 0) {
        return intl.formatMessage({ id: 'common.dropdown.select' })
      }

      return intl.formatMessage({ id: 'common.dropdown.nSelected' }, {
        n: value.length,
      })
    }

    const selectedOption = options.find((option) => option.value === value)

    return selectedOption?.label || intl.formatMessage({ id: 'common.dropdown.select' })
  }, [intl, multiple, options, value])

  return (
    <Box
      data-testid={dataTestId || DropdownMenuComponent.name}
    >
      <DropdownButtonComponent
        label={label}
        dataTestId={dataTestId}
        onClick={handleOpen}
        name={name}
        open={openMenu}
        bold={true}
        outlined={true}
        disabled={disabled}
        overline={overline}
        handleRef={anchorRef}
        {...buttonProps}
      />

      <Menu
        open={openMenu}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            transform: 'translate(0px, 2px) !important',
            maxHeight,
          },
        }}
        MenuListProps={{
          sx: {
            width: fitMenu ? (anchorRef && anchorRef.current?.offsetWidth) : '100%',
            minWidth,
          },
        }}
      >
        {
          options?.map((option) => {
            const isChecked = Array.isArray(value) ? value.includes(option.value) : value === option.value
            const isDisabled = forbidUnselected && Array.isArray(value) && isChecked && value.length === 1

            return (
              <MenuItem
                key={option.value}
                onClick={() => handleOptionClick(option)}
                disabled={isDisabled}
                selected={isChecked && !multiple}
              >
                <ListItemText primary={option.label} />
              </MenuItem>
            )
          })
        }
      </Menu>
    </Box>
  )
}

export default DropdownMenuComponent
