import React from 'react'
import { Grid } from '@mui/material'
import KpiTileComponent, { KpiTileComponentProps } from '@base/tiles/KpiTile/KpiTile.component'

export interface KpiCard extends KpiTileComponentProps {
  formatter?: (value: number | string | null) => string
}

export interface InsightsKpisComponentProps {
  /**
   * Whether the component is loading
   */
  loading?: boolean
  /**
   * Whether the values are loading
   */
  loadingValues?: boolean
  /**
   * List of KPI cards to render
   */
  cards: KpiCard[]
  /**
   * The size of the grid item
   *
   * @default 3
   */
  gridItemSize?: number
}

const InsightsKpisComponent: React.FC<InsightsKpisComponentProps> = ({
  loading,
  loadingValues,
  gridItemSize = 3,
  cards,
}) => {
  if (!cards || !cards.length) {
    return null
  }

  return (
    <Grid data-testid={InsightsKpisComponent.name} container={true} spacing={2} mb={2}>
      {
        cards.map((card, index) => {
          const formattedValue = card.formatter ? card.formatter(card.value) : card.value

          return (
            <Grid item={true} xs={gridItemSize} key={index}>
              <KpiTileComponent
                {...card}
                value={formattedValue}
                loading={loading}
                loadingValues={loadingValues}
              />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default InsightsKpisComponent
