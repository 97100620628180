import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { requestTopArticlesArtifactAction } from '@redux/modules/recommendation-artifacts/recommendation-artifacts.actions'
import { isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'

import {
  isFetchingTopArticleArtifactss,
  getTopArticlesArtifact,
  getComparisonModeState,
} from '@redux/modules/recommendation-artifacts/recommendation-artifacts.selectors'

import TopArticlesComponent from '@components/recommendation-artifacts/TopArticles'

interface TopArticlesContainerProps {}

const TopArticlesArtifactContainer: React.FC<TopArticlesContainerProps> = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase

  const topArticlesArtifact = useSelector(getTopArticlesArtifact)
  const isFetching = useSelector(isFetchingTopArticleArtifactss)
  const isFetchingUseCaseDetails = useSelector(isFetchingUseCase)
  const comparisonMode = useSelector(getComparisonModeState)

  useEffect(() => {
    dispatch(
      requestTopArticlesArtifactAction({
        useCaseId,
      }),
    )
  }, [useCaseId, dispatch])

  const {
    tables = [],
    metaData = {} as RecommendationArtifacts.AnalyzeViewTopArticlesMetaData,
  } = topArticlesArtifact

  if ((tables.length === 0) && !isFetching) {
    return null
  }

  return (
    <TopArticlesComponent
      comparisonMode={comparisonMode}
      loading={isFetching || isFetchingUseCaseDetails}
      tables={tables}
      totalCount={metaData.totalCount}
      topOptions={metaData.topOptions}
      defaultTopOption={metaData.defaultTopOption}
      tablesPerRow={metaData.tablesPerRow}
    />
  )
}

export default TopArticlesArtifactContainer
