import { useSelector } from '@redux/hooks'
import { IntlShape } from 'react-intl'
import { useEffect } from 'react'

import get from 'lodash.get'
import set from 'lodash.set'
import { IS_LOCAL_ENV } from '@constants/common.constants'
import SYNC_STATUS from '@containers/modals/connector-edit-modal/EditConnectorModal.status'
import * as API from '@redux/modules/hermes/hermes.api'
import { getSelectedCustomerId } from '@redux/modules/customer/customer.selectors'
import { HERMES_DIRECTIONS } from '@constants/hermes.constants'

const CREDENTIALS_PLACEHOLDER = '**********'

/**
 * @function openOAuthPopup Opens an OAuth popup window with the given title
 *
 * @param {string} oAuthUrl The oauth url that should be opened
 */
export function openOAuthPopup(oAuthUrl?: string): void {
  const width = 500
  const height = 650
  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2.5

  window.open(oAuthUrl, 'Sign In', `toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${top}`)
}

/**
 * @function useOAuthListener Custom hook to listen to OAuth data from the popup
 *
 * @param {string} id The sourceId or destinationId we try to auth for
 * @param {function} callback Callback that is being called with the token payload
 * @param {HERMES_DIRECTIONS} direction source or destination
 */
export function useOAuthListener(id: string, callback: Function, direction: HERMES_DIRECTIONS): void {
  const customerId = useSelector(getSelectedCustomerId)

  useEffect(() => {
    window.addEventListener('message', receiveOAuthMessage, false)
    return () => {
      window.removeEventListener('message', receiveOAuthMessage, false)
    }
  })

  const receiveOAuthMessage = async (event: MessageEvent) => {
    if (!id || ((event.origin !== window.location.origin) && !IS_LOCAL_ENV)) {
      return
    }

    const { data } = event
    const params = new URLSearchParams(data)
    const state = params.get('state')
    const code = params.get('code')

    if (!state || !code) {
      return
    }

    try {
      const token = await API.postOAuthCallback(customerId, id, { state, code }, direction)
      callback(token)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}

/**
 * @function getConfigFromSource Create a config object including default values by sourceId (object)
 *
 * @param {string} sourceId The source id for which to get the config
 * @param {Array} sources The list of available sources
 * @param {string} defaultDate The default date
 *
 * @return {Object}
 */
export function getConfigFromSource(
  sourceId: string,
  sources?: Hermes.SourceDetails[],
  defaultDate?: string,
): Hermes.ConfigurationForm {
  const source = sources?.find((e) => e.sourceId === sourceId)
  const configuration = {} as Hermes.ConfigurationForm

  if (!sourceId || !source) {
    return configuration
  }

  for (const block of source.configurationForm) {
    for (const [path, value] of Object.entries(block.fields)) {
      const isDateType = value.type === 'date'
      const shouldUseDefaultDate = isDateType && defaultDate

      set(configuration, path, shouldUseDefaultDate ? defaultDate : (value.default ?? null))
    }
  }

  return configuration
}

/**
 * @function setObjectValuesByPath Copies values from a source object to a target object by key paths
 *
 * @param {Object} source The source object
 * @param {Object} target The target object
 */
export function setObjectValuesByPaths(source: object, target: object) {
  for (const [path, value] of Object.entries(source)) {
    set(target, path, value)
  }
}

/**
 * @function getSourceFieldSchemas Collect all config field schemas in a flat object
 *
 * @param {string} sourceId The source id for which to get the field schemas
 * @param {Array} sources The list of available sources
 *
 * @return {Object}
 */
export function getSourceFieldSchemas(sourceId: string, sources: Hermes.SourceDetails[]) {
  const source = sources.find((e) => e.sourceId === sourceId)
  const fields = {}

  source?.configurationForm?.forEach((block) => Object.assign(fields, block.fields))

  return fields
}

/**
 * @function encodeStreamName Encodes a stream name, so that dots and spaces don't break Formik
 *
 * @param {string} streamName The name of the stream that may contain dots
 *
 * @return {string}
 */
export function encodeStreamName(streamName: string): string {
  return window.btoa(streamName)
}

/**
 * @function mapSyncStatusToTranslation Maps a sync status to its translation (string)
 *
 * @param {Object} intl The intl object used for translating the status
 * @param {string} status The raw status to translate
 *
 * @return {string}
 */
export function mapSyncStatusToTranslation(intl: IntlShape, status: string): string {
  switch (status) {
    case SYNC_STATUS.running:
      return intl.formatMessage({ id: 'connectors.modal.sync.running' })
    case SYNC_STATUS.cancelled:
      return intl.formatMessage({ id: 'connectors.modal.sync.cancelled' })
    case SYNC_STATUS.succeeded:
      return intl.formatMessage({ id: 'connectors.modal.sync.succeeded' })
    case SYNC_STATUS.pending:
      return intl.formatMessage({ id: 'connectors.modal.sync.pending' })
    case SYNC_STATUS.failed:
      return intl.formatMessage({ id: 'connectors.modal.sync.failed' })
    case SYNC_STATUS.resetting:
      return intl.formatMessage({ id: 'connectors.modal.sync.resetting' })
    default:
      return intl.formatMessage({ id: 'connectors.modal.sync.nosync' })
  }
}

/**
 * @function checkifOAuthRequired Checks whether oAuth is required
 *
 * @param {Object} source Hermes source
 *
 * @return {Boolean}
 */
export function checkifOAuthRequired(source: Hermes.SourceDetails): boolean {
  return Boolean(source?.configurationForm?.find((block) => Boolean(block.oauth)))
}

/**
 * @function isAuthDone Checks whether oAuth is done
 *
 * @param {Object} formValues Configuration form values
 *
 * @return {Boolean}
 */
export function isOAuthDone(formValues: object): boolean {
  const isRefreshTokenPresent = Boolean(get(formValues, 'configuration.credentials.refreshToken'))
  const isAccessTokenPresent = Boolean(get(formValues, 'configuration.credentials.accessToken'))
  const isRefreshTokenPlaceholder = get(formValues, 'configuration.credentials.refreshToken') === CREDENTIALS_PLACEHOLDER
  const isAccessTokenPlaceholder = get(formValues, 'configuration.credentials.accessToken') === CREDENTIALS_PLACEHOLDER

  return (isRefreshTokenPresent && !isRefreshTokenPlaceholder) || (isAccessTokenPresent && !isAccessTokenPlaceholder)
}
