import produce from 'immer'
import orderBy from 'lodash.orderby'

import { FilesServiceState, ReducerPayload } from '@redux/modules/types'
import { getNameByFilePath, getFileExtension, getSftpTreePath } from '@utils/files.utils'

import {
  FILE_BROWSER_DEFAULT_SORTING_KEY, FILE_BROWSER_DEFAULT_SORTING_ORDER,
  FILE_BROWSER_SORTING_OPTIONS, FILE_BROWSER_SORTING_ORDER_OPTIONS,
  SFTP_BUCKET_NAME,
} from '@constants/files.constants'

import { fileListToTree } from './file-service.utils'

export const initialState: FilesServiceState = {
  fetchingKeys: [],
  filePreview: null,
  filesList: [],
  browserTree: null,
  sortingKey: FILE_BROWSER_DEFAULT_SORTING_KEY,
  sortingOrder: FILE_BROWSER_DEFAULT_SORTING_ORDER,
}

export const receiveSortingChanges = (
  state: FilesServiceState,
  action: ReducerPayload<{
    sortingKey: FILE_BROWSER_SORTING_OPTIONS,
    sortingOrder: FILE_BROWSER_SORTING_ORDER_OPTIONS
  }>,
) => {
  const nextState = produce(state, (draftState) => {
    draftState.sortingKey = action.payload.sortingKey
    draftState.sortingOrder = action.payload.sortingOrder

    const sortedFilesList = orderBy(draftState.filesList, [draftState.sortingKey], draftState.sortingOrder) as FileService.ReduxFileItem[]

    draftState.filesList = sortedFilesList
    draftState.browserTree = fileListToTree(sortedFilesList, draftState.sortingKey, draftState.sortingOrder)
  })

  return nextState
}

export const receiveFilesList = (state: FilesServiceState, action: ReducerPayload<{
  filesList: FileService.FileItem[],
  sftpFilesList: FileService.FileItem[],
  companySlug: string,
  useCaseSlug: string,
}>) => {
  const mapFileItem = (fileItem: FileService.FileItem, isSftp: boolean) => {
    const name = getNameByFilePath(fileItem.fileKey)

    return {
      fileKey: fileItem.fileKey,
      path: getSftpTreePath(fileItem.fileKey, action.payload.companySlug, action.payload.useCaseSlug),
      sizeInBytes: fileItem.metadata.sizeInBytes,
      createdAt: fileItem.metadata.createdAt,
      fileExtension: getFileExtension(name, false),
      bucketName: isSftp ? SFTP_BUCKET_NAME : action.payload.useCaseSlug,
      name,
      isSftp,
    }
  }

  const nextState = produce(state, (draftState) => {
    const filesList = action.payload.filesList.map((fileItem, index) => mapFileItem(fileItem, false))
    const sftpItemsList = action.payload.sftpFilesList.map((fileItem, index) => mapFileItem(fileItem, true))
    const itemsList = [...filesList, ...sftpItemsList]

    const sortedFilesList = orderBy(itemsList, [draftState.sortingKey], draftState.sortingOrder) as FileService.ReduxFileItem[]

    draftState.filesList = sortedFilesList
    draftState.browserTree = fileListToTree(sortedFilesList, draftState.sortingKey, draftState.sortingOrder)
  })

  return nextState
}

export const receiveFilePreview = (state: FilesServiceState, action: ReducerPayload<FileService.FilePreview>) => {
  const nextState = produce(state, (draftState) => {
    draftState.filePreview = action.payload
  })

  return nextState
}
