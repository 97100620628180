import React from 'react'

import { useSelector } from '@redux/hooks'
import { getAnalyzeTable, isFetchingAnalyzeTable } from '@redux/modules/analyze/analyze.selectors'
import { requestAnalyzeGridStateChangeAction } from '@redux/modules/analyze/analyze.actions'
import { getInsightsAbcTotals } from '@redux/modules/insights/insights.selectors'
import { DEFAULT_ANALYZE_PREDICTION_COLUMN } from '@constants/insights.constants'

import {
  transformAnalyzeTableColumns,
  analyzeAggregationModelTransformer,
} from '@utils/analyze.utils'

import InsightsTableComponent from '@components/insights/InsightsTable'

const AnalyzeTableContainer = () => {
  const analyzeTable = useSelector(getAnalyzeTable)
  const isFetching = useSelector(isFetchingAnalyzeTable)
  const abcTotals = useSelector(getInsightsAbcTotals)

  const {
    tableId,
    rows,
    rowCount,
    columns,
    gridState,
  } = analyzeTable

  const alwaysVisibleColumns = [
    DEFAULT_ANALYZE_PREDICTION_COLUMN,
  ]

  return (
    <InsightsTableComponent
      tableId={tableId}
      rows={rows}
      rowCount={rowCount}
      totalRowCount={abcTotals.total}
      columns={columns}
      gridState={gridState}
      isFetching={isFetching}
      requestGridStateChangeAction={requestAnalyzeGridStateChangeAction}
      columnTransformer={transformAnalyzeTableColumns}
      aggregationModelTransformer={analyzeAggregationModelTransformer}
      defaultSortingColumn={DEFAULT_ANALYZE_PREDICTION_COLUMN}
      alwaysVisibleColumns={alwaysVisibleColumns}
    />
  )
}

export default AnalyzeTableContainer
