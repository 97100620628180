import { createAction } from 'redux-actions'

import {
  RECEIVE_ANALYZE_TABLE,
  REQUEST_ANALYZE_TABLE,
  REQUEST_ANALYZE_CHART,
  RECEIVE_ANALYZE_CHART,
  REQUEST_ANALYZE_GRID_STATE_CHANGE,
  RECEIVE_ANALYZE_GRID_STATE_CHANGE,
  RESET_ANALYZE,
  REQUEST_ANALYZE_VIEW,
  START_FETCHING_ANALYZE,
  STOP_FETCHING_ANALYZE,
  REQUEST_ANALYZE_TIME_WINDOW_DETAILS,
  RECEIVE_ANALYZE_TIME_WINDOW_DETAILS,
} from './analyze.action-types'

import {
  RequestAnalyzeActionPayload,
  RequestAnalyzeTableActionPayload,
  RequestAnalyzeGridStateChangePayload,
  RequesAnalyzeChartActionPayload,
} from './analyze.types'

export const startFetchingAnalyzeAction = createAction<string>(START_FETCHING_ANALYZE)
export const stopFetchingAnalyzeAction = createAction<string>(STOP_FETCHING_ANALYZE)

export const requestAnalyzeTableAction = createAction<RequestAnalyzeTableActionPayload>(REQUEST_ANALYZE_TABLE)
export const receiveAnalyzeTableAction = createAction(RECEIVE_ANALYZE_TABLE)

export const requestAnalyzeChartAction = createAction<RequesAnalyzeChartActionPayload>(REQUEST_ANALYZE_CHART)
export const receiveAnalyzeChartAction = createAction(RECEIVE_ANALYZE_CHART)

export const requestAnalyzeTimeWindowDetailsAction = createAction<RequestAnalyzeActionPayload>(REQUEST_ANALYZE_TIME_WINDOW_DETAILS)
export const receiveAnalyzeTimeWindowDetailsAction = createAction(RECEIVE_ANALYZE_TIME_WINDOW_DETAILS)

export const requestAnalyzeGridStateChangeAction = createAction<RequestAnalyzeGridStateChangePayload>(REQUEST_ANALYZE_GRID_STATE_CHANGE)
export const receiveAnalyzeGridStateChangeAction = createAction(RECEIVE_ANALYZE_GRID_STATE_CHANGE)

export const resetAnalyzeViewAction = createAction(RESET_ANALYZE)
export const requestAnalyzeViewAction = createAction<RequestAnalyzeActionPayload>(REQUEST_ANALYZE_VIEW)
