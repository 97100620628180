import React, { useCallback, useLayoutEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { Box } from '@mui/material'

import PageLayoutContainer from '@containers/application/PageLayout'

import { requestOptimizeDrillDown } from '@redux/modules/optimize/optimize.actions'
import { formatNumberWithSuffixUnit } from '@utils/analysis.utils'
import { exportTo } from '@utils/export.utils'
import { EXPORT_AS_PNG, EXPORT_AS_CSV } from '@constants/export.constants'
import { OPTIMIZE_PATH } from '@constants/routes.constants'
import { getDrillDownData, getRangeSelectorData, isFetchingData } from '@redux/modules/optimize/optimize.selectors'
import { OPTIMIZE_RESTOCK_SELECTOR_TYPES } from '@constants/optimize.constants'

import ViewInfoComponent from '@base/pagebar/ViewInfo'
import ContextMenuComponent from '@base/menus/ContextMenu'
import OptimizeDrillDownComponent from '@components/(features)/optimize/optimize-drill-down'

const EXPORT_ID = 'optimize-drill-down'
const OptimizeDrillDownContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { params: { usecase, sku, region } } = useRouteMatch<Common.RouterMatch>()

  const drillDownData = useSelector(getDrillDownData)
  const isFetching = useSelector(isFetchingData)
  const rangeSelector = useSelector(getRangeSelectorData)

  const { meta, data } = drillDownData
  const { targetRevenueSelected, targetSelectorType, targeReplenishmentCostSelected } = rangeSelector
  const { productName } = meta

  useLayoutEffect(() => {
    dispatch(
      requestOptimizeDrillDown({
        useCaseId: usecase,
        sku,
        region,
        fetchUseCase: true,
      }),
    )
  }, [dispatch, usecase, sku, region])

  const handleExport = useCallback((type: string) => {
    setTimeout(() => {
      exportTo(
        type,
        intl.formatMessage({ id: 'optimize.drillDown.title' }, { name: productName }),
        {
          js: data,
          selector: document.getElementById(EXPORT_ID)!,
        },
      )
    }, 1000)
  }, [intl, data, productName])

  const pageBarRightSideBlocks = useMemo(() => {
    const isRevenueSelected = targetSelectorType === OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE

    return (
      <>
        <ViewInfoComponent
          value={
            isRevenueSelected ?
              intl.formatMessage({ id: 'optimize.drillDown.revenue' }, { revenue: formatNumberWithSuffixUnit(targetRevenueSelected) }) :
              intl.formatMessage({ id: 'optimize.drillDown.replenishmentCost' }, { cost: formatNumberWithSuffixUnit(targeReplenishmentCostSelected) })
          }
          label={intl.formatMessage({ id: 'optimize.drillDown.restock' })}
        />

        <ContextMenuComponent
          name='exportAsContextMenu'
          label={intl.formatMessage({ id: 'common.button.export_as' })}
          actions={[{
            name: 'exportAsPng',
            label: intl.formatMessage({ id: 'common.button.export_png' }),
            disabled: false,
            handler: () => handleExport(EXPORT_AS_PNG),
          }, {
            name: 'exportAsCsv',
            label: intl.formatMessage({ id: 'common.button.export_csv' }),
            disabled: false,
            handler: () => handleExport(EXPORT_AS_CSV),
          }]}
        />

      </>
    )
  }, [intl, targetRevenueSelected, targeReplenishmentCostSelected, targetSelectorType, handleExport])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'optimize.drillDown.title' }, { name: productName })}
      isFetching={isFetching}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      backToPath={usecase ? generatePath(OPTIMIZE_PATH, { usecase }) : undefined}
      customTitle={
        intl.formatMessage({ id: 'common.layout.drill_down.title' }, {
          name: <Box component='span' fontWeight={300}>{productName}</Box>,
        })
      }
    >
      <OptimizeDrillDownComponent
        drillDownData={drillDownData}
        exportId={EXPORT_ID}
      />
    </PageLayoutContainer>
  )
}

export default OptimizeDrillDownContainer
