import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'

import {
  requestTrainingDataListAction,
  uploadTrainingDataAction,
  trainingDataAutoRefreshStopAction,
  removeFileIdentifierVersionAction,
} from '@redux/modules/training-files/training-files.actions'

import {
  isSubmittingTrainingData,
  isUploadingTrainingData,
  getUseCaseFileIdentifiers,
  isFetchingTrainingData,
} from '@redux/modules/training-files/training-files.selectors'

import { changeToastAction } from '@redux/modules/common/common.actions'
import { MAX_FILE_NAME_LENGTH, SUPPORTED_TRAINING_FILE_TYPES } from '@constants/files.constants'
import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { isLegacyUploadCheck } from '@utils/use-cases.utils'
import { isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { isFileNameLengthValid } from '@utils/training-data.utils'
import { getFileExtension } from '@utils/files.utils'

import {
  USE_CASE_DATA_TYPES_MODAL_NAME,
  USE_CASE_DATA_TYPE_VERSIONS_MODAL_NAME,
  USE_CASE_TRAINING_DATA_MODAL_NAME,
} from '@constants/modals.constants'

import CopyIcon from '@icons/copy.icon'
import LayoutIcon from '@icons/flow/layout.icon'
import { CONNECT_PATH } from '@constants/routes.constants'

import DropzoneComponent from '@base/utils/Dropzone'
import ButtonComponent from '@base/buttons/Button'
import TrainingModalHeaderBlockComponent from '@components/training-data/TrainingModalHeaderBlock'
import TrainingModalRemovalBlockComponent from '@components/training-data/TrainingModalRemovalBlock'
import TrainingModalWarningBlockComponent from '@components/training-data/TrainingModalWarningBlock'
import TrainingFilesListComponent from '@components/training-data/TrainingFilesList'

const UseCaseTrainingDataModalContainer: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: {
      usecase,
      version: selectedVersion,
      identifier: selectedFileIdentifier,
      file: selectedFile,
    },
  } = useRouteMatch<Common.RouterMatch>()

  const [initialMount, setInitialMount] = useState(true)
  const [numOfUploads, setNumOfUploads] = useState(1)

  const isAdmin = useSelector(getIsAdmin)
  const isUploading = useSelector(isUploadingTrainingData)
  const isSubmitting = useSelector(isSubmittingTrainingData)
  const isFetchingUseCaseData = useSelector(isFetchingUseCase)
  const isFetchingTrainingDataList = useSelector(isFetchingTrainingData)
  const modalPageName = useSelector(getOpenedModal)
  const fileIdentifiersList = useSelector(getUseCaseFileIdentifiers)
  const isFetching = isFetchingUseCaseData || isFetchingTrainingDataList

  const open = (modalPageName === USE_CASE_TRAINING_DATA_MODAL_NAME) || Boolean(selectedFileIdentifier)
  const isLegacyUpload = isLegacyUploadCheck(selectedFileIdentifier)

  useEffect(() => {
    if (open && usecase) {
      dispatch(
        requestTrainingDataListAction({
          useCaseId: usecase,
          fileIdentifier: selectedFileIdentifier,
          version: selectedVersion ? Number(selectedVersion) : undefined,
          checkIdentifier: initialMount,
          checkVersion: initialMount && !isLegacyUpload,
          withAutoRefresh: true,
        }),
      )

      setInitialMount(false)
    }

    if (!open) {
      dispatch(
        trainingDataAutoRefreshStopAction({
          useCaseId: usecase,
        }),
      )
    }
  }, [
    selectedFileIdentifier,
    dispatch, initialMount, isLegacyUpload,
    open, usecase, selectedVersion,
  ])

  const handleModalClose = useCallback(() => {
    history.push(generatePath(CONNECT_PATH, { usecase }))

    dispatch(
      setPrimaryModalPageName(USE_CASE_DATA_TYPES_MODAL_NAME),
    )
  }, [dispatch, history, usecase])

  const handleManageVersionsClick = useCallback(() => {
    trackEvent({
      componentName: 'manageVersionButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
      fileIdentifier: selectedFileIdentifier,
      version: selectedVersion,
    })

    dispatch(
      setPrimaryModalPageName(USE_CASE_DATA_TYPE_VERSIONS_MODAL_NAME),
    )
  }, [dispatch, usecase, selectedFileIdentifier, selectedVersion])

  const onFileDrop = useCallback((files: File[]) => {
    const data = new FormData()
    const file = files[0]

    const fileName = file?.name || ''
    const fileType = file?.type || ''
    const fileExtension = getFileExtension(fileName)
    const isCsvExtension = fileExtension === SUPPORTED_TRAINING_FILE_TYPES.CSV
    const isFileTypeCsv = fileType.includes(SUPPORTED_TRAINING_FILE_TYPES.CSV)
    const showFileExtensionError = (!isCsvExtension && !isFileTypeCsv) && !isLegacyUpload

    trackEvent({
      componentName: 'trainingDataFilesDropzone',
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      useCaseId: usecase,
      fileIdentifier: selectedFileIdentifier,
      version: selectedVersion,
      uploadCounter: numOfUploads,
      fileNameLength: fileName.length,
    })

    if (files.length > 1) {
      dispatch(
        changeToastAction({
          message: intl.formatMessage({ id: 'connect.data_input.modal.dropzone.too_many_files' }),
          severity: TOAST_TYPE_ERROR,
        }),
      )

      return
    }

    if (showFileExtensionError) {
      dispatch(
        changeToastAction({
          message: intl.formatMessage({ id: 'connect.data_input.modal.dropzone.invalid_file_type' }),
          severity: TOAST_TYPE_ERROR,
        }),
      )

      return
    }

    if (!isFileNameLengthValid(fileName, MAX_FILE_NAME_LENGTH)) {
      dispatch(
        changeToastAction({
          message: intl.formatMessage({ id: 'connect.data_input.modal.dropzone.file_name_too_large' }, { limit: MAX_FILE_NAME_LENGTH }),
          severity: TOAST_TYPE_ERROR,
        }),
      )

      return
    }

    if (!isLegacyUpload) {
      data.append('fileIdentifier', selectedFileIdentifier)
      data.append('version', selectedVersion)
    }

    data.append('trainingDataFile', file)
    data.append('useCaseId', usecase!)

    dispatch(
      uploadTrainingDataAction({
        data,
        meta: {
          fileName: file?.name,
          useCaseId: usecase,
          fileIdentifier: selectedFileIdentifier,
          version: selectedVersion ? Number(selectedVersion) : undefined,
          fetchList: true,
          showToast: true,
        },
      }),
    )

    setNumOfUploads(numOfUploads + 1)
  }, [
    intl, usecase, selectedFileIdentifier, selectedVersion,
    isLegacyUpload, numOfUploads, dispatch,
  ])

  const handleRemoveVersion = useCallback(() => {
    dispatch(
      removeFileIdentifierVersionAction({
        useCaseId: usecase,
        fileIdentifier: selectedFileIdentifier,
        version: Number(selectedVersion),
      }),
    )
  }, [usecase, selectedFileIdentifier, selectedVersion, dispatch])

  const fileIdentifierItem = useMemo(() => {
    return fileIdentifiersList.find((item) => item.value === selectedFileIdentifier)
  }, [fileIdentifiersList, selectedFileIdentifier])

  const latestVersion = useMemo(() => {
    return fileIdentifierItem ? fileIdentifierItem.latestVersion : ''
  }, [fileIdentifierItem])

  const fileIdentifierVersions = useMemo(() => {
    return fileIdentifierItem ? fileIdentifierItem.versions : []
  }, [fileIdentifierItem])

  const selectedVersionDetails = useMemo(() => {
    return fileIdentifierVersions.find((item) => String(item.versionNumber) === String(selectedVersion)) || {} as TrainingFiles.FileIdentifierVersionItem
  }, [fileIdentifierVersions, selectedVersion])

  const latestVersionDetails = useMemo(() => {
    return fileIdentifierVersions.find((item) => String(item.versionNumber) === latestVersion) || {} as TrainingFiles.FileIdentifierVersionItem
  }, [fileIdentifierVersions, latestVersion])

  return (
    <SidePanelComponent
      open={open}
      hasUnsavedChanges={isUploading}
      customPromptMessage={intl.formatMessage({ id: 'common.dropzone.leave' })}
      title={intl.formatMessage({ id: 'connect.data_input.modal.title' })}
      handleClose={handleModalClose}
      SidePanelIcon={LayoutIcon}
    >
      <SidePanelLoadingComponent loading={false}>
        <SidePanelCardComponent>
          <TrainingModalHeaderBlockComponent
            isLegacyUpload={isLegacyUpload}
            createdAt={selectedVersionDetails.createdAt}
          />

          <Box
            sx={{
              minHeight: '100px',
              marginBottom: 3,
            }}
          >
            <Box sx={{ zIndex: 1, position: 'relative' }}>
              <DropzoneComponent
                onDrop={onFileDrop}
                inactiveKey='connect.data_input.modal.dropzone'
                isUploading={isUploading}
                disabled={isSubmitting}
                multiple={true}
              />
            </Box>

            <TrainingModalWarningBlockComponent
              isLegacyUpload={isLegacyUpload}
              isFetching={isFetching}
              latestVersionDetails={latestVersionDetails}
            />
          </Box>

          <TrainingFilesListComponent
            selectedFile={selectedFile}
          />

          {
            isLegacyUpload ? (
              null
            ) : (
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                gap={2}
                mt={2}
              >
                <TrainingModalRemovalBlockComponent
                  isAdmin={isAdmin}
                  handleRemoveVersion={handleRemoveVersion}
                  isSubmitting={isSubmitting}
                  isUploading={isUploading}
                />

                <ButtonComponent
                  color='tertiary'
                  name='manageVersionsButtton'
                  StartIconComponent={CopyIcon}
                  label={intl.formatMessage({ id: 'connect.data_input.modal.versions.manage' })}
                  onClick={handleManageVersionsClick}
                  disabled={isSubmitting || isUploading}
                />
              </Box>
            )
          }
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='useCaseTrainingDataModalCloseButton'
            onClick={handleModalClose}
            type='back'
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </SidePanelCardActionsComponent>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default UseCaseTrainingDataModalContainer
