import React, { SyntheticEvent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { bindActionCreators } from 'redux'
import { formatDate } from '@utils/moment.utils'
import {
  Box, useTheme,
  Typography, styled,
} from '@mui/material'

import IconButtonComponent from '@base/buttons/IconButton'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { getParametersTemplatesListBySourceType, isFetching as isFetchingTemplates } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { requestParametersTemplatesAction } from '@redux/modules/parameters-templates/parameters-templates.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import {
  fetchConnectionsWithDetailsAction,
  modifyTransformationAction,
} from '@redux/modules/hermes/hermes.actions'

import {
  getCompanyConnectionsBySource,
  isFetching as isFetchingHermes,
  isSubmittingHermes,
} from '@redux/modules/hermes/hermes.selectors'

import ChevronIcon from '@icons/flow/chevron.icon'
import ConnectIcon from '@icons/flow/connect.icon'
import EditFlowIcon from '@icons/flow/editFlow.icon'

import { SOURCE_TYPES } from '@constants/flow.constants'
import { createId } from '@utils/common.utils'

import ToggleButtonComponent from '@base/pagebar/ToggleButton'
import ParameterTemplatesTableComponent from '@components/connect-view/parameters/ParameterTemplatesTable'

import { AuthDataSourceModalDetails } from '@containers/modals/auth-data-source-modal/AuthDataSourceModal.container'
import { CONNECT_DATA_SOURCE_MODAL_NAME, AUTH_DATA_SOURCE_MODAL_NAME } from '@constants/modals.constants'

const StyledListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: theme.spacing(6),
  padding: theme.spacing(0, 3),
  borderTop: `1px solid ${theme.palette.new.grey_a}`,
  '&:hover': {
    backgroundColor: theme.palette.new.grey_d,
  },
  '&:first-of-type': {
    borderTop: 'none',
    cursor: 'default',
    '& .itemName': {
      color: theme.palette.new.grey_c,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

export interface ConnectDataSourceModalDetails extends Common.ModalDetails, AuthDataSourceModalDetails {
  icon: React.ElementType
  title: string
  help: string
  sourceId: string
  returnTo: string
  key: SOURCE_TYPES
}

const ConnectDataSourceModalContainer = () => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()

  const fetchConnectionsWithDetails = bindActionCreators(fetchConnectionsWithDetailsAction, dispatch)
  const updateTransformation = bindActionCreators(modifyTransformationAction, dispatch)
  const requestParametersTemplates = bindActionCreators(requestParametersTemplatesAction, dispatch)

  const companyId = useSelector(getSelectedCompanyId)
  const modalDetails = useSelector(getModalDetails<ConnectDataSourceModalDetails>)
  const isSubmitting = useSelector(isSubmittingHermes)
  const { useCaseId } = useSelector(getUseCaseItem)
  const modalPageName = useSelector(getOpenedModal)
  const open = modalPageName === CONNECT_DATA_SOURCE_MODAL_NAME

  useEffect(() => {
    if (open) {
      fetchConnectionsWithDetails({ companyId, useCaseId, updateConnectView: true })
      requestParametersTemplates()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const {
    key,
    icon: SidePanelIcon,
    title: sourceName,
    sourceId,
    returnTo,
  } = modalDetails

  const parametersTemplatesList = useSelector((state) => getParametersTemplatesListBySourceType(state, key))
  const connectionsList = useSelector((state) => getCompanyConnectionsBySource(state, sourceId))
  const isConnectionListEmpty = connectionsList.length === 0
  const hasConnectedItems = (connectionsList.filter((item) => item.isConnectedToUseCase) || []).length > 0
  const hasParametersTemplates = (parametersTemplatesList && parametersTemplatesList.length > 0)
  const isFetchingTemplatesList = useSelector(isFetchingTemplates)
  const isFetchingHermesConnections = useSelector(isFetchingHermes)
  const isFetching = isFetchingTemplatesList || isFetchingHermesConnections

  const handleConnect = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: AUTH_DATA_SOURCE_MODAL_NAME,
        modalDetails: {
          ...modalDetails,
          returnTo: CONNECT_DATA_SOURCE_MODAL_NAME,
        } as AuthDataSourceModalDetails,
      }),
    )
  }

  const handleClose = () => {
    dispatch(
      setPrimaryModalPageName(returnTo),
    )
  }

  const handleEditClick = (e: SyntheticEvent, connectionId: string) => {
    e.stopPropagation()

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: AUTH_DATA_SOURCE_MODAL_NAME,
        modalDetails: {
          ...modalDetails,
          returnTo: CONNECT_DATA_SOURCE_MODAL_NAME,
          connectionId,
        } as AuthDataSourceModalDetails,
      }),
    )
  }

  const handleLinkClick = (e: SyntheticEvent, item: Hermes.ConnectionDetails) => {
    updateTransformation({
      sourceId,
      useCaseId,
      connectionId: item.connectionId,
      companyId,
      toRemove: false,
    })
  }

  const handleUnLinkClick = (e: SyntheticEvent, item: Hermes.ConnectionDetails) => {
    updateTransformation({
      sourceId,
      useCaseId,
      connectionId: item.connectionId,
      companyId,
      toRemove: true,
    })
  }

  const handleToggleClick = (e: SyntheticEvent, isConnectedToUseCase: boolean, item: Hermes.ConnectionDetails) => {
    e.preventDefault()
    e.stopPropagation()

    if (isSubmitting) {
      return
    }

    if (isConnectedToUseCase) {
      handleUnLinkClick(e, item)
    } else {
      handleLinkClick(e, item)
    }
  }

  return (
    <SidePanelComponent
      open={open}
      title={sourceName}
      handleClose={handleClose}
      SidePanelIcon={SidePanelIcon}
      hasUnsavedChanges={isSubmitting}
    >
      <SidePanelLoadingComponent loading={isFetching}>
        <SidePanelCardComponent disableHorizontalSpacing={true}>
          <Box
            onClick={handleConnect}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              height: theme.spacing(6),
              margin: theme.spacing(3, 3, 4, 3),
              border: `1px solid ${theme.palette.new.grey_a}`,
              borderRadius: theme.spacing(0.5),
              padding: theme.spacing(0, 2),
              cursor: 'pointer',
              '&:hover': {
                border: `1px solid ${theme.palette.new.grey_c}`,
                backgroundColor: theme.palette.new.grey,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <ConnectIcon />
              <Typography
                sx={{
                  fontSize: '18px',
                  color: theme.palette.new.black,
                  marginLeft: theme.spacing(2),
                }}
              >
                {intl.formatMessage({ id: 'connect.modal.connect.connect_with' }, { name: sourceName })}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                transform: 'rotate(-90deg)',
              }}
            >
              <ChevronIcon />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: theme.spacing(0),
              marginBottom: hasParametersTemplates ? theme.spacing(3) : theme.spacing(0),
              width: '100%',
              '& .listItem:last-child': {
                borderBottom: `1px solid ${theme.palette.new.grey_a}`,
              },
            }}
          >
            <StyledListItem
              className='listItem'
            >
              {
                isConnectionListEmpty ? (
                  null
                ) : (
                  <Typography
                    sx={{
                      fontSize: '18px',
                      color: theme.palette.new.grey_c,
                      minWidth: '90px',
                      maxWidth: '10%',
                    }}
                    noWrap={true}
                  >
                    &nbsp;
                  </Typography>
                )
              }
              <Typography
                sx={{
                  fontSize: '18px',
                  color: theme.palette.new.grey_c,
                  width: '100%',
                }}
                noWrap={true}
              >
                {intl.formatMessage({ id: 'connect.modal.connect.source_name' })}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '18px',
                  color: theme.palette.new.grey_c,
                  minWidth: theme.spacing(15),
                  justifyContent: 'flex-end',
                  marginRight: theme.spacing(4.5),
                }}
                noWrap={true}
                component='div'
              >
                {intl.formatMessage({ id: 'connect.modal.connect.connection_date' })}
              </Typography>
            </StyledListItem>

            {
              isConnectionListEmpty ? (
                <Typography
                  noWrap={true}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderTop: `1px solid ${theme.palette.new.grey_a}`,
                    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                    color: theme.palette.new.grey_c,
                    width: '100%',
                    height: theme.spacing(6),
                    padding: theme.spacing(0, 3),
                  }}
                >
                  {intl.formatMessage({ id: 'connect.modal.connect.empty' })}
                </Typography>
              ) : (
                null
              )
            }

            {
              connectionsList.map((item, index) => {
                const {
                  name, createdAt,
                  connectionId, isConnectedToUseCase,
                } = item
                const date = formatDate(intl, createdAt)

                return (
                  <StyledListItem
                    key={createId(name, index)}
                    className='listItem'
                    sx={{
                      cursor: isConnectedToUseCase ? 'pointer' : 'default',
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: '90px',
                      }}
                    >
                      <ToggleButtonComponent
                        name={createId(name, 'switch')}
                        label=''
                        onChange={(e) => handleToggleClick(e, isConnectedToUseCase, item)}
                        value={isConnectedToUseCase}
                        checked={isConnectedToUseCase}
                        transparent={true}
                      />
                    </Box>
                    <Typography
                      noWrap={true}
                      title={name}
                      sx={{
                        fontSize: '18px',
                        color: isConnectedToUseCase ? theme.palette.new.black : theme.palette.new.grey_c,
                        width: '100%',
                      }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      noWrap={true}
                      component='div'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '18px',
                        color: theme.palette.new.grey_c,
                        minWidth: theme.spacing(15),
                        justifyContent: 'flex-end',
                      }}
                    >
                      {date}

                      <IconButtonComponent
                        IconComponent={EditFlowIcon}
                        name='editFlowButton'
                        label='Edit'
                        color='tertiary'
                        onClick={(e) => handleEditClick(e, connectionId)}
                        sx={{
                          marginLeft: theme.spacing(1),
                        }}
                      />
                    </Typography>
                  </StyledListItem>
                )
              })
            }
          </Box>

          {
            hasConnectedItems ? (
              <ParameterTemplatesTableComponent
                list={parametersTemplatesList}
                title={intl.formatMessage({ id: 'connect.modal.connect.connect_with.parameters_list' }, { name: sourceName })}
              />
            ) : (
              null
            )
          }
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='connectDataSourceModalCloseButton'
            onClick={handleClose}
            type='back'
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </SidePanelCardActionsComponent>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ConnectDataSourceModalContainer
