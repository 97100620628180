import React, { useMemo } from 'react'

import {
  Box,
  Typography,
  useTheme,
} from '@mui/material'

import RefreshIcon from '@icons/flow/refresh.icon'
import WarningIcon from '@icons/flow/warning.icon'
import { DEFAULT_PADDING, spinAnimation } from '@constants/ui.constants'

export interface StatusBarComponentProps {
  /**
   * Label of the status bar
   */
  label: string | React.ReactNode
  /**
   * Type of the status bar
   * @default 'info'
   */
  type?: 'info' | 'alert' | 'processing'
}

const StatusBarComponent: React.FC<StatusBarComponentProps> = ({
  label,
  type = 'info',
}) => {
  const theme = useTheme()
  const animationStyles = useMemo(() => ({
    animation: `${spinAnimation} 2s linear infinite`,
  }), [])

  const icon = useMemo(() => {
    switch (type) {
      case 'alert':
        return (
          <WarningIcon
            detailsFill={theme.palette.new.rebellious_red}
          />
        )
      case 'processing':
        return (
          <Box
            component='span'
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={animationStyles}
          >
            <RefreshIcon
              detailsFill={theme.palette.new.black}
            />
          </Box>
        )
      case 'info':
      default:
        return null
    }
  }, [theme, animationStyles, type])

  const color = useMemo(() => {
    switch (type) {
      case 'alert':
        return theme.palette.new.rebellious_red
      case 'processing':
        return theme.palette.new.black
      case 'info':
      default:
        return theme.palette.new.black
    }
  }, [
    theme,
    type,
  ])

  return (
    <Typography
      data-testid={StatusBarComponent.name}
      variant='body1'
      color={color}
      noWrap={true}
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      gap={DEFAULT_PADDING.SMALL}
      py={DEFAULT_PADDING.MEDIUM}
      px={DEFAULT_PADDING.MEDIUM}
      width='fit-content'
    >
      {icon}

      {label}
    </Typography>
  )
}

export default StatusBarComponent
