import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'

import {
  Box, styled,
  Typography, useTheme,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import ButtonComponent from '@base/buttons/Button'
import DeleteIcon from '@icons/delete.icon'
import IconButtonComponent from '@base/buttons/IconButton'

import { CreateArtifactsMappingsPayload } from '@redux/modules/use-case/use-case.types'
import { formatDate } from '@utils/moment.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { DEFAULT_USE_CASE_ARTIFACTS_TYPE, USE_CASE_ARTIFACTS_TYPES_OPTIONS_LIST } from '@constants/use-cases.constants'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { USE_CASE_ARTIFACTS_MAPPING_MODAL_NAME } from '@constants/modals.constants'

import {
  requestArtifactsMappingsAction,
  createArtifactsMappingsAction,
  deleteArtifactsMappingsAction,
} from '@redux/modules/use-case/use-case.actions'

import {
  getFullArtifactMappings, isDeletingArtifactUseCase,
  isFetchingUseCaseArtifactMappings, isSubmittingUseCase,
} from '@redux/modules/use-case/use-case.selectors'

import validation from './UseCaseArtifactMappingsModal.validations'

export interface UseCaseArtifactMappingsModalDetails extends Common.ModalDetails {
  useCaseId: string
  useCaseName: string
}

export interface UseCaseArtifactMappingsModalContainerProps {}

export const TypographyName = styled(Typography)(({ theme }) => ({
  color: theme.palette.new.black,
  width: '400px',
}))

export const TypographyType = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.new.grey_c,
}))

export const TypographyDate = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.new.grey_c,
}))

export const ListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: theme.spacing(6),
  padding: theme.spacing(0, 4),
  borderTop: `1px solid ${theme.palette.new.grey_a}`,
  '&:hover': {
    backgroundColor: theme.palette.new.grey_d,
  },
  '&:first-of-type': {
    borderTop: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const UseCaseArtifactMappingsModalContainer: React.FC<UseCaseArtifactMappingsModalContainerProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const theme = useTheme()

  const artifactMappings = useSelector(getFullArtifactMappings)
  const isFetching = useSelector(isFetchingUseCaseArtifactMappings)
  const isSubmitting = useSelector(isSubmittingUseCase)
  const isDeleting = useSelector(isDeletingArtifactUseCase)
  const modalDetails = useSelector(getModalDetails<UseCaseArtifactMappingsModalDetails>)
  const modalPageName = useSelector(getOpenedModal)

  const open = modalPageName === USE_CASE_ARTIFACTS_MAPPING_MODAL_NAME
  const usecaseId = modalDetails.useCaseId
  const usecaseName = modalDetails.useCaseName

  const initialValues: CreateArtifactsMappingsPayload = {
    artifactType: DEFAULT_USE_CASE_ARTIFACTS_TYPE,
    useCaseId: usecaseId,
  } as CreateArtifactsMappingsPayload

  useEffect(() => {
    if (open && usecaseId) {
      dispatch(
        requestArtifactsMappingsAction({
          useCaseId: usecaseId,
        }),
      )
    }
  }, [dispatch, usecaseId, open])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(
        setPrimaryModalPageName(modalDetails.returnTo),
      )
    }
  }

  const handleSubmitAction = (values: CreateArtifactsMappingsPayload) => {
    const payload: CreateArtifactsMappingsPayload = {
      ...initialValues,
      ...values,
    }

    dispatch(
      createArtifactsMappingsAction(payload),
    )
  }

  const handleDeleteAction = (artifactId: string) => {
    dispatch(
      deleteArtifactsMappingsAction({
        useCaseId: usecaseId!,
        artifactId,
      }),
    )
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: validation(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'use_cases.artifacts.configuration' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={isFetching || !artifactMappings}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'use_cases.artifacts.general' })}
              >
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='useCaseId'
                    value={usecaseId}
                    label={intl.formatMessage({ id: 'use_cases.form.id.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='name'
                    value={usecaseName}
                    label={intl.formatMessage({ id: 'use_cases.form.name.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'use_cases.artifacts.create' })}
              >
                <FormLayoutItem xs={5}>
                  <TextFieldComponent
                    name='artifactId'
                    touched={touched.artifactId}
                    errors={errors.artifactId}
                    value={values.artifactId}
                    label={intl.formatMessage({ id: 'use_cases.artifacts.artifactId' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={5}>
                  <SelectFieldComponent
                    name='artifactType'
                    value={values.artifactType}
                    label={intl.formatMessage({ id: 'use_cases.artifacts.artifactType' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={false}
                    labelKey='label'
                    options={USE_CASE_ARTIFACTS_TYPES_OPTIONS_LIST}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={2}>
                  <Box
                    display='flex'
                    height='100%'
                    alignItems='center'
                    mt='9px'
                  >
                    <ButtonComponent
                      name='addArtifactButton'
                      color='primary'
                      onClick={(e) => handleSubmitAction(values)}
                      loading={isSubmitting || isDeleting}
                      disabled={!isValid || !dirty}
                      label={intl.formatMessage({ id: 'use_cases.artifacts.add' })}
                    />
                  </Box>
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: !artifactMappings.length ? 'none' : 'flex',
                    flexDirection: 'column',
                    margin: theme.spacing(0),
                    width: 'calc(100% + 80px)',
                    position: 'absolute',
                    left: '-40px',
                    top: '-20px',

                    '&:last-child': {
                      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                    },
                  }}
                >
                  <ListItem>
                    <TypographyName
                      noWrap={true}
                      fontWeight={500}
                    >
                      {intl.formatMessage({ id: 'use_cases.artifacts.artifactId' })}
                    </TypographyName>
                    <TypographyType
                      noWrap={true}
                      fontWeight={500}
                    >
                      {intl.formatMessage({ id: 'use_cases.artifacts.artifactType' })}
                    </TypographyType>
                    <TypographyDate
                      noWrap={true}
                      fontWeight={500}
                      mr={theme.spacing(5)}
                    >
                      {intl.formatMessage({ id: 'use_cases.artifacts.createdAt' })}
                    </TypographyDate>
                  </ListItem>

                  {
                    artifactMappings.map((artifact, index) => {
                      const artifactId = artifact.id
                      const artifactType = artifact.type
                      const date = formatDate(intl, artifact.createdAt)

                      return (
                        <ListItem
                          key={artifactId}
                        >
                          <TypographyName
                            noWrap={true}
                            title={artifactId}
                          >
                            {artifactId}
                          </TypographyName>
                          <TypographyType
                            noWrap={true}
                            title={artifactType}
                          >
                            {artifactType}
                          </TypographyType>
                          <TypographyDate
                            noWrap={true}
                          >
                            {date}

                            <IconButtonComponent
                              color='tertiary'
                              IconComponent={DeleteIcon}
                              name='useCaseArtifactMappingsDeleteButton'
                              onClick={isSubmitting || isDeleting ? undefined : () => handleDeleteAction(artifactId)}
                              label={intl.formatMessage({ id: 'use_cases.artifacts.delete' })}
                              sx={{
                                marginLeft: theme.spacing(1.5),
                              }}
                            />
                          </TypographyDate>
                        </ListItem>
                      )
                    })
                  }
                </Box>
              </Box>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='useCaseArtifactMappingsModalCloseButton'
              onClick={() => handleCloseAction()}
              type='back'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default UseCaseArtifactMappingsModalContainer
