import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { Position } from '@xyflow/react'
import { useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'

import {
  SOURCE_TYPES_TO_ICONS_MAP,
  TRIPPLE_CONNECTOR_STYLES,
  SOURCE_TYPES, SOURCE_TYPES_OPTIONS,
  MAX_SOURCES_TO_SHOW,
  SourceTypeOption,
  NODES_WIDTHS,
  NODE_TYPES,
  FLOW_ELEMENTS_TRANSITIONS,
} from '@constants/flow.constants'

import { getSourceGroupHeight } from '@utils/flow.utils'
import { Box, Typography, useTheme } from '@mui/material'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'
import { createId } from '@utils/common.utils'
import { USE_CASE_DATA_TYPES_MODAL_NAME, DATA_SOURCES_MODAL_NAME } from '@constants/modals.constants'
import { getFileIdentifiersCount } from '@utils/use-cases.utils'
import { DataSourcesWidgetModalDetails } from '@containers/modals/data-sources-modal/data-sources-widget/DataSourcesWidget.component'
import { DataSourceConnectedModalDetails } from '@containers/modals/data-source-connected-modal/DataSourceConnectedModal.container'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import ReactFlowBlockHeaderComponent from '@components/connect-view/flow/ReactFlowBlockHeader'
import AddFlowIcon from '@icons/flow/addFlow.icon'
import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'

export interface ReactFlowInputNodeComponentProps {
  /**
   * Data for the node
   */
  data?: {
    /**
     * Number of input types
     */
    numOfInputTypes?: number,
    /**
     * Unique sources to be displayed
     */
    uniqueSources?: string[]
    /**
     * Number of sources
     */
    numOfSources?: number,
  }
}

const ReactFlowInputNodeComponent: React.FC<ReactFlowInputNodeComponentProps> = ({ data }) => {
  const {
    numOfInputTypes = 0,
    uniqueSources = [],
    numOfSources = 0,
  } = data || {}

  const theme = useTheme()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const isAdmin = useSelector(getIsAdmin)
  const fileIdentifiersList = useSelector(getUseCaseFileIdentifiers)
  const isUploadDisabled = getFileIdentifiersCount(fileIdentifiersList, true) && !isAdmin
  const hiddenSourcesCount = numOfSources - MAX_SOURCES_TO_SHOW
  const flowHandles = []

  const onUploadButtonClick = () => {
    trackEvent({
      componentName: 'uploadDataSourceShortcutButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
    })

    dispatch(
      setPrimaryModalPageName(USE_CASE_DATA_TYPES_MODAL_NAME),
    )
  }

  const onAddButtonClick = () => {
    trackEvent({
      componentName: 'addDataSourceButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
    })

    dispatch(
      setPrimaryModalPageName(DATA_SOURCES_MODAL_NAME),
    )
  }

  const onStaticSourceClick = (key: string) => {
    trackEvent({
      componentName: 'mockDataSourceShortcutButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
      dataSourceType: key,
    })

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: DATA_SOURCES_MODAL_NAME,
        modalDetails: {
          panelToOpen: key,
          returnTo: '',
        } as DataSourcesWidgetModalDetails,
      }),
    )
  }

  const onSourceClick = (sourceItem: any, modalName: string) => {
    trackEvent({
      componentName: 'dataSourceShortcutButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
      dataSourceType: sourceItem?.key,
    })

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: modalName,
        modalDetails: {
          ...sourceItem,
          returnTo: '',
        } as DataSourceConnectedModalDetails,
      }),
    )
  }

  for (let i = 0; i < numOfInputTypes; i += 1) {
    const style = (numOfInputTypes === 1) ? TRIPPLE_CONNECTOR_STYLES[1] : TRIPPLE_CONNECTOR_STYLES[i]

    flowHandles.push((
      <ReactFlowHandleComponent
        type='source'
        position={Position.Right}
        id={String(i + 1)}
        key={String(i + 1)}
        style={style}
      />
    ))
  }

  const soucesItems = uniqueSources.map((key) => {
    const sourceDetails = SOURCE_TYPES_OPTIONS.find((item) => item.value === key) || ({} as SourceTypeOption)
    const {
      sourceId,
      value,
      labelKey,
      descriptionKey,
      documentationLink,
      videoLink,
      videoHeight,
      helpKey,
      modalName,
    } = sourceDetails

    const isCustomFiles = key === SOURCE_TYPES.CUSTOM
    const isParetos = key === SOURCE_TYPES.PARETOS
    const isHermesConnected = Boolean(sourceId)

    const sourceItems = {
      key: value,
      title: labelKey ? intl.formatMessage({ id: labelKey }) : '',
      description: descriptionKey ? intl.formatMessage({ id: descriptionKey }) : '',
      help: helpKey ? intl.formatMessage({ id: helpKey }) : '',
      status: null,
      subTitle: null,
      icon: SOURCE_TYPES_TO_ICONS_MAP[value],
      documentationLink,
      videoLink,
      videoHeight,
      sourceId,
      onClick: () => onStaticSourceClick(value),
    }

    const customOverrite = isCustomFiles ? {
      subTitle: (
        <Typography
          variant='caption'
          sx={{
            color: theme.palette.new.grey_c,
            marginLeft: theme.spacing(2),
            marginBottom: '-2px',
          }}
        >
          {intl.formatMessage({ id: 'connect.block.data_input.sources.custom_files' }, {
            count: getFileIdentifiersCount(fileIdentifiersList),
          })}
        </Typography>
      ),
      onClick: () => onUploadButtonClick(),
      disabled: isUploadDisabled,
    } : {}

    const hermesOverrite = (isHermesConnected || isParetos) ? {
      onClick: () => onSourceClick(sourceItems, modalName!),
    } : {}

    return {
      ...sourceItems,
      ...customOverrite,
      ...hermesOverrite,
    }
  })

  const isDataSouceConnected = soucesItems.length > 0
  const isMoreItemAvailable = hiddenSourcesCount > 0

  return (
    <Box
      data-testid={ReactFlowInputNodeComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: NODES_WIDTHS[NODE_TYPES.INPUT],
        height: getSourceGroupHeight(numOfSources),
        background: theme.palette.new.white,
        border: '1px solid',
        borderColor: theme.palette.new.grey_a,
        borderRadius: theme.spacing(0.5),
        alignItems: 'flex-start',
        position: 'relative',
        cursor: 'default',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: NODES_WIDTHS[NODE_TYPES.INPUT],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          padding: theme.spacing(2),
        }}
      >
        <ReactFlowBlockHeaderComponent
          name={intl.formatMessage({ id: 'connect.block.data_input' })}
          help={intl.formatMessage({ id: 'connect.block.data_input.help' })}
          onAddButtonClick={onAddButtonClick}
          isAddButtonAvailable={isDataSouceConnected}
          parentComponentName={ReactFlowInputNodeComponent.name}
          ignoreFrozen={true}
        />

        {
          !isDataSouceConnected ? (
            <Box
              onClick={onAddButtonClick}
              data-testid='newSource'
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                height: theme.spacing(6),
                padding: theme.spacing(1),
                borderRadius: theme.spacing(0.5),
                marginTop: theme.spacing(2),
                cursor: 'pointer',
                background: theme.palette.new.smokey_silver,
                border: '1px solid rgba(0, 0, 0, 0.2)',
                ...FLOW_ELEMENTS_TRANSITIONS,
                '&:hover': {
                  background: theme.palette.new.white,
                  border: '1px solid rgba(0, 0, 0, 0.4)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                    background: theme.palette.new.white,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: theme.spacing(5),
                  }}
                >
                  <AddFlowIcon width={22} height={22} />
                </Box>
                <Typography
                  variant='body1'
                  sx={{
                    color: theme.palette.new.black,
                    marginLeft: theme.spacing(0.7),
                  }}
                >
                  {intl.formatMessage({ id: 'connect.block.data_input.new' })}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                marginTop: theme.spacing(2),
                '& .sourceItem': {
                  border: `1px solid ${theme.palette.new.grey_a}`,
                  borderBottom: 'none',
                  borderTop: '1px solid transparent',
                  ...FLOW_ELEMENTS_TRANSITIONS,

                  '&:after': {
                    position: 'absolute',
                    bottom: '-1px',
                    left: 0,
                    right: 0,
                    width: '100%',
                    height: '1px',
                    content: '""',
                    opacity: 1,
                    backgroundColor: theme.palette.new.grey_a,
                  },
                  '&:first-of-type': {
                    borderTop: `1px solid ${theme.palette.new.grey_a}`,
                    borderTopLeftRadius: theme.spacing(0.5),
                    borderTopRightRadius: theme.spacing(0.5),
                  },
                  '&:last-child': {
                    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                    borderBottomLeftRadius: theme.spacing(0.5),
                    borderBottomRightRadius: theme.spacing(0.5),

                    '&:after': {
                      display: 'none',
                    },
                  },
                },
                '& .isMoreItemAvailable': {
                  '&:last-child': {
                    borderBottomLeftRadius: `${theme.spacing(0)} !important`,
                    borderBottomRightRadius: `${theme.spacing(0)} !important`,
                  },
                },
              }}
            >
              {
                soucesItems.map((item, index) => {
                  const {
                    onClick,
                    icon: IconComponent,
                    title,
                    status,
                    subTitle,
                  } = item

                  return (
                    <Box
                      className={classNames('sourceItem', {
                        isMoreItemAvailable,
                      })}
                      key={createId(title, index)}
                      onClick={onClick}
                      role='button'
                      tabIndex={0}
                      data-testid={`source-${index}`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'relative',
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%',
                        height: theme.spacing(6),
                        padding: theme.spacing(2),
                        outline: 'none',
                        cursor: 'pointer',

                        '&:focus-visible': {
                          backgroundColor: theme.palette.new.grey,
                          border: `1px solid ${theme.palette.new.grey_c}`,
                          borderBottom: 'none',
                          '&:after': {
                            backgroundColor: theme.palette.new.grey_c,
                          },
                          '&:first-of-type': {
                            borderTop: `1px solid ${theme.palette.new.grey_c}`,
                          },
                          '&:last-child': {
                            borderBottom: `1px solid ${theme.palette.new.grey_c}`,
                          },
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.new.grey,
                          border: `1px solid ${theme.palette.new.grey_c}`,
                          borderBottom: 'none',
                          '&:after': {
                            backgroundColor: theme.palette.new.grey_c,
                          },
                          '&:first-of-type': {
                            borderTop: `1px solid ${theme.palette.new.grey_c}`,
                          },
                          '&:last-child': {
                            borderBottom: `1px solid ${theme.palette.new.grey_c}`,
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <Box display='flex'>
                          <IconComponent />
                        </Box>
                        <Typography
                          variant='body1'
                          sx={{
                            color: theme.palette.new.black,
                            marginLeft: theme.spacing(0.7),
                          }}
                        >
                          {title}
                        </Typography>
                        {
                          subTitle ? (
                            <>
                              {subTitle}
                            </>
                          ) : (
                            null
                          )
                        }
                      </Box>
                      {
                        status ? (
                          <Box>
                            {status}
                          </Box>
                        ) : (
                          null
                        )
                      }
                    </Box>
                  )
                })
              }
            </Box>
          )
        }

        {
          (isMoreItemAvailable && isDataSouceConnected) ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '1px',
                    backgroundColor: theme.palette.new.grey_a,
                    height: theme.spacing(2),
                  }}
                />
              </Box>
              <Box
                onClick={onAddButtonClick}
                data-testid='moreSources'
                role='button'
                tabIndex={0}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: '100%',
                  height: theme.spacing(6),
                  padding: theme.spacing(2),
                  border: `1px solid ${theme.palette.new.grey_a}`,
                  outline: 'none',
                  borderRadius: theme.spacing(0.5),
                  cursor: 'pointer',
                  ...FLOW_ELEMENTS_TRANSITIONS,

                  '&:hover': {
                    backgroundColor: theme.palette.new.grey,
                    border: `1px solid ${theme.palette.new.grey_c}`,
                  },
                  '&:focus-visible': {
                    backgroundColor: theme.palette.new.grey,
                    border: `1px solid ${theme.palette.new.grey_c}`,
                  },
                }}
              >
                <Typography
                  variant='body1'
                  sx={{
                    letterSpacing: '3px',
                  }}
                >
                  {`+${hiddenSourcesCount}`}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    marginLeft: theme.spacing(1.5),
                    color: theme.palette.new.black,
                  }}
                >
                  {intl.formatMessage({ id: 'connect.block.data_input.more' })}
                </Typography>
              </Box>
            </>
          ) : (
            null
          )
        }
      </Box>

      {flowHandles}
    </Box>
  )
}

export default ReactFlowInputNodeComponent
