import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from '@redux/hooks'
import { bindActionCreators } from 'redux'
import { Box } from '@mui/material'

import {
  SidePanelCardActionsComponent,
  SidePanelCardComponent,
  SidePanelComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import {
  getConfigFromSource,
  checkifOAuthRequired,
  isOAuthDone,
  encodeStreamName,
} from '@utils/connectors.utils'

import { getAvailableSources } from '@redux/modules/hermes/hermes.selectors'
import { requestAddConnectionAction } from '@redux/modules/hermes/hermes.actions'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'

import * as API from '@redux/modules/hermes/hermes.api'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import SelectFieldComponent from '@base/forms/SelectField'
import ConnectorConfigurationContainer from '@components/connectors/ConnectorConfiguration'
import StreamsConfigurationComponent from '@components/connectors/StreamsConfiguration'
import validateNewConnectorModal from '@containers/modals/connector-new-modal/NewConnectorModal.validations'
import TextFieldComponent from '@base/forms/TextField'

const FormStep = {
  CONFIG: 0,
  STREAMS: 1,
}

const initialFormValues = {
  sourceId: '',
  name: '',
  configuration: {},
  streams: {},
} as Hermes.ConnectionFormItem

export interface NewConnectorModalContainerProps {
  isOpen?: boolean,
  handleClose?: {
    (): any,
  },
}

const NewConnectorModalContainer: React.FC<NewConnectorModalContainerProps> = ({
  isOpen,
  handleClose,
}) => {
  const dispatch = useDispatch()
  const addConnection = bindActionCreators(requestAddConnectionAction, dispatch)
  const showToast = bindActionCreators(changeToastAction, dispatch)

  const companyId = useSelector(getSelectedCompanyId)
  const sources = useSelector(getAvailableSources)

  const [formValues, setFormValues] = useState(initialFormValues)
  const [activeFormStep, setActiveFormStep] = useState(FormStep.CONFIG)
  const [isLoading, setIsLoading] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    if (isOpen) {
      setActiveFormStep(FormStep.CONFIG)
      setFormValues(initialFormValues)
    }
  }, [isOpen])

  const handleFetchStreams = async (
    values: Hermes.ConnectionFormItem,
    { setSubmitting } :
    { setSubmitting(state: boolean): void },
  ) => {
    const { sourceId } = values

    setIsLoading(true)

    try {
      const apiStreams: Hermes.Stream[] = await API.getSourceSchema({
        sourceId,
        configuration: values.configuration,
      })

      const updatedFormValues = {
        ...values,
        configuration: values.configuration,
        streams: Object.assign({}, ...apiStreams.map((s) => ({ [encodeStreamName(s.name)]: s }))),
      }

      setSubmitting(false)
      setFormValues(updatedFormValues)
      setActiveFormStep(FormStep.STREAMS)
    } catch (e: any) {
      showToast({ message: e.message, severity: TOAST_TYPE_ERROR })
    }

    setIsLoading(false)
  }

  const handleSubmitConnection = async (
    values: Hermes.ConnectionFormItem,
    { setSubmitting } :
    { setSubmitting(state: boolean): void },
  ) => {
    const { sourceId, configuration } = values

    const streamConfig = Object.entries(values.streams).map(([name, data]) => {
      return data
    })

    const payload = {
      sourceId,
      companyId,
      name: values.name,
      configuration,
      streams: streamConfig,
    }

    setIsLoading(true)
    try {
      const connectionId = await API.addConnection(payload)
      addConnection({
        connectionId,
        name: values.name,
        createdAt: new Date().toISOString(),
      })

      if (handleClose) {
        handleClose()
      }
    } catch (e: any) {
      showToast({
        message: e.message,
        severity: TOAST_TYPE_ERROR,
      })
    }
    setIsLoading(false)
    setSubmitting(false)
  }

  const handleNextOrSubmitClick = async (values: Hermes.ConnectionFormItem, actions: { setSubmitting(state: boolean): void }) => {
    return (activeFormStep === 0) ?
      handleFetchStreams(values, actions) :
      handleSubmitConnection(values, actions)
  }

  const handleCancel = () => {
    setActiveFormStep(FormStep.CONFIG)
    setFormValues(initialFormValues)

    if (handleClose) {
      handleClose()
    }
  }

  const handleSourceChanged = (event: any, handleFormikChange: any) => {
    const sourceId = event.target.value
    const configuration = getConfigFromSource(sourceId, sources)

    setFormValues({
      ...formValues,
      configuration,
      sourceId,
      streams: [],
    } as any)

    handleFormikChange(event)
  }

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    values,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: formValues,
    onSubmit: handleNextOrSubmitClick,
    enableReinitialize: true,
    validate: (valuesToValidate: Hermes.ConnectionFormItem) => validateNewConnectorModal(valuesToValidate, {}, intl, sources),
  })

  const selectedSource = sources.find((e) => e.sourceId === values.sourceId) || {} as Hermes.SourceDetails
  const isOAuthRequired = checkifOAuthRequired(selectedSource)
  const isAuthDone = isOAuthDone(values)
  const sourceOptions = sources.map((source) => {
    return {
      label: source.name,
      value: source.sourceId,
    }
  })

  const hasUnsavedChanges = dirty || isSubmitting || Boolean(values.sourceId)

  return (
    <SidePanelComponent
      open={isOpen}
      title={intl.formatMessage({ id: 'connectors.modal.title_new' })}
      handleClose={handleClose}
      hasUnsavedChanges={hasUnsavedChanges}
    >
      <Box component='form' onSubmit={handleSubmit}>
        <SidePanelCardComponent>
          <FormLayoutContainer>
            <FormLayoutItemsContainer
              title={intl.formatMessage({ id: 'connectors.modal.general' })}
            >
              <FormLayoutItem xs={12}>
                <SelectFieldComponent
                  name='sourceId'
                  label={intl.formatMessage({ id: 'connectors.modal.source' })}
                  options={sourceOptions}
                  value={values.sourceId ?? ''}
                  onChange={(e) => handleSourceChanged(e, handleChange)}
                />
              </FormLayoutItem>
              <FormLayoutItem
                xs={12}
                hidden={!values.sourceId}
              >
                <TextFieldComponent
                  name='name'
                  label={intl.formatMessage({ id: 'connectors.modal.name' })}
                  floatingHelp={intl.formatMessage({ id: 'connectors.modal.name.help' })}
                  touched={touched.name}
                  errors={errors.name}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormLayoutItem>
            </FormLayoutItemsContainer>

            {
              values.sourceId ? (
                <ConnectorConfigurationContainer
                  values={values}
                  touched={touched}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  source={sources.find((e) => e.sourceId === values.sourceId) as Hermes.SourceDetails}
                  setFormValues={setFormValues}
                  isOpen={isOpen}
                />
              ) : (
                null
              )
            }

            <FormLayoutItemsContainer
              title={intl.formatMessage({ id: 'connectors.modal.synchronized_streams' })}
              hidden={!(Object.keys(values.streams).length > 0)}
              divider={false}
            >
              <FormLayoutItem xs={12}>
                <StreamsConfigurationComponent
                  streams={values.streams}
                  handleChange={handleChange}
                />
              </FormLayoutItem>
            </FormLayoutItemsContainer>
          </FormLayoutContainer>
        </SidePanelCardComponent>
        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='newConnectorModalCancelButton'
            onClick={handleCancel}
            type='cancel'
            disabled={isSubmitting}
          />
          <ModalButtonComponent
            name='newConnectorModalSubmitButton'
            onClick={handleSubmit as any}
            loading={isLoading}
            disabled={isSubmitting || !(values.sourceId) || (!dirty && activeFormStep === FormStep.CONFIG && (!isAuthDone && isOAuthRequired)) || isLoading || !isValid}
            type='submit'
            label={activeFormStep === 0 ?
              intl.formatMessage({ id: 'connectors.modal.button.next' }) :
              intl.formatMessage({ id: 'common.modal.button.submit' })}
          />
        </SidePanelCardActionsComponent>
      </Box>
    </SidePanelComponent>
  )
}

export default NewConnectorModalContainer
