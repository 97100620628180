import get from 'lodash.get'
import * as Sentry from '@sentry/react'

import {
  ERROR_CODES_TO_IGNORE_IN_SENTRY,
  IS_LOCAL_ENV, BACK_END_REASONS_TO_IGNORE_IN_SENTRY,
} from '@constants/common.constants'

export const STATUS_FAIL = 'fail'
export const STATUS_CODES = {
  404: 404,
}

export class CustomError extends Error {
  public message: string

  public code: string | number

  public internalReason: string

  constructor(message: string, code: string | number, internalReason: string) {
    super(message)

    this.message = message
    this.code = code
    this.internalReason = internalReason
  }
}

/**
 * @function reportEventToSentry Reports error to sentry
 *
 * @param {Object} error Error object
 *
 * @return {void}
 */
export const reportEventToSentry = (error: CustomError, payload = {}) => {
  const errorCode = get(error, 'code', '')
  const internalReason = get(error, 'internalReason', '')

  if (ERROR_CODES_TO_IGNORE_IN_SENTRY.includes(errorCode as string)) {
    return
  }

  if (BACK_END_REASONS_TO_IGNORE_IN_SENTRY.includes(internalReason)) {
    return
  }

  if (!IS_LOCAL_ENV) {
    Sentry.withScope((scope) => {
      scope.setExtra('data', payload)

      Sentry.captureException(error)
    })
  }
}

/**
 * @function checkApiForError Check API response for error
 *
 * @param {Object} response API response object
 * @param {Boolean} withException if true, will throw an error
 *
 * @return {String} Error message
 */
export const checkApiForError = (response: any, withException = true) => {
  /**
   * Out API sometimes returns 200 status code with error message
   * and status: fail, so we need to set status code to 400
   */
  if (response && (response.status === STATUS_FAIL)) {
    const errorMessage = get(response, 'data.description')
    const error = new CustomError(
      errorMessage,
      400,
      get(response, 'data.reason'),
    )

    if (withException) {
      throw error
    }

    return error
  }

  return null
}

/**
 * @function parseAndReportErrorResponse Parses error from Promises
 *
 * @param {Object} error Error object
 *
 * @return {String} Error message
 */
export const parseAndReportErrorResponse = (error: Error | any, payload: any) => {
  reportEventToSentry(error, payload)

  // eslint-disable-next-line
  console.error(error, payload)

  return get(error, 'response.data.data.description') || get(error, 'message') || get(error, 'response.message') || get(error, 'data.message') || get(error, 'response.data.error')
}

/**
 * @function getErrorReason Get error reason
 *
 * @param {Object} error Error object
 *
 * @return {String} Error reason
 */
export const getErrorReason = (response: any) => {
  return get(response, 'data.data.reason')
}

/**
 * @function isNotFound Check whether it is 404
 *
 * @param {Object} error Error object
 *
 * @return {Boolean} true, if 404 true
 */
export const isNotFound = (error: Error) => {
  return (get(error, 'response.status') === STATUS_CODES['404'])
}
