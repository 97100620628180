export const START_FETCHING_MONITOR = 'START_FETCHING_MONITOR'
export const STOP_FETCHING_MONITOR = 'STOP_FETCHING_MONITOR'

export const REQUEST_BACKTESTING = 'REQUEST_BACKTESTING'
export const RESET_BACKTESTING = 'RESET_BACKTESTING'

export const REQUEST_BACKTESTING_TABLE = 'REQUEST_BACKTESTING_TABLE'
export const RECEIVE_BACKTESTING_TABLE = 'RECEIVE_BACKTESTING_TABLE'

export const REQUEST_BACKTESTING_CHART = 'REQUEST_BACKTESTING_CHART'
export const RECEIVE_BACKTESTING_CHART = 'RECEIVE_BACKTESTING_CHART'

export const REQUEST_BACKTESTING_AVAILABLE_FOLDS = 'REQUEST_BACKTESTING_AVAILABLE_FOLDS'
export const RECEIVE_BACKTESTING_AVAILABLE_FOLDS = 'RECEIVE_BACKTESTING_AVAILABLE_FOLDS'

export const REQUEST_BACKTESTING_GRID_STATE_CHANGE = 'REQUEST_BACKTESTING_GRID_STATE_CHANGE'
export const RECEIVE_BACKTESTING_GRID_STATE_CHANGE = 'RECEIVE_BACKTESTING_GRID_STATE_CHANGE'

export const REQUEST_LIVE_MONITORING = 'REQUEST_LIVE_MONITORING'
export const RESET_LIVE_MONITORING = 'RESET_LIVE_MONITORING'

export const REQUEST_LIVE_MONITORING_TABLE = 'REQUEST_LIVE_MONITORING_TABLE'
export const RECEIVE_LIVE_MONITORING_TABLE = 'RECEIVE_LIVE_MONITORING_TABLE'

export const REQUEST_LIVE_MONITORING_CHART = 'REQUEST_LIVE_MONITORING_CHART'
export const RECEIVE_LIVE_MONITORING_CHART = 'RECEIVE_LIVE_MONITORING_CHART'

export const REQUEST_LIVE_MONITORING_FORECAST_HORIZONS = 'REQUEST_LIVE_MONITORING_FORECAST_HORIZONS'
export const RECEIVE_LIVE_MONITORING_FORECAST_HORIZONS = 'RECEIVE_LIVE_MONITORING_FORECAST_HORIZONS'

export const REQUEST_LIVE_MONITORING_GRID_STATE_CHANGE = 'REQUEST_LIVE_MONITORING_GRID_STATE_CHANGE'
export const RECEIVE_LIVE_MONITORING_GRID_STATE_CHANGE = 'RECEIVE_LIVE_MONITORING_GRID_STATE_CHANGE'

export const REQUEST_BASELINE_COMPARISON = 'REQUEST_BASELINE_COMPARISON'
export const RESET_BASELINE_COMPARISON = 'RESET_BASELINE_COMPARISON'

export const REQUEST_BASELINE_COMPARISON_TABLE = 'REQUEST_BASELINE_COMPARISON_TABLE'
export const RECEIVE_BASELINE_COMPARISON_TABLE = 'RECEIVE_BASELINE_COMPARISON_TABLE'

export const REQUEST_BASELINE_COMPARISON_CHART = 'REQUEST_BASELINE_COMPARISON_CHART'
export const RECEIVE_BASELINE_COMPARISON_CHART = 'RECEIVE_BASELINE_COMPARISON_CHART'

export const REQUEST_BASELINE_COMPARISON_GRID_STATE_CHANGE = 'REQUEST_BASELINE_COMPARISON_GRID_STATE_CHANGE'
export const RECEIVE_BASELINE_COMPARISON_GRID_STATE_CHANGE = 'RECEIVE_BASELINE_COMPARISON_GRID_STATE_CHANGE'

export const REQUEST_BASELINE_COMPARISON_FORECAST_HORIZONS = 'REQUEST_BASELINE_COMPARISON_FORECAST_HORIZONS'
export const RECEIVE_BASELINE_COMPARISON_FORECAST_HORIZONS = 'RECEIVE_BASELINE_COMPARISON_FORECAST_HORIZONS'
