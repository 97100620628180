import React, { useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'

import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { getTableData, isFetchingData } from '@redux/modules/optimize/optimize.selectors'
import { requestOptimizePageData } from '@redux/modules/optimize/optimize.actions'
import { getStartOfTheWeek } from '@utils/moment.utils'

import PageLayoutContainer from '@containers/application/PageLayout'
import OptimizeRestockSelectorComponent from '@components/(features)/optimize/optimize-restock-selector'
import ViewInfoComponent from '@base/pagebar/ViewInfo'
import ViewInfoDateComponent from '@base/pagebar/ViewInfoDate'
import OptimizeOverviewComponent from '@components/(features)/optimize/optimize-overivew'

const OptimizeContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const useCase = useSelector(getUseCaseItem)
  const tableData = useSelector(getTableData)
  const isFetching = useSelector(isFetchingData)
  const optimizeUpdateDate = getStartOfTheWeek()

  useLayoutEffect(() => {
    dispatch(
      requestOptimizePageData({ useCaseId: usecase, fetchUseCase: true }),
    )
  }, [dispatch, usecase])

  const pageBarRightSideBlocks = (
    <>
      <ViewInfoComponent
        value={intl.formatMessage({ id: 'optimize.table.updates_info' })}
        label={intl.formatMessage({ id: 'optimize.table.updates' })}
      />

      <ViewInfoDateComponent
        date={optimizeUpdateDate}
        label={intl.formatMessage({ id: 'optimize.lastModified.title' })}
      />
    </>
  )

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'optimize.browser_tab.title' }, { name: useCase.name })}
      isFetching={isFetching}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
    >
      <OptimizeRestockSelectorComponent />

      <OptimizeOverviewComponent list={tableData} />
    </PageLayoutContainer>
  )
}

export default OptimizeContainer
