import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Typography, useTheme } from '@mui/material'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'

export interface ChartTooltipValueComponentProps {
  /**
   * The value of the data point
   */
  value?: string | number | null | React.ReactNode
  /**
   * The color of the value
   */
  color?: string | 'positive' | 'negative' | 'neutral'
  /**
   * The variant of the value
   *
   * @default 'regular'
   */
  variant?: Common.TooltipRow<any>['valueVariant']
}

export const ChartTooltipValueComponent: React.FC<ChartTooltipValueComponentProps> = ({
  value,
  color,
  variant = 'regular',
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const gradientColor = color?.includes('linear-gradient')

  const valueToRender = useMemo(() => {
    if (value === null) {
      return intl.formatMessage({ id: 'common.na' })
    }

    return value
  }, [
    value,
    intl,
  ])

  const variantStyles = useMemo(() => {
    switch (variant) {
      case 'contained':
        return {
          px: '5px',
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
        }
      default:
        return {}
    }
  }, [
    variant,
  ])

  const typographyColors = useMemo(() => {
    switch (color) {
      case 'positive':
        return {
          color: theme.palette.new.generous_green,
          backgroundColor: theme.palette.new.generous_green_10,
        }
      case 'negative':
        return {
          color: theme.palette.new.rebellious_red,
          backgroundColor: theme.palette.new.rebellious_red_10,
        }
      case 'neutral':
        return {
          backgroundColor: theme.palette.new.business_black_5,
          color: theme.palette.new.business_black_40,
        }
      default: {
        return color ? {
          ...(gradientColor ? {
            ...getTextBackgroundFillStyles(color as string),
            backgroundImage: color,
          } : {
            color,
          }),
        } : {
          color: theme.palette.new.business_black_60,
        }
      }
    }
  }, [
    gradientColor,
    color,
    theme,
  ])

  const typographyStyles = {
    ...variantStyles,
    ...typographyColors,
  }

  return (
    <Typography
      display='inline-flex'
      alignItems='center'
      variant='body1'
      sx={typographyStyles}
      data-testid={ChartTooltipValueComponent.name}
    >
      {valueToRender}
    </Typography>
  )
}

export default ChartTooltipValueComponent
