import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { isRecommendationUseCase } from '@redux/modules/use-case/use-case.selectors'
import { isCompositePipelineRunning, isSubmittingPipeline } from '@redux/modules/pipelines/pipelines.selectors'
import { Box } from '@mui/material'

import ModalButtonComponent from '@base/buttons/ModalButton'

import { startCompositePipelineAction } from '@redux/modules/pipelines/pipelines.actions'

export interface StartOptimizationButtonComponentProps {
}

const StartOptimizationButtonComponent: React.FC<StartOptimizationButtonComponentProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const isRecommendation = useSelector(isRecommendationUseCase)
  const isRunning = useSelector(isCompositePipelineRunning)
  const isSubmitting = useSelector(isSubmittingPipeline)

  const handleStartOptimization = useCallback(() => {
    dispatch(
      startCompositePipelineAction({
        useCaseId: match.params.usecase,
      }),
    )
  }, [dispatch, match])

  if (!isRecommendation) {
    return null
  }

  return (
    <Box
      data-testid={StartOptimizationButtonComponent.name}
    >
      <ModalButtonComponent
        name='startOptimizationButton'
        onClick={handleStartOptimization}
        type='submit'
        loading={false}
        disabled={isRunning || isSubmitting}
        label={intl.formatMessage({ id: isRunning ? 'connect.setup.button.optimization_in_progress' : 'connect.setup.button.start_button' })}
      />
    </Box>
  )
}

export default StartOptimizationButtonComponent
