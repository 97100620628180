import React from 'react'

import {
  Box, styled,
  Typography,
} from '@mui/material'

import { defaultNumberFormatter } from '@utils/analysis.utils'
import { useIntl } from 'react-intl'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

export const ActualValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.new.black,
}))

export const BaselineValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.new.versatile_violet,
}))

export const ParetosValue = styled(Typography)(({ theme }) => ({
  ...getTextBackgroundFillStyles(),
}))

export interface BaselineKpiValuesComponentProps {
  /**
   * Actual value
   */
  actualValue: number | null
  /**
   * Baseline value
   */
  baselineValue: number | null
  /**
   * Paretos value
   */
  paretosValue: number | null
}

const BaselineKpiValuesComponent: React.FC<BaselineKpiValuesComponentProps> = ({
  actualValue,
  baselineValue,
  paretosValue,
}) => {
  const intl = useIntl()

  const defaultFormatter = (val: number | null) => {
    if (val === null) {
      return intl.formatMessage({ id: 'common.na' })
    }

    return defaultNumberFormatter(val, {
      intl,
    })
  }

  const formatedActualValue = defaultFormatter(actualValue)
  const formatedBaselineValue = defaultFormatter(baselineValue)
  const formatedParetosValue = defaultFormatter(paretosValue)

  return (
    <Box
      data-testid={BaselineKpiValuesComponent.name}
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      gap='10px'
      width='100%'
    >
      <Box
        component='table'
        sx={{
          borderSpacing: '0px',
          marginBottom: '-5px',
        }}
      >
        <Box component='tbody'>
          <Box component='tr'>
            <Box
              component='td'
              textAlign='left'
              paddingRight='10px'
            >
              <ActualValue
                fontWeight={500}
                variant='subtitle2'
              >
                {intl.formatMessage({ id: 'insights.kpis.actual' })}
              </ActualValue>
            </Box>
            <Box component='td'>
              <ActualValue
                fontWeight={400}
                variant='subtitle2'
              >
                {formatedActualValue}
              </ActualValue>
            </Box>
            <Box component='td' />
          </Box>
          <Box component='tr'>
            <Box
              component='td'
              textAlign='left'
              paddingRight='10px'
            >
              <BaselineValue
                fontWeight={500}
                variant='subtitle2'
              >
                {intl.formatMessage({ id: 'insights.kpis.baseline' })}
              </BaselineValue>
            </Box>
            <Box component='td'>
              <BaselineValue
                fontWeight={400}
                variant='subtitle2'
              >
                {formatedBaselineValue}
              </BaselineValue>
            </Box>
            <Box component='td' />
          </Box>

          <Box component='tr'>
            <Box
              component='td'
              textAlign='left'
              paddingRight='10px'
            >
              <ParetosValue
                fontWeight={500}
                variant='subtitle2'
              >
                {intl.formatMessage({ id: 'insights.kpis.paretos' })}
              </ParetosValue>
            </Box>
            <Box component='td'>
              <ParetosValue
                fontWeight={400}
                variant='subtitle2'
              >
                {formatedParetosValue}
              </ParetosValue>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BaselineKpiValuesComponent
