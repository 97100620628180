import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { createCompanyAction, updateCompanyAction } from '@redux/modules/customer/customer.actions'
import { CreateCompanyActionPayload, UpdateCompanyActionPayload } from '@redux/modules/customer/customer.types'

import {
  isFetching as isFetchingCustomer,
  isSubmitting as isSubmittingCustomer,
} from '@redux/modules/customer/customer.selectors'

import { getModalDetails } from '@redux/modules/modal-manager/modal-manager.selectors'
import TextFieldComponent from '@base/forms/TextField'

import companyValidations from './CompanyModal.validations'

export interface CompanyModalPayload {
  record: Customer.CompanyItem | null,
  returnTo: string,
}

export interface CompanyModalContainerProps {
  open?: boolean,
  handleClose?: {
    (): any,
  },
}

const CompanyModalContainer: React.FC<CompanyModalContainerProps> = ({
  open,
  handleClose,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const modalDetails = useSelector(getModalDetails<CompanyModalPayload>)
  const isSubmitting = useSelector(isSubmittingCustomer)
  const isFetching = useSelector(isFetchingCustomer)

  const edit = useMemo(() => {
    return modalDetails.record !== null
  }, [modalDetails])

  const initialValues = useMemo(() => {
    if (edit && modalDetails.record) {
      return modalDetails.record
    }

    return {
      name: '',
    } as Customer.CompanyItem
  }, [modalDetails, edit])

  const handleModalClose = () => {
    if (handleClose) {
      handleClose()
    }

    dispatch(
      setPrimaryModalPageName(null),
    )
  }

  const handleModalSubmit = (values: Customer.CompanyItem) => {
    const payload = edit ? {
      companyId: modalDetails?.record?.companyId || '',
      name: values.name,
    } : {
      name: values.name,
    }

    if (edit) {
      dispatch(
        updateCompanyAction(payload as UpdateCompanyActionPayload),
      )
    } else {
      dispatch(
        createCompanyAction(payload as CreateCompanyActionPayload),
      )
    }

    handleModalClose()
  }

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
    validationSchema: companyValidations(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const modalTitle = edit ? (
    intl.formatMessage({ id: 'companies.modal.edit' }, { name: modalDetails?.record?.name })
  ) : (
    intl.formatMessage({ id: 'companies.modal.create' })
  )

  return (
    <SidePanelComponent
      open={open}
      loading={isFetching}
      title={modalTitle}
      handleClose={handleModalClose}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={isFetching}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='name'
                    touched={touched.name}
                    errors={errors.name}
                    value={values.name}
                    label={intl.formatMessage({ id: 'companies.form.name.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='companyModalCloseButton'
              onClick={handleModalClose}
              type='cancel'
              disabled={isSubmitting}
            />

            <ModalButtonComponent
              name='companyModalSubmitButton'
              onClick={(e) => handleModalSubmit(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !dirty || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default CompanyModalContainer
