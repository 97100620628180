import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { PipelineReportDeliveryActionPayload } from '@redux/modules/pipelines/pipelines.types'
import { reportPipelineDeliveryAction } from '@redux/modules/pipelines/pipelines.actions'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { isSubmittingPipeline } from '@redux/modules/pipelines/pipelines.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { formatDateString, getFormattedCurrentDate } from '@utils/moment.utils'
import { PIPELINE_REPORT_DELIVERY_MODAL_NAME } from '@constants/modals.constants'

import TextFieldComponent from '@base/forms/TextField'

export interface PipelineReportDeliveryModalDetails extends Common.ModalDetails {
  pipeline: Pipelines.ReduxPipelineItem
}

const PipelineReportDeliveryModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const isSubmitting = useSelector(isSubmittingPipeline)

  const { pipeline } = useSelector(getModalDetails<PipelineReportDeliveryModalDetails>)
  const modalPageName = useSelector(getOpenedModal)

  const open = modalPageName === PIPELINE_REPORT_DELIVERY_MODAL_NAME
  const pipelineId = pipeline?.pipelineId || ''
  const pipelineName = pipeline?.name || ''

  const initialValues = {
    pipelineId,
    deliveryFor: getFormattedCurrentDate(),
  }

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: PipelineReportDeliveryActionPayload) => {
    dispatch(
      reportPipelineDeliveryAction({
        pipelineId,
        deliveryFor: formatDateString(values.deliveryFor),
      }),
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <SidePanelComponent
      open={open && isAdmin}
      title={intl.formatMessage({ id: 'pipelines.reportDelivery.dialog.title' }, { name: pipelineName })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='pipelineName'
                    value={pipelineName}
                    label={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.pipelineName' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='deliveryFor'
                    label={intl.formatMessage({ id: 'pipelines.reportDelivery.dialog.deliveryFor' })}
                    touched={touched.deliveryFor}
                    errors={errors.deliveryFor}
                    value={values.deliveryFor}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='date'
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='pipelineReportDeliveryModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='pipelineReportDeliveryModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default PipelineReportDeliveryModalContainer
