import React, { useMemo } from 'react'
import get from 'lodash.get'
import { useIntl } from 'react-intl'
import { TooltipProps } from 'recharts'
import { useTheme } from '@mui/material'

import { formatDateForTooltip } from '@utils/moment.utils'
import { getEventDetailsAtDatapoint } from '@utils/events.utils'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { INSIGHTS_GROUPING_LAST_YEAR_COLORWAY_OPACITY } from '@constants/insights.constants'

import {
  defaultInsightsTooltipValueFormatter,
  generateAbsDeviationPayloadKey,
  generateBaselinePayloadKey,
  generateLastYearPayloadKey,
  generatePredictionPayloadKey,
  getInsightsLineColor,
} from '@utils/insights.utils'

import ChartTabularTooltipComponent from '@components/charts/tooltips/ChartTabularTooltip'

export interface InsightsChartTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
  active?: boolean
  isFetching?: boolean
  eventsVisibility?: boolean
  lastYearVisibility?: boolean
  lastYearTargetPayloadKeyPrefix?: string
  baselinePayloadKeyPrefix?: string
  predictionKeyPrefix?: string
  absDeviationKeyPrefix?: string
  selectedRows?: GridRowSelectionModel
  payload?: Insights.BaseChartDatasetItem[]
  lines: Insights.BaseChartLineItem[]
  eventsToExclude?: string[]
}

const InsightsChartGroupingTooltipComponent: React.FC<InsightsChartTooltipProps> = ({
  active, payload, lines, isFetching,
  selectedRows = [],
  eventsToExclude = [],
  eventsVisibility = true,
  lastYearVisibility = true,
  predictionKeyPrefix = '',
  absDeviationKeyPrefix = '',
  lastYearTargetPayloadKeyPrefix = '',
  baselinePayloadKeyPrefix = '',
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const data: Insights.BaseChartDatasetItem = get(payload, '[0].payload', {})

  const {
    formattedEvents,
    hasEvents,
    label: promotionLabel,
  } = getEventDetailsAtDatapoint({
    data,
    eventsVisibility,
    eventsToExclude,
    intl,
  })

  const sortedRows = useMemo(() => {
    const rows: Common.TooltipRow<any>[] = []

    if (!active || !payload || !payload.length || !lines || !lines.length) {
      return rows
    }

    lines.forEach((line, index) => {
      rows.push({
        key: line.id,
        label: line.label,
        legendType: 'line',
        legendColor: getInsightsLineColor(index, line.id, {
          isFetching,
          hasGroupingEnabled: true,
          useColorWay: true,
          selectedRows,
        }),
      })
    })

    return rows.sort((a, b) => {
      const aPredictionKey = generatePredictionPayloadKey(a.key!, predictionKeyPrefix)
      const bPredictionKey = generatePredictionPayloadKey(b.key!, predictionKeyPrefix)

      const aPredictionValue = get(data, aPredictionKey, 0)
      const bPredictionValue = get(data, bPredictionKey, 0)
      const aTruthValue = get(data, a.key!, 0)
      const bTruthValue = get(data, b.key!, 0)

      if (bPredictionValue !== aPredictionValue) {
        return bPredictionValue - aPredictionValue
      }

      if (bTruthValue !== aTruthValue) {
        return bTruthValue - aTruthValue
      }

      return a.label!.localeCompare(b.label!)
    })
  }, [
    predictionKeyPrefix,
    lines, isFetching, selectedRows, data,
    active, payload,
  ])

  const primaryRows = useMemo(() => {
    const list: Common.TooltipRow<any>[] = [{
      key: 'date',
      label: intl.formatMessage({ id: 'insights.chart.x.title' }),
      valueFormatter: (value: any) => formatDateForTooltip(intl, value),
    }]

    if (hasEvents) {
      list.push({
        key: 'events',
        label: promotionLabel,
        valueFormatter: () => formattedEvents,
        sx: {
          '& td': {
            verticalAlign: 'top',
          },
        },
      })
    }

    return list
  }, [
    intl,
    formattedEvents,
    hasEvents,
    promotionLabel,
  ])

  const columns = useMemo(() => {
    const list: Common.TabularTooltipColumns[] = []

    if (lastYearVisibility) {
      list.push({
        key: (rowIndex: number, rowKey: string) => generateLastYearPayloadKey(rowKey, lastYearTargetPayloadKeyPrefix),
        headerLabel: intl.formatMessage({ id: 'insights.chart.tooltip.lastYearResults' }),
        headerAlignment: 'right',
        valueAlignment: 'right',
        headerColor: theme.palette.new.business_black_40,
        valueFormatter: (value: any) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true }),
        valueColor: (rowIndex: number, rowKey: string) => getInsightsLineColor(rowIndex, rowKey, {
          isFetching,
          hasGroupingEnabled: true,
          useColorWay: true,
          colorWayOpacity: INSIGHTS_GROUPING_LAST_YEAR_COLORWAY_OPACITY,
          selectedRows,
        }),
      })
    }

    list.push({
      key: (rowIndex: number, rowKey: string) => rowKey,
      headerLabel: intl.formatMessage({ id: 'insights.chart.tooltip.actual' }),
      headerAlignment: 'right',
      valueAlignment: 'right',
      valueFormatter: (value: any, dataPoint: Insights.BaseChartDatasetItem) => {
        const finalValue = dataPoint.virtualTarget ? null : value

        return defaultInsightsTooltipValueFormatter(intl, finalValue, { showIntervals: true })
      },
      valueColor: (rowIndex: number, rowKey: string) => getInsightsLineColor(rowIndex, rowKey, {
        isFetching,
        hasGroupingEnabled: true,
        useColorWay: true,
        selectedRows,
      }),
    })

    if (baselinePayloadKeyPrefix) {
      list.push({
        key: (rowIndex: number, rowKey: string) => generateBaselinePayloadKey(rowKey, baselinePayloadKeyPrefix),
        headerLabel: intl.formatMessage({ id: 'insights.chart.tooltip.baseline' }),
        headerAlignment: 'center',
        valueAlignment: 'right',
        valueFormatter: (value: any) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true }),
        valueColor: (rowIndex: number, rowKey: string) => getInsightsLineColor(rowIndex, rowKey, {
          isFetching,
          hasGroupingEnabled: true,
          useColorWay: true,
          selectedRows,
        }),

        secondaryValueColor: 'neutral',
        secondaryValueFormatter: (value: any) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true, showAbs: true }),
        secondaryValueGetter: (d: Insights.BaseChartDatasetItem, rowKey: string) => {
          const targetValue = d[rowKey]
          const baselineValue = d[generateBaselinePayloadKey(rowKey, baselinePayloadKeyPrefix)]

          if (baselineValue === null || targetValue === null) {
            return null
          }

          return baselineValue - targetValue
        },
      })
    }

    list.push({
      key: (rowIndex: number, rowKey: string) => generatePredictionPayloadKey(rowKey, predictionKeyPrefix),
      valueFormatter: (value: any) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true }),
      headerLabel: baselinePayloadKeyPrefix ? intl.formatMessage({ id: 'insights.chart.tooltip.paretos' }) : intl.formatMessage({ id: 'insights.chart.tooltip.predicted' }),
      headerAlignment: baselinePayloadKeyPrefix ? 'center' : 'right',
      valueAlignment: 'right',
      valueColor: (rowIndex: number, rowKey: string) => getInsightsLineColor(rowIndex, rowKey, {
        isFetching,
        hasGroupingEnabled: true,
        useColorWay: true,
        selectedRows,
      }),

      ...(baselinePayloadKeyPrefix ? {
        secondaryValueColor: 'neutral',
        secondaryValueFormatter: (value: any) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true, showAbs: true }),
        secondaryValueGetter: (d: Insights.BaseChartDatasetItem, rowKey: string) => {
          const targetValue = d[rowKey]
          const predictionValue = d[generatePredictionPayloadKey(rowKey, predictionKeyPrefix)]

          if (predictionValue === null || targetValue === null) {
            return null
          }

          return predictionValue - targetValue
        },
      } : {}),
    })

    if (absDeviationKeyPrefix) {
      list.push({
        headerLabel: intl.formatMessage({ id: 'insights.chart.tooltip.absDeviation' }),
        headerAlignment: 'right',
        valueAlignment: 'right',
        key: (rowIndex: number, rowKey: string) => generateAbsDeviationPayloadKey(rowKey, absDeviationKeyPrefix),
        valueVariant: 'contained',
        valueColor: 'neutral',
        valueFormatter: (value) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: false }),
      })
    }

    if (baselinePayloadKeyPrefix) {
      list.push({
        key: '',
        headerLabel: intl.formatMessage({ id: 'insights.chart.tooltip.comparison' }),
        headerAlignment: 'right',
        valueAlignment: 'right',
        valueFormatter: (value: any) => defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true, showAbs: true }),
        valueVariant: 'contained',
        valueGetter: (d: Insights.BaseChartDatasetItem, rowKey: string) => {
          const predictionValue = d[generatePredictionPayloadKey(rowKey, predictionKeyPrefix)]
          const baselineValue = d[generateBaselinePayloadKey(rowKey, baselinePayloadKeyPrefix)]

          if (baselineValue === null || predictionValue === null) {
            return null
          }

          return predictionValue - baselineValue
        },
        valueColor: (rowIndex: number, rowKey: string, d: Insights.BaseChartDatasetItem) => {
          const predictionValue = d[generatePredictionPayloadKey(rowKey, predictionKeyPrefix)]
          const baselineValue = d[generateBaselinePayloadKey(rowKey, baselinePayloadKeyPrefix)]

          if (predictionValue === null || baselineValue === null) {
            return 'neutral'
          }

          const difference = Math.round(predictionValue - baselineValue)

          if (difference > 0) {
            return 'negative'
          }

          if (difference < 0) {
            return 'positive'
          }

          return 'neutral'
        },
      })
    }

    return list
  }, [
    intl,
    lastYearTargetPayloadKeyPrefix,
    baselinePayloadKeyPrefix,
    predictionKeyPrefix,
    absDeviationKeyPrefix,
    lastYearVisibility,
    theme,
    isFetching,
    selectedRows,
  ])

  return (
    <ChartTabularTooltipComponent
      columns={columns}
      primaryRows={primaryRows}
      rows={sortedRows}
      payload={payload}
      active={active}
    />
  )
}

export default InsightsChartGroupingTooltipComponent
