import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment'
import { useSelector } from '@redux/hooks'
import { getSelectedLanguage } from '@redux/modules/customer/customer.selectors'

const DatePickerProvider = ({ children } : { children: any }) => {
  const userLanguage = useSelector(getSelectedLanguage)

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={userLanguage}
    >
      {children}
    </LocalizationProvider>
  )
}

export default DatePickerProvider
