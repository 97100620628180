import React, { useCallback, useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from '@redux/hooks'
import { useIntl } from 'react-intl'

import PageLayoutContainer from '@containers/application/PageLayout'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'
import UseCasesListComponent from '@components/dashboard/UseCasesList'
import InitialSetupComponent from '@components/dashboard/InitialSetup'
import PlusIcon from '@icons/plus.icon'

import { USE_CASE_ADMIN_MODAL_NAME } from '@constants/modals.constants'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { fetchAllUseCasesAction, redirectToUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getUseCasesList, isFetchingUseCases } from '@redux/modules/use-case/use-case.selectors'

import UseCaseArtifactMappingsModalContainer from '@containers/modals/use-case-artifact-mappings-modal/UseCaseArtifactMappingsModal.container'
import UseCaseForecastParametersModalContainer from '@containers/modals/use-case-forecast-parameters-modal/UseCaseForecastParametersModal.container'
import UseCaseAdminModalContainer, { UseCaseAdminModalModalDetails } from '@containers/modals/use-case-admin-modal/UseCaseAdminModal.container'

const DashboardContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const modalPageName = useSelector(getOpenedModal)
  const isFetchingUseCasesFlag = useSelector(isFetchingUseCases)
  const useCases = useSelector(getUseCasesList)
  const isAdmin = useSelector(getIsAdmin)

  const isFetching = isFetchingUseCasesFlag && !modalPageName
  const isEmptyUseCasesList = useCases && (useCases.length === 0)
  const isEmpty = (!isFetching && isEmptyUseCasesList)

  const onCreateButtonClick = useCallback(() => {
    dispatch(setPrimaryModalPageName(USE_CASE_ADMIN_MODAL_NAME))
  }, [dispatch])

  const onUseCaseNameClick = (useCaseId: string) => {
    dispatch(
      redirectToUseCaseAction({
        useCaseId,
        currentLevel: 'company',
      }),
    )
  }

  const onUseCaseSettingButtonClick = (useCaseId: string) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: USE_CASE_ADMIN_MODAL_NAME,
        modalDetails: {
          returnTo: '',
          useCaseId,
        } as UseCaseAdminModalModalDetails,
      }),
    )
  }

  const pageBarRightSideBlocks = useMemo(() => {
    if (isEmpty) {
      return null
    }

    return (
      <CallToActionButtonComponent
        name='createUseCaseButton'
        StartIconComponent={PlusIcon}
        onClick={onCreateButtonClick}
        label={intl.formatMessage({ id: 'common.create_use_cases.button' })}
      />
    )
  }, [
    intl,
    isEmpty,
    onCreateButtonClick,
  ])

  useLayoutEffect(() => {
    dispatch(fetchAllUseCasesAction({ includeForecast: true }))
  }, [dispatch])

  if (isFetching) {
    return (
      <PageLayoutContainer
        title={intl.formatMessage({ id: 'use_cases.browser_tab.title' })}
        isFetching={true}
      >
        <LoadingFallbackComponent size='large' showLongRequestNote={true} />
      </PageLayoutContainer>
    )
  }

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'use_cases.browser_tab.title' })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
    >
      {
        (isEmpty) ? (
          <InitialSetupComponent
            onCreateButtonClick={onCreateButtonClick}
          />
        ) : (
          <UseCasesListComponent
            useCases={useCases}
            isAdmin={isAdmin}
            onItemClick={onUseCaseNameClick}
            onAdminButtonClick={onUseCaseSettingButtonClick}
          />
        )
      }

      <UseCaseArtifactMappingsModalContainer />

      <UseCaseForecastParametersModalContainer />

      <UseCaseAdminModalContainer />
    </PageLayoutContainer>
  )
}

export default DashboardContainer
