import React from 'react'
import { useIntl } from 'react-intl'
import { Box, useTheme } from '@mui/material'
import { formatNumberWithSuffixUnit } from '@utils/analysis.utils'
import OptimizeRestockDetailsItemComponent from '@components/(features)/optimize/optimize-restock-details-item'
import OptimizeStrategyBlockComponent from '@components/(features)/optimize/optimize-strategy-block'
import { getPredictionOverview, getRangeSelectorType } from '@redux/modules/optimize/optimize.selectors'
import { setTargetSelectorType } from '@redux/modules/optimize/optimize.actions'
import { getSidebarState } from '@redux/modules/common/common.selectors'
import { useSelector, useDispatch } from '@redux/hooks'
import { OPTIMIZE_RESTOCK_SELECTOR_TYPES } from '@constants/optimize.constants'
import { PARETOS_GRADIENT } from '@constants/ui.constants'

const OptimizeRestockSelectorComponent: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()

  const sidebarState = useSelector(getSidebarState)
  const predictionOverview = useSelector(getPredictionOverview)
  const selectedType = useSelector(getRangeSelectorType)
  const setSelectedType = (type: OPTIMIZE_RESTOCK_SELECTOR_TYPES) => {
    dispatch(setTargetSelectorType(type))
  }

  const {
    totalPredictedReplenishmentCost,
    totalPredictedRevenue,
  } = predictionOverview

  const mediaQuery = '@media (max-width: 1400px)'
  const breakpoint = sidebarState ? mediaQuery : theme.breakpoints.down('lg')

  return (
    <Box
      data-testid={OptimizeRestockSelectorComponent.name}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        width: '100%',
        height: '280px',
        [breakpoint]: {
          width: '100%',
          height: 'auto',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '50%',
          background: 'linear-gradient(259.87deg, #666666 0.94%, #000000 97.62%)',
          borderRadius: theme.spacing(1, 0, 0, 1),
          height: '100%',
          [breakpoint]: {
            width: '100%',
            borderRadius: theme.spacing(1, 1, 0, 0),
          },
        }}
      >
        <OptimizeStrategyBlockComponent
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '50%',
          background: PARETOS_GRADIENT,
          borderRadius: theme.spacing(0, 1, 1, 0),
          height: '100%',
          [breakpoint]: {
            width: '100%',
            borderRadius: theme.spacing(0, 0, 1, 1),
          },
        }}
      >
        <OptimizeRestockDetailsItemComponent
          header={intl.formatMessage({ id: 'optimize.selector.replenishmentCost' })}
          subHeader={intl.formatMessage({ id: 'optimize.selector.predicted' })}
          description={intl.formatMessage({ id: 'optimize.selector.replenishmentCost.description' })}
          borderRight={true}
          value={formatNumberWithSuffixUnit(totalPredictedReplenishmentCost)}
        />
        <OptimizeRestockDetailsItemComponent
          header={intl.formatMessage({ id: 'optimize.selector.revenue' })}
          subHeader={intl.formatMessage({ id: 'optimize.selector.predicted' })}
          description={intl.formatMessage({ id: 'optimize.selector.revenue.description' })}
          value={formatNumberWithSuffixUnit(totalPredictedRevenue)}
        />
      </Box>
    </Box>
  )
}

export default OptimizeRestockSelectorComponent
