import { useDispatch } from '@redux/hooks'
import { requestInsightsPreloadAction } from '@redux/modules/insights/insights.actions'
import { useLayoutEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

/**
 * PreLoaderContainer is a container that fetches data to speed up the page load.
 * For the views that require a lot of data to load, we can use this container to
 * fetch the data before the page is loaded/navigated to.
 */
const PreLoaderContainer = () => {
  const dispatch = useDispatch()

  const {
    params: {
      usecase,
    },
  } = useRouteMatch<Common.RouterMatch>()

  useLayoutEffect(() => {
    if (usecase) {
      dispatch(
        requestInsightsPreloadAction({
          useCaseId: usecase,
        }),
      )
    }
  }, [
    dispatch,
    usecase,
  ])

  return null
}

export default PreLoaderContainer
