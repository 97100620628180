import React, {
  useMemo, useLayoutEffect,
  useEffect, useCallback,
  useState,
} from 'react'

import isEqual from 'lodash.isequal'

import { useIntl } from 'react-intl'

import composeClasses from '@mui/utils/composeClasses'

import {
  GridCallbackDetails,
  GridColumnHeaderParams,
  GridRowSelectionModel,
  getDataGridUtilityClass,
  gridRowSelectionStateSelector,
  gridTabIndexColumnHeaderSelector,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-premium'

import { INSIGHTS_MAX_SELECTED_ROWS_IN_GROUPING_MODE } from '@constants/insights.constants'

import { DataGridPremiumProcessedProps } from '@mui/x-data-grid-premium/models/dataGridPremiumProps'
import { DataGridCheckboxBaseComponentProps } from '@base/datagrid/data-grid-checkbox-base/DataGridCheckboxBase.component'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

type OwnerState = { classes: DataGridPremiumProcessedProps['classes'] }

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState

  const slots = {
    root: ['checkboxInput'],
  }

  return composeClasses(slots, getDataGridUtilityClass, classes)
}

export const getNextRowSelection = ({
  isChecked,
  isIndeterminate,
  isGroupingEnabled,
  reversed,
}: {
  /**
   * Whether the checkbox is checked
   */
  isChecked?: boolean,
  /**
   * Whether the checkbox is in indeterminate state
   */
  isIndeterminate?: boolean,
  /**
   * Whether grouping is enabled
   */
  isGroupingEnabled?: boolean,
  /**
   * Whether the selection is reversed (exclude/include)
   */
  reversed?: boolean,
}): {
  model: GridRowSelectionModel,
  mode: Hera.RowSelectionModelMode
} => {
  if (isIndeterminate) {
    return {
      model: [],
      mode: 'include',
    }
  }

  if (reversed) {
    return {
      model: [],
      mode: isChecked ? 'exclude' : 'include',
    }
  }

  if (!isChecked && !isGroupingEnabled) {
    return {
      model: [],
      mode: 'exclude',
    }
  } else if (isGroupingEnabled) {
    // no-op, should be disabled by isDisabled
  }

  return {
    model: [],
    mode: reversed ? 'exclude' : 'include',
  }
}

export interface InsightsGridHeaderCheckboxComponentProps extends GridColumnHeaderParams {
}

export interface InsightsGridHeaderCheckboxGridCallbackDetails extends GridCallbackDetails {
  mode: Hera.RowSelectionModelMode
}

const InsightsGridHeaderCheckboxComponent = React.forwardRef<HTMLButtonElement, InsightsGridHeaderCheckboxComponentProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function InsightsGridHeaderCheckbox(props, ref) {
    const {
      field,
      colDef,
      ...other
    } = props

    const [, forceUpdate] = useState(false)
    const intl = useIntl()
    const apiRef = useGridApiContext() as React.MutableRefObject<GridApiPremium>
    const rootProps = useGridRootProps()
    const ownerState = { classes: rootProps.classes }
    const classes = useUtilityClasses(ownerState)
    const tabIndexState = useGridSelector(apiRef, gridTabIndexColumnHeaderSelector)
    const visibleRowIds = useGridSelector(apiRef, gridPaginatedVisibleSortedGridRowIdsSelector)
    const selection = useGridSelector(apiRef, gridRowSelectionStateSelector)
    const tabIndex = tabIndexState !== null && tabIndexState.field === props.field ? 0 : -1
    const {
      reversed,
      groupingModel,
    } = (rootProps.slotProps?.baseCheckbox || {}) as DataGridCheckboxBaseComponentProps

    const isGroupingEnabled = (groupingModel || []).length > 0
    const totalRowCount = (rootProps.rowCount || 0)

    const selectedRowCount = useMemo(
      () => selection.length,
      [selection],
    )

    const isIndeterminate = useMemo(() => {
      if (selectedRowCount <= 0) {
        return false
      }

      if (totalRowCount === 0 && selectedRowCount > 0) {
        return true
      }

      if (selectedRowCount < totalRowCount) {
        return true
      }

      if ((selectedRowCount === totalRowCount) && !isEqual(selection, visibleRowIds)) {
        return true
      }

      return false
    }, [
      selectedRowCount, totalRowCount,
      selection, visibleRowIds,
    ])

    const isChecked = selectedRowCount > 0

    const handleStateChange = useCallback((newState: boolean) => {
      if (rootProps.onRowSelectionModelChange) {
        const nextState = getNextRowSelection({
          isChecked,
          isIndeterminate,
          isGroupingEnabled,
          reversed,
        })

        rootProps.onRowSelectionModelChange(nextState.model, {
          mode: nextState.mode,
        } as InsightsGridHeaderCheckboxGridCallbackDetails)
      }
    }, [rootProps, isChecked, isIndeterminate, isGroupingEnabled, reversed])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      handleStateChange(event.target.checked)
    }

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent) => {
        if (event.key === ' ') {
          handleStateChange(!isChecked)
        }
      },
      [handleStateChange, isChecked],
    )

    const handleSelectionChange = useCallback(() => {
      forceUpdate((p) => !p)
    }, [])

    useEffect(() => {
      return apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange)
    }, [apiRef, handleSelectionChange])

    useLayoutEffect(() => {
      const element = apiRef.current.getColumnHeaderElement(props.field)
      if (tabIndex === 0 && element) {
        element!.tabIndex = -1
      }
    }, [tabIndex, apiRef, props.field])

    const label = apiRef.current.getLocaleText(
      isChecked ? 'checkboxSelectionUnselectAllRows' : 'checkboxSelectionSelectAllRows',
    )

    /*
     * Disable the checkbox if there are no rows to select.
     * Since all rows cannot be selected in grouping mode, disable the checkbox if all rows are selected.
     * https://paretos.atlassian.net/browse/PD-5903
     */
    const isDisabled = (selectedRowCount === 0 && isGroupingEnabled)
    const disabledHint = rootProps.loading ? '' : intl.formatMessage({
      id: 'insights.table.headerCheckboxDisabledHint',
    }, {
      num: INSIGHTS_MAX_SELECTED_ROWS_IN_GROUPING_MODE,
    })

    return (
      <rootProps.slots.baseCheckbox
        ref={ref}
        indeterminate={isIndeterminate}
        checked={isChecked}
        onChange={handleChange}
        className={classes.root}
        inputProps={{ 'aria-label': label }}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        {...rootProps.slotProps?.baseCheckbox}
        {...other}
        dataTestId='InsightsGridHeaderCheckboxComponent'
        disabled={isDisabled}
        disabledHint={disabledHint}
      />
    )
  },
)

export default InsightsGridHeaderCheckboxComponent
