import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from '@redux/hooks'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'

import { getCompanyConnections, isSubmittingHermes, isFetching } from '@redux/modules/hermes/hermes.selectors'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'

import PlusIcon from '@icons/plus.icon'
import PageLayoutContainer from '@containers/application/PageLayout'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import ConnectorsOverviewComponent from '@containers/pages/(company-global)/connectors/connectors-overview'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'
import NewConnectorModalContainer from '@containers/modals/connector-new-modal'
import EditConnectorModalContainer from '@containers/modals/connector-edit-modal'

import {
  fetchAllSourcesAction,
  fetchConnectionsByCompanyIdAction,
  requestRemoveConnectionAction,
} from '@redux/modules/hermes/hermes.actions'

const ConnectorsContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isFetchingConnectors = useSelector(isFetching)
  const isSubmitting = useSelector(isSubmittingHermes)
  const connections = useSelector(getCompanyConnections)
  const companyId = useSelector(getSelectedCompanyId)

  const [isNewSourceModalOpen, setIsNewSourceModalOpen] = useState(false)
  const [isEditSourceModalOpen, setIsEditSourceModalOpen] = useState(false)
  const [connectionId, setConnectionId] = useState('')

  const [dialogState, setDialogState] = useState({
    isOpen: false,
    connectionId: '',
    name: '',
  })

  useLayoutEffect(() => {
    dispatch(
      fetchConnectionsByCompanyIdAction(companyId),
    )

    dispatch(
      fetchAllSourcesAction(),
    )
  }, [companyId, dispatch])

  const handleEditClick = useCallback((record: Hermes.ConnectionListItem) => {
    setConnectionId(record.connectionId)

    setIsEditSourceModalOpen(true)
  }, [])

  const handleDeleteClick = useCallback((record: Hermes.ConnectionListItem) => {
    setDialogState({
      isOpen: true,
      name: record.name,
      connectionId: record.connectionId,
    })
  }, [])

  const handleConfirmClick = (deleteConnectionId: string) => {
    dispatch(
      requestRemoveConnectionAction({
        companyId,
        connectionId: deleteConnectionId,
      }),
    )
  }

  const handleNewSourceClick = () => {
    setIsNewSourceModalOpen(true)
  }

  const pageBarRightSideBlocks = (
    <CallToActionButtonComponent
      name='createConnectorButton'
      StartIconComponent={PlusIcon}
      onClick={handleNewSourceClick}
      label={intl.formatMessage({ id: 'connectors.elements.new_connector' })}
    />
  )

  return (
    <PageLayoutContainer
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      title={intl.formatMessage({ id: 'connectors.browser_tab.title' })}
    >
      <ConnectorsOverviewComponent
        list={connections}
        handleEdit={handleEditClick}
        handleDelete={handleDeleteClick}
        isFetching={isFetchingConnectors || isSubmitting}
      />

      <NewConnectorModalContainer
        isOpen={isNewSourceModalOpen}
        handleClose={() => setIsNewSourceModalOpen(false)}
      />

      <EditConnectorModalContainer
        selectedConnectionId={connectionId}
        isOpen={isEditSourceModalOpen}
        handleClose={() => setIsEditSourceModalOpen(false)}
      />

      <TextConfirmationDialogComponent
        open={dialogState.isOpen}
        onClose={() => setDialogState({ isOpen: false, connectionId: '', name: '' })}
        onSubmit={() => handleConfirmClick(dialogState.connectionId)}
        confirmationText={dialogState.name}
        confirmationInputLabel={intl.formatMessage({ id: 'connectors.table.name' })}
        description={
          intl.formatMessage({ id: 'connectors.table.dialog.content' }, {
            name: <Box component='strong'>{dialogState.name}</Box>,
          })
        }
      />
    </PageLayoutContainer>
  )
}

export default ConnectorsContainer
