import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { Card } from '@mui/material'
import { GRID_ACTIONS_COLUMN_TYPE, GridColDef, GridRowParams } from '@mui/x-data-grid-premium'

import EditIcon from '@icons/edit.icon'
import DeleteIcon from '@icons/delete.icon'
import SyncIcon from '@icons/flow/sync.icon'

import { getIsAdmin, getSelectedCustomerId } from '@redux/modules/customer/customer.selectors'

import {
  generateCopyButtonColumnDefinition,
  generateActionsColumnDefinition,
  generateDateTimeColumnDefinition,
  generateRegularColumnDefinition,
} from '@utils/data-grid-cells.utils'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'

import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import DataGridComponent from '@base/datagrid/data-grid'
import useGridInitialState from '@hooks/useGridInitialState.hook'

export interface CompanyUsersOverviewComponentProps {
  list: Customer.UserItem[],
  handleEdit: {
    (record: Customer.UserItem): any,
  },
  handleDelete: {
    (record: Customer.UserItem): any,
  },
  handleChangeCompany: {
    (record: Customer.UserItem): any,
  },
  isFetching?: boolean,
}

const CompanyUsersOverviewComponent: React.FC<CompanyUsersOverviewComponentProps> = (props) => {
  const {
    list = [],
    isFetching,
    handleEdit,
    handleDelete,
    handleChangeCompany,
  } = props

  const intl = useIntl()
  const isAdmin = useSelector(getIsAdmin)
  const customerId = useSelector(getSelectedCustomerId)
  const tableId = getDataGridId(DATA_GRIDS.COMPANY_USERS_TABLE, 2)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'updatedAt', sort: 'desc' }],
    },
  })

  const getActionItems = useCallback((params: GridRowParams<Customer.UserItem>) => {
    const actions = []

    if (isAdmin) {
      actions.push((
        <DataGridActionButtonComponent
          name='userCompanyChange'
          icon={<SyncIcon />}
          onClick={() => handleChangeCompany(params.row)}
          label={intl.formatMessage({ id: 'customers.users.table.changeCompany' })}
          id={params.id}
        />
      ))
    }

    if ((params.row.customerId === customerId) || isAdmin) {
      actions.push((
        <DataGridActionButtonComponent
          name='userEdit'
          icon={<EditIcon />}
          onClick={() => handleEdit(params.row)}
          label={intl.formatMessage({ id: 'common.tables.actions.edit' })}
          id={params.id}
        />
      ))
    }

    if (isAdmin) {
      actions.push((
        <DataGridActionButtonComponent
          name='userDelete'
          icon={<DeleteIcon />}
          onClick={() => handleDelete(params.row)}
          label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
          id={params.id}
        />
      ))
    }

    return actions
  }, [intl, customerId, isAdmin, handleChangeCompany, handleEdit, handleDelete])

  const columns = useMemo<GridColDef[]>(() => {
    const columnsList = [
      generateRegularColumnDefinition({
        intl,
        field: 'firstName',
        headerName: intl.formatMessage({ id: 'customers.users.table.firstName' }),
      }),
      generateRegularColumnDefinition({
        intl,
        field: 'lastName',
        headerName: intl.formatMessage({ id: 'customers.users.table.lastName' }),
      }),
      generateCopyButtonColumnDefinition({
        field: 'email',
        headerName: intl.formatMessage({ id: 'customers.users.table.email' }),
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'updatedAt',
        headerName: intl.formatMessage({ id: 'customers.users.table.updatedAt' }),
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: isAdmin ? 3 : 1,
      }),
    ]

    if (isAdmin) {
      columnsList.unshift(
        generateCopyButtonColumnDefinition({
          field: 'customerId',
          headerName: intl.formatMessage({ id: 'customers.users.table.customerId' }),
        }),
      )
    }

    return columnsList
  }, [
    intl,
    isAdmin,
    getActionItems,
  ])

  const getRowId = useCallback((row: Customer.UserItem) => row.customerId, [])

  return (
    <Card
      data-testid={CompanyUsersOverviewComponent.name}
      elevation={0}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.COMPANY_USERS_TABLE}
        columns={columns}
        loading={isFetching}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={getRowId}
        initialState={initialState}
        disableRowGrouping={true}
      />
    </Card>
  )
}

export default CompanyUsersOverviewComponent
