import React from 'react'
import { Box, useTheme } from '@mui/material'

export interface InsightsFiltersLayoutComponentProps {
  /**
   * ABC Section
   */
  abcSection?: React.ReactNode,
  /**
   * Left Side Section
   */
  leftSideSection: React.ReactNode,
  /**
   * Data test id
   */
  dataTestId?: string,
}

const InsightsFiltersLayoutComponent: React.FC<InsightsFiltersLayoutComponentProps> = ({
  abcSection, leftSideSection, dataTestId,
}) => {
  const theme = useTheme()

  return (
    <Box
      data-testid={dataTestId || InsightsFiltersLayoutComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: '7px',
        border: `1px solid ${theme.palette.new.business_black_20}`,
        marginBottom: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          width: '100%',
          padding: '9px',
        }}
      >
        {leftSideSection}
      </Box>
      <Box
        sx={{
          display: abcSection ? 'flex' : 'none',
          flexDirection: 'row',
          width: '100%',
          padding: '0px',
          backgroundColor: theme.palette.new.smokey_silver,
          borderLeft: `1px solid ${theme.palette.new.business_black_20}`,
          borderRadius: '0px 7px 7px 0px',
        }}
      >
        {abcSection}
      </Box>
    </Box>
  )
}

export default InsightsFiltersLayoutComponent
