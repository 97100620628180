import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import {
  Box, styled,
  Typography, useTheme,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import { sortByLabelKey } from '@utils/list.utils'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'
import { DESC_SORTING_ORDER } from '@constants/filters.constants'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { DATA_SOURCES_MODAL_NAME, PARETOS_DATA_SOURCE_MODAL_NAME } from '@constants/modals.constants'
import { ParetosSourceOption, PARETOS_DATA_CATEGORY_LABEL_MAP, PARETOS_SOURCE_OPTIONS_BY_CATEGORY } from '@constants/flow.constants'

import SearchFieldComponent from '@base/forms/SearchField'

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: theme.spacing(6),
  padding: theme.spacing(0, 4),
  borderBottom: `1px solid ${theme.palette.new.grey_a}`,
}))

export interface ParetosDataSourceModalDetails extends Common.ModalDetails {
  icon: React.ElementType
  title: string
  help: string
  connectionId: string
}

const ParetosDataSourceModalContainer = () => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [searchValue, setSearchValue] = useState('')

  const modalDetails = useSelector(getModalDetails<ParetosDataSourceModalDetails>)
  const modalPageName = useSelector(getOpenedModal)

  const open = modalPageName === PARETOS_DATA_SOURCE_MODAL_NAME
  const categories = Object.keys(PARETOS_SOURCE_OPTIONS_BY_CATEGORY)

  const {
    icon,
    title: sourceName,
    returnTo,
  } = modalDetails

  const handleClose = () => {
    setSearchValue('')

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: returnTo,
        modalDetails: {
          ...modalDetails,
          connectionId: '',
          returnTo: DATA_SOURCES_MODAL_NAME,
        } as ParetosDataSourceModalDetails,
      }),
    )
  }

  const filterBySearch = (item: ParetosSourceOption) => {
    const name = intl.formatMessage({ id: item.labelKey }).toLowerCase()
    const searchTerm = searchValue.toLowerCase()

    if (!searchValue) {
      return true
    }

    return name.includes(searchTerm)
  }

  const listToRender = categories.map((category) => {
    const items = (sortByLabelKey(PARETOS_SOURCE_OPTIONS_BY_CATEGORY[category], 'labelKey', DESC_SORTING_ORDER, intl) as ParetosSourceOption[]).filter(filterBySearch)

    if (!items || items.length === 0) {
      return null
    }

    return (
      <Box key={category}>
        <Row
          sx={{
            backgroundColor: theme.palette.new.grey,
          }}
        >
          <Typography
            variant='overline'
            sx={{
              fontWeight: 500,
              color: theme.palette.new.black,
              marginTop: theme.spacing(1.5),
            }}
          >
            {intl.formatMessage({ id: PARETOS_DATA_CATEGORY_LABEL_MAP[category] })}
          </Typography>
        </Row>
        <Box>
          {
            items.map((item) => {
              return (
                <Row
                  key={item.labelKey}
                >
                  <Typography
                    variant='body1'
                  >
                    {intl.formatMessage({ id: item.labelKey })}
                  </Typography>
                  <Typography
                    variant='overline'
                  >
                    {intl.formatMessage({ id: item.unitKey })}
                  </Typography>
                </Row>
              )
            })
          }
        </Box>
      </Box>
    )
  })

  const isEmptyList = listToRender.filter((item) => item !== null).length === 0

  return (
    <SidePanelComponent
      open={open}
      title={sourceName}
      handleClose={handleClose}
      SidePanelIcon={icon}
    >
      <>
        <SidePanelCardComponent>
          <Typography
            variant='h5'
            sx={{
              fontSize: '30px',
              lineHeight: '35px',
              ...getTextBackgroundFillStyles('linear-gradient(219.13deg, #8F6BDE 0.5%, #F06DAC 97.79%)'),
              marginBottom: theme.spacing(2),
            }}
          >
            {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.title' })}
          </Typography>
          <Typography
            variant='body1'
            sx={{
              fontSize: '18px',
              lineHeight: '20px',
              color: theme.palette.new.black_b,
            }}
          >
            {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.description' })}
          </Typography>

          <Box
            sx={{
              marginTop: theme.spacing(5),
              marginLeft: theme.spacing(-4),
              marginRight: theme.spacing(-4),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: theme.spacing(0, 4),
                borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                paddingBottom: theme.spacing(2),
              }}
            >
              <Typography
                variant='h5'
              >
                {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.available_data' })}
              </Typography>
              <Box>
                <SearchFieldComponent
                  value={searchValue}
                  label={null}
                  fullWidth={false}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Box>
            </Box>

            {
              isEmptyList ? (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: theme.spacing(4),
                  }}
                >
                  <Typography
                    variant='h4'
                    sx={{
                      color: theme.palette.new.black,
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                      textAlign: 'center',
                    }}
                  >
                    {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.search_empty_title' })}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      color: theme.palette.new.grey_h,
                      textAlign: 'center',
                      maxWidth: '600px',
                    }}
                  >
                    {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.search_empty_body' })}
                  </Typography>
                </Box>
              ) : (
                listToRender
              )
            }
          </Box>
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='paretosDataSourcesModalCloseButton'
            onClick={handleClose}
            type='back'
          />
        </SidePanelCardActionsComponent>
      </>
    </SidePanelComponent>
  )
}

export default ParetosDataSourceModalContainer
