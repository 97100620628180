import { State } from '@redux/modules/types'

import {
  REQUEST_BACKTESTING,
  REQUEST_BACKTESTING_CHART,
  REQUEST_BACKTESTING_TABLE,

  REQUEST_LIVE_MONITORING,
  REQUEST_LIVE_MONITORING_CHART,
  REQUEST_LIVE_MONITORING_TABLE,

  REQUEST_BASELINE_COMPARISON,
  REQUEST_BASELINE_COMPARISON_CHART,
  REQUEST_BASELINE_COMPARISON_TABLE,
} from './monitor.action-types'

export const getBacktestingTable = (state: State) => {
  return state.monitor.backtestingTable
}

export const getBacktestingAvailableFolds = (state: State) => {
  return state.monitor.backtestingAvailableFolds
}

export const getBacktestingChart = (state: State) => {
  return state.monitor.backtestingChart
}

export const getBacktestingTableState = (state: State) => {
  return state.monitor.backtestingTable?.gridState
}

export const isFetchingBacktestingTable = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_BACKTESTING_TABLE)
}

export const isFetchingBacktestingChart = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_BACKTESTING_CHART)
}

export const isFetchingBacktesting = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_BACKTESTING)
}

export const getLiveMonitoringTable = (state: State) => {
  return state.monitor.liveMonitoringTable
}

export const getLiveMonitoringAvailableForecastHorizons = (state: State) => {
  return state.monitor.liveMonitoringAvailableForecastHorizons
}

export const getLiveMonitoringChart = (state: State) => {
  return state.monitor.liveMonitoringChart
}

export const getLiveMonitoringTableState = (state: State) => {
  return state.monitor.liveMonitoringTable?.gridState
}

export const isFetchingLiveMonitoringTable = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_LIVE_MONITORING_TABLE)
}

export const isFetchingLiveMonitoringChart = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_LIVE_MONITORING_CHART)
}

export const isFetchingLiveMonitoring = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_LIVE_MONITORING)
}

export const getBaselineComparisonTable = (state: State) => {
  return state.monitor.baselineComparisonTable
}

export const getBaselineComparisonAvailableForecastHorizons = (state: State) => {
  return state.monitor.baselineComparisonAvailableForecastHorizons
}

export const getBaselineComparisonChart = (state: State) => {
  return state.monitor.baselineComparisonChart
}

export const getBaselineComparisonTableState = (state: State) => {
  return state.monitor.baselineComparisonTable?.gridState
}

export const isFetchingBaselineComparisonTable = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_BASELINE_COMPARISON_TABLE)
}

export const isFetchingBaselineComparisonChart = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_BASELINE_COMPARISON_CHART)
}

export const isFetchingBaselineComparison = (state: State) => {
  return state.monitor.fetchingKeys.includes(REQUEST_BASELINE_COMPARISON)
}

export const isBaselineComparisonInsightsAvailable = (state: State) => {
  return !state.monitor.baselineComparisonNoInsightsAvailable
}
