import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { isFetchingBacktesting, getBacktestingChart, isFetchingBacktestingChart } from '@redux/modules/monitor/monitor.selectors'
import { formatRelativeDeviation } from '@utils/insights.utils'

import InsightsKpisComponent from '@components/insights/InsightsKpis'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'

const BacktestingKpisContainer = () => {
  const intl = useIntl()
  const backtestingChart = useSelector(getBacktestingChart)
  const isFetchingBacktestingChartFlag = useSelector(isFetchingBacktestingChart)
  const isFetchingBacktestingFlag = useSelector(isFetchingBacktesting)

  const {
    targetName,
    overallNumbers = {
      absDeviation: 0,
      relDeviation: 0,
      target: 0,
      backtestPrediction: 0,
    },
  } = backtestingChart

  const kpiCards = [
    {
      title: intl.formatMessage({ id: 'insights.kpis.absDeviation' }),
      unit: intl.formatMessage({ id: 'insights.table.mae' }),
      tooltip: intl.formatMessage({ id: 'insights.table.mae.help' }, {
        name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.mae.label' })}</IntlFormatBoldComponent>,
      }),
      value: overallNumbers.absDeviation,
    },
    {
      title: intl.formatMessage({ id: 'insights.kpis.relDeviation' }),
      unit: intl.formatMessage({ id: 'insights.table.wape' }),
      tooltip: intl.formatMessage({ id: 'insights.table.wape.help' }, {
        name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.wape.label' })}</IntlFormatBoldComponent>,
      }),
      value: formatRelativeDeviation(intl, overallNumbers.relDeviation),
      valueType: 'string' as const,
    },
    {
      title: intl.formatMessage({ id: 'insights.table.targetColumn' }, { name: targetName }),
      unit: intl.formatMessage({ id: 'insights.table.sum' }),
      value: overallNumbers.target,
    },
    {
      title: intl.formatMessage({ id: 'insights.table.predictionColumn' }, { name: targetName }),
      unit: intl.formatMessage({ id: 'insights.table.sum' }),
      value: overallNumbers.backtestPrediction,
      variant: 'highlighted' as const,
    },
  ]

  return (
    <InsightsKpisComponent
      loading={isFetchingBacktestingFlag}
      loadingValues={isFetchingBacktestingChartFlag}
      cards={kpiCards}
    />
  )
}

export default BacktestingKpisContainer
