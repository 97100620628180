export const REQUEST_PIPELINES_LIST = 'REQUEST_PIPELINES_LIST'
export const RECEIVE_PIPELINES_LIST = 'RECEIVE_PIPELINES_LIST'

export const REQUEST_PIPELINE_OVERVIEW = 'REQUEST_PIPELINE_OVERVIEW'
export const RECEIVE_PIPELINE_OVERVIEW = 'RECEIVE_PIPELINE_OVERVIEW'

export const SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG = 'SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG'
export const UPDATE_PIPELINE_EXECUTION_SCHEDULE = 'UPDATE_PIPELINE_EXECUTION_SCHEDULE'
export const UPDATE_PIPELINE_DELIVERY_SCHEDULE = 'UPDATE_PIPELINE_DELIVERY_SCHEDULE'
export const DELETE_PIPELINE_DELIVERY_SCHEDULE = 'DELETE_PIPELINE_DELIVERY_SCHEDULE'
export const UPDATE_PIPELINE_CONFIGURATION = 'UPDATE_PIPELINE_CONFIGURATION'
export const REPORT_PIPELINE_DELIVERY = 'REPORT_PIPELINE_DELIVERY'

export const START_COMPOSITE_PIPELINE = 'START_COMPOSITE_PIPELINE'
export const STOP_COMPOSITE_PIPELINE = 'STOP_COMPOSITE_PIPELINE'

export const CREATE_PIPELINE = 'CREATE_PIPELINE'
export const DELETE_PIPELINE = 'DELETE_PIPELINE'
export const TRIGGER_PIPELINE = 'TRIGGER_PIPELINE'

export const REQUEST_COMPOSITE_PIPELINE_STATUS = 'REQUEST_COMPOSITE_PIPELINE_STATUS'
export const RECEIVE_COMPOSITE_PIPELINE_STATUS = 'RECEIVE_COMPOSITE_PIPELINE_STATUS'

export const START_FETCHING_PIPELINES = 'START_FETCHING_PIPELINES'
export const STOP_FETCHING_PIPELINES = 'STOP_FETCHING_PIPELINES'
