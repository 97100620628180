import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box, Typography } from '@mui/material'
import sortBy from 'lodash.sortby'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'

import {
  requestParameterTemplateAction,
  createParameterTemplateAction,
  updateParameterTemplateAction,
} from '@redux/modules/parameters-templates/parameters-templates.actions'

import {
  SOURCE_TYPES_TO_ICONS_MAP,
  SourceTypeOption,
  DATA_SOURCES_OPTIONS,
  CATEGORIES_TO_LABEL_MAP,
  SOURCE_TYPES_CATEGORIES,
} from '@constants/flow.constants'

import TextFieldComponent from '@base/forms/TextField'
import HelpButtonComponent from '@base/buttons/HelpButton'
import ParameterTypesSelectorFieldComponent from '@components/connect-view/parameters/ParameterTypesSelectorField'
import SelectFieldComponent from '@base/forms/SelectField'

import { AGGREGATION_TYPES_OPTIONS, DEFAULT_AGGREGATION_TYPE } from '@constants/date.constants'
import { getParameterTemplateItem, isFetching, isSubmittingParameterTemplate } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { MANAGE_PREDIFINED_PARAMETER_MODAL_NAME } from '@constants/modals.constants'

import managePredefinedParametersModalValidations from './ManagePredefinedParametersModal.validations'

export interface ManagePredefinedParametersModalDetails extends Common.ModalDetails {
  parameterPresetId: string
}

const ManagePredefinedParametersModalContainer: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const isSubmitting = useSelector(isSubmittingParameterTemplate)
  const isFetchingData = useSelector(isFetching)
  const modalDetails = useSelector(getModalDetails<ManagePredefinedParametersModalDetails>)
  const parameterTemplateItem = useSelector(getParameterTemplateItem)
  const isAdmin = useSelector(getIsAdmin)
  const modalPageName = useSelector(getOpenedModal)

  const open = modalPageName === MANAGE_PREDIFINED_PARAMETER_MODAL_NAME
  const parameterPresetId = modalDetails.parameterPresetId
  const edit = Boolean(parameterPresetId)
  const initialValues = edit ? { ...parameterTemplateItem } : {
    name: '',
    description: '',
    unitLabel: '',
    parameterPresetId: '',
    aggregationFunction: DEFAULT_AGGREGATION_TYPE,
    dataColumnName: '',
    sourceType: '',
    possiblePresetTypes: [],
  } as UseCase.ParameterTemplateItem

  const allowedOptions: SourceTypeOption[] = sortBy(DATA_SOURCES_OPTIONS, (option) => {
    const category: SOURCE_TYPES_CATEGORIES = option.category

    return CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : ''
  })

  const handleModalClose = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(
        setPrimaryModalPageName({
          primaryModalPage: modalDetails.returnTo || '',
          modalDetails: {
            returnTo: '',
          },
        }),
      )
    }
  }

  const handleModalSubmit = (values: UseCase.ParameterTemplateItem) => {
    if (edit) {
      dispatch(updateParameterTemplateAction(values as any))
    } else {
      dispatch(createParameterTemplateAction(values as any))
    }
  }

  useEffect(() => {
    if (open && edit) {
      dispatch(
        requestParameterTemplateAction({ parameterPresetId }),
      )
    }
  }, [dispatch, parameterPresetId, open, edit])

  const {
    handleChange,
    handleSubmit,
    resetForm,
    handleBlur,
    setFieldValue,
    touched,
    errors,
    isValid,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
    validationSchema: managePredefinedParametersModalValidations(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const sourceType = values.sourceType

  if (!isAdmin) {
    return null
  }

  const modalTitle = edit ? (
    intl.formatMessage({ id: 'connect.modal.parameter.edit' }, { name: initialValues.name })
  ) : (
    intl.formatMessage({ id: 'connect.modal.predifined_parameters' })
  )

  return (
    <SidePanelComponent
      open={open}
      title={modalTitle}
      handleClose={handleModalClose}
      hasUnsavedChanges={dirty || isSubmitting}
      loading={isFetchingData}
      headerRightSideBlocks={(
        <HelpButtonComponent
          tooltip={intl.formatMessage({ id: 'connect.modal.parameter.help' })}
          name='modalHelpButton'
        />
      )}
    >
      <SidePanelLoadingComponent loading={isFetchingData}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer
                divider={false}
              >
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={6} container={true} alignContent='flex-start'>
                    <FormLayoutItem xs={12}>
                      <AutocompleteSelectFieldComponent<SourceTypeOption>
                        name='sourceType'
                        label={intl.formatMessage({ id: 'connect.modal.parameter.source' })}
                        disabled={isSubmitting}
                        options={allowedOptions}
                        placeholder={intl.formatMessage({ id: 'connect.modal.parameter.source.placeholder' })}
                        handleChangeCallback={(e, selectedValue) => {
                          if (!selectedValue) {
                            return
                          }

                          handleChange({
                            target: {
                              name: 'sourceType',
                              value: selectedValue?.value,
                            },
                          })
                        }}
                        value={DATA_SOURCES_OPTIONS.find((item) => {
                          return item.value === sourceType
                        }) || null}
                        getOptionLabel={(option) => {
                          return option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
                        }}
                        groupBy={(option) => {
                          const category: SOURCE_TYPES_CATEGORIES = option.category

                          return intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] })
                        }}
                        renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: SourceTypeOption, { selected }) => {
                          const label = option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
                          const IconComponent = SOURCE_TYPES_TO_ICONS_MAP[option.value]

                          return (
                            <Box component='li' {...props}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <IconComponent />
                                </Box>
                                <Typography
                                  title={label}
                                  variant='body1'
                                >
                                  {label}
                                </Typography>
                              </Box>
                            </Box>
                          )
                        }}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={12}>
                      <TextFieldComponent
                        label={intl.formatMessage({ id: 'connect.modal.parameter.dataColumnName' })}
                        floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.dataColumnName.help' })}
                        name='dataColumnName'
                        id='dataColumnName'
                        value={values.dataColumnName || ''}
                        touched={touched.dataColumnName}
                        errors={errors.dataColumnName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={12}>
                      <SelectFieldComponent
                        label={intl.formatMessage({ id: 'connect.modal.parameter.aggregation_type' })}
                        name='aggregationFunction'
                        id='aggregationFunction'
                        value={values.aggregationFunction || DEFAULT_AGGREGATION_TYPE}
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          setFieldValue('aggregationFunction', e.target.value, false)
                        }}
                        options={AGGREGATION_TYPES_OPTIONS.map((item) => ({ ...item, label: intl.formatMessage({ id: item.labelKey }) }))}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <ParameterTypesSelectorFieldComponent
                      label={intl.formatMessage({ id: 'connect.modal.parameter.possible_types' })}
                      value={values.possiblePresetTypes || []}
                      onChange={(value: string[]) => setFieldValue('possiblePresetTypes', value, true)}
                      disabled={edit}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.name' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.name.help' })}
                    name='name'
                    id='name'
                    value={values.name}
                    touched={touched.name}
                    errors={errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.unit' })}
                    floatingHelp={intl.formatMessage({ id: 'connect.modal.parameter.unit.help' })}
                    name='unitLabel'
                    id='unitLabel'
                    value={values.unitLabel}
                    touched={touched.unitLabel}
                    errors={errors.unitLabel}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    label={intl.formatMessage({ id: 'connect.modal.parameter.description_field' })}
                    name='description'
                    id='description'
                    touched={touched.description}
                    errors={errors.description}
                    value={values.description || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline={true}
                    minRows={6}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='managePredefinedParametersModalCloseButton'
              onClick={() => handleModalClose(true)}
              disabled={isSubmitting}
              type='cancel'
            />

            <ModalButtonComponent
              name='managePredefinedParametersModalSubmitButton'
              onClick={(e) => handleModalSubmit(values)}
              type='submit'
              loading={isSubmitting}
              disabled={!isValid || !dirty}
              label={intl.formatMessage({ id: 'common.modal.button.apply' })}
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ManagePredefinedParametersModalContainer
