import React from 'react'

import { Box, Typography, useTheme } from '@mui/material'

import { INPUT_TYPES, FLOW_ELEMENTS_TRANSITIONS } from '@constants/flow.constants'
import { PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import { getUseCaseFreezeStatus } from '@redux/modules/use-case/use-case.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { trackEvent, TRACKING_ACTIONS } from '@utils/tracking.utils'
import { useSelector } from '@redux/hooks'

import AddFlowIcon from '@icons/flow/addFlow.icon'

export interface ReactFlowAddNewButtonComponentProps {
  /**
   * Button text
   */
  buttonText?: string,
  /**
   * Type of the input
   */
  type?: INPUT_TYPES | PARAMETER_BLOCK_TYPES.TARGET,
  /**
   * On add button click handler
   */
  onAddButtonClick?: {
    (e?: React.SyntheticEvent): any,
  },
  /**
   * Parent component name for testing
   */
  parentComponentName?: string,
}

const ReactFlowAddNewButtonComponent: React.FC<ReactFlowAddNewButtonComponentProps> = ({
  buttonText,
  type,
  onAddButtonClick,
  parentComponentName,
}) => {
  const theme = useTheme()
  const frozen = useSelector(getUseCaseFreezeStatus)
  const isAdmin = useSelector(getIsAdmin)
  const creationDisabled = !isAdmin && frozen
  const rootStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: theme.spacing(10),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    cursor: creationDisabled ? 'not-allowed' : 'pointer',
    background: theme.palette.new.smokey_silver,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    outline: 'none',
    ...FLOW_ELEMENTS_TRANSITIONS,
    '&:hover': {
      background: theme.palette.new.white,
      border: '1px solid rgba(0, 0, 0, 0.4)',
    },
    '&:focus-visible': {
      background: theme.palette.new.white,
      border: '1px solid rgba(0, 0, 0, 0.4)',
    },
    ...(type === PARAMETER_BLOCK_TYPES.TARGET ? {
      background: 'rgba(255, 255, 255, 0.4)',
      border: '1px solid transparent',
      '& .buttonIcon': {
        ...FLOW_ELEMENTS_TRANSITIONS,
        background: 'rgba(255, 255, 255, 0.4)',
      },
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.6)',
        border: '1px solid transparent',

        '& .buttonIcon': {
          background: 'transparent',
        },
      },
    } : {}),
  }

  const handleOnClick = (e: React.MouseEvent) => {
    trackEvent({
      componentName: 'addAdditionalNodeButton',
      actionName: TRACKING_ACTIONS.CLICK,
    })

    if (onAddButtonClick) {
      onAddButtonClick(e)
    }
  }

  return (
    <Box
      onClick={creationDisabled ? () => {} : handleOnClick}
      role='button'
      tabIndex={0}
      aria-label={buttonText}
      data-testid={`${ReactFlowAddNewButtonComponent.name}-${parentComponentName}`}
      sx={rootStyles}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <Box
          className='buttonIcon'
          sx={{
            display: 'flex',
            height: theme.spacing(4),
            width: theme.spacing(4),
            background: theme.palette.new.white,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: theme.spacing(5),
          }}
        >
          <AddFlowIcon width={22} height={22} />
        </Box>
        <Typography
          variant='button'
          color={type === PARAMETER_BLOCK_TYPES.TARGET ? theme.palette.new.white : theme.palette.new.business_black}
        >
          {buttonText}
        </Typography>
      </Box>
    </Box>
  )
}

export default ReactFlowAddNewButtonComponent
