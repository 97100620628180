import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { foldsToChartAnnotations } from '@utils/monitor.utils'
import { getEventsToExclude, getEventsVisibility } from '@redux/modules/insights/insights.selectors'
import { requestEventsVisibilityChangeAction } from '@redux/modules/insights/insights.actions'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { MANAGE_EVENTS_MODAL_NAME } from '@constants/modals.constants'

import {
  DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD,
  DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD_PREFIX,
  DEFAULT_LIVE_MONITORING_PREDICTION_FIELD,
  DEFAULT_LIVE_MONITORING_PREDICTION_FIELD_PREFIX,
  DEFAULT_LIVE_MONITORING_TARGET_FIELD,
} from '@constants/insights.constants'

import {
  getLiveMonitoringChart, isFetchingLiveMonitoringChart,
  getLiveMonitoringTable, getLiveMonitoringAvailableForecastHorizons,
} from '@redux/modules/monitor/monitor.selectors'

import InsightsChartComponent from '@components/insights/InsightsChart'

const LiveMonitoringChartContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const liveMonitoringTable = useSelector(getLiveMonitoringTable)
  const liveMonitoringChart = useSelector(getLiveMonitoringChart)
  const liveMonitoringAvailableForecastHorizons = useSelector(getLiveMonitoringAvailableForecastHorizons)
  const isFetching = useSelector(isFetchingLiveMonitoringChart)
  const eventsVisibility = useSelector(getEventsVisibility)
  const eventsToExclude = useSelector(getEventsToExclude)

  const handleShowEventsToggle = useCallback(() => {
    dispatch(
      requestEventsVisibilityChangeAction(!eventsVisibility),
    )
  }, [dispatch, eventsVisibility])

  const handleManageEvents = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(MANAGE_EVENTS_MODAL_NAME),
    )
  }, [dispatch])

  const {
    gridState,
  } = liveMonitoringTable

  const {
    dataset,
    lines,
    targetName,
    targetUnit,
    events,
  } = liveMonitoringChart

  const chartSettingsActions: Common.ContextMenuAction[] = [{
    name: 'showEvents',
    type: 'toggle',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.showEvents' }),
    disabled: false,
    checked: eventsVisibility,
    handler: handleShowEventsToggle,
  }, {
    name: 'manageEvents',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.manageEvents' }),
    disabled: false,
    handler: handleManageEvents,
  }]

  const annotations: Insights.BaseChartAnnotationItem[] = useMemo(() => {
    if (!gridState) {
      return []
    }

    return foldsToChartAnnotations(intl, gridState.liveForecastHorizonOffset, liveMonitoringAvailableForecastHorizons)
  }, [intl, gridState, liveMonitoringAvailableForecastHorizons])

  return (
    <Box
      data-testid={LiveMonitoringChartContainer.name}
    >
      <InsightsChartComponent
        dataset={dataset}
        lines={lines}
        annotations={annotations}
        gridState={gridState}
        events={events}
        targetName={targetName}
        targetUnit={targetUnit}
        isFetching={isFetching}
        eventsToExclude={eventsToExclude}
        eventsVisibility={eventsVisibility}
        lastYearVisibility={false}
        chartSettingsActions={chartSettingsActions}
        groupingModeAttributes={{
          predictionPayloadKeyPrefix: DEFAULT_LIVE_MONITORING_PREDICTION_FIELD_PREFIX,
          absDeviationPayloadKeyPrefix: DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD_PREFIX,
        }}
        regularModeAttributes={{
          targetPayloadKey: DEFAULT_LIVE_MONITORING_TARGET_FIELD,
          predictionPayloadKey: DEFAULT_LIVE_MONITORING_PREDICTION_FIELD,
          absDeviationPayloadKey: DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD,
        }}
      />
    </Box>
  )
}

export default LiveMonitoringChartContainer
