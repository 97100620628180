import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { Box, Typography } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import { setModalDetails as setModalDetailsAction, setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { SOURCE_TYPES } from '@constants/flow.constants'

import palette from '@configuration/theme/theme.palette'
import HelpButtonComponent from '@base/buttons/HelpButton'
import ParameterTemplatesTableComponent from '@components/connect-view/parameters/ParameterTemplatesTable'

import { DATA_SOURCES_MODAL_NAME, DATA_SOURCE_CONNECTED_MODAL_NAME } from '@constants/modals.constants'
import { requestParametersTemplatesAction } from '@redux/modules/parameters-templates/parameters-templates.actions'
import { getParametersTemplatesListBySourceType, isFetching } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

export interface DataSourceConnectedModalDetails extends Common.ModalDetails {
  icon: React.ElementType
  title: string
  help: string
  sourceId: string
  returnTo: string
  key: SOURCE_TYPES
}

const DataSourceConnectedModalContainer: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const isFetchingList = useSelector(isFetching)
  const modalDetails = useSelector(getModalDetails<DataSourceConnectedModalDetails>)
  const modalPageName = useSelector(getOpenedModal)
  const open = modalPageName === DATA_SOURCE_CONNECTED_MODAL_NAME

  const {
    key,
    icon: SidePanelIcon,
    title: sourceName,
    returnTo,
  } = modalDetails

  const parametersTemplatesList = useSelector((state) => getParametersTemplatesListBySourceType(state, key))
  const isParametersTemplatesListEmpty = (!parametersTemplatesList || parametersTemplatesList.length === 0)

  const handleModalClose = () => {
    dispatch(setPrimaryModalPageName(returnTo))

    dispatch(
      setModalDetailsAction({
        ...modalDetails,
        returnTo: DATA_SOURCES_MODAL_NAME,
      }),
    )
  }

  const handleDoneClick = () => {
    dispatch(setPrimaryModalPageName(''))

    dispatch(
      setModalDetailsAction({
        returnTo: '',
      }),
    )
  }

  useEffect(() => {
    if (open) {
      dispatch(requestParametersTemplatesAction())
    }
  }, [dispatch, open])

  return (
    <SidePanelComponent
      open={open}
      title={sourceName}
      SidePanelIcon={SidePanelIcon}
      handleClose={handleModalClose}
      headerRightSideBlocks={(
        <HelpButtonComponent
          tooltip={intl.formatMessage({ id: 'connect.modal.data_sources.final_step_help' })}
          name='modalHelpButton'
        />
      )}
    >
      <SidePanelLoadingComponent loading={isFetchingList}>
        <SidePanelCardComponent disableHorizontalSpacing={true}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pt: 6,
              pb: 3,
              pl: 3,
              pr: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: '30px',
                lineHeight: '30px',
                marginBottom: 2,
                ...getTextBackgroundFillStyles(),
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.data_sources.final_step_header' })}
            </Typography>
            <Typography
              sx={{
                color: palette.new.black_b,
                fontSize: '18px',
                lineHeight: '20px',
                marginBottom: 4,
                maxWidth: '600px',
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.data_sources.final_step_sub_header' }, { name: sourceName })}
            </Typography>
          </Box>
          {
            !isParametersTemplatesListEmpty ? (
              <ParameterTemplatesTableComponent
                list={parametersTemplatesList}
              />
            ) : (
              null
            )
          }
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='dataSourcesConnectedModalBackButton'
            onClick={handleModalClose}
            type='back'
          />
          <ModalButtonComponent
            name='dataSourcesConnectedModalDoneButton'
            onClick={handleDoneClick}
            type='submit'
            label={intl.formatMessage({ id: 'common.modal.button.done' })}
          />
        </SidePanelCardActionsComponent>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default DataSourceConnectedModalContainer
