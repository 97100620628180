import moment from 'moment'
import get from 'lodash.get'

import {
  put, call,
  select, take,
  all,
} from 'redux-saga/effects'

import { IntlShape } from 'react-intl'
import { GridRowSelectionModel, GridAggregationModel } from '@mui/x-data-grid-premium'
import { convertAPIFieldTypeToMUIFieldType, parseStoredTimeWindow } from '@redux/modules/hera/hera.utils'

import palette from '@configuration/theme/theme.palette'

import {
  INSIGHTS_COLOR_WAY,
  DEFAULT_BACKTEST_TARGET_FIELD,
  DEFAULT_ANALYZE_TARGET_FIELD,
  DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX,
  DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX,
  INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD,
  INSIHGTS_DEFAULT_LAST_YEAR_LINE_COLOR,
  DEFAULT_ANALYZE_PREDICTION_FIELD,
  DEFAULT_LIVE_MONITORING_PREDICTION_FIELD,
  DEFAULT_BACKTEST_PREDICTION_FIELD,
  INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD_PREFIX,
  INSIGHTS_TRUTH_LINE_STROKE_WIDTH,
  INSIGHTS_PREDICTION_LINE_STROKE_WIDTH,
  INSIGHTS_LAST_YEAR_TRUTH_LINE_STROKE_WIDTH,
  DEFAULT_LIVE_MONITORING_TARGET_FIELD,
  DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD_PREFIX,
  DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD,
  INSIGHTS_BASELINE_STORKE_DASHARRAY,
  DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD,
} from '@constants/insights.constants'

import {
  getDataGridId, getTableStateFromStorage,
  removeTableStateFromStorage, storeTableStateInStorage,
} from '@utils/data-grid.utils'

import { DATA_GRIDS, DEFAULT_PAGE_SIZE } from '@constants/data-grid.constants'
import { NO_INSIGHTS_AVAILABLE, TOAST_TYPE_ERROR } from '@constants/common.constants'
import { getErrorReason, parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'
import { dateStringToUnixTimestamp } from '@utils/moment.utils'
import { getEventsAtDate, getEventsDatesByYearRange } from '@utils/events.utils'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { TIME_RESOLUTION } from '@constants/date.constants'
import { getDemandProblemDefinition } from '@redux/modules/use-case/use-case.selectors'
import { isRecommendationFamilyCheck } from '@utils/use-cases.utils'

/**
 * Creates the initial data grid state
 *
 * @param tableId Table id
 *
 * @returns merged table configs
 */
export function createInsightsDataGridState<T extends Insights.BaseGridState>(tableId: string, initialState?: Insights.BaseGridState, customProps?: Partial<T>) {
  const tableStateFromStorage = getTableStateFromStorage<T>(tableId)
  const parsedLsState = (tableStateFromStorage?.localStorageState || {}) as Analyze.AnalyzeGridState
  const parsedSsState = (tableStateFromStorage?.sessionStorageState || {}) as Analyze.AnalyzeGridState
  const hasGroupingsInSs = (parsedSsState?.groupingModel && parsedSsState?.groupingModel.length > 0)

  const columnVisibilityModel = {
    ...(initialState?.columnVisibilityModel || {}),
  }

  if (hasGroupingsInSs) {
    Object.assign(columnVisibilityModel, {
      ...(parsedLsState?.columnVisibilityModel || {}),
    })
  } else {
    Object.assign(columnVisibilityModel, {
      /**
        * Since we use custom server side row grouping, where we change the visibility of the columns depending on the row grouping model,
        * We need to store the additional column visibility model which reflects user's choice of columns visibility.
      */
      ...(parsedLsState?.internalColumnVisibilityModel || {}),
    })
  }

  return {
    /**
     * Non-persistent state
     */
    rowSelectionModel: initialState?.rowSelectionModel,
    rowSelectionModelMode: hasGroupingsInSs ? 'include' : initialState?.rowSelectionModelMode,

    /**
     * Local Storage persistence
     */
    aggregationModel: {
      ...initialState?.aggregationModel,
      ...parsedLsState?.aggregationModel,
    },
    columnVisibilityModel,
    pinnedColumns: {
      ...initialState?.pinnedColumns,
      ...parsedLsState?.pinnedColumns,
    },
    internalColumnVisibilityModel: {
      ...initialState?.internalColumnVisibilityModel,
      ...parsedLsState?.internalColumnVisibilityModel,
    },
    /**
     * Session Storage persistence
     */
    groupingModel: parsedSsState?.groupingModel || initialState?.groupingModel,
    sortModel: parsedSsState?.sortModel || initialState?.sortModel,
    filterModel: {
      ...initialState?.filterModel,
      ...parsedSsState?.filterModel,
    },
    paginationModel: {
      page: parsedSsState?.paginationModel?.page || 0,
      pageSize: parsedLsState?.paginationModel?.pageSize || DEFAULT_PAGE_SIZE,
    },
    timeWindow: parseStoredTimeWindow(parsedSsState?.timeWindow as [string | null, string | null]) || initialState?.timeWindow,
    abcFilter: parsedSsState?.abcFilter || initialState?.abcFilter,

    /**
     * Custom state and overrides
     */
    ...customProps,
  } as T
}

/**
 * Returns the color for the line
 *
 * @param lineIndex Line index
 * @param lineId Line ID
 * @param options Options
 *
 * @param options Options
 * @returns Color
 */
export const getInsightsLineColor = (lineIndex: number, lineId: string, options: {
  hasGroupingEnabled?: boolean,
  useColorWay?: boolean,
  colorWayOpacity?: string,
  isFetching?: boolean,
  lastYearVisibility?: boolean,
  selectedRows?: GridRowSelectionModel,
} = {
  hasGroupingEnabled: false,
  useColorWay: false,
  colorWayOpacity: '',
  isFetching: false,
  lastYearVisibility: false,
  selectedRows: [],
}) => {
  if (options.isFetching) {
    return palette.new.black
  }

  if (!options.hasGroupingEnabled) {
    if (lineId === INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD) {
      return INSIHGTS_DEFAULT_LAST_YEAR_LINE_COLOR
    }

    if (
      lineId === DEFAULT_ANALYZE_PREDICTION_FIELD ||
      lineId === DEFAULT_LIVE_MONITORING_PREDICTION_FIELD ||
      lineId === DEFAULT_BACKTEST_PREDICTION_FIELD ||
      lineId === DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD
    ) {
      return palette.new.pink
    }

    if (lineId === DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD) {
      return palette.new.versatile_violet
    }

    return palette.new.black
  }

  const colorIndex = (options.selectedRows || []).findIndex((row) => String(row) === lineId)
  const indexToUse = options.hasGroupingEnabled ? colorIndex : lineIndex

  if (options.useColorWay) {
    if (options.colorWayOpacity) {
      return `${INSIGHTS_COLOR_WAY[indexToUse].base}${options.colorWayOpacity}`
    }

    return INSIGHTS_COLOR_WAY[indexToUse].base
  }

  return palette.new.black
}

/**
 * Returns the stroke width for the line
 *
 * @param lineId Line ID
 * @param hasGroupingEnabled if grouping is enabled
 *
 * @returns stroke width
 */
export const getInsightsLineStrokeWidth = (lineId: string) => {
  if (lineId === INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD) {
    return INSIGHTS_LAST_YEAR_TRUTH_LINE_STROKE_WIDTH
  }

  if (
    lineId === DEFAULT_ANALYZE_PREDICTION_FIELD ||
    lineId === DEFAULT_LIVE_MONITORING_PREDICTION_FIELD ||
    lineId === DEFAULT_BACKTEST_PREDICTION_FIELD ||
    lineId === DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD ||
    lineId === DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD
  ) {
    return INSIGHTS_PREDICTION_LINE_STROKE_WIDTH
  }

  return INSIGHTS_TRUTH_LINE_STROKE_WIDTH
}

/**
 * Returns the stroke dasharray for the line
 * @param lineId Line ID
 * @returns stroke dasharray
 */
export const getInsightsLineStrokeDashArray = (lineId: string) => {
  if (lineId === DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD) {
    return INSIGHTS_BASELINE_STORKE_DASHARRAY
  }

  return ''
}

/**
 * Returns the line label
 * @param intl React Intl
 * @param line Insight chart line item
 * @param prediction Is prediction line
 * @returns Line label
 */
export const getInsightsLineLabel = (intl: IntlShape, line: Insights.BaseChartLineItem, targetName = '') => {
  if ([
    DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD,
  ].includes(line.id)) {
    return intl.formatMessage({
      id: 'insights.chart.baselineResults',
    }, {
      name: targetName,
    })
  }

  if ([
    DEFAULT_ANALYZE_TARGET_FIELD,
    DEFAULT_BACKTEST_TARGET_FIELD,
    DEFAULT_LIVE_MONITORING_TARGET_FIELD,
  ].includes(line.id)) {
    return intl.formatMessage({
      id: 'insights.chart.actualResults',
    }, {
      name: targetName,
    })
  }

  if ([
    DEFAULT_BASELINE_COMPARISON_PREDICTION_FIELD,
  ].includes(line.id)) {
    return intl.formatMessage({
      id: 'insights.chart.paretosResults',
    }, {
      name: targetName,
    })
  }

  if ([
    DEFAULT_ANALYZE_PREDICTION_FIELD,
    DEFAULT_BACKTEST_PREDICTION_FIELD,
    DEFAULT_LIVE_MONITORING_PREDICTION_FIELD,
  ].includes(line.id)) {
    return intl.formatMessage({
      id: 'insights.chart.predictionResults',
    }, {
      name: targetName,
    })
  }

  if ([
    INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD,
  ].includes(line.id)) {
    return intl.formatMessage({
      id: 'insights.chart.lastYearResults',
    }, {
      name: targetName,
    })
  }

  return line.label
}

/**
 * Returns the key for the prediction payload
 * @param id Key
 * @returns Returns the key for the prediction payload
 */
export const generatePredictionPayloadKey = (id: string, prefix = DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX) => `${prefix}_${id}`

/**
 * Returns the key for the prediction payload
 * @param id Key
 * @returns Returns the key for the prediction payload
 */
export const generateAbsDeviationPayloadKey = (id: string, prefix = DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX) => `${prefix}_${id}`

/**
 * Returns the key for the prediction payload
 * @param id Key
 * @returns Returns the key for the prediction payload
 */
export const generateLastYearPayloadKey = (id: string, prefix = INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD_PREFIX) => `${prefix}_${id}`

/**
 * Returns the key for the baseline comparison payload
 * @param id Key
 * @param prefix Prefix
 * @returns Returns the key for the baseline comparison payload
 */
export const generateBaselinePayloadKey = (id: string, prefix = DEFAULT_BASELINE_COMPARISON_BASELINE_FIELD_PREFIX) => `${prefix}_${id}`

/**
 * Returns the class name for the grouping row
 * @param rowIndex Row index
 * @returns Class name
 */
export const generateGroupingRowClassName = (rowIndex: number) => {
  return `insights-row-${rowIndex}`
}

/**
 * Aggregation model transformer for insights table
 * @param currentAggregationModel Current aggregation model
 * @param newAggregationModel New aggregation model
 * @param fields Fields
 * @returns Synced aggregation model
 */
export const aggregationModelTransformer = (
  currentAggregationModel: GridAggregationModel,
  newAggregationModel: GridAggregationModel,
  fields: {
    target: string,
    prediction: string,
  },
) => {
  /**
   * We change the aggregation model for both target and prediction columns at the same time
   */
  const prevTargetAggregation = currentAggregationModel[fields.target]
  const newTargetAggregation = newAggregationModel?.[fields.target]
  const newPredictionAggregation = newAggregationModel?.[fields.prediction]
  const aggregationToUse = prevTargetAggregation === newTargetAggregation ? newPredictionAggregation : newTargetAggregation
  const syncAggregationModel = {
    [fields.target]: aggregationToUse,
    [fields.prediction]: aggregationToUse,
  }

  return syncAggregationModel
}

/**
 * Returns data grid columns from Hera columns
 *
 * @param columns Columns definition
 * @param specialColumns Special columns to be treated differently (e.g. target)
 * @param disableAggregation Disable aggregation, otherwise special columns will be aggregable
 *
 * @returns Data grid columns
 */
export const getDataGridColumnsFromHeraColumns = ({
  columns,
  specialColumnNames,
  disableAggregation,
}: {
  columns: Hera.APITableColumnDefinition[],
  specialColumnNames: string[]
  disableAggregation?: boolean
}) => {
  return columns.map((column) => {
    const field = column.name
    const type = convertAPIFieldTypeToMUIFieldType(column.type)
    const isSpecialColumn = specialColumnNames.includes(field)

    return {
      field,
      type,
      headerName: column.label,
      pinnable: !isSpecialColumn,
      hideable: !isSpecialColumn,
      groupable: !isSpecialColumn,
      aggregable: disableAggregation ? false : isSpecialColumn,
    }
  }) as Insights.SimplifiedGridColumnDefinition[]
}

/**
 * Returns the target name from the column definitions
 *
 * @param columns Column definitions
 * @param defaultValue Default value for the target name
 *
 * @returns Target name
 */
export const getTargetNameFromColumnDefinitions = (columns: Hera.APITableColumnDefinition[], defaultValue = '') => {
  const targetColumns = columns.filter((column) => column.isTarget)
  const targetColumnsNames = targetColumns.map((column) => column.name)

  return targetColumns[0]?.label || targetColumnsNames[0] || defaultValue
}

/**
 * Returns the chart definition
 *
 * @param dataPoints list of data points
 * @param gridState grid state
 * @param baseLegend base legend
 * @param groupingLegend grouping legend
 * @param targetField target field
 * @param predictionPrefix prediction prefix
 * @param deviationPrefix deviation prefix
 * @param timeResolution time resolution
 * @param shouldEnrichWithVirtualTarget if true, the truth line will be extended to cover the whole time period
 *
 * @returns lines definition and dataset
 */
export function processChartData<
  TState extends Insights.BaseGridState,
>({
  dataPoints,
  lastYearDataPoints,
  gridState,
  baseLegend,
  targetPayloadKey,
  predictionPayloadKey,
  predictionGroupingPayloadPrefix,
  groupingLegend,
  timeResolution,
  shouldEnrichWithVirtualTarget,
}: {
  dataPoints: Hera.APIChartDatapoint[]
  lastYearDataPoints: Hera.APIChartDatapoint[]
  gridState: TState
  shouldEnrichWithVirtualTarget?: boolean
  baseLegend: string[]
  predictionGroupingPayloadPrefix: string
  targetPayloadKey: string
  predictionPayloadKey: string
  groupingLegend: Hera.APIChartMetaData['legend']
  timeResolution: TIME_RESOLUTION
}) {
  const selectedRows = gridState.rowSelectionModel || []
  const groupingModel = gridState.groupingModel || []
  const hasGrouping = groupingModel.length > 0
  const keys = hasGrouping ? Object.keys(groupingLegend) : baseLegend

  /**
   * For grouping: Sort the legend alphabetically.
   * Filter out the keys that are not selected if grouping is enabled.
   */
  const finalKeys = hasGrouping ? keys.filter((key) => {
    return selectedRows.includes(String(key))
  }).sort((a, b) => {
    return groupingLegend[a] > groupingLegend[b] ? 1 : -1
  }) : (
    keys
  )

  /**
   * Create the base lines definition for the chart.
   */
  const lines: Insights.BaseChartLineItem[] = finalKeys.map((key, index) => {
    return {
      id: key,
      label: groupingLegend[key] || key,
    }
  })

  const firstDate = dateStringToUnixTimestamp(get(dataPoints, '[0].date', ''))
  const lastDate = dateStringToUnixTimestamp(get(dataPoints, `[${dataPoints.length - 1}].date`, ''))
  const firstYear = moment.unix(firstDate).year()
  const lastYear = moment.unix(lastDate).year()
  /**
   * Some of the events might appear in previous year and continue in the current year.
   * We need to include the events from the previous year to cover the whole time period.
   */
  const events = getEventsDatesByYearRange(firstYear - 1, lastYear)

  const dataset = dataPoints.map((item, index) => {
    const unixDate = dateStringToUnixTimestamp(item.date)
    const eventsAtDate = getEventsAtDate(events, timeResolution, unixDate)

    const baseDatapoint = {
      ...item,
      date: unixDate,
      events: eventsAtDate,
    } as Insights.BaseChartDatasetItem

    /**
     * Enrich the datapoint with last year's data if available.
     */
    Object.assign(baseDatapoint, enrichWithLastYearData({
      datapoint: item,
      lastYearDataPoints,
      hasGrouping,
      targetPayloadKey,
      groupingTargetKeys: finalKeys,
      timeResolution,
    }))

    /**
     * Enrich the datapoint with the virtual target if enabled.
     * This is used to extend the truth line to cover the whole time period.
     * This is only used for the last datapoint of the truth line.
     *
     * Details: PD-9258 | Cockpit | Analyze view | Get rid of the gap between Truth and Prediction line
     */
    if (shouldEnrichWithVirtualTarget) {
      Object.assign(baseDatapoint, enrichWithVirtualTarget({
        datapoint: item,
        prevDatapoint: dataPoints[index - 1],
        hasGrouping,
        targetPayloadKey,
        predictionGroupingPayloadPrefix,
        predictionPayloadKey,
        groupingTargetKeys: finalKeys,
      }))
    }

    return baseDatapoint
  })

  return { lines, dataset, events }
}

/**
 * Enriches a chart datapoint with a virtual target to eliminate gaps between
 * the "truth" and "prediction" lines in a time-series chart.
 *
 * This function examines the current and previous datapoints to determine
 * whether a virtual target should be added to bridge the gap in the truth line.
 * It supports both grouped and non-grouped data structures.
 *
 * @param {Object} params - The function parameters.
 * @param {Hera.APIChartDatapoint} params.datapoint - The current chart datapoint being processed.
 * @param {Hera.APIChartDatapoint} [params.prevDatapoint] - The previous chart datapoint, if available.
 * @param {boolean} params.hasGrouping - Whether the chart data is grouped by categories.
 * @param {string} params.targetPayloadKey - The key for the "truth" value in the datapoint.
 * @param {string} params.predictionPayloadKey - The key for the "prediction" value in the datapoint.
 * @param {string} params.predictionGroupingPayloadPrefix - The prefix for prediction keys in grouped data.
 * @param {string[]} params.groupingTargetKeys - The keys for the target values in grouped data.
 *
 * @returns {Object} - An enrichment object containing the virtual target if applicable, or an empty object.
 *                     If a virtual target is added, the object includes:
 *                     - The key-value pair for the target.
 *                     - A `virtualTarget: true` flag indicating the target is virtual.
 */
export const enrichWithVirtualTarget = ({
  datapoint,
  prevDatapoint,
  hasGrouping,
  predictionPayloadKey,
  groupingTargetKeys,
  targetPayloadKey,
  predictionGroupingPayloadPrefix,
} : {
  datapoint: Hera.APIChartDatapoint
  prevDatapoint?: Hera.APIChartDatapoint
  hasGrouping: boolean
  targetPayloadKey: string
  predictionPayloadKey: string
  predictionGroupingPayloadPrefix: string
  groupingTargetKeys: string[]
}) => {
  if (hasGrouping) {
    const baseGroupingEnrichment = {}

    groupingTargetKeys.forEach((targetKey) => {
      const groupingPredictionPayloadKey = generatePredictionPayloadKey(targetKey, predictionGroupingPayloadPrefix)
      const hasPredictionValue = datapoint[groupingPredictionPayloadKey] !== null
      const hasTargetValue = datapoint[targetKey] !== null
      const hasPrevPredictionValue = prevDatapoint?.[groupingPredictionPayloadKey] !== null
      const hasPrevTargetValue = prevDatapoint?.[targetKey] !== null

      if ((hasPredictionValue && hasTargetValue) || (hasPrevPredictionValue) || !hasPrevTargetValue) {
        return {}
      }

      if (hasPrevTargetValue && !hasPrevPredictionValue && hasPredictionValue) {
        Object.assign(baseGroupingEnrichment, {
          [targetKey]: prevDatapoint?.[targetKey],
          virtualTarget: true,
        })
      }

      return {}
    })

    return baseGroupingEnrichment
  }

  const hasPredictionValue = datapoint[predictionPayloadKey] !== null
  const hasTargetValue = datapoint[targetPayloadKey] !== null
  const hasPrevPredictionValue = prevDatapoint?.[predictionPayloadKey] !== null
  const hasPrevTargetValue = prevDatapoint?.[targetPayloadKey] !== null

  if ((hasPredictionValue && hasTargetValue) || (hasPrevPredictionValue) || !hasPrevTargetValue) {
    return {}
  }

  if (hasPrevTargetValue && !hasPrevPredictionValue && hasPredictionValue) {
    return {
      [targetPayloadKey]: prevDatapoint?.[targetPayloadKey],
      virtualTarget: true,
    } as Insights.BaseChartDatasetItem
  }

  return {}
}

/**
 * Enriches a chart datapoint with last year's data if available.
 *
 * @param {Object} params - Parameters for enrichment.
 *
 * @param {Hera.APIChartDatapoint} params.datapoint - The current year's datapoint.
 * @param {Hera.APIChartDatapoint[]} params.lastYearDataPoints - Array of last year's datapoints.
 * @param {boolean} params.hasGrouping - Whether grouping is enabled.
 * @param {string} params.targetPayloadKey - Field to target for last year's data.
 * @param {TIME_RESOLUTION} params.timeResolution - Resolution of the prediction.
 *
 * @returns {Partial<Insights.BaseChartDatasetItem>} Enriched datapoint with last year's data.
 */
export const enrichWithLastYearData = ({
  datapoint,
  lastYearDataPoints,
  hasGrouping,
  groupingTargetKeys,
  targetPayloadKey,
  timeResolution,
}: {
  datapoint: Hera.APIChartDatapoint
  lastYearDataPoints: Hera.APIChartDatapoint[]
  timeResolution: TIME_RESOLUTION
  hasGrouping: boolean
  groupingTargetKeys: string[]
  targetPayloadKey: string
}): Partial<Insights.BaseChartDatasetItem> => {
  const nulledGrouping = groupingTargetKeys.reduce((acc, key) => {
    const lastYearKey = generateLastYearPayloadKey(key)
    acc[lastYearKey] = null

    return acc
  }, {} as Record<string, unknown>)

  const nulledRegular = {
    [INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD]: null,
  }

  if (!lastYearDataPoints.length) {
    if (hasGrouping) {
      return nulledGrouping
    }

    return nulledRegular
  }

  const date = moment.utc(datapoint.date)

  const lastYearDatapoint = lastYearDataPoints.find((lastYearPoint) => {
    if (!date || !lastYearPoint.date) {
      return undefined
    }

    const lastYearDate = date.clone().subtract(1, 'year')

    /**
     * Check if the datapoint is from the same week.
     * Because Monday might be different day of the week in last year.
     */
    if (timeResolution === TIME_RESOLUTION.WEEKLY) {
      const lastYearMoment = moment(lastYearPoint.date)

      return lastYearMoment.isoWeek() === date.isoWeek()
    }

    return moment(lastYearPoint.date).isSame(lastYearDate, 'day')
  })

  if (!lastYearDatapoint) {
    if (hasGrouping) {
      return nulledGrouping
    }

    return nulledRegular
  }

  if (hasGrouping) {
    return groupingTargetKeys.reduce((acc, key) => {
      const lastYearKey = generateLastYearPayloadKey(key)

      acc[lastYearKey] = lastYearDatapoint[key] ?? null

      return acc
    }, {} as Record<string, unknown>)
  }

  return {
    [INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD]: lastYearDatapoint[targetPayloadKey] ?? null,
  }
}

/**
 * Creates the grid state change handler
 *
 * @param getGridState Function to get the grid state
 * @param getTableDetails Function to get the table details
 * @param receiveGridStateChangeAction Action to receive the grid state change
 * @param requestTableAction Action to request the table data
 * @param requestChartAction Action to request the chart data
 * @param receiveTableAction Action to receive the table action done
 *
 * @returns Generator function for the grid state change handler
 */
export function createGridStateChangeHandler<
  TPayload extends Insights.BaseGridStateChangePayload,
  TState extends Insights.BaseGridState,
  TTable extends { useCaseId: string, tableId: string }
>({
  getGridState,
  getTableDetails,
  receiveGridStateChangeAction,
  requestTableAction,
  requestChartAction,
  receiveTableAction,
}: {
  getGridState: (state: State) => TState | undefined
  getTableDetails: (state: State) => TTable | undefined
  receiveGridStateChangeAction: (payload: TState) => any
  requestTableAction: (payload: any) => any
  requestChartAction: (payload: any) => any
  receiveTableAction: (payload: any) => any
}) {
  return function* gridStateChangeHandler({ payload }: ActionPayload<TPayload>) {
    try {
      const {
        requestTableData = true,
        requestChartData = true,
        resetPagination = true,
        resetRowSelection = false,
        preselectRows = false,
        ...newGridState
      } = payload

      const state: State = yield select()
      const gridState: TState = yield call(getGridState, state)
      const tableDetails: TTable = yield call(getTableDetails, state)

      /**
       * Reset the pagination if requested.
       * Page size has to be restored to persisted value.
       */
      if (resetPagination) {
        Object.assign(newGridState, {
          paginationModel: {
            pageSize: newGridState?.paginationModel?.pageSize || gridState?.paginationModel?.pageSize,
            page: 0,
          },
        })
      }

      /**
       * Reset the row selection if requested.
       * If preselect rows is set, we need to reset the row selection model.
       * Since row preselection is done only for grouping, and works in 'include' mode,
       */
      if (resetRowSelection || preselectRows) {
        Object.assign(newGridState, {
          rowSelectionModel: [],
          rowSelectionModelMode: preselectRows ? 'include' : 'exclude',
        })
      }

      /**
       * If the table data is not requested meaning that the grid state is changed by actions like pinning columns, changing column visibility etc.
       * We need to store the internal column visibility model, since it is used to restore it when toggling the row grouping.
       */
      if (!requestTableData) {
        Object.assign(newGridState, {
          internalColumnVisibilityModel: newGridState.columnVisibilityModel,
        })
      }

      const finalState = {
        ...gridState,
        ...newGridState,
      }

      yield put(receiveGridStateChangeAction(finalState))

      const tableRequest = {
        useCaseId: tableDetails.useCaseId,
        ...finalState,
      }

      if (requestTableData) {
        yield put(requestTableAction(tableRequest))
      }

      const chartRequest = {
        useCaseId: tableDetails.useCaseId,
        preselectRows,
        ...finalState,
      }

      if (requestChartData) {
        yield put(requestChartAction(chartRequest))
      }

      storeTableStateInStorage(tableDetails.tableId, finalState)
    } catch (e) {
      const message = parseAndReportErrorResponse(e, payload)

      yield put(receiveTableAction({}))

      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }
  }
}

/**
 * Creates the chart request handler
 * @param startFetchingAction Start fetching action
 * @param stopFetchingAction Stop fetching action
 * @param getTableFetchingState Function to get the table fetching state
 * @param requestChartAPI Function to request the chart API
 * @param receiveChartAction Action to receive the chart action
 * @param requestGridStateChangeAction Action to request the grid state change
 * @param convertAPIPayload Function to convert the API payload
 * @param receiveGridStateChangeType Type to receive the grid state change
 * @param receiveTableActionType Type to receive the table action
 *
 * @returns Generator function for the chart request handler
 */
export function createChartRequestHandler<
  TPayload extends Insights.BaseChartRequestPayload,
  TRequest extends Hera.BaseChartAPIRequest,
  TResponse extends Hera.BaseChartAPIResponse,
  TStateChangePayload extends Insights.BaseGridStateChangePayload
>({
  startFetchingAction,
  stopFetchingAction,
  getTableFetchingState,
  requestChartAPI,
  receiveChartAction,
  requestGridStateChangeAction,
  convertAPIPayload,
  includeLastYear,
  receiveGridStateChangeType,
  receiveTableActionType,
  ignoreNoInsightsAvailableError,
}: {
  startFetchingAction: () => any
  stopFetchingAction: () => any
  getTableFetchingState: (state: State) => boolean
  requestChartAPI: (payload: TRequest) => Promise<TResponse>
  receiveChartAction: (apiResponse: {
    noInsightsAvailable?: boolean
    response: TResponse | {}
    lastYearResponse?: TResponse | {}
    timeResolution: TIME_RESOLUTION
  }) => any
  requestGridStateChangeAction: (payload: TStateChangePayload) => any
  convertAPIPayload: (payload: TPayload, shouldPreselectRows: boolean) => TRequest
  includeLastYear: boolean
  receiveGridStateChangeType: string
  receiveTableActionType: string
  ignoreNoInsightsAvailableError?: boolean
}) {
  return function* chartRequestHandler({ payload }: ActionPayload<TPayload>): any {
    try {
      yield put(startFetchingAction())

      const shouldPreselectRows = Boolean(payload.preselectRows || (payload.initialization && ((payload.groupingModel || []).length > 0)))
      const reqPayload: TRequest = yield call(convertAPIPayload, payload, shouldPreselectRows)
      const lastYearPayload: TRequest = yield call(convertAPIPayload, getChartLastYearRequestPayload<TPayload>(payload), shouldPreselectRows)
      const parallelRequests = [call(requestChartAPI, reqPayload)]

      /**
       * Include the last year data if the date range is set.
       * Do not include the last year data if the date range is Since Inception.
       */
      if (includeLastYear) {
        parallelRequests.push(call(requestChartAPI, lastYearPayload))
      }

      const [response, lastYearResponse] = yield all(parallelRequests)

      /**
       * Wait for the table data to be fetched before showing the chart.
       * The information from the table is used to preselect the rows in the chart.
       */
      const state: State = yield select()
      const demandProblemDefinition: UseCase.DemandProblemDefinition = yield call(getDemandProblemDefinition, state)
      const isFetchingTable: boolean = yield call(getTableFetchingState, state)

      if (isFetchingTable) {
        yield take(receiveTableActionType)
      }

      /**
       * In case the rows should be preselected, we need to adjust the grid state before showing the chart.
       * No need to fetch the table & chart data again, since it was already fetched.
       */
      if (shouldPreselectRows) {
        const preselectedRows = Object.keys(response.metaData.legend || {}).map((rowId) => String(rowId))

        yield put(requestGridStateChangeAction({
          resetPagination: false,
          requestTableData: false,
          requestChartData: false,
          rowSelectionModel: preselectedRows,
          rowSelectionModelMode: 'include',
        } as TStateChangePayload))

        yield take(receiveGridStateChangeType)
      }

      yield put(receiveChartAction({
        response,
        lastYearResponse,
        timeResolution: demandProblemDefinition.timeResolution || TIME_RESOLUTION.DAILY,
      }))
    } catch (e) {
      const message = parseAndReportErrorResponse(e, payload)
      const reason = getErrorReason(e)

      yield put(receiveChartAction({
        noInsightsAvailable: reason === NO_INSIGHTS_AVAILABLE,
        response: {},
        lastYearResponse: {},
        timeResolution: TIME_RESOLUTION.DAILY,

      }))

      if (ignoreNoInsightsAvailableError && reason === NO_INSIGHTS_AVAILABLE) {
        return
      }

      /**
       * Ignore the errors during preload.
       */
      if (!payload.initialization) {
        yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
      }
    } finally {
      yield put(stopFetchingAction())
    }
  }
}

/**
 * Creates the table request handler
 *
 * @param tableName Table name
 * @param tableVersion Table version
 * @param startFetchingAction Start fetching action
 * @param stopFetchingAction Stop fetching action
 * @param getInitialGridState Function to get the initial grid state
 * @param createGridState Function to create the grid state
 * @param requestTableAPI Function to request the table API
 * @param receiveTableAction Action to receive the table action done
 * @param retryAction Retry action
 * @param convertAPIPayload Function to convert the API payload
 *
 * @returns Generator function for the table request handler
 */
export function createTableRequestHandler<
  TPayload extends Insights.BaseTableRequestPayload,
  TRequest extends Hera.BaseTablePaginatedAPIRequest,
  TResponse extends Hera.BaseTablePaginatedAPIResponse,
  TState extends Insights.BaseGridState,
>({
  tableName,
  tableVersion,
  ignoreNoInsightsAvailableError,
  startFetchingAction,
  stopFetchingAction,
  getInitialGridState,
  createGridState,

  requestTableAPI,
  receiveTableAction,
  retryAction,
  convertAPIPayload,
}: {
  tableName: DATA_GRIDS
  tableVersion: number
  ignoreNoInsightsAvailableError?: boolean
  startFetchingAction: () => any
  stopFetchingAction: () => any
  getInitialGridState: (payload: TState) => any
  createGridState: (tableId: string, initialState: TState) => any

  requestTableAPI: (payload: TRequest) => Promise<TResponse>
  receiveTableAction: (apiResponse: TResponse | {}) => any
  retryAction: (payload: { useCaseId: string }) => any
  convertAPIPayload: (payload: TPayload) => TRequest
}) {
  return function* tableRequestHandler({ payload }: ActionPayload<any>) {
    const tableId: string = yield call(getDataGridId, tableName, tableVersion, payload.useCaseId)
    const tableFromStorage: TState = yield call(getTableStateFromStorage, tableId)
    const hasTableStateInStorage = Boolean(tableFromStorage)

    try {
      yield put(startFetchingAction())

      const initialGridState: TState = yield call(getInitialGridState, payload)

      const gridStateFromStorage: TState = yield call(createGridState, tableId, initialGridState)

      const reqPayload: TRequest = yield call(convertAPIPayload, payload)

      const response: TResponse = yield call(requestTableAPI, reqPayload)

      const reducerPayload = {
        useCaseId: payload.useCaseId,
        tableId,
        response,
        gridInitialState: gridStateFromStorage,
        initialization: payload.initialization,
      }

      yield put(receiveTableAction(reducerPayload))
    } catch (e) {
      const reason = getErrorReason(e)

      if (ignoreNoInsightsAvailableError && reason === NO_INSIGHTS_AVAILABLE) {
        yield put(receiveTableAction({
          noInsightsAvailable: true,
        }))

        return
      }

      /**
       * Sometimes the table state might be broken or outdated. This might cause the table request to fail.
       * If the table state is in the storage, we remove it and retry the action.
       */
      if (hasTableStateInStorage) {
        removeTableStateFromStorage(tableId)

        yield put(retryAction({ useCaseId: payload.useCaseId }))
      } else {
        const message = parseAndReportErrorResponse(e, payload)

        yield put(receiveTableAction({}))

        /**
         * Ignore the errors during preload.
         */
        if (!payload.initialization) {
          yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
        }
      }
    } finally {
      yield put(stopFetchingAction())
    }
  }
}

/**
 * Creates reset view generator
 *
 * @param receiveTableAction Action to receive the table action done
 * @param receiveChartAction Action to receive the chart action
 *
 * @returns Generator function for the reset view generator
 */
export function createResetViewGenerator({
  receiveTableAction,
  receiveChartAction,
}: {
  receiveTableAction: (payload: {}) => any
  receiveChartAction: (payload: {}) => any
}) {
  return function* resetViewGenerator() {
    yield put(receiveTableAction({}))

    yield put(receiveChartAction({}))
  }
}

/**
 * Default formatter for the insights tooltip value
 *
 * @param intl IntlShape
 * @param value datapoint value
 * @param options formatter options
 * @param options.showIntervals show intervals for float values
 * @param options.showSign show sign
 * @param options.showAbs show absolute value
 *
 * @returns formatted value
 */
export const defaultInsightsTooltipValueFormatter = (
  intl: IntlShape,
  value: number | null,
  options: {
    showIntervals: boolean
    showSign?: boolean
    showAbs?: boolean
  },
) => {
  const MIN_INTERVAL = 0
  const MAX_INTERVAL = 3

  if (value === null) {
    return intl.formatMessage({ id: 'common.na' })
  }

  const {
    showIntervals,
    showSign,
    showAbs,
  } = options || {}

  /**
   * In case value is float, we want to show the interval. Only for limited range of values.
   */
  if (showIntervals && !Number.isInteger(value) && value >= MIN_INTERVAL && value <= MAX_INTERVAL) {
    const formattedFrom = Math.floor(value)
    const formattedTo = formattedFrom + 1

    return intl.formatMessage({ id: 'insights.chart.tooltip.interval' }, { from: formattedFrom, to: formattedTo })
  }

  const finalValue = showAbs ? Math.abs(value) : value
  const formattedValue = defaultNumberFormatter(finalValue, { intl })

  if (showSign) {
    return value > 0 ? `+${formattedValue}` : formattedValue
  }

  return formattedValue
}

/**
 * Returns the chart last year request payload
 * @param payload Request payload
 * @returns Chart request payload
 */
export function getChartLastYearRequestPayload<TPayload extends Insights.BaseGridState>(payload: TPayload) {
  if (!payload.timeWindow) {
    return payload
  }

  const startDate = payload.timeWindow[0] ? moment(payload.timeWindow[0]).subtract(1, 'year') : null
  const endDate = payload.timeWindow[1] ? moment(payload.timeWindow[1]).subtract(1, 'year') : null

  return {
    ...payload,
    timeWindow: [
      startDate,
      endDate,
    ],
  }
}

/**
 * Checks if the data preload is available
 *
 * @param useCaseIdInTableState Use case id in table state
 * @param useCaseIdInPayload Use case id in payload
 * @param demandUseCaseIdInState Demand use case id in state
 * @param isPreloadRequest Is preload request
 *
 * @returns True if the data preload is available, false otherwise
 */
export const isDataPreloadAvailable = ({
  useCaseIdInTableState,
  useCaseIdInPayload,
  demandUseCaseIdInState,
  isPreloadRequest,
}: {
  useCaseIdInTableState: string
  useCaseIdInPayload: string
  demandUseCaseIdInState?: string | null
  isPreloadRequest?: boolean
}) => {
  if (((useCaseIdInTableState === useCaseIdInPayload) || (useCaseIdInTableState === demandUseCaseIdInState)) && !isPreloadRequest) {
    return true
  }

  return false
}

/**
 * Checks if the use case is suitable for insights
 *
 * @param useCase Use case
 *
 * @returns True if the use case is suitable for insights, false otherwise
 */
export const isUseCaseSuitableForInsights = (useCase: UseCase.DetailsExtended) => {
  return isRecommendationFamilyCheck(useCase.family) ? Boolean(useCase.demandUseCaseId) : true
}

/**
 * Formats the relative deviation
 * @param intl Intl
 * @param value Value
 *
 * @returns Formatted relative deviation
 */
export const formatRelativeDeviation = (intl: IntlShape, value: number | null) => {
  if (value === null) {
    return null
  }

  return `${defaultNumberFormatter(value * 100, { intl })}%`
}
