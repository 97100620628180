import React, { useState } from 'react'
import get from 'lodash.get'

import { FormikState, getIn } from 'formik'
import { useIntl } from 'react-intl'
import { Typography, Box, useTheme } from '@mui/material'
import { useDispatch, useSelector } from '@redux/hooks'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import TextFieldComponent from '@base/forms/TextField'
import { TOAST_TYPE_ERROR } from '@constants/common.constants'

import * as API from '@redux/modules/hermes/hermes.api'
import { HERMES_DIRECTIONS } from '@constants/hermes.constants'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { requestDeleteConnectionAction } from '@redux/modules/hermes/hermes.actions'
import { getSelectedCustomerId, getSelectedCompanyId, getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'

import {
  isOAuthDone, useOAuthListener,
  openOAuthPopup, setObjectValuesByPaths,
  checkifOAuthRequired,
} from '@utils/connectors.utils'

import ButtonComponent from '@base/buttons/Button'

import GoogleAuthButtonComponent from '@base/buttons/GoogleAuthButton'

import { SOURCE_TYPES, SOURCE_TYPES_OPTIONS, SourceTypeOption } from '@constants/flow.constants'

export interface ConnectorConfigurationContainerProps extends Partial<FormikState<any>> {
  /**
   * If the form is customer facing
   */
  customerFacing?: boolean,
  /**
   * The connection id
   */
  connectionId?: string,
  /**
   * If the form is shown
   */
  isOpen?: boolean,
  /**
   * If the form is in edit mode
   */
  edit?: boolean,
  /**
   * The source details
   */
  source: Hermes.SourceDetails,
  /**
   * The function to set form values
   */
  setFormValues: {
    (payload: any): void,
  },
  /**
   * The function to delete the connection
   */
  deleteCallback?: {
    (): void,
  },
  /**
   * The function to handle input change
   */
  onChange: any,
  /**
   * The function to handle input blur
   */
  onBlur: any,
}

const ConnectorConfigurationContainer: React.FC<ConnectorConfigurationContainerProps> = ({
  values = {},
  touched,
  errors,
  onChange,
  onBlur,
  source,
  customerFacing,
  edit,
  isOpen,
  connectionId,
  deleteCallback,
  setFormValues,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [oauthError, setOauthError] = useState(false)
  const customerId = useSelector(getSelectedCustomerId)
  const companyId = useSelector(getSelectedCompanyId)
  const { useCaseId } = useSelector(getUseCaseItem)
  const isAdmin = useSelector(getIsAdmin)

  const isAuthDone = isOAuthDone(values)
  const isOAuthRequired = checkifOAuthRequired(source)
  const sourceId = source?.sourceId || values.sourceId

  useOAuthListener(sourceId, (token: any) => {
    if (!isOpen) {
      return
    }

    const configuration = { ...values.configuration }

    setObjectValuesByPaths(token, configuration)

    setFormValues({
      ...values,
      configuration,
    })
  }, HERMES_DIRECTIONS.SOURCE)

  const handleOAuthClick = async () => {
    setOauthError(false)

    try {
      const link = await API.getOAuthLink(customerId, sourceId, HERMES_DIRECTIONS.SOURCE)
      openOAuthPopup(link)
    } catch (e: any) {
      dispatch(changeToastAction({ message: e.message, severity: TOAST_TYPE_ERROR }))

      setOauthError(true)
    }
  }

  const handleDeleteConnection = () => {
    dispatch(
      requestDeleteConnectionAction({
        companyId,
        connectionId: connectionId!,
        useCaseId,
      }),
    )

    deleteCallback && deleteCallback()
  }

  const getInputType = (typeProperty: string) => {
    let type = 'text'
    if (typeProperty === 'integer') {
      type = 'number'
    } else if (typeProperty === 'password') {
      type = 'password'
    } else if (typeProperty === 'date') {
      type = 'date'
    }

    return type
  }

  const renderField = ([name, properties]: [string, Hermes.ConfigurationFormField]) => {
    const inputType = getInputType(properties?.type)
    const isDate = (inputType === 'date')
    const isHiddenField = (isDate && customerFacing) || ((properties?.hidden ?? false) && customerFacing) || ((properties?.adminOnly ?? false) && !isAdmin)
    const additionalProps = !isDate ? {} : {
      InputLabelProps: {
        shrink: true,
      },
    }

    const field = (
      <TextFieldComponent
        key={name}
        name={`configuration.${name}`}
        type={inputType}
        label={properties?.label}
        floatingHelp={properties?.description?.text}
        touched={Boolean(touched?.configuration)}
        errors={getIn(errors, `configuration.${name}`)}
        value={get(values.configuration, `${name}`) ?? ''}
        onChange={onChange}
        onBlur={onBlur}
        hidden={isHiddenField}
        {...additionalProps}
      />
    )

    return isHiddenField ? (
      field
    ) : (
      <FormLayoutItem xs={12} key={name}>
        {field}
      </FormLayoutItem>
    )
  }

  const renderOAuthButton = (label: string) => {
    const sourceType = SOURCE_TYPES_OPTIONS.find((st) => st.sourceId === sourceId) || ({} as SourceTypeOption)
    const isGoogleOAuth =
      sourceType.value === SOURCE_TYPES.GOOGLE_ADS ||
      sourceType.value === SOURCE_TYPES.GOOGLE_SHEETS ||
      sourceType.value === SOURCE_TYPES.GOOGLE_ANALYTICS ||
      sourceType.value === SOURCE_TYPES.GOOGLE_ANALYTICS_V4

    if (isGoogleOAuth) {
      return (
        <GoogleAuthButtonComponent
          onClick={handleOAuthClick}
        />
      )
    }

    return (
      <ButtonComponent
        name='oAuthButton'
        onClick={handleOAuthClick}
        color='primary'
        label={label}
      />
    )
  }

  return (
    <>
      {source && source.configurationForm?.map((block) => {
        return (
          <FormLayoutContainer
            key={block.title}
          >
            <FormLayoutItemsContainer
              title={customerFacing ? undefined : block.title}
              divider={!customerFacing}
            >
              {
                Object.entries(block.fields).map(renderField)
              }

              {
                block.oauth ? (
                  <FormLayoutItem xs={12}>
                    <Box
                      sx={{
                        display: isAuthDone ? 'none' : 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        {renderOAuthButton(block.oauth.label)}
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '18px',
                            color: '#C730BC',
                            ...(!oauthError ? { display: 'none' } : {}),
                          }}
                        >
                          {intl.formatMessage({ id: 'connect.modal.connect.error_oauth' })}
                        </Typography>
                      </Box>
                      {
                        (edit && customerFacing && connectionId) ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <ButtonComponent
                              name='removeDataSource'
                              color='secondary'
                              onClick={handleDeleteConnection}
                              label={intl.formatMessage({ id: 'connect.modal.connect.remove_data_source' })}
                            />
                          </Box>
                        ) : (
                          null
                        )
                      }
                    </Box>
                    <Box
                      sx={{
                        display: !isAuthDone ? 'none' : 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '18px',
                          color: theme.palette.new.black,
                        }}
                      >
                        {intl.formatMessage({ id: 'connect.modal.connect.success_oauth' })}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <ButtonComponent
                          name='reOauth'
                          color='primary'
                          onClick={handleOAuthClick}
                          label={intl.formatMessage({ id: 'connect.modal.connect.re_oauth' })}
                        />

                        {
                          (edit && customerFacing && connectionId) ? (
                            <Box
                              sx={{
                                marginLeft: theme.spacing(2),
                              }}
                            >
                              <ButtonComponent
                                name='removeDataSource'
                                color='primary'
                                onClick={handleDeleteConnection}
                                label={intl.formatMessage({ id: 'connect.modal.connect.remove_data_source' })}
                              />
                            </Box>
                          ) : (
                            null
                          )
                        }
                      </Box>
                    </Box>
                  </FormLayoutItem>
                ) : (
                  null
                )
              }
            </FormLayoutItemsContainer>
          </FormLayoutContainer>
        )
      })}

      {
        (!isOAuthRequired && edit && customerFacing && connectionId) ? (
          <FormLayoutContainer>
            <FormLayoutItemsContainer>
              <FormLayoutItem xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <ButtonComponent
                      name='removeDataSource'
                      color='primary'
                      onClick={handleDeleteConnection}
                      label={intl.formatMessage({ id: 'connect.modal.connect.remove_data_source' })}
                    />
                  </Box>
                </Box>
              </FormLayoutItem>
            </FormLayoutItemsContainer>
          </FormLayoutContainer>
        ) : (
          null
        )
      }
    </>
  )
}

export default ConnectorConfigurationContainer
